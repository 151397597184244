import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSendEmailVerificationCodeCorporateRegistration } from '@api/Corporate/corporateApi';
import { useCreatePassword, useValidatePassword } from '@api/Password/passwordApi';

import { useBoundStore } from '@stores/BoundStore';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import PasswordInfoBlock from '@components/PasswordInfoBlock/PasswordInfoBlock';

import Toast from '@elements/Toast/Toast';

import {
  CreatePasswordForm,
  CustomButton,
  CustomOnboardingStepNavigation,
  Heading,
  Label,
  PasswordErrorMessage,
  SubHeading,
  WeavrPassword,
} from './OnboardingCreatePassword.styles';

const OnboardingCreatePassword = () => {
  const [paths, setPaths] = useState({ REGISTER: false, RESTORE: false });
  const [passwordInputFocused, setPasswordInputFocused] = useState<boolean>(false);
  const [confirmPasswordInputFocused, setConfirmPasswordInputFocused] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useParams();

  const { t } = useTranslation();

  const isWeavrUiComponentLibraryLoaded = useBoundStore(
    (state) => state.isWeavrUiComponentLibraryLoaded
  );
  const setIsLoggedIn = useBoundStore((state) => state.setIsLoggedIn);
  const formRef = useRef<HTMLFormElement>(null); // Ref to our form component
  const secureFormRef = useRef<Form | null>(null); // Ref to window.OpcUxSecureClient.form()

  const { mutate: validatePassword } = useValidatePassword();
  const { mutate: createPasswordMutation } = useCreatePassword();
  const { mutate: emailVerificationCodeMutation } =
    useSendEmailVerificationCodeCorporateRegistration();

  const errorHandling = useMemo(
    () => ({
      403: () => {
        toast.error(
          <Toast
            title="Authentication Failed"
            message={t('403')}
          />
        );
      },
      409: (error: unknown) => {
        const { errorCode } = error as { errorCode: string };
        if (errorCode === 'UNRESOLVED_IDENTITY') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('unresolvedIdentity')}
            />
          );
        } else if (errorCode === 'PASSWORD_PROFILE_NOT_CONFIGURED_FOR_CREDENTIAL_TYPE') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('passwordProfileNotConfiguredForCredentialType')}
            />
          );
        } else if (errorCode === 'PASSWORD_TOO_SHORT') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordTooShort')}
            />
          );
        } else if (errorCode === 'PASSWORD_TOO_LONG') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordTooLong')}
            />
          );
        } else if (errorCode === 'PASSWORD_TOO_SIMPLE') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordTooSimple')}
            />
          );
        } else if (errorCode === 'PASSWORD_KEY_ALREADY_IN_USE') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordKeyAlreadyInUse')}
            />
          );
        } else if (errorCode === 'PASSWORD_ALREADY_CREATED') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordAlreadyCreated')}
            />
          );
        }
      },
    }),
    [t]
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errorHandling);

  useEffect(() => {
    if (!location.state) navigate(-1);

    if (location.state) {
      if (location.state.from) {
        setPaths({
          REGISTER: location.state.from === '/register/5',
          RESTORE: location.state.from === '/restore/2',
        });
      }
    }
  }, [location.state, navigate]);

  const handleRedirect = (destination: string) => {
    navigate(destination, {
      state: { from: location.pathname },
    });
  };

  useEffect(() => {
    if (isWeavrUiComponentLibraryLoaded && window.OpcUxSecureClient) {
      secureFormRef.current = window.OpcUxSecureClient.form();

      const passwordInput = secureFormRef.current.input('password', 'password', {
        placeholder: 'Password',
        maxlength: 30,
        style: {
          base: {
            fontSize: '16px',
            fontWeight: '500',
          },
        },
      });
      const confirmPasswordInput = secureFormRef.current.input(
        'confirmPassword',
        'confirmPassword',
        {
          placeholder: 'Confirm password',
          maxlength: 30,
          style: {
            base: {
              fontSize: '16px',
              fontWeight: '500',
            },
          },
        }
      );

      passwordInput.mount('#password');
      confirmPasswordInput.mount('#confirmPassword');
      confirmPasswordInput.on('submit', () => {
        if (formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      });
      passwordInput.on('focus', () => {
        setPasswordInputFocused(true);
      });
      passwordInput.on('blur', () => {
        setPasswordInputFocused(false);
      });
      confirmPasswordInput.on('focus', () => {
        setConfirmPasswordInputFocused(true);
      });
      confirmPasswordInput.on('blur', () => {
        setConfirmPasswordInputFocused(false);
      });
    }
  }, [isWeavrUiComponentLibraryLoaded]);

  useEffect(() => {
    const handleBackButton = () => {
      navigate(location.pathname);
    };

    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [location.pathname, navigate]);

  const toggleError = (message: string) => {
    const errorMessageElement = document.getElementById('passwordConfirmResponse');
    if (errorMessageElement) {
      errorMessageElement.innerText = message || '';
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (submitting) return;
    if (!secureFormRef.current) return;

    setSubmitting(true);
    if (!user) return;
    secureFormRef.current.tokenize(
      (response: TokenizedResponse) => {
        if (!response || !response.password || !response.confirmPassword) {
          setSubmitting(false);
          return;
        }

        const { password } = response;
        toggleError('');

        const decodedUser = JSON.parse(atob(user));

        validatePassword(password, {
          onSuccess: () => {
            createPasswordMutation(
              { userId: decodedUser.id, password },
              {
                onSuccess: (data) => {
                  if (data.token) {
                    const { token } = data;
                    setIsLoggedIn(token);
                    emailVerificationCodeMutation(decodedUser.email, {
                      onError: (error) => {
                        handleHTTPErrors([error]);
                        setSubmitting(false);
                      },
                    });
                    if (paths.REGISTER || !location.state) {
                      handleRedirect('/');
                    } else {
                      handleRedirect('/otp');
                    }
                  }
                },
                onError: (error) => {
                  handleHTTPErrors([error]);
                  setSubmitting(false);
                },
              }
            );
          },
          onError: (error) => {
            handleHTTPErrors([error]);
            setSubmitting(false);
          },
        });
      },
      (error: unknown) => {
        if (error instanceof Error) {
          toggleError(error.message);
        } else {
          toggleError('Login error');
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <CreatePasswordForm
      ref={formRef}
      onSubmit={handleSubmit}
      $restore={paths.RESTORE}
    >
      <CustomOnboardingStepNavigation
        text="Previous Page"
        $restore={paths.RESTORE}
      />
      <Heading $restore={paths.RESTORE}>
        {paths.REGISTER ? 'Setup your password' : 'Setup your new password'}
      </Heading>
      <SubHeading $restore={paths.RESTORE}>
        Create a secure Password for Application Access
      </SubHeading>
      <Label>Password</Label>
      <WeavrPassword
        id="password"
        $focused={passwordInputFocused}
      />
      <Label>Confirm Password</Label>
      <WeavrPassword
        id="confirmPassword"
        $focused={confirmPasswordInputFocused}
      />
      <PasswordErrorMessage
        id="passwordConfirmResponse"
        $visible={true}
      />
      <PasswordInfoBlock />
      <CustomButton
        disabled={submitting}
        $restore={paths.RESTORE}
        variant="blueWhite"
        text="Complete"
      />
    </CreatePasswordForm>
  );
};

export default OnboardingCreatePassword;
