import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import {
  NoContactsDashboardMobileSet,
  NoContactsDashboardTabletSet,
  NoContactsSendMoneyMobileSet,
  NoContactsSendMoneyTabletSet,
  NoContactsSendMoneyVerifyMobileSet,
} from '@constants/images';

export const Container = styled.div<{
  size: 'mobile' | 'tablet';
  $screen: 'dashboard' | 'sendMoney';
  $verify?: boolean;
  $show?: boolean;
}>`
  display: ${(props) => (props.$show ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  gap: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  ${(props) =>
    props.size === 'tablet' &&
    props.$screen === 'dashboard' &&
    css`
      background-image: url(${NoContactsDashboardTabletSet[0]});
      background-image: -webkit-image-set(
        url(${NoContactsDashboardTabletSet[0]}) 1x,
        url(${NoContactsDashboardTabletSet[1]}) 2x
      );
      width: 258px;
      height: 353px;
    `};

  ${(props) =>
    props.size === 'mobile' &&
    props.$screen === 'dashboard' &&
    css`
      background-image: url(${NoContactsDashboardMobileSet[0]});
      background-image: -webkit-image-set(
        url(${NoContactsDashboardMobileSet[0]}) 1x,
        url(${NoContactsDashboardMobileSet[1]}) 2x
      );
      width: 258px;
      height: 201px;
    `};

  ${(props) =>
    props.size === 'tablet' &&
    props.$screen === 'sendMoney' &&
    css`
      background-image: url(${NoContactsSendMoneyTabletSet[0]});
      background-image: -webkit-image-set(
        url(${NoContactsSendMoneyTabletSet[0]}) 1x,
        url(${NoContactsSendMoneyTabletSet[1]}) 2x
      );
      width: 427px;
      height: 518px;
    `};

  ${(props) =>
    props.size === 'mobile' &&
    props.$screen === 'sendMoney' &&
    css`
      background-image: url(${NoContactsSendMoneyMobileSet[0]});
      background-image: -webkit-image-set(
        url(${NoContactsSendMoneyMobileSet[0]}) 1x,
        url(${NoContactsSendMoneyMobileSet[1]}) 2x
      );
      width: 288px;
      height: 518px;
    `};

  ${(props) =>
    props.size === 'mobile' &&
    props.$screen === 'sendMoney' &&
    props.$verify === true &&
    css`
      background-image: url(${NoContactsSendMoneyVerifyMobileSet[0]});
      background-image: -webkit-image-set(
        url(${NoContactsSendMoneyVerifyMobileSet[0]}) 1x,
        url(${NoContactsSendMoneyVerifyMobileSet[1]}) 2x
      );
      width: 288px;
      height: 518px;
    `};
`;

export const Text = styled.h5<{
  size: 'mobile' | 'tablet';
  $screen: 'dashboard' | 'sendMoney';
  $verify?: boolean;
}>`
  text-align: center;
  ${FontStyles.h5};
  color: ${(props) => props.theme.palette.primary.black};
  width: 230px;

  ${(props) =>
    props.size === 'mobile' &&
    props.$screen === 'sendMoney' &&
    !props.$verify &&
    css`
      margin-top: 80px;
    `};
`;

export const Circle = styled.div<{ size: 'mobile' | 'tablet' }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: radial-gradient(
      45.7% 45.7% at 73.66% 94.62%,
      rgba(34, 255, 255, 0.2) 0%,
      rgba(34, 255, 255, 0) 100%
    ),
    radial-gradient(98.33% 59.29% at 0% 100%, #7a5e9e 0%, rgba(122, 94, 158, 0) 100%),
    linear-gradient(352.43deg, #232222 2.59%, #34354a 96.38%);
  height: ${(props) => (props.size === 'tablet' ? '93px' : '50px')};
  width: ${(props) => (props.size === 'tablet' ? '93px' : '50px')};

  svg {
    height: ${(props) => (props.size === 'tablet' ? 'initial' : '17px')};
  }
`;
