import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import PosterVideoLarge from '@assets/images/Card_Large_2x.png';
import PosterVideoMobile from '@assets/images/Card_Mobile_2x.png';
import CardVideoMobile from '@assets/videos/Card_1x.mp4';
import CardVideoLarge from '@assets/videos/Card_2x.mp4';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { LogoMasterCard } from '@constants/logos';

import useMediaQuery from '@hooks/useMediaQuery';

import CardPopup from '@components/CardPopup/CardPopup';
import MobileCardPopup from '@components/MobileCardPopup/MobileCardPopup';

import EyeSwitch from '@elements/EyeSwitch/EyeSwitch';
import PopOver from '@elements/PopOver/PopOver';

interface Props {
  cardNumber: string;
  companyName: string;
  cvv: number;
  validThrough: string;
  status: 'Active' | 'Inactive';
}

interface DetailProps {
  headline: string;
  value: string;
  copyValue?: number;
  hidden?: boolean;
}
const maskCardNumber = (cardNumber: string, hidden: boolean) => {
  if (hidden) {
    return cardNumber.slice(0, -4) + cardNumber.slice(-4).replace(/\d/g, '*');
  }
  return cardNumber;
};
const VirtualCard = ({ cardNumber, companyName, cvv, validThrough, status }: Props) => {
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);
  const isDesktop = useMediaQuery(`(${breakpoints.desktop})`);

  const hidden = useBoundStore((state) => state.hidden);
  const setEnter = useBoundStore((state) => state.setEnter);
  const enter = useBoundStore((state) => state.enter);
  const timer = useBoundStore((state) => state.timer);
  const setTimer = useBoundStore((state) => state.setTimer);
  const openModal = useBoundStore((state) => state.openModal);
  const timeLeft = useBoundStore((state) => state.timeLeft);
  const setTimeLeft = useBoundStore((state) => state.setTimeLeft);
  const setAttempts = useBoundStore((state) => state.setAttempts);
  const setWrongPin = useBoundStore((state) => state.setWrongPin);
  const setWrongField = useBoundStore((state) => state.setWrongField);
  const setHidden = useBoundStore((state) => state.setHidden);

  useEffect(() => {
    if (timer && timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (timer && timeLeft <= 0) {
      setTimer(false);
      setAttempts(3);
      setWrongPin(false);
      setWrongField(false);
      setHidden(true);
      setTimeLeft(300);
      setEnter(false);
    }
  }, [
    setAttempts,
    setEnter,
    setHidden,
    setTimeLeft,
    setTimer,
    setWrongField,
    setWrongPin,
    timeLeft,
    timer,
  ]);

  const handleEye = () => {
    setEnter(!enter);
    if (!isLaptop && !timer && enter) {
      openModal(<MobileCardPopup />);
    }
  };

  const videoSource = !isDesktop ? CardVideoMobile : CardVideoLarge;
  const posterSource = !isDesktop ? PosterVideoMobile : PosterVideoLarge;

  return (
    <Container>
      <VideoContainer>
        <Video
          autoPlay
          muted
          loop
          playsInline={true}
          src={videoSource}
          poster={posterSource}
        />
      </VideoContainer>
      <HeadlineRow>
        Virtual Debit Card
        <BlueText>{status}</BlueText>
      </HeadlineRow>
      <CardNumberRow>
        {maskCardNumber(cardNumber, hidden)}
        <CustomPopOver copyValue={cardNumber} />
        <EyeSwitch
          onClick={() => handleEye()}
          style={{ marginLeft: 'auto' }}
        />
      </CardNumberRow>
      <BlurredContainer>
        <CardDetails>
          <DetailSection
            headline="Code"
            value={cvv.toString()}
            copyValue={cvv}
            hidden={hidden}
          />
          <DetailSection
            headline="Valid through"
            value={validThrough}
          />
        </CardDetails>
        {enter || timer ? (
          <CustomContainerLaptop>
            <Wrapper>
              <CardPopup />
            </Wrapper>
            <CustomLogoMasterCardLaptop />
          </CustomContainerLaptop>
        ) : (
          <CustomLogoMasterCardMobile />
        )}
      </BlurredContainer>
    </Container>
  );
};

export default VirtualCard;

const DetailSection = ({ headline, value, copyValue, hidden }: DetailProps) => (
  <DetailColumn>
    <DetailHeadlines>{headline}</DetailHeadlines>
    <H5>
      {hidden ? 'CVV' : value}
      {copyValue && !hidden && <CustomPopOver copyValue={copyValue.toString()} />}
    </H5>
  </DetailColumn>
);

const Wrapper = styled.div`
  position: absolute;
  top: -20px;
  right: 35px;
  z-index: 20;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 300px;
  position: relative;
  transition: 300ms ease-in-out;
  z-index: 1;

  &:hover {
    -webkit-box-shadow: 0 20px 24px 0 rgba(16, 153, 253, 0.35);
    box-shadow: 0 20px 24px 0 rgba(16, 153, 253, 0.35);
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  z-index: -1;
`;

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: translateY(-50%) translateX(-50%);
  object-fit: cover;
`;

const HeadlineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.greys.darkGrey};
  ${FontStyles.h7}
`;

const CardNumberRow = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.white};
  ${FontStyles.buttonsNeuePlakType1}
`;

const H5 = styled.div`
  ${FontStyles.h5}
`;

const BlurredContainer = styled.div`
  display: flex;
  background-color: rgba(16, 153, 253, 0.1);
  margin: -20px;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 20px;
`;

const CardDetails = styled.div`
  display: flex;
  gap: 32px;
  margin-right: auto;
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.white};
`;

const DetailHeadlines = styled.div`
  ${FontStyles.body2}
  color: ${({ theme }) => theme.palette.greys.middleGrey}
`;

const BlueText = styled.div`
  color: ${({ theme }) => theme.palette.primary.blue};
`;

const CustomContainerLaptop = styled.div`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: block;
  }
`;

const CustomLogoMasterCardLaptop = styled(LogoMasterCard)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: initial;
  }
`;

const CustomLogoMasterCardMobile = styled(LogoMasterCard)`
  display: initial;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

const CustomPopOver = styled(PopOver)`
  margin-left: 8px;
`;
