import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  text: string;
  onClick: () => void;
  className?: string;
}

const SmallButton = ({ text, onClick, className }: Props) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
};
export default SmallButton;

const StyledButton = styled.button`
  ${FontStyles.bodySmallGilroy};
  border-radius: 30px;
  padding: 8px 20px;
`;
