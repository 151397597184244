import { StateCreator } from 'zustand';

export interface UserSlice {
  externalId: string;
  setExternalId: (value: string) => void;
}

export const createUserSlice: StateCreator<UserSlice> = (set) => ({
  externalId: '',
  setExternalId: (externalId) => set({ externalId: externalId }),
});
