import React from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconNoMatchesFound } from '@constants/icons';

const NoMatchesFound = () => {
  return (
    <Container>
      <IconNoMatchesFound />
      <CustomText>No matching results</CustomText>
    </Container>
  );
};

export default NoMatchesFound;

const Container = styled.div`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  width: 150px;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  box-sizing: border-box;
  gap: 5px;
`;

const CustomText = styled.p`
  width: 100px;
  text-align: center;
  ${FontStyles.h7};
`;
