import React, { useState } from 'react';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';

import InviteUserSuccess from '@components/InviteUserSuccess/InviteUserSuccess';
import Modal from '@components/Modal/Modal';

import LargeButton from '@elements/LargeButton/LargeButton';
import PointyButton from '@elements/PointyButton/PointyButton';
import RoleInfoBox from '@elements/RoleInfoBox/RoleInfoBox';
import Input from '@elements/input/Input/Input';

const InviteUserModal = () => {
  const openModal = useBoundStore((state) => state.openModal);
  const [selection, setSelection] = useState(0);
  const [email, setEmail] = useState('');

  const str = '';

  return (
    <CustomModal>
      <Container>
        <HeadText>Invite User</HeadText>
        <SubText>Enter the user&lsquo;s email and select the desired permission role.</SubText>
        <div className="buttons">
          <PointyButton
            text="Transaction Manager"
            active={selection === 0}
            onClick={() => setSelection(0)}
          />
          <PointyButton
            text="Auditor"
            active={selection === 1}
            onClick={() => setSelection(1)}
          />
        </div>
        <div className="info">
          Enter the user&lsquo;s email address, and we&lsquo;ll send further instructions to that
          account.
        </div>
        <Input
          placeholder="Example@email.com"
          onChange={(event) => setEmail(event.target.value)}
          type="email"
          className="input"
          value={email}
        />
        <RoleInfoBox
          className="infoBox"
          text={
            selection === 0
              ? 'The Transaction Manger: Possesses the capability to review and execute SEPA or SWIFT transactions; however, card expenditures are not permitted.'
              : `${str}The auditor has view-only access to the account's transactions, statements, and reports, solely for compliance and audit purposes.`
          }
        />
        <LargeButton
          className="send"
          onClick={() => openModal(<InviteUserSuccess email={email} />, true)}
          text="Send"
          variant={email === '' ? 'inactive' : 'blueWhite'}
          disabled={email === ''}
        />
      </Container>
    </CustomModal>
  );
};

export default InviteUserModal;

const CustomModal = styled(Modal)`
  & * > .subheadline {
    ${FontStyles.bodyMiddleGilroy};
    width: 346px;
    padding-bottom: 15px;
  }
`;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .info {
    ${FontStyles.h6};
    text-align: center;
    margin-bottom: 10px;
  }

  & > .buttons {
    display: flex;
    gap: 10px;
    position: relative;
    padding-bottom: 40px;
    width: 100%;
    justify-content: center;
  }

  & > .input {
    width: 100%;
    margin-bottom: 40px;
  }

  & > .infoBox,
  > .send {
    margin-bottom: 30px;
  }

  & > .send {
    width: 200px;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    & > .info {
      width: 506px;
      ${FontStyles.h5};
    }

    & > .buttons {
      display: flex;
      gap: 25px;
      padding-bottom: 55px;
    }

    & > .input {
      width: 100%;
      margin-bottom: 40px;
    }

    & > .infoBox,
    > .send {
      margin-bottom: 35px;
    }
  }
`;

const HeadText = styled.div`
  ${FontStyles.h3};
  margin-bottom: 10px;
  background: ${({ theme }) => theme.palette.primary.black};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 60px 0 10px 0;
    ${FontStyles.h2};
  }
`;

const SubText = styled.div`
  ${FontStyles.h6};
  margin-bottom: 35px;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  text-align: center;
  width: 346px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
    color: ${({ theme }) => theme.palette.primary.black};
  }
`;
