import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconBank, IconCardCheck } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

const TransactionEntryDummy = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);
  const isDesktop = useMediaQuery(`(${theme.breakpoints.desktop})`);

  return (
    <Wrapper>
      <Row>
        <IconWithBackground className="transaction-entry-icon">
          <IconCardCheck />
        </IconWithBackground>
        <IconWithBackground className="transaction-entry-icon second">
          <IconBank />
        </IconWithBackground>
        <LeftCol>
          <Name>Your First Incoming Transaction</Name>
          {isLaptop && (
            <TransferKind>
              <Trans t={t}>Card Deposit/Bank Transfer</Trans>
            </TransferKind>
          )}
        </LeftCol>
      </Row>
      <RightCol>
        <Balance>
          +{!isLaptop && ' '}€{isLaptop && ' Deposit'}
        </Balance>
        {isLaptop && (
          <Timestamp>
            {isDesktop && `${t('date_and_time')} `}
            {isLaptop && '18/03/23 7:18 AM'}
          </Timestamp>
        )}
      </RightCol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  transition: background-color ease-in-out 300ms;
  border-top: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  position: relative;

  & .transaction-entry-icon {
    background: ${(props) => props.theme.palette.positive.lessContrastGreenLighter};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
    border-radius: 10px;
    padding: 12px 15px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.spacing.s};
  gap: 2px;
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    align-items: end;
    gap: 2px;
  }
`;

const Name = styled.div`
  ${FontStyles.buttonGilroyType3}
  color: ${(props) => props.theme.palette.greys.darkestGrey};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

const TransferKind = styled.div`
  ${FontStyles.bodySmallGilroy}
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const Balance = styled.div`
  ${FontStyles.buttonGilroyType3}
  color: ${(props) => props.theme.palette.primary.blue};
  transition: ease-in-out 300ms;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

const Timestamp = styled.div`
  ${FontStyles.bodySmallGilroy}
  color: ${(props) => props.theme.palette.greys.asphaltTint};
`;

const IconWithBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: background-color 300ms ease-in-out;

  &.second {
    margin-left: -6px;
  }

  & svg {
    height: 15px;
    margin: 7.5px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 40px;
    height: 40px;

    &.second {
      margin-left: 10px;
    }

    & svg {
      height: 20px;
      margin: 10px;
    }
  }
`;

export default TransactionEntryDummy;
