import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import IconButton from '@elements/IconButton/IconButton';

export const NavIconButton = styled(IconButton)`
  display: none;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  background-color: ${(props) => props.theme.palette.greys.middleGrey};
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.black};
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
`;

export const NotificationIcon = styled(NavIconButton)<{
  $visibleOnMobile: boolean;
  $newNotification: number;
}>`
  display: ${({ $visibleOnMobile }) => ($visibleOnMobile ? 'flex' : 'none')};

  &.open {
    border: 1px solid transparent;
    color: ${(props) => props.theme.palette.primary.blue};
    & svg {
      fill: ${(props) => props.theme.palette.primary.blue};
    }
  }
  & svg {
    fill: ${(props) => props.theme.palette.greys.darkestGrey};
  }
  // &:hover svg {
  //   fill: ${(props) => props.theme.palette.primary.blue};
  // }
  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;
export const NotificationIconWrapper = styled.div`
  position: relative;
`;

export const NotificationCount = styled.div<{ $visibleOnMobile: boolean }>`
  display: ${({ $visibleOnMobile }) => ($visibleOnMobile ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  ${FontStyles.bodySmallGilroy};
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding-top: 1px;
  width: 15px;
  height: 14px;
  line-height: 13px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.primary.white};
  background: ${(props) => props.theme.palette.gradients.primaryBlackGradient};
  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;
