import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

type Size = 'small' | 'medium' | 'large';
interface Props {
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  icon?: string;
  children: ReactNode;
  size?: Size;
  color?: ButtonProps['color'];
}

const BlueButton = ({
  onClick,
  className,
  style,
  loading = false,
  disabled = false,
  children,
  size = 'small',
  color = 'primary',
}: Props) => {
  return (
    <ButtonWrap
      color={color}
      onClick={onClick}
      className={className}
      style={style}
      disabled={disabled}
      size={size}
    >
      {loading ? <CircularProgress /> : <>{children}</>}
    </ButtonWrap>
  );
};

const ButtonWrap = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 75px !important;
  height: ${(props) =>
    props.size === 'small' ? '36px' : props.size === 'medium' ? '40px' : '50px'};
  padding: ${(props) => (props.size === 'small' ? '3px 19px' : '6px 22px')} !important;
  ${FontStyles.bodySmallGilroy};
  font-size: ${(props) =>
    props.size === 'small' || props.size === 'medium' ? '14px' : '15px'}!important;
  font-family: ${(props) => props.theme.typography.buttonGilroyType3.fontFamily}!important;
  font-weight: ${(props) => props.theme.typography.buttonGilroyType3.fontWeight}!important;
  cursor: pointer;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette.greys.middleGrey
      : props.color === 'warning'
        ? props.theme.palette.warning.main
        : props.theme.palette.primary.blue}!important;
  color: ${(props) => props.theme.palette.primary.white}!important;
  text-transform: initial !important;
  &:disabled {
    background-color: ${(props) => props.theme.palette.greys.darkGrey};
    color: #fff;
    cursor: unset;
  }
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.palette.greys.middleGrey
        : props.color === 'warning'
          ? props.theme.palette.warning.disabled
          : props.theme.palette.primary.secondBlue}!important;
  }
  svg {
    fill: ${({ theme }) => theme.palette.primary.white};
  }
  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: 236px;
  }
`;

export default BlueButton;
