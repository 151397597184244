import { CanceledError } from 'axios';

import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const createManagedAccount = async (accountData: ManagedAccountCreationData) => {
  try {
    const result = await weavrAuthorizedAxiosClient.post(
      '/managed/create',
      { friendlyName: accountData.friendlyName, currency: accountData.currency },
      {
        headers: {
          'idempotency-ref': accountData.idempotency,
        },
      }
    );
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};
const upgradeManagedAccountToIban = async (id: string) => {
  try {
    const result = await weavrAuthorizedAxiosClient.post('/managed/iban', { id });
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const getAllManagedAccounts = async (
  signal: AbortSignal,
  managedAccountsSearchQuery?: ManagedAccountsSearchQuery
) => {
  try {
    const result = await weavrAuthorizedAxiosClient.get('/managed', {
      signal,
      params: { managedAccountsSearchQuery },
    });
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const getManagedAccountIban = async (signal: AbortSignal, managedAccountId: string) => {
  try {
    const result = await weavrAuthorizedAxiosClient.get('/managed/iban', {
      signal,
      params: {
        managedAccountId,
      },
    });
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

export const ManagedAccountsService = {
  createManagedAccount,
  getAllManagedAccounts,
  upgradeManagedAccountToIban,
  getManagedAccountIban,
};
