import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import { useGetCorporate, useGetKYB, useUpdateCorporate } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { convertCountryFromISO3166ToFullName } from '@shared/functions';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';
import useMediaQuery from '@hooks/useMediaQuery';

import ChangeDetailsModal from '@components/ChangeDetailsModal/ChangeDetailsModal';
import CheckPricing from '@components/CheckPricing/CheckPricing';
import {
  ButtonWrapper,
  ChangeWrapper,
  Container,
  Content,
  CustomOnboardingCountryInput,
  FieldContainer,
  FieldRow,
  Group,
  RequestButton,
  SaveChangesButton,
  TitleBar,
  TitleCheckPricing,
  TitleWrapper,
} from '@components/Settings/SettingsCompanyDetails/SettingsCompanyDetails.styles';

import Toast from '@elements/Toast/Toast';
import Input from '@elements/input/Input/Input';

const SettingsCompanyDetails = () => {
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const theme = useTheme();
  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);
  const queryClient = useQueryClient();

  const [editable, setEditable] = useState<boolean>(false);
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [currentAddressLine1, setCurrentAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [currentAddressLine2, setCurrentAddressLine2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [currentCity, setCurrentCity] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [currentPostCode, setCurrentPostCode] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [currentState, setCurrentState] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [currentCountry, setCurrentCountry] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const openModal = useBoundStore((state) => state.openModal);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const {
    data: corporate,
    isError: isGetCorporateError,
    error: getCorporateError,
  } = useGetCorporate();
  const { mutate: updateCorporateMutation } = useUpdateCorporate();
  const { data: kybData, isError: isGetKYBError, error: getKYBError } = useGetKYB();

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (isGetKYBError && getKYBError) {
      errorsToHandle.push(getKYBError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [getCorporateError, getKYBError, handleHTTPErrors, isGetCorporateError, isGetKYBError]);

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [getCorporateError, handleHTTPErrors, isGetCorporateError]);

  useEffect(() => {
    if (!editable) {
      const addressLine1 = corporate?.company?.businessAddress?.addressLine1 ?? '-';
      const addressLine2 = corporate?.company?.businessAddress?.addressLine2 ?? '-';
      const city = corporate?.company?.businessAddress?.city ?? '-';
      const postCode = corporate?.company?.businessAddress?.postCode ?? '-';
      const state = corporate?.company?.businessAddress?.state ?? '-';
      const country = convertCountryFromISO3166ToFullName(
        corporate?.company?.businessAddress?.country
      );

      setAddressLine1(addressLine1);
      setCurrentAddressLine1(addressLine1);
      setAddressLine2(addressLine2);
      setCurrentAddressLine2(addressLine2);
      setCity(city);
      setCurrentCity(city);
      setPostCode(postCode);
      setCurrentPostCode(postCode);
      setState(state);
      setCurrentState(state);
      setCountry(country);
      setCurrentCountry(country);
    }
  }, [
    corporate?.company?.businessAddress?.addressLine1,
    corporate?.company?.businessAddress?.addressLine2,
    corporate?.company?.businessAddress?.city,
    corporate?.company?.businessAddress?.country,
    corporate?.company?.businessAddress?.postCode,
    corporate?.company?.businessAddress?.state,
    editable,
  ]);

  const handleSaveChanges = () => {
    if (submitting) return;

    setSubmitting(true);

    setEditable(false);

    if (
      (currentAddressLine1 !== addressLine1 && addressLine1 !== '') ||
      currentAddressLine2 !== addressLine2 ||
      (currentCity !== city && city !== '') ||
      (currentPostCode !== postCode && postCode !== '') ||
      (currentCountry !== country && country !== '') ||
      (currentState !== state && state !== '')
    ) {
      const updatedCorporateData = {
        companyBusinessAddress: {
          addressLine1,
          city,
          postCode,
          country,
          state,
          addressLine2: addressLine2 === '' ? undefined : addressLine2,
        },
      };

      updateCorporateMutation(updatedCorporateData, {
        onSuccess: async () => {
          toast.success(
            <Toast
              title="Company Details updated"
              message="Your data has been successfully updated."
            />
          );
          await queryClient.invalidateQueries({
            queryKey: ['getCorporate'],
            exact: true,
            refetchType: 'active',
          });
          setSubmitting(false);
        },
        onError: (error) => {
          handleHTTPErrors([error]);
          setSubmitting(false);
        },
      });
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <TitleBar>
        <TitleWrapper>
          Company Details
          <TitleCheckPricing />
        </TitleWrapper>
        {verifiedKYC || checkIfKybInitiated() ? (
          <RequestButton onClick={() => openModal(<ChangeDetailsModal />)}>
            Request change of details
          </RequestButton>
        ) : (
          <ChangeWrapper>
            <RequestButton
              disabled={submitting}
              onClick={() => setEditable((prevState) => !prevState)}
            >
              {editable ? 'Cancel' : 'Change Details'}
            </RequestButton>
            {editable && (
              <SaveChangesButton
                disabled={submitting}
                onClick={handleSaveChanges}
                text="Save Changes"
                variant="blueWhite"
              />
            )}
          </ChangeWrapper>
        )}
      </TitleBar>
      <Content>
        <FieldRow>
          <Group>
            <FieldContainer>
              Registration Number
              <Input
                placeholder="Registration Number"
                onChange={() => undefined}
                locked
                value={corporate?.company?.registrationNumber ?? '-'}
              />
            </FieldContainer>
            <FieldContainer>
              Registration Country
              <Input
                placeholder="Registration Country"
                onChange={() => undefined}
                locked
                value={convertCountryFromISO3166ToFullName(
                  corporate?.company?.countryOfRegistration
                )}
              />
            </FieldContainer>
          </Group>

          {/*<FieldContainer>*/}
          {/*  Type of Business*/}
          {/*  <input*/}
          {/*    placeholder="-"*/}
          {/*    onChange={() => undefined}*/}
          {/*    locked*/}
          {/*    value={corporate.company.type}*/}
          {/*  />*/}
          {/*</FieldContainer>*/}
          <Group>
            <FieldRow>
              <FieldContainer>
                City
                <Input
                  placeholder="-"
                  onChange={(event) => setCity(event.target.value)}
                  locked={!editable}
                  value={city}
                />
              </FieldContainer>
              <FieldContainer>
                Post Code
                <Input
                  placeholder="-"
                  onChange={(event) => setPostCode(event.target.value)}
                  locked={!editable}
                  value={postCode}
                />
              </FieldContainer>
            </FieldRow>
            <FieldRow>
              <FieldContainer>
                Country
                <CustomOnboardingCountryInput
                  setValue={(value) => setCountry(value)}
                  value={country}
                  locked={!editable}
                />
              </FieldContainer>
              <FieldContainer>
                State/Region
                <Input
                  placeholder="State/Region"
                  onChange={(event) => setState(event.target.value)}
                  locked={!editable}
                  value={state}
                />
              </FieldContainer>
            </FieldRow>
          </Group>
        </FieldRow>
        <Group>
          <FieldRow>
            <FieldRow>
              <FieldContainer>
                Business Address
                <Input
                  placeholder="Address Line 1"
                  onChange={(event) => setAddressLine1(event.target.value)}
                  locked={!editable}
                  value={addressLine1}
                />
                <Input
                  placeholder="Address Line 2 (optional)"
                  onChange={(event) => setAddressLine2(event.target.value)}
                  locked={!editable}
                  value={addressLine2}
                />
              </FieldContainer>
            </FieldRow>
          </FieldRow>
        </Group>
        <ButtonWrapper>
          {!isLaptop &&
            (verifiedKYC || checkIfKybInitiated() ? (
              <RequestButton onClick={() => openModal(<ChangeDetailsModal />)}>
                Request change of details
              </RequestButton>
            ) : (
              <ChangeWrapper>
                {editable && (
                  <SaveChangesButton
                    disabled={submitting}
                    onClick={handleSaveChanges}
                    text="Save Changes"
                    variant="blueWhite"
                  />
                )}
                <RequestButton
                  disabled={submitting}
                  onClick={() => setEditable((prevState) => !prevState)}
                >
                  {editable ? 'Cancel' : 'Change Details'}
                </RequestButton>
              </ChangeWrapper>
            ))}
          <CheckPricing />
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default SettingsCompanyDetails;
