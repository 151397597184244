import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { DeleteItem } from '@constants/icons';

export interface SelectedFilterProps {
  name?: string;
  handleResetType?: () => void;
  size?: 'small' | 'medium' | 'large';
  variant?: Variant;
  startIcon?: ReactNode;
}

const Chip: React.FC<SelectedFilterProps> = ({
  name,
  handleResetType,
  size = 'medium',
  variant = 'default',
  startIcon,
}) => {
  return (
    <SelectedFilterWrap
      $size={size}
      $variant={variant}
    >
      {startIcon && <StartIconWrap $size={size}>{startIcon}</StartIconWrap>}
      <Name $size={size}>{name}</Name>
      {!handleResetType && (
        <IconWrap
          $size={size}
          onClick={handleResetType}
          className="closeIcon"
        >
          <StyledDeleteItem $variant={variant} />
        </IconWrap>
      )}
    </SelectedFilterWrap>
  );
};

export default Chip;

export const Name = styled.div<{ $size: string }>`
  padding: ${(props) => {
    switch (props.$size) {
      case 'small':
        return '0 6px';
      default:
        return '3px 6px ';
    }
  }};
`;

export const SelectedFilterWrap = styled.div<{ $size: string; $variant: Variant }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  ${FontStyles.label};
  color: ${(props) => {
    switch (props.$variant) {
      case 'info':
      case 'success':
      case 'warning':
      case 'error':
      case 'primary':
      case 'secondary':
        return props.theme.palette.primary.white;
      default:
        return props.theme.palette.text.primary;
    }
  }};
  padding: ${(props) => {
    switch (props.$size) {
      case 'small':
        return '3px 3px';
      default:
        return '4px 5px ';
    }
  }};
  background-color: ${(props) => {
    switch (props.$variant) {
      case 'info':
        return props.theme.palette.info.main;
      case 'success':
        return props.theme.palette.success.main;
      case 'warning':
        return props.theme.palette.warning.main;
      case 'error':
        return props.theme.palette.error.main;
      case 'primary':
        return props.theme.palette.primary.main;
      case 'secondary':
        return props.theme.palette.secondary.main;
      default:
        return props.theme.palette.default.main;
    }
  }};
  &:hover {
    background-color: ${(props) => {
      switch (props.$variant) {
        case 'info':
          return props.theme.palette.info.hover;
        case 'success':
          return props.theme.palette.success.hover;
        case 'warning':
          return props.theme.palette.warning.hover;
        case 'error':
          return props.theme.palette.error.hover;
        case 'primary':
          return props.theme.palette.primary.hover;
        case 'secondary':
          return props.theme.palette.secondary.hover;
        default:
          return props.theme.palette.default.hover;
      }
    }};
  }
  & button {
    padding: 0;
    width: ${(props) => (props.$size === 'small' ? '15px' : '20px')};
    height: ${(props) => (props.$size === 'small' ? '15px' : '20px')};
    border-radius: 50%;
    background-color: #dcdcdc;
    margin-left: 4px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.primary};
  }
`;
export const StartIconWrap = styled.div<{ $size: string }>`
  width: ${(props) => (props.$size === 'small' ? '14px' : '20px')};
  height: ${(props) => (props.$size === 'small' ? '14px' : '20px')};
`;

export const IconWrap = styled.div<{ $size: string }>`
  display: flex;
  width: ${(props) => (props.$size === 'small' ? '14px' : '20px')};
  height: ${(props) => (props.$size === 'small' ? '14px' : '20px')};
  margin-left: 3px;
  opacity: 0.7;
  color: ${(props) => props.theme.palette.primary.white};
  & svg {
    width: 100%;
    height: 100%;
    color: ${(props) => props.theme.palette.primary.white};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 0;
  }
`;

const StyledDeleteItem = styled(DeleteItem)<{ $variant: string }>`
  opacity: 0.26;
  path {
    fill: ${(props) => {
      switch (props.$variant) {
        case 'info':
        case 'success':
        case 'warning':
        case 'error':
        case 'primary':
        case 'secondary':
          return props.theme.palette.primary.white;
        default:
          return props.theme.palette.text.primary;
      }
    }};
  }
`;
