import { friendlyFormatIBAN } from 'ibantools';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import {
  useGetAllManagedAccounts,
  useGetManagedAccountIban,
} from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconCopied, IconCopy } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import CopyField from '@components/CopyField/CopyField';
import Modal from '@components/Modal/Modal';
import ModalHeadline from '@components/Modal/ModalHeadline';
import ModalSubheadline from '@components/Modal/ModalSubheadline';

const ShareDetails = () => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState<boolean>(false);

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const {
    data: managedAccountData,
    isError: isGetAllManagedAccountsError,
    error: getAllManagedAccountsError,
  } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const {
    data: mainManagedAccountData,
    isError: isGetManagedAccountIbanError,
    error: getManagedAccountIbanError,
  } = useGetManagedAccountIban(managedAccountId);

  useEffect(() => {
    const errorsToHandle = [];
    if (isGetAllManagedAccountsError && getAllManagedAccountsError) {
      errorsToHandle.push(getAllManagedAccountsError);
    }
    if (isGetManagedAccountIbanError && getManagedAccountIbanError) {
      errorsToHandle.push(getManagedAccountIbanError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [
    getAllManagedAccountsError,
    getManagedAccountIbanError,
    handleHTTPErrors,
    isGetAllManagedAccountsError,
    isGetManagedAccountIbanError,
  ]);

  const getIBAN = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      const IBAN = mainManagedAccountData.bankAccountDetails[1].details.iban;
      return IBAN ? (friendlyFormatIBAN(IBAN) as string) : '--';
    } else {
      return '--';
    }
  };

  const getBIC = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      const BIC = mainManagedAccountData.bankAccountDetails[1].details.bankIdentifierCode;
      return BIC as string;
    } else {
      return '--';
    }
  };

  const getBeneficiary = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      return mainManagedAccountData.bankAccountDetails[1].beneficiaryNameAndSurname ?? '--';
    } else {
      return '--';
    }
  };

  const getBankName = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      return mainManagedAccountData.bankAccountDetails[1].beneficiaryBank ?? '--';
    } else {
      return '--';
    }
  };

  const getBankAddress = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      return mainManagedAccountData.bankAccountDetails[1].beneficiaryBankAddress ?? '--';
    } else {
      return '--';
    }
  };

  const handleCopyAll = () => {
    const beneficiary = getBeneficiary();
    const bic = getBIC();
    const iban = getIBAN();
    const bankName = getBankName();
    const bankAddress = getBankAddress();

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);

    const copyText = `Beneficiary: ${beneficiary}\nIBAN: ${iban}\nBIC: ${bic}\nBank Name: ${bankName}\nBank Address: ${bankAddress}`;

    navigator.clipboard.writeText(copyText).catch((error) => {
      console.error('Could not copy text: ', error);
    });
  };

  return (
    <Modal>
      <Line></Line>
      <ModalHeadline>{t('share_details')}</ModalHeadline>
      <ModalSubheadline>{t('share_details_subheading')}</ModalSubheadline>
      <Container>
        <Border>
          <CopyField
            value={getBeneficiary()}
            label="Beneficiary"
            copyBottom={false}
          />
        </Border>
        <Border>
          <CopyField
            value={getBIC()}
            label="BIC"
            copyBottom={false}
          />
        </Border>
        <Border>
          <CopyField
            value={getIBAN()}
            label="IBAN"
            copyBottom={false}
          />
        </Border>
        <Border>
          <CopyField
            value={getBankName()}
            label="Bank Name"
            copyBottom={false}
          />
        </Border>
        <Border>
          <CopyField
            value={getBankAddress()}
            label="Bank Address"
            copyBottom={false}
          />
        </Border>
        <Footer>
          <CopyButton
            onClick={handleCopyAll}
            $copied={copied}
          >
            {copied ? (
              <>
                <IconCopied /> Copied
              </>
            ) : (
              <>
                <IconCopy />
                Copy All
              </>
            )}
          </CopyButton>
        </Footer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: initial;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
`;

const Border = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  padding: 12px 20px;
  border-radius: 10px;
`;
const Line = styled.div`
  width: 100%;
  padding-top: 50px;
`;

const Label = styled.label`
  ${FontStyles.body2};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 5px;
`;

const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: end;
    justify-content: end;
  }
`;

const CopyButton = styled.button<{ $copied: boolean }>`
  display: flex;
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.primary.white};
  transition: 300ms ease-in-out;
  cursor: pointer;
  border-radius: 25px;
  width: 190px;
  padding: 15px 39px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  ${FontStyles.buttonGilroyType3};
  background-color: ${(props) => props.theme.palette.primary.black};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.blue};
  }

  ${(props) =>
    props.$copied &&
    css`
      background-color: ${(props) => props.theme.palette.positive.lessContrastGreen};
      color: ${(props) => props.theme.palette.primary.white};

      &:hover {
        background-color: ${(props) => props.theme.palette.positive.lessContrastGreen};
      }
    `};
`;
export default ShareDetails;
