import React from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  title: string;
  message: string;
  link?: string;
}

const Toast = ({ title, message, link }: Props) => {
  return (
    <ToastifyContainer>
      <ToastTitle>{title}</ToastTitle>
      <div>{message}</div>
      {link && <Link href={link}>Link</Link>}
    </ToastifyContainer>
  );
};

export default Toast;

export const ToastifyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToastTitle = styled.div`
  ${FontStyles.h5};
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.blue};
  cursor: pointer;
  transition: 300ms ease-in-out;

  &:hover,
  &:focus {
    outline: none;
    color: ${(props) => props.theme.palette.primary.black};
  }
`;
