import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import useMediaQuery from '@hooks/useMediaQuery';

import SendMoneyButton from '@components/SendMoneyButton/SendMoneyButton';
import WelcomeModal from '@components/WelcomeModal/WelcomeModal';
import AccountDetails from '@components/account/AccountDetails/AccountDetails';
import Transactions from '@components/transaction/Transactions/Transactions';

import {
  ContentContainer,
  Inner,
  QuickTransferMobileAndSmallDesktop,
  QuickTransferTabletAndLaptop,
  Wrapper,
} from './Overview.styles';

const Overview = () => {
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const collapsed = useBoundStore((state) => state.collapsed);
  const setCollapsed = useBoundStore((state) => state.setCollapsed);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const setScrolled = useBoundStore((state) => state.setScrolled);
  const fullAccessUser = useBoundStore((state) => state.fullAccessUser);
  const invitedUser = useBoundStore((state) => state.invitedUser);
  const openModal = useBoundStore((state) => state.openModal);

  useEffect(() => {
    if (invitedUser && !verifiedKYC) {
      openModal(<WelcomeModal role={fullAccessUser ? 'Transaction Manager' : 'Auditor'} />, false);
    }
  }, [fullAccessUser, invitedUser, openModal, verifiedKYC]);

  useEffect(() => {
    setSelectedContact(null);
  }, [setSelectedContact]);

  useEffect(() => {
    setScrolled(false);
  }, [setScrolled]);

  useEffect(() => {
    if (!isLaptop || !verifiedKYC) {
      setCollapsed(false);
    }
  }, [collapsed, setCollapsed, isLaptop, verifiedKYC]);

  return (
    <ContentContainer>
      <Wrapper>
        <AccountDetails
          collapsed={collapsed}
          setCollapse={setCollapsed}
        />
        <Inner>
          <Transactions />
          <QuickTransferTabletAndLaptop />
        </Inner>
      </Wrapper>
      <QuickTransferMobileAndSmallDesktop />
      <SendMoneyButton />
    </ContentContainer>
  );
};

export default Overview;
