import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconBlueCheck, IconRedX } from '@constants/icons';
import { ErrorBGs, ErrorBGsMobile, SuccessBGs, SuccessBGsMobile } from '@constants/images';

import ModalNew from '@components/Modal/ModalNew';

import BlueButton from '@elements/BlueButton/BlueButton';
import BorderButtonBlue from '@elements/BorderButtonBlue/BorderButton';
import LargeButton from '@elements/LargeButton/LargeButton';

interface Props {
  isError: boolean;
  message: string;
}

const SuccessError = ({ isError, message }: Props) => {
  const { t } = useTranslation();
  const closeModal = useBoundStore((state) => state.closeModal);

  const handleClick = () => closeModal;

  return (
    <MainWrapper
      $isError={isError}
      dark={isError}
    >
      <TopContent>
        <Icon $isError={isError}>{isError ? <IconRedX /> : <IconBlueCheck />}</Icon>
        <HeadLine $isError={isError}>
          <Trans t={t}>{isError ? 'error' : 'success'}</Trans>
        </HeadLine>
        <SubHeadLine $isError={isError}>
          <Trans t={t}>{message}</Trans>
        </SubHeadLine>
      </TopContent>
      <BottomContent $isError={isError}>
        {isError ? (
          <>
            <BorderButtonBlue
              onClick={closeModal}
              style={{ maxWidth: '230px', width: '100%' }}
              size="large"
            >
              Done
            </BorderButtonBlue>
            <BlueButton
              onClick={handleClick}
              style={{ maxWidth: '230px', width: '100%' }}
              size="large"
            >
              Retry
            </BlueButton>
          </>
        ) : (
          <LargeButton
            onClick={closeModal}
            text="Done"
            variant="blueWhite"
            style={{ maxWidth: '230px', width: '100%' }}
          />
        )}
      </BottomContent>
    </MainWrapper>
  );
};

export default SuccessError;

const MainWrapper = styled(ModalNew)<{ $isError?: boolean }>`
  background-image: ${(props) =>
    props.$isError ? `url(${ErrorBGsMobile[0]})` : `url(${SuccessBGsMobile[0]})`};

  background-image: ${(props) =>
    props.$isError
      ? `-webkit-image-set(
     url(${ErrorBGsMobile[0]}) 1x,
     url(${ErrorBGsMobile[1]}) 2x,
  )`
      : `-webkit-image-set(
     url(${SuccessBGsMobile[0]}) 1x,
     url(${SuccessBGsMobile[1]}) 2x,
  )`};

  background-image: ${(props) =>
    props.$isError
      ? `image-set(
     url(${ErrorBGsMobile[0]}) 1x,
     url(${ErrorBGsMobile[1]}) 2x,
  )`
      : `image-set(
     url(${SuccessBGsMobile[0]}) 1x,
     url(${SuccessBGsMobile[1]}) 2x,
  )`};

  background-size: cover;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    background-image: ${(props) =>
      props.$isError ? `url(${ErrorBGs[0]})` : `url(${SuccessBGs[0]})`};
    background-image: ${(props) =>
      props.$isError
        ? `-webkit-image-set(
     url(${ErrorBGs[0]}) 1x,
     url(${ErrorBGs[1]}) 2x,
  )`
        : `-webkit-image-set(
     url(${SuccessBGs[0]}) 1x,
     url(${SuccessBGs[1]}) 2x,
  )`};

    background-image: ${(props) =>
      props.$isError
        ? `image-set(
     url(${ErrorBGs[0]}) 1x,
     url(${ErrorBGs[1]}) 2x,
  )`
        : `image-set(
     url(${SuccessBGs[0]}) 1x,
     url(${SuccessBGs[1]}) 2x,
  )`};
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px 0 24px;
  //width: 292px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 16px;
    //width: 376px;
  }
`;

const BottomContent = styled.div<{ $isError: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 24px 64px 24px;
  button {
    width: 83px !important;
    height: 40px;
  }
`;

const Icon = styled.div<{ $isError: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  width: 76px;
  background-color: ${(props) => props.theme.palette.primary.white};
  box-shadow: ${(props) =>
    props.$isError
      ? '0px -6px 16px rgba(235, 0, 27, 0.25), 0px -2px 4px rgba(255, 255, 255, 0.29), 0px 13px 7px rgba(52, 53, 74, 0.29), 0px 8px 7px #34354A;'
      : '-6px -6px 15px #ffffff, -8px -8px 20px #ffffff, 6px 6px 15px rgba(123, 123, 138, 0.08),8px 8px 20px rgba(174, 174, 192, 0.2)'};
  border-radius: 85px;

  svg {
    width: ${(props) => (props.$isError ? 31 : 33)}px;
    height: ${(props) => (props.$isError ? 31 : 25)}px;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 76px;
    width: 76px;

    svg {
      width: ${(props) => (props.$isError ? '34px' : '47px')}
      height: initial;
    }
  }
`;

const HeadLine = styled.h3<{ $isError: boolean }>`
  ${FontStyles.h4};
  color: ${(props) =>
    props.$isError ? props.theme.palette.primary.white : props.theme.palette.primary.black};
  padding-top: 34px;
  padding-bottom: 18px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding-top: 21px;
`;

const SubHeadLine = styled.h6<{ $isError: boolean }>`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) =>
    props.$isError ? props.theme.palette.primary.white : props.theme.palette.greys.darkestGrey};
  margin-top: 10px;
  text-align: center;
  display: flex;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    color: ${(props) =>
      props.$isError ? props.theme.palette.primary.white : props.theme.palette.greys.darkestGrey};
    ${FontStyles.bodyMiddleGilroy};
  }
`;
