import {
  femaleAvatar1,
  femaleAvatar2,
  femaleAvatar3,
  femaleAvatar4,
  femaleAvatar5,
  femaleAvatar6,
  femaleAvatar7,
  maleAvatar1,
  maleAvatar2,
  maleAvatar3,
  maleAvatar5,
} from '@constants/avatars';

const DUMMY_RECENT_CONTACTS: Contact[] = [
  {
    id: '42471fdc-ac21-4448-a42b-5c5rc7fc2cd3',
    name: 'John Smith',
    tag: 'Family',
    avatar: maleAvatar1,
    ibans: [
      {
        iban: 'US 1111 2222 3333 4444 5555 6666',
        bankName: 'Bank of America',
        bic: 'BOFAUS6S',
      },
      {
        iban: 'GB 7777 8888 9999 0000 1111 2222',
        bankName: 'Barclays Bank',
      },
      {
        iban: 'CA 3333 4444 5555 6666 7777 8888',
        bankName: 'TD Canada Trust',
        bic: 'TDCTCATT',
      },
    ],
    address: {
      address: '456 Elm Street',
      city: 'London',
      country: 'United Kingdom',
      postcode: 'SW1A 1AA',
    },
  },
  {
    id: 'c374df2e-fbcf-47f9-be4c-db8ec6134c0a',
    name: 'Emily Johnson',
    tag: 'Coworkers',
    avatar: '',
    ibans: [
      {
        iban: 'ES 1234 5678 9012 3456 7890 1234',
        bankName: 'Spanish Bank',
        bic: 'SPBNESMM',
      },
      {
        iban: 'IT 9876 5432 1098 7654 3210 9876',
        bankName: 'Italian Bank',
      },
      {
        iban: 'JP 2468 1357 8024 6801 3579 2468',
        bankName: 'Japanese Bank',
        bic: 'JAPNBKJP',
      },
    ],
    accountNumbers: [
      {
        account: '9876543210',
        routing: '1234567890',
      },
    ],
    address: {
      address: '123 Main Street',
      city: 'Paris',
      country: 'France',
      postcode: '75000',
    },
    bank: {
      address: '789 Bank Street',
      name: 'Bank of Paris',
    },
  },
  {
    id: '29ee49db-4778-4d43-a496-2faad1ae0e2c',
    name: 'Michael Brown',
    tag: 'Logistics',
    avatar: maleAvatar2,
    accountNumbers: [
      {
        account: '1234567890',
        routing: '9876543210',
      },
    ],
    address: {
      address: '789 Elm Avenue',
      city: 'Berlin',
      country: 'Germany',
      postcode: '10115',
    },
    bank: {
      address: '456 Bank Street',
      name: 'German Bank',
    },
  },
  {
    id: '2ae1370f-4564-469f-8653-0b182dff07a2',
    name: 'Olivia Wilson',
    tag: 'Friends',
    avatar: femaleAvatar1,
    ibans: [
      {
        iban: 'DE 1234 5678 9012 3456 7890 1234',
        bankName: 'German Bank',
      },
      {
        iban: 'AT 9876 5432 1098 7654 3210 9876',
        bankName: 'Austrian Bank',
      },
      {
        iban: 'CH 2468 1357 8024 6801 3579 2468',
        bankName: 'Swiss Bank',
      },
    ],
    address: {
      address: '4th Avenue',
      city: 'New York',
      country: 'United States',
      postcode: '84839',
    },
  },
];

const DUMMY_CONTACTS: Contact[] = [
  {
    id: '8ae1370f-4564-469f-8653-0b182dff07a2',
    name: 'Sophia Campbell',
    tag: 'ResearchAndDevelopment',
    avatar: femaleAvatar2,
    ibans: [
      {
        iban: 'DE 1234 5678 9012 3456 7890 1234',
        bankName: 'German Bank',
      },
      {
        iban: 'AT 9876 5432 1098 7654 3210 9876',
        bankName: 'Austrian Bank',
      },
      {
        iban: 'CH 2468 1357 8024 6801 3579 2468',
        bankName: 'Swiss Bank',
      },
    ],
    address: {
      address: '4th Avenue',
      city: 'New York',
      country: 'United States',
      postcode: '84839',
    },
  },
  {
    id: '29e549db-4778-4d43-a496-2faad1ae0e2c',
    name: 'Isaac Jenkins',
    tag: 'ResearchAndDevelopment',
    avatar: '',
    accountNumbers: [
      {
        account: '987654321',
        routing: '123456789',
      },
    ],
    address: {
      address: 'Mainzer str. 94',
      city: 'Köln',
      country: 'Germany',
      postcode: '77499',
    },
    bank: {
      address: '456 Bank Avenue',
      name: 'German Bank',
    },
  },
  {
    id: '42471fdc-ac21-4448-a42b-5c5ec7fc2cd3',
    name: 'Leah Green Davis',
    tag: 'Family',
    avatar: femaleAvatar3,
    ibans: [
      {
        iban: 'US 1234 5678 9012 3456 7890 1234',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
      {
        iban: 'GB 9876 5432 1098 7654 3210 9876',
        bankName: 'Royal Bank of London',
      },
      {
        iban: 'CA 2468 1357 8024 6801 3579 2468',
        bankName: 'Maple Leaf Bank',
        bic: 'NUL393',
      },
      {
        iban: 'AU 7531 0987 2468 7531 0987 2468',
        bankName: 'Kangaroo Bank',
      },
      {
        iban: 'FR 1357 8024 6801 3579 2468 1357',
        bankName: 'Parisian Bank',
        bic: 'JUC3008',
      },
    ],
    address: {
      address: '123 Main Street',
      city: 'London',
      country: 'United Kingdom',
      postcode: 'SW1A 1AA',
    },
  },
  {
    id: 'c374df2e-fbcf-47f9-bd4c-db8ec6134c0a',
    name: 'Ethan Alexander Lee',
    tag: 'Coworkers',
    avatar: maleAvatar3,
    ibans: [
      {
        iban: 'ES 8024 6801 3579 2468 1357 8024',
        bankName: 'Iberian Bank',
      },
      {
        iban: 'IT 0987 2468 7531 0987 2468 7531',
        bankName: 'Pasta Bank',
      },
      {
        iban: 'JP 3579 2468 1357 8024 6801 3579',
        bankName: 'Sakura Bank',
      },
      {
        iban: 'KR 6801 3579 2468 1357 8024 6801',
        bankName: 'K-Pop Bank',
      },
      {
        iban: 'MX 2468 1357 8024 6801 3579 2468',
        bankName: 'Sombrero Bank',
      },
    ],
    accountNumbers: [
      {
        account: '1234567890',
        routing: '987654321',
      },
    ],
    address: {
      address: '456 Elm Avenue',
      city: 'Paris',
      country: 'France',
      postcode: '75000',
    },
    bank: {
      address: '123 Bank Street',
      name: 'International Bank',
    },
  },
  {
    id: 'f23b84f5-01b7-41e4-89f1-3dd4e9819f9d',
    name: 'Oliver Johnson',
    tag: 'Coworkers',
    avatar: '',
    ibans: [
      {
        iban: 'DE 9876 5432 1098 7654 3210 9876',
        bankName: 'German Bank',
      },
      {
        iban: 'FR 2468 1357 8024 6801 3579 2468',
        bankName: 'Parisian Bank',
      },
      {
        iban: 'GB 8024 6801 3579 2468 1357 8024',
        bankName: 'Royal Bank of London',
      },
    ],
    address: {
      address: '789 Oak Street',
      city: 'Berlin',
      country: 'Germany',
      postcode: '12345',
    },
  },
  {
    id: '2dcb2b09-0668-4d7e-a6b4-4c637e6b9ea5',
    name: 'Emma Thompson',
    tag: 'Family',
    avatar: femaleAvatar4,
    ibans: [
      {
        iban: 'US 2468 1357 8024 6801 3579 2468',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
      {
        iban: 'GB 0987 2468 7531 0987 2468 7531',
        bankName: 'Royal Bank of London',
      },
    ],
    address: {
      address: '321 Elm Street',
      city: 'New York',
      country: 'United States',
      postcode: '10001',
    },
  },
  {
    id: '9a012f39-46c5-4ee7-a6e1-2ffdc256a153',
    name: 'Daniel Wilson',
    tag: 'Recruiting',
    avatar: maleAvatar5,
    accountNumbers: [
      {
        account: '9876543210',
        routing: '1234567890',
      },
    ],
    address: {
      address: '567 Pine Lane',
      city: 'Toronto',
      country: 'Canada',
      postcode: 'M1X 2Y3',
    },
    bank: {
      address: '789 Bank Street',
      name: 'Maple Leaf Bank',
    },
  },
  {
    id: '84c4dbce-849d-47d5-8c5e-ff04c7c2ed56',
    name: 'Ava Anderson',
    tag: 'Employee',
    avatar: femaleAvatar5,
    ibans: [
      {
        iban: 'US 1234 5678 9012 3456 7890 1234',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
    ],
    address: {
      address: '123 Oak Avenue',
      city: 'Los Angeles',
      country: 'United States',
      postcode: '90001',
    },
  },
  {
    id: '7efb8e60-72e7-4ea4-97cd-75b9b7a24f44',
    name: 'James Smith',
    tag: 'Employee',
    avatar: '',
    ibans: [
      {
        iban: 'GB 9876 5432 1098 7654 3210 9876',
        bankName: 'Royal Bank of London',
      },
    ],
    address: {
      address: '456 Elm Street',
      city: 'London',
      country: 'United Kingdom',
      postcode: 'W1K 6TA',
    },
  },
  {
    id: 'b8ac6aa3-9a42-4f50-b2ad-481caaf6d5ef',
    name: 'Mia Johnson',
    tag: 'Family',
    avatar: femaleAvatar6,
    ibans: [
      {
        iban: 'AU 7531 0987 2468 7531 0987 2468',
        bankName: 'Kangaroo Bank',
      },
      {
        iban: 'FR 1357 8024 6801 3579 2468 1357',
        bankName: 'Parisian Bank',
        bic: 'JUC3008',
      },
    ],
    address: {
      address: '789 Maple Avenue',
      city: 'Sydney',
      country: 'Australia',
      postcode: '2000',
    },
  },
  {
    id: 'c1d5c551-2621-4a2e-9787-ff17ff211c4b',
    name: 'Oliver Wilson',
    tag: 'Investors',
    avatar: '',
    ibans: [
      {
        iban: 'DE 2468 1357 8024 6801 3579 2468',
        bankName: 'German Bank',
      },
      {
        iban: 'FR 8024 6801 3579 2468 1357 8024',
        bankName: 'Parisian Bank',
      },
      {
        iban: 'GB 0987 2468 7531 0987 2468 7531',
        bankName: 'Royal Bank of London',
      },
    ],
    address: {
      address: '456 Pine Street',
      city: 'Berlin',
      country: 'Germany',
      postcode: '10115',
    },
  },
  {
    id: '34a3deaa-3d5f-46e4-b749-4f2923280c2d',
    name: 'Nathalie Green',
    tag: 'Investors',
    avatar: femaleAvatar7,
    accountNumbers: [
      {
        account: '1234567890',
        routing: '9876543210',
      },
    ],
    address: {
      address: '789 Maple Lane',
      city: 'Toronto',
      country: 'Canada',
      postcode: 'M2N 3C5',
    },
    bank: {
      address: '123 Bank Street',
      name: 'Maple Leaf Bank',
    },
  },
  {
    id: 'e6a577e1-7e9a-4c41-8ce9-6c73a09edf5c',
    name: 'Liam Martinez',
    tag: 'Advisors',
    avatar: '',
    ibans: [
      {
        iban: 'US 8024 6801 3579 2468 1357 8024',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
      {
        iban: 'GB 0987 2468 7531 0987 2468 7531',
        bankName: 'Royal Bank of London',
      },
    ],
    address: {
      address: '123 Oak Street',
      city: 'Los Angeles',
      country: 'United States',
      postcode: '90001',
    },
  },
  {
    id: 'a6a577e1-7e3a-4c41-8ce9-6c73a09edf5c',
    name: 'Online Store A',
    tag: 'Shopping',
    avatar: '',
    ibans: [
      {
        iban: 'US 8024 6801 3579 2468 1357 8024',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
    ],
    address: {
      address: '123 Oak Street',
      city: 'Los Angeles',
      country: 'United States',
      postcode: '90001',
    },
  },
  {
    id: '46a577e1-7e3a-4c41-8ce9-6c73a09edf5c',
    name: 'Online Store B',
    tag: 'Shopping',
    avatar: '',
    ibans: [
      {
        iban: 'TR 8024 6801 3579 2468 1357 8024',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
    ],
    address: {
      address: '123 Oak Street',
      city: 'Los Angeles',
      country: 'United States',
      postcode: '90001',
    },
  },
  {
    id: '76a577e1-7e3a-4c41-8ce9-6c73a09edf5c',
    name: 'Online Store C',
    tag: 'Shopping',
    avatar: '',
    ibans: [
      {
        iban: 'DE 8024 6801 3579 2468 1357 8024',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
    ],
    address: {
      address: '123 Oak Street',
      city: 'Los Angeles',
      country: 'United States',
      postcode: '90001',
    },
  },
  {
    id: 'b6a577e1-7e3a-4c41-8ce9-6c73a09edf5c',
    name: 'Online Store D',
    tag: 'Shopping',
    avatar: '',
    ibans: [
      {
        iban: 'CA 8024 6801 3579 2468 1357 8024',
        bankName: 'First National Bank',
        bic: 'DEFS004',
      },
    ],
    address: {
      address: '123 Oak Street',
      city: 'Los Angeles',
      country: 'United States',
      postcode: '90001',
    },
  },
];

const ALL_DUMMY_CONTACTS = [...DUMMY_CONTACTS, ...DUMMY_RECENT_CONTACTS];

export { DUMMY_CONTACTS, DUMMY_RECENT_CONTACTS, ALL_DUMMY_CONTACTS };
