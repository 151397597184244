import { useMutation, useQuery } from '@tanstack/react-query';

import { ManagedAccountsService } from '@services/managed-accounts-service';

export const useCreateManagedAccount = () => {
  return useMutation({
    mutationFn: (accountData: ManagedAccountCreationData) => {
      return ManagedAccountsService.createManagedAccount(accountData);
    },
  });
};

export const useUpgradeManagedAccountToIban = () => {
  return useMutation({
    mutationFn: (accountId: string) => {
      return ManagedAccountsService.upgradeManagedAccountToIban(accountId);
    },
  });
};

export const useGetAllManagedAccounts = (
  userHasPassedKYC: VerifiedKYCType,
  managedAccountsSearchQuery?: ManagedAccountsSearchQuery
) => {
  return useQuery({
    queryKey: ['getAllManagedAccounts'],
    queryFn: async ({ signal }) => {
      return await ManagedAccountsService.getAllManagedAccounts(signal, managedAccountsSearchQuery);
    },
    enabled: !!userHasPassedKYC,
  });
};

export const useGetManagedAccountIban = (managedAccountId: string) => {
  return useQuery({
    queryKey: ['getManagedAccountIban', managedAccountId],
    queryFn: async ({ signal }) => {
      return await ManagedAccountsService.getManagedAccountIban(signal, managedAccountId);
    },
    enabled: !!managedAccountId,
  });
};
