import { useMutation, useQuery } from '@tanstack/react-query';

import { CorporateService } from '@services/corporate-service';
import { OnboardingService } from '@services/onboarding-service';

export const useGetCorporate = () => {
  return useQuery({
    queryKey: ['getCorporate'],
    queryFn: async ({ signal }) => {
      return await CorporateService.getCorporate(signal);
    },
  });
};

export const useStartKYB = () => {
  return useMutation({
    mutationFn: () => {
      return CorporateService.startKYB();
    },
  });
};

export const useUpdateCorporate = () => {
  return useMutation({
    mutationFn: (updatedCorporateData: WeavrUpdatedCorporateData) => {
      return CorporateService.updateCorporate(updatedCorporateData);
    },
  });
};

export const useCreateCorporate = ({
  captchaToken,
  idempotencyRef,
}: Omit<CloudflareCaptcha, 'isValid'>) => {
  return useMutation({
    mutationFn: (corporateData: CorporateRegistrationData) => {
      return CorporateService.createCorporate(corporateData, { captchaToken, idempotencyRef });
    },
  });
};

export const useChargeFee = () => {
  return useMutation({
    mutationFn: (feeBody: WeavrChargeFeeBody) => {
      return CorporateService.chargeFee(feeBody);
    },
  });
};

export const useSendEmailVerificationCodeCorporateRegistration = () => {
  return useMutation({
    mutationFn: (email: string) => {
      return OnboardingService.sendEmailVerificationCode(email);
    },
  });
};

export const useVerifyCorporateEmail = () => {
  return useMutation({
    mutationFn: ({
      email,
      verificationCode,
      signal,
    }: WeavrEmailVerification & { signal: AbortSignal }) => {
      return OnboardingService.verifyEmail({ email, verificationCode, signal });
    },
  });
};

export const useGetKYB = () => {
  return useQuery({
    queryKey: ['getKYB'],
    queryFn: async ({ signal }) => {
      return await CorporateService.getKYB(signal);
    },
  });
};
