import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  onClick: () => void;
  text: string;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  icon?: ReactNode;
}

const DeleteButton = ({ onClick, text, className, style, disabled, icon }: Props) => {
  return (
    <Delete
      className={className}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {icon} {text}
    </Delete>
  );
};
export default DeleteButton;

const Delete = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 40px;
  box-sizing: border-box;
  border-radius: 75px;
  gap: 9px;
  ${FontStyles.buttonGilroyType3};
  width: 274px;
  background: transparent;
  flex: 1;
  margin-top: 20px;
  transition: 300ms ease-in-out;
  border: 1px solid ${(props) => props.theme.palette.negative.lessContrastRed};
  color: ${(props) => props.theme.palette.negative.lessContrastRed};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.palette.negative.lessContrastRed};
    color: ${(props) => props.theme.palette.primary.white};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: unset;
  }
`;
