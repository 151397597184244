import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconPlus } from '@constants/icons';

import InviteUserModal from '@components/InviteUserModal/InviteUserModal';

interface Props {
  className?: string;
}

const SettingsInviteUserButton = ({ className }: Props) => {
  const openModal = useBoundStore((state) => state.openModal);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const setMessage = useBoundStore((state) => state.setMessage);
  const setNotify = useBoundStore((state) => state.setNotify);
  const setPopUpType = useBoundStore((state) => state.setPopUpType);

  return (
    <Container
      className={className}
      onClick={() => {
        if (verifiedKYC) {
          openModal(<InviteUserModal />);
        } else {
          setNotify(true);
          setPopUpType('verify');
          setMessage('Verify account first to add more users');
        }
      }}
    >
      <Circle className="circle">
        <IconPlus />
      </Circle>
      Invite additional users
    </Container>
  );
};

export default SettingsInviteUserButton;

const Container = styled.button`
  display: flex;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.palette.primary.blue};
  ${FontStyles.bodySmallGilroy};
  justify-content: center;
  align-items: center;
  height: 80px;
  border-radius: 20px;
  color: ${(props) => props.theme.palette.primary.blue};
  cursor: pointer;
  transition: 300ms ease-in-out;
  gap: 15px;
  width: 100%;
  flex: 1 0 auto;

  &:hover {
    color: ${(props) => props.theme.palette.primary.white};
    background-color: ${(props) => props.theme.palette.primary.blue};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.subtitleForH1SlogansOnly};
    height: 120px;
    width: calc(50% - 10px);
  }

  &:hover .circle {
    color: ${(props) => props.theme.palette.primary.blue};
    background-color: ${(props) => props.theme.palette.primary.white};
  }
`;

const Circle = styled.div`
  display: flex;
  border-radius: 50%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  color: ${(props) => props.theme.palette.primary.white};
  background-color: ${(props) => props.theme.palette.primary.blue};
  transition: 300ms ease-in-out;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    width: 40px;
  }
`;
