import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { useGetContacts } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { IconPlus } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';
import useMediaQuery from '@hooks/useMediaQuery';
import useOnboardingSteps from '@hooks/useOnboardingSteps';

import ContactsList from '@components/ContactsList/ContactsList';
import NoContactsPlaceholder from '@components/NoContactsPlaceholder/NoContactsPlaceholder';
import {
  AddIconButton,
  Container,
  Content,
  ContentWrapper,
  Fade,
  Footer,
  FooterWrapper,
  Header,
  HeaderTop,
  LargeMobileButton,
  Link,
  TopHeading,
  VerifyButton,
} from '@components/QuickTransfer/QuickTransfer.styles';
import SearchBar from '@components/SearchBar/SearchBar';

interface Props {
  className?: string;
}

const defaultContacts: ContactsWithBankDetails[] = [];

const QuickTransfer = ({ className }: Props) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);
  const {
    submitting,
    emailVerified,
    mobileNumberVerified,
    isKYBInitiatedStatus,
    isKYBApprovedStatus,
    userHasManagedAccounts,
    handleButtonClick,
  } = useOnboardingSteps();

  const collapsed = useBoundStore((state) => state.collapsed);
  const searchQuery = useBoundStore((state) => state.searchQuery);

  const {
    data: managedAccounts,
    isError: isGetAllManagedAccountsError,
    error: managedAccountsError,
  } = useGetAllManagedAccounts(isKYBApprovedStatus);

  const managedAccountId = managedAccounts?.accounts ? managedAccounts.accounts[0].id : '';

  const { data: contactData } = useGetContacts(managedAccountId, searchQuery);

  const contacts = contactData?.contacts ?? [];

  const { handleHTTPErrors } = useHTTPErrorHandler();

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetAllManagedAccountsError && managedAccountsError) {
      errorsToHandle.push(managedAccountsError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [handleHTTPErrors, isGetAllManagedAccountsError, managedAccountsError]);

  const getButtonText = () => {
    if (!emailVerified) {
      return 'Verify Email';
    }

    if (!mobileNumberVerified) {
      return 'Get Started';
    }

    if (isKYBInitiatedStatus) {
      return 'View Status';
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return 'Get IBAN';
    }

    return t('verify_account');
  };

  return (
    <Container
      $verified={isKYBApprovedStatus && userHasManagedAccounts}
      $collapsed={collapsed}
      className={className}
    >
      <Header>
        <HeaderTop>
          <TopHeading>{t('quick_transfer')}</TopHeading>
          <AddIconButton to="/send-money">
            <IconPlus />
          </AddIconButton>
        </HeaderTop>
        <div>
          <SearchBar />
        </div>
      </Header>
      <ContentWrapper>
        <Content $verified={isKYBApprovedStatus}>
          {contacts.length > 0 ? (
            <ContactsList
              contacts={contacts}
              searching={searchQuery}
            />
          ) : (
            <NoContactsPlaceholder
              screen="dashboard"
              size={isTablet ? 'tablet' : 'mobile'}
              text="no_contacts_yet"
            />
          )}
          {(!isKYBApprovedStatus || (isKYBApprovedStatus && !userHasManagedAccounts)) && (
            <VerifyButton
              disabled={submitting}
              onClick={handleButtonClick}
              text={getButtonText()}
              variant="transparentBlack"
            />
          )}
        </Content>
      </ContentWrapper>
      <FooterWrapper>
        {isKYBApprovedStatus && userHasManagedAccounts && <Fade />}
        <Footer
          $verified={isKYBApprovedStatus && userHasManagedAccounts}
          $contacts={contacts ? contacts.length : defaultContacts.length}
        >
          <Link to="/send-money">{t('contact_list')}</Link>
        </Footer>
      </FooterWrapper>
      <LargeMobileButton
        className={!isKYBApprovedStatus || !userHasManagedAccounts ? 'disabled' : ''}
        to="/send-money"
      >
        {t('contact_list')}
      </LargeMobileButton>
    </Container>
  );
};

export default QuickTransfer;
