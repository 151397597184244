import React from 'react';
import styled from 'styled-components';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { IconInfo, IconInterfaceLink } from '@constants/icons';
import { RocketBlasting } from '@constants/images';

import { theme } from '@theme/muiTheme';

import TotalCardSpending from '@elements/Card/TotalCardSpending/TotalCardSpending';

const CardHeader = () => {
  const { symbol, amountWithoutSymbol } = Commons.currencyFormatterWithSymbolAtStart(23233);

  return (
    <Container>
      <CardWrap>
        <Info>
          <TitleRow>
            <StyledIconInfo color={theme.legacy.palette.greys.asphaltTint} />
            <AvailableAmount>Available Amount</AvailableAmount>
            <IconInterfaceLink />
            <InterfaceLink>LT 2323 3323..</InterfaceLink>
          </TitleRow>
          <BalanceRow>
            <BalanceText>
              <Symbol>{symbol}</Symbol>
              <Amount>{amountWithoutSymbol}</Amount>
            </BalanceText>
          </BalanceRow>
        </Info>
        <TotalCardSpending />
      </CardWrap>
    </Container>
  );
};

export default CardHeader;

// Styled components
export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 12px 12px;
`;

export const Info = styled.div`
  padding: 5px 1px 11px;
  display: flex;
  width: 390px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const InterfaceLink = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  display: flex;
  text-align: center;
  padding: 6px 10px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  border-radius: 79px;
  gap: 10px;
  color: #1099fd;
  background-color: #eefbff;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const BalanceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  box-sizing: border-box;
`;

const BalanceText = styled.div`
  display: flex;
  align-items: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.7%;
  gap: 16px;
`;

const Symbol = styled.span`
  ${FontStyles.buttonsNeuePlakType1};
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

const Amount = styled.span`
  ${FontStyles.buttonsNeuePlakType1};
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.7%; /* 56.016px */
`;

const AvailableAmount = styled.div`
  ${FontStyles.buttonsNeuePlakType1};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
  letter-spacing: 0.15px;
`;

export const CardWrap = styled.div`
  width: 889px;
  display: flex;
  justify-content: space-between;
  padding: 25px 30px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
  background: var(
    --Gradients-Grey_light_gradient,
    linear-gradient(264deg, #f3f3f9 19.09%, #d0d4e5 99.05%)
  );
`;

const StyledIconInfo = styled(IconInfo)`
  color: ${({ color }) => color || 'currentColor'};
`;
