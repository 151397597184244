import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '@hooks/useMediaQuery';

import TransactionFormWrapper from '@components/transaction/TransactionFormWrapper/TransactionFormWrapper';

const TransactionFormRedirect = () => {
  const isLaptop = useMediaQuery('(min-width: 1200px)');
  const navigate = useNavigate();

  useEffect(() => {
    if (isLaptop) {
      navigate(-1);
    }
  }, [isLaptop, navigate]);

  return <TransactionFormWrapper />;
};

export default TransactionFormRedirect;
