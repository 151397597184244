import { ReactNode } from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  children?: ReactNode;
  className?: string;
}

// TODO: Vielleicht refactoren wegen den first-child Vorkommen?
const ModalSubHeadline = ({ children, className }: Props) => {
  return <SubHeadLine className={className}>{children}</SubHeadLine>;
};

const SubHeadLine = styled.h6`
  ${FontStyles.h5};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin-top: 10px;
  padding-bottom: 36px;
  text-align: center;

  & > :first-child {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    & > :first-child {
      display: flex;
    }
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export default ModalSubHeadline;
