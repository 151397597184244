import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

import LargeButton from '@elements/LargeButton/LargeButton';

export const CreatePasswordForm = styled.form<{ $restore: boolean }>`
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  box-sizing: border-box;
  padding: 65px 20px 65px;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
    padding: ${(props) => (props.$restore ? '0 90px' : 'initial')};
    align-items: ${(props) => (props.$restore ? 'center' : 'start')};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 25px 0 60px;
    align-items: center;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: ${(props) => (props.$restore ? '20px 50px 0 95px' : '0 50px 0 95px')};
  }
`;

export const Heading = styled.p<{ $restore: boolean }>`
  ${FontStyles.h3};
  text-align: center;
  width: 300px;
  display: flex;
  margin-top: 30px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 350px;
    align-self: center;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: unset;
    ${FontStyles.h4};
    margin-top: ${(props) => (props.$restore ? 30 : 105)}px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h3};
    margin-top: ${(props) => (props.$restore ? 30 : 82.5)}px;
  }
`;

export const SubHeading = styled.p<{ $restore: boolean }>`
  ${FontStyles.bodyMiddleGilroy};
  text-align: center;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  width: 300px;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    text-align: ${(props) => (props.$restore ? 'center' : 'start')};
    align-self: center;
    width: unset;
  }
`;

export const Label = styled.p`
  ${FontStyles.h5};
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h6};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h5};
  }
`;

export const WeavrPassword = styled.div<{ $focused: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  transition: border ease-in-out 300ms;
  height: 50px;
  max-height: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;

  ${({ $focused }) =>
    $focused &&
    css`
      border: 1px solid ${(props) => props.theme.palette.primary.blue};
    `}

  & > * {
    width: 100%;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    max-height: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 50px;
    max-height: 50px;
  }
`;

export const CustomButton = styled(LargeButton)<{ $restore: boolean }>`
  max-height: 50px;
  transition: background 300ms ease-in-out;
  min-width: 205px;
  width: 205px;
  align-self: center;
  margin-top: 25px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: end;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-height: 40px;
    order: unset;
    width: 182px;
    min-width: 182px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-height: 50px;
    width: 165px;
    min-width: 165px;
  }
`;

export const CustomOnboardingStepNavigation = styled(OnboardingStepNavigation)<{
  $restore: boolean;
}>`
  align-self: start;
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: ${(props) => (props.$restore ? 'flex' : 'none')};
    margin-bottom: 30px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-bottom: initial;
  }
`;

export const PasswordErrorMessage = styled.span<{ $visible: boolean }>`
  box-sizing: border-box;
  display: flex;
  ${FontStyles.bodySmallGilroy};
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  height: 30px;
  align-self: end;
  align-items: center;
`;
