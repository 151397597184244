import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import Captcha from '@components/Captcha/Captcha';
import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

import LargeButton from '@elements/LargeButton/LargeButton';
import TextInput from '@elements/input/TextInput/TextInput';

export const FormWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 64px 20px;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    align-items: start;
    padding: 0;
    height: unset;
    width: unset;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    margin: 15px 9px 0 60px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin: 88px 50px 0 95px;
  }
`;

export const CustomStepNavigation = styled(OnboardingStepNavigation)`
  display: none;
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0 39px 0 39px;
    width: calc(100% - 78px);
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    margin: 0;
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 30px 0;
  gap: 20px;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 92px 0 50px 0;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    margin: 100px 0 45px 0;
    align-items: start;
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin: 95px 0 51px 0;
  }
`;

export const Title = styled.div`
  ${FontStyles.h3};
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    ${FontStyles.h4};
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    ${FontStyles.h3};
  }
`;

export const Subtitle = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  display: flex;
  justify-content: center;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 314px;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
    text-align: start;
    width: 361px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Label = styled.label<{ color?: 'white' }>`
  ${FontStyles.h5};
  color: ${(props) =>
    props.color ? props.theme.palette.primary.white : props.theme.palette.primary.black};
  height: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: end;
`;

export const CustomTextInput = styled(TextInput)`
  width: 100%;
  ${FontStyles.bodyMiddleGilroy};

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    height: 40px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    height: 50px;
  }
`;

export const EmailErrorContainer = styled.div<{ $visible: boolean }>`
  ${FontStyles.bodySmallGilroy};
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  opacity: ${(props) => (props.$visible ? '1' : '0')};
  justify-content: end;
`;

export const CustomLargeButton = styled(LargeButton)`
  transition: unset;
  width: 199px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 45px;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    width: 182px;
    height: 40px;
    margin: 100px 0 0 calc(100% - 182px);
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    width: 119px;
    height: 50px;
    margin: 35px calc(100% - 119px) 0 0;
  }
`;

export const CustomCaptcha = styled(Captcha)`
  padding: 5px 0 35px 0;
`;
