import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import Contact from '@components/Contact/Contact';

interface Props {
  contacts?: ContactsWithBankDetails[];
  searching?: string;
}

const ContactsList = ({ contacts, searching }: Props) => {
  const { t } = useTranslation();

  if (!contacts) return null;

  const recentContacts = contacts.slice(0, 3);
  const otherContacts = contacts.slice(3);

  return (
    <Container>
      <Wrapper className="recent">
        <CategoryHeading>{searching === '' ? t('recent_contacts') : 'Results'}</CategoryHeading>
        {contacts.length < 15
          ? contacts.map((contact: ContactsWithBankDetails, index) => (
              <Contact
                key={index}
                contact={contact}
              />
            ))
          : recentContacts.map((contact: ContactsWithBankDetails, index) => (
              <Contact
                key={index}
                contact={contact}
              />
            ))}
      </Wrapper>
      {contacts.length >= 15 && (
        <Wrapper>
          <CategoryHeading>{t('contacts')}</CategoryHeading>
          {otherContacts.map((contact: ContactsWithBankDetails, index) => (
            <Contact
              key={index}
              contact={contact}
            />
          ))}
        </Wrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  gap: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  & h2:not(:first-child) {
    margin-top: 20px;
  }

  & > :not(.recent) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    & > :not(.recent) {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-sizing: border-box;
  width: 100%;

  & > :not(:nth-child(-n + 4)) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 10px;
    & > :not(:nth-child(-n + 4)) {
      display: flex;
    }
  }
`;

const CategoryHeading = styled.h2`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export default ContactsList;
