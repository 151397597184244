import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import {
  IconStatusBar,
  IconStatusBarBlue,
  IconStatusBarStar,
  IconStatusBarStarBlue,
  IconStatusBarStarCompleted,
  IconVerify,
} from '@constants/icons';
import {
  KYCBackgroundEmailSet,
  KYCBackgroundPhoneSet,
  KYCBackgroundSet,
  StatusBarProgressCompleted,
} from '@constants/images';

import useOnboardingSteps from '@hooks/useOnboardingSteps';

import LargeButton from '@elements/LargeButton/LargeButton';

interface Props {
  company: boolean;
}

const AccountDetailsKYC = ({ company }: Props) => {
  const {
    isLoading,
    submitting,
    emailVerified,
    mobileNumberVerified,
    isKYBNotStartedStatus,
    isKYBInitiatedStatus,
    isKYBApprovedStatus,
    userHasManagedAccounts,
    handleButtonClick,
  } = useOnboardingSteps();

  if (isLoading) return null;

  const getStatusBarContent = () => {
    if (!emailVerified || !mobileNumberVerified) {
      return <IconVerify />;
    }

    if (isKYBInitiatedStatus) {
      return (
        <>
          <IconStatusBarStar />
          <IconStatusBar />
        </>
      );
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return (
        <>
          <IconStatusBarStarCompleted />
          <StatusBarProgressCompleted />
        </>
      );
    }

    return (
      <>
        <IconStatusBarStarBlue />
        <IconStatusBarBlue />
      </>
    );
  };

  const getHeading = () => {
    if (!emailVerified) {
      return 'Verify your email address';
    }

    if (!mobileNumberVerified) {
      return 'Enable 2-factor authentication';
    }

    if (company && isKYBInitiatedStatus) {
      return 'Verification process initiated';
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return 'Your account is now verified.';
    }

    if (company && isKYBNotStartedStatus) {
      return 'Start verification process';
    }

    return 'Verify yourself to access all features';
  };

  const getSubheading = () => {
    if (!emailVerified) {
      return 'Complete your account setup by verifying your email address.';
    }

    if (!mobileNumberVerified) {
      return 'Please confirm your phone number to finalize your registration.';
    }

    if (isKYBInitiatedStatus) {
      return 'We are currently processing the documents you provided.';
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return 'Click here to get your IBAN';
    }

    if (company && isKYBNotStartedStatus) {
      return 'To get started, you need to verify your organization.';
    }

    return 'To get started, you need to verify yourself.';
  };

  const getButtonText = () => {
    if (!emailVerified) {
      return 'Verify Email';
    }

    if (!mobileNumberVerified) {
      return 'Get Started';
    }

    if (isKYBInitiatedStatus) {
      return 'View Status';
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return 'Get IBAN';
    }

    if (company && isKYBNotStartedStatus) {
      return 'Verify Organization';
    }

    return 'Verify Myself';
  };

  return (
    <Container
      $email={!emailVerified}
      $phone={!mobileNumberVerified}
    >
      <WrapperContent className={company ? 'company' : ''}>
        <StatusBar
          $email={!emailVerified}
          $phone={!mobileNumberVerified}
        >
          {getStatusBarContent()}
        </StatusBar>
        <Text
          $email={!emailVerified}
          $phone={!mobileNumberVerified}
        >
          <p className="huge">{getHeading()}</p>
          <p className="small">{getSubheading()}</p>
        </Text>
        <VerifyButton
          disabled={submitting}
          onClick={handleButtonClick}
          text={getButtonText()}
          variant="blueBlack"
        />
      </WrapperContent>
    </Container>
  );
};

export default AccountDetailsKYC;

const Container = styled.div<{ $phone: boolean; $email: boolean }>`
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding: 35px;
  box-sizing: border-box;
  flex: 1;
  min-width: 943px;
  height: 313px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-image: url(${KYCBackgroundSet[0]});
  background-image: -webkit-image-set(
    url(${KYCBackgroundSet[0]}) 1x,
    url(${KYCBackgroundSet[1]}) 2x
  );

  ${(props) =>
    props.$email
      ? css`
          background-image: url(${KYCBackgroundEmailSet[0]});
          background-image: -webkit-image-set(
            url(${KYCBackgroundEmailSet[0]}) 1x,
            url(${KYCBackgroundEmailSet[1]}) 2x
          );
        `
      : props.$phone
        ? css`
            background-image: url(${KYCBackgroundPhoneSet[0]});
            background-image: -webkit-image-set(
              url(${KYCBackgroundPhoneSet[0]}) 1x,
              url(${KYCBackgroundPhoneSet[1]}) 2x
            );
          `
        : null}

  & > .company {
    width: 320px;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  & > .company {
    width: 320px;
  }
`;

const StatusBar = styled.div<{ $phone: boolean; $email: boolean }>`
  display: flex;
  gap: 7px;
  padding-bottom: 20px;

  ${(props) =>
    (props.$email || props.$phone) &&
    css`
      margin-left: -10px;
      padding-bottom: initial;
    `}
`;

const Text = styled.div<{ $phone: boolean; $email: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.primary.white};
  gap: 10px;
  //padding-bottom: 25px;
  width: 278px;

  ${(props) =>
    (props.$email || props.$phone) &&
    css`
      padding-bottom: initial;
    `}

  & > .huge {
    ${FontStyles.h4};
  }

  & > .small {
    ${FontStyles.bodySmallGilroy};
    height: 52px;
  }
`;

const VerifyButton = styled(LargeButton)`
  display: flex;
  width: 190px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
`;
