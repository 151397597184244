import { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { IconEyeDashed, IconEyeVisible } from '@constants/icons';

interface Props {
  style?: CSSProperties;
  onClick: () => void;
}
const EyeSwitch = ({ style, onClick }: Props) => {
  const [hover, setHover] = useState('');

  const timer = useBoundStore((state) => state.timer);
  const setTimer = useBoundStore((state) => state.setTimer);
  const setTimeLeft = useBoundStore((state) => state.setTimeLeft);
  const hidden = useBoundStore((state) => state.hidden);
  const setHidden = useBoundStore((state) => state.setHidden);
  const enter = useBoundStore((state) => state.enter);
  const setEnter = useBoundStore((state) => state.setEnter);

  const handleClick = () => {
    if (timer) {
      setTimer(false);
      setTimeLeft(300);
      setHidden(true);
    } else {
      setEnter(!enter);
    }
  };
  return (
    <Tooltip style={style}>
      <InvisibleButton
        onClick={() => {
          handleClick();
          onClick();
        }}
        onMouseEnter={() => setHover('visible')}
        onMouseLeave={() => setHover('')}
      >
        {!hidden || enter ? (
          <IconEyeVisible className="icon" />
        ) : (
          <IconEyeDashed className="icon" />
        )}
      </InvisibleButton>
      <TooltipText className={hover}>Reveal Details</TooltipText>
    </Tooltip>
  );
};
export default EyeSwitch;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;

  .visible {
    opacity: 100%;
    visibility: visible;
  }
`;

const TooltipText = styled.span`
  opacity: 0;
  visibility: hidden;
  background-color: #ffffff;
  border-radius: 30px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  padding: 0.6rem 1.2rem;
  white-space: nowrap;
  transform: translateX(-50%);
  transition: ease-in-out 300ms;
  font-family: ${(props) => props.theme.typography.h6.fontFamily};
  font-weight: ${(props) => props.theme.typography.h6.fontWeight};
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  line-height: ${(props) => props.theme.typography.h6.lineHeight};
  color: ${(props) => props.theme.palette.primary.black};

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

const InvisibleButton = styled.button`
  color: ${(props) => props.theme.palette.greys.darkGrey};
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;

  &:hover .icon {
    color: ${(props) => props.theme.palette.primary.white};
    cursor: pointer;
  }
`;
