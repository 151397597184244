import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import useMediaQuery from '@hooks/useMediaQuery';

import CompanyTypeInput from '@components/CompanyTypeInput/CompanyTypeInput';

import {
  ButtonRow,
  Container,
  CustomIconOnboardingCheck,
  CustomLargeButton,
  CustomOnboardingCountryInput,
  CustomStepNavigation,
  CustomTextInput,
  ErrorContainer,
  Heading,
  InputRow,
  Label,
  SubHeadline,
} from './OnboardingCompanyDetailsForm.styles';

const OnboardingCompanyDetailsForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);

  useEffect(() => {
    if (!location.state) navigate('/register');
    if (!location.state.from) navigate('/register');
    if (location.state.from !== '/register/2') navigate('/register');
  }, [location.state, navigate]);

  const setCorporateData = useBoundStore((state) => state.setCorporateData);
  const corporateData = useBoundStore((state) => state.corporateData);

  const [formDataTouched, setFormDataTouched] = useState({
    companyNameTouched: false,
    registrationNumberTouched: false,
  });

  const [registrationNumberValid, setRegistrationNumberValid] = useState<boolean>(true);
  const [companyNameValid, setCompanyNameValid] = useState<boolean>(true);
  const [formFilled, setFormFilled] = useState<boolean>(false);
  const [companyType, setCompanyType] = useState<string>('');
  const [companyRegistrationCountry, setCompanyRegistrationCountry] = useState<string>('');

  const handleChange = useCallback(
    (name: string, value: string) => {
      setCorporateData((prevState) => ({ ...prevState, [name]: value }));
    },
    [setCorporateData]
  );

  const handleCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange('companyName', event.target.value);
    setCompanyNameValid(event.target.value !== '');
  };

  const handleCompanyNameInputBlur = () => {
    setCompanyNameValid(corporateData.companyName !== '');
    setFormDataTouched((prevState) => ({ ...prevState, companyNameTouched: true }));
  };

  const handleRegistrationNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange('companyRegistrationNumber', event.target.value);
    setRegistrationNumberValid(event.target.value.length > 3);
  };

  const handleRegistrationNumberInputBlur = () => {
    setRegistrationNumberValid(
      corporateData.companyRegistrationNumber !== '' &&
        corporateData.companyRegistrationNumber.length > 3
    );
    setFormDataTouched((prevState) => ({ ...prevState, registrationNumberTouched: true }));
  };

  useEffect(() => {
    setFormFilled(
      corporateData.companyName !== '' &&
        corporateData.companyType !== '' &&
        corporateData.companyRegistrationCountry !== '' &&
        corporateData.companyRegistrationNumber !== '' &&
        companyNameValid &&
        registrationNumberValid
    );
  }, [
    companyNameValid,
    corporateData.companyName,
    corporateData.companyRegistrationCountry,
    corporateData.companyRegistrationNumber,
    corporateData.companyType,
    registrationNumberValid,
  ]);

  useEffect(() => {
    handleChange('companyType', companyType);
    handleChange('companyRegistrationCountry', companyRegistrationCountry);
  }, [companyType, companyRegistrationCountry, handleChange]);

  return (
    <Container>
      <CustomStepNavigation text="Previous Step" />
      <Heading>Submit your Details</Heading>
      <SubHeadline>Uncompromised Safety for Your Data</SubHeadline>
      {!isTablet && (
        <InputRow>
          <Label>Country</Label>
          <CustomOnboardingCountryInput
            soleTrader={corporateData.companyType === 'Sole Trader'}
            value={corporateData.companyRegistrationCountry}
            setValue={setCompanyRegistrationCountry}
          />
        </InputRow>
      )}
      <InputRow>
        <Label>Company Name</Label>
        <CustomTextInput
          autoComplete="do-not-autofill"
          value={corporateData.companyName}
          valid={companyNameValid}
          icon={corporateData.companyName && <CustomIconOnboardingCheck />}
          onChange={handleCompanyNameChange}
          blurHandler={handleCompanyNameInputBlur}
          placeholder="Company Name"
        />
        <ErrorContainer $visible={!companyNameValid}>No valid input</ErrorContainer>
      </InputRow>
      <InputRow>
        <Label>Type of Business</Label>
        <CompanyTypeInput
          value={corporateData.companyType}
          setValue={setCompanyType}
        />
        <ErrorContainer $visible={true} />
      </InputRow>
      <InputRow>
        <Label>Registration Number</Label>
        <CustomTextInput
          valid={registrationNumberValid}
          value={corporateData.companyRegistrationNumber}
          onChange={handleRegistrationNumberChange}
          blurHandler={handleRegistrationNumberInputBlur}
          placeholder="21555121213"
          icon={
            corporateData.companyRegistrationNumber &&
            registrationNumberValid && <CustomIconOnboardingCheck />
          }
        />
        <ErrorContainer
          $visible={formDataTouched.registrationNumberTouched && !registrationNumberValid}
        >
          Not a valid registration number
        </ErrorContainer>
      </InputRow>
      {isTablet && (
        <InputRow>
          <Label>Registration Country</Label>
          <CustomOnboardingCountryInput
            soleTrader={corporateData.companyType === 'Sole Trader'}
            value={corporateData.companyRegistrationCountry}
            setValue={setCompanyRegistrationCountry}
          />
        </InputRow>
      )}
      <ButtonRow>
        <CustomLargeButton
          onClick={() =>
            navigate('/register/4', {
              state: {
                from: location.pathname,
              },
            })
          }
          variant="blueWhite"
          text="Proceed"
          disabled={!formFilled}
        />
      </ButtonRow>
    </Container>
  );
};

export default OnboardingCompanyDetailsForm;
