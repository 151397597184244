import React, { useState } from 'react';

import { useExportCsv } from '@api/Analytics/analyticsApi';
import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { createFileName, downloadCSV } from '@shared/functions';

import { IconDownload } from '@constants/icons';

import BlueButton from '@elements/BlueButton/BlueButton';

import { ButtonContainer } from './ExportButton.styles';

export const ExportButton = () => {
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const timespan = useBoundStore((state) => state.timespan);
  const searchQuery = useBoundStore((state) => state.searchQuery);
  const orderByField = useBoundStore((state) => state.orderByField);
  const sortingDirection = useBoundStore((state) => state.sortingDirection);
  const offset = useBoundStore((state) => state.offset);
  const visibleRows = useBoundStore((state) => state.visibleRows);
  const filterType = useBoundStore((state) => state.filterType);
  const filterStatus = useBoundStore((state) => state.filterStatus);
  const selected = useBoundStore((state) => state.selected);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const { mutate: exportCsvMutation } = useExportCsv();

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const exportClick = () => {
    if (submitting) return;

    setSubmitting(true);

    exportCsvMutation(
      {
        transactionRequest: {
          start: timespan.start,
          end: timespan.end,
          searchQuery: searchQuery ?? '',
          orderBy: orderByField,
          sortingDirection: sortingDirection,
          offset: offset.toString(),
          limit: visibleRows.toString(),
          filterType: filterType,
          filterStatus: filterStatus,
          managedAccountId,
        },
        ids: selected.length > 0 ? selected : undefined,
      },
      {
        onSuccess: (data) => {
          downloadCSV(data, createFileName());
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <ButtonContainer>
      <BlueButton
        disabled={!verifiedKYC || submitting}
        onClick={exportClick}
      >
        Export
        <IconDownload />
      </BlueButton>
    </ButtonContainer>
  );
};
