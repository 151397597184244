import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import { Field } from '@elements/input/TextInput/TextInput';

export const Container = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;

export const CustomField = styled(Field)`
  ${FontStyles.bodyMiddleGilroy};
  height: 50px;
  align-items: center;
  padding: 15px 10px;
  border-radius: 10px;
  &:focus {
    border: 1px solid ${(props) => props.theme.palette.primary.blue} !important;
    color: ${(props) => props.theme.palette.primary.blue};
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
  & ::placeholder {
    ${FontStyles.body2};
    ${Field} {
      ${FontStyles.body2};
    }
  }
`;
