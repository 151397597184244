import React from 'react';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { formatDate } from '@shared/datehalper';
import { Commons, TransactionCommons } from '@shared/functions';

import useMediaQuery from '@hooks/useMediaQuery';

import TransactionDetailsPendingModal from '@components/PendingDepositModal/TransactionDetailsPendingModal';
import {
  AmountFieldMobile,
  AmountFieldTablet,
  CheckBoxField,
  DateField,
  FlexWrapper,
  FromToField,
  IconField,
  IconWithBackground,
  Row,
  RowMobile,
  RowWrap,
  StatusWrap,
  TransactionType,
} from '@components/analytics/AnalyticsTransactions/AnalyticsTransaction.styles';
import TransactionDetails from '@components/transaction/TransactionDetails/TransactionDetails';

import BorderButtonBlue from '@elements/BorderButtonBlue/BorderButton';
import CheckBox from '@elements/CheckBox/CheckBox';

import StatusField from '../../../elements/TransactionStatus/Status';

interface Props {
  entry: DbTransaction;
  handleSelect: (id: string) => void;
  selected: boolean;
}

const TransactionRow = ({ selected, entry, handleSelect }: Props) => {
  const openModal = useBoundStore((state) => state.openModal);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const IS_STATUS_PENDING = entry.status.toUpperCase() === 'PENDING';
  const { breakpoints } = useTheme();

  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  const handleOpenModal = (entry: DbTransaction) => {
    openModal(
      IS_STATUS_PENDING ? (
        <TransactionDetailsPendingModal transaction={entry} />
      ) : (
        <TransactionDetails
          transaction={entry}
          icon={TransactionCommons.getTransactionIcon(entry)}
        />
      )
    );
  };

  return !isLaptop ? (
    <RowMobile className={`${selected ? 'selected' : ''}`}>
      <RowWrap>
        <TransactionType>
          <IconWithBackground
            $status={entry.status.toUpperCase()}
            $direction={entry.direction}
          >
            {TransactionCommons.getTransactionIcon(entry)}
          </IconWithBackground>
          <span>
            {entry.direction === 'out' ? 'Outgoing ' : 'Incoming '}
            <br />
            {entry.type + ' Transfer'}
          </span>
        </TransactionType>
        <CheckBoxField>
          <CheckBox
            checked={selected}
            onChange={() => handleSelect(entry.id)}
          />
        </CheckBoxField>
      </RowWrap>
      <StatusWrap>
        Amount, {entry.currency}
        <AmountFieldMobile
          $failed={entry.status === 'Failed'}
          onClick={() => handleOpenModal(entry)}
        >
          {verifiedKYC
            ? Commons.currencyFormatter(
                entry.direction === 'in' ? entry.amount : -entry.amount,
                'EUR'
              )
            : 'First Deposit'}
        </AmountFieldMobile>
      </StatusWrap>
      <StatusWrap>
        Status of Transaction
        <StatusField status={entry.status} />
      </StatusWrap>
      <BorderButtonBlue onClick={() => handleOpenModal(entry)}>
        Transaction Details
      </BorderButtonBlue>
    </RowMobile>
  ) : (
    <Row
      className={`${selected ? 'selected' : ''}`}
      onClick={() => handleOpenModal(entry)}
    >
      <FlexWrapper>
        <CheckBoxField>
          <CheckBox
            checked={selected}
            onChange={() => handleSelect(entry.id)}
          />
        </CheckBoxField>
        <AmountFieldTablet
          className="amount"
          $failed={entry.status === 'Failed'}
        >
          {verifiedKYC
            ? Commons.currencyFormatter(
                entry.direction === 'in' ? entry.amount : -entry.amount,
                entry.currency
              )
            : 'First Deposit'}
        </AmountFieldTablet>
      </FlexWrapper>
      <DateField>
        {formatDate(entry.scheduledAt ? entry.scheduledAt.toString() : entry.dateAndTime)}
      </DateField>
      {/*<TagField onClick={() => handleOpenModal(entry)}>{entry.tag}</TagField>*/}
      <IconField>
        <IconWithBackground
          $status={entry.status.toUpperCase()}
          $direction={entry.direction}
        >
          {TransactionCommons.getTransactionIcon(entry)}
        </IconWithBackground>
      </IconField>
      <FromToField>{entry.name}</FromToField>
      <StatusField status={entry.status} />
    </Row>
  );
};

export default TransactionRow;
