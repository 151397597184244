export const convertStringToNumber = (amount: string): number => {
  // Remove thousands separators and replace the decimal separator
  return Number(amount.replace(/\./g, '').replace(',', '.'));
};

export const convertNumberToString = (amount: string | number): string => {
  if (amount) {
    return amount.toString().replace('.', ',');
  } else {
    return '';
  }
};
