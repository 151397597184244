import styled from 'styled-components';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { ImageCoin, ImageCoinLarge } from '@constants/images';

interface Props {
  incoming: number;
  outgoing: number;
  className?: string;
}

const AnalyticsBalance = ({ incoming, outgoing, className }: Props) => {
  return (
    <Wrapper className={className}>
      <Container>
        <Headline>Period Incoming / Outgoing</Headline>
        <Balance className="positive">{Commons.currencyFormatter(incoming)}</Balance>
        <Balance className="negative">{Commons.currencyFormatter(outgoing)}</Balance>
      </Container>
      <ImageContainer />
    </Wrapper>
  );
};

export default AnalyticsBalance;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 295px;
  width: 100%;
  height: 158px;
  box-sizing: border-box;
  border-radius: 20px;
  background: ${(props) => props.theme.palette.greys.lightLightGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-width: 330px;
    height: 163px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    min-width: 515px;
    height: 141px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    min-width: initial;
    max-width: 397.5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 20px 0 25px 20px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 20px;
    gap: 8px;
  }
`;

const ImageContainer = styled.div`
  display: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    width: 98px;
    border-radius: 20px;
    background: url(${ImageCoin}) no-repeat;
    background-size: cover;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 154px;
    background: url(${ImageCoinLarge}) center;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    width: 140px;
    background: url(${ImageCoinLarge}) unset;
  }
`;

const Headline = styled.div`
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  ${FontStyles.bodyMiddleGilroy};
`;

const Balance = styled.div`
  ${FontStyles.h4};

  &.positive {
    margin-top: 5px;
    color: ${(props) => props.theme.palette.positive.contrastGreen};

    @media (${(props) => props.theme.breakpoints.laptop}) {
      margin-top: 2px;
    }
  }

  &.negative {
    color: ${(props) => props.theme.palette.negative.contrastRed};
  }
`;
