import { DateValidationError } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import Datepicker from '@components/Datepicker/Datepicker';
import Modal from '@components/Modal/Modal';
import TabNavigation from '@components/TabNavigation/TabNavigation';

import DateField from '@elements/DateField';
import DeleteButton from '@elements/DeleteButton/DeleteButton';
import LargeButton from '@elements/LargeButton/LargeButton';

const tabs = [{ label: 'Date' }, { label: 'Date Range' }];

const DatePickerModal = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const setTimespan = useBoundStore((state) => state.setTimespan);
  const timespan = useBoundStore((state) => state.timespan);

  const [span, setSpan] = useState<{ start: Date; end: Date }>({
    start: new Date(),
    end: new Date(),
  });

  const closeModal = useBoundStore((state) => state.closeModal);

  function onDateChange(value: Date | Date[]) {
    if (currentIndex === 1 && Array.isArray(value)) {
      if (value.length === 2) {
        setSpan({ start: value[0], end: value[1] });
      }
    } else if (value instanceof Date) {
      setSpan({ start: value, end: value });
    }
  }

  const handleStartDateChange = (
    newValue: Date | null,
    error: FieldChangeHandlerContext<DateValidationError>
  ) => {
    if (error?.validationError === 'invalidDate') return;
    if (newValue) {
      setSpan((prevState) => ({
        ...prevState,
        start: newValue,
        ...(currentIndex === 0 && { end: newValue }),
      }));
    }
  };

  const handleEndDateChange = (
    newValue: Date | null,
    error: FieldChangeHandlerContext<DateValidationError>
  ) => {
    if (error?.validationError === 'invalidDate') return;
    if (newValue) setSpan((prevState) => ({ ...prevState, end: newValue }));
  };

  useEffect(() => {
    setSpan({ start: currentIndex === 1 ? timespan.start : new Date(), end: new Date() });
  }, [currentIndex, timespan.start]);

  return (
    <DateSelectorModal>
      <Wrapper>
        <Navigation>
          <TabNavigation
            currentTabIndex={currentIndex}
            setCurrentTabIndex={setCurrentIndex}
            tabs={tabs}
            uniqueName="datepicker"
            topOffset={10}
          />
        </Navigation>
        <Divider />
        <Container>
          <DateField
            value={span.start}
            onChange={handleStartDateChange}
          />
          {currentIndex === 1 && (
            <DateField
              value={currentIndex === 1 ? span.end : new Date()}
              onChange={handleEndDateChange}
            />
          )}
          <Datepicker
            startDate={span.start}
            endDate={span.end}
            currentIndex={currentIndex}
            onDateChange={onDateChange}
          />
          <ButtonGroup>
            <ClearSetupButton
              className="delete"
              onClick={() => {
                setSpan({ start: new Date(), end: new Date() });
              }}
              text="Clear Setup"
            />
            <ConfirmSetupButton
              className="confirm"
              onClick={async () => {
                setTimespan({ start: span.start, end: span.end });
                closeModal();
              }}
              text="Confirm Setup"
              variant="blueWhite"
            />
          </ButtonGroup>
        </Container>
      </Wrapper>
    </DateSelectorModal>
  );
};

export default DatePickerModal;

const DateSelectorModal = styled(Modal)`
  padding: 0 20px 0 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 40px 0 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-items: start;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 20px 0;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    justify-content: start;
    margin: 85px 0 20px 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  width: 290px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
    width: 348px;
    margin-top: 15px;
  }
`;

const ClearSetupButton = styled(DeleteButton)`
  width: 290px;
  white-space: nowrap;
  margin: 0;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 129px;
  }
`;

const ConfirmSetupButton = styled(LargeButton)`
  width: 290px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const Divider = styled.div`
  padding-top: 18px;
  display: flex;
  box-sizing: border-box;
  margin-top: -50px;
  width: 172px;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 5px;
    width: 348px;
  }
`;
