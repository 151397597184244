import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { CardBefores } from '@constants/images';
import { LogoMasterCard } from '@constants/logos';

import CardSetup from '@components/CardSetup/CardSetup';

interface Props {
  setVisible: (value: boolean) => void;
  ordered: boolean;
}

const CardBefore = ({ setVisible, ordered }: Props) => {
  const openModal = useBoundStore((state) => state.openModal);

  return (
    <Container onClick={() => setVisible(true)}>
      <MobileContainer>
        <HeadlineRow>
          Virtual Debit Card
          <BlueText>Activation</BlueText>
        </HeadlineRow>
        {ordered ? (
          <OrderedText>
            Once the debit card program is live,
            <br />
            you&apos;ll be notified
          </OrderedText>
        ) : (
          <Link onClick={() => openModal(<CardSetup />)}>
            <u>Click here to order</u>
          </Link>
        )}
      </MobileContainer>
      <BlurredContainer>
        <Logo />
      </BlurredContainer>
    </Container>
  );
};
export default CardBefore;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  box-sizing: border-box;
  width: 300px;
  padding: 20px;
  position: relative;
  transition: 300ms ease-in-out;
  z-index: 1;
  overflow: hidden;
  background:
    image-set(url(${CardBefores[0]}) 1x, url(${CardBefores[1]}) 2x) center/cover no-repeat,
    ${(props) => props.theme.palette.gradients.primaryBlackGradient};

  &:hover {
    -webkit-box-shadow: 0 20px 24px 0 rgba(16, 153, 253, 0.35);
    box-shadow: 0 20px 24px 0 rgba(16, 153, 253, 0.35);
  }
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
`;

const HeadlineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  ${FontStyles.h7}
`;

const BlueText = styled.div`
  color: ${(props) => props.theme.palette.primary.blue};
`;

const Logo = styled(LogoMasterCard)`
  width: 39.88px;
  height: 25px;
`;

const Link = styled.button`
  ${FontStyles.h5};
  color: ${(props) => props.theme.palette.primary.white};
  margin-top: auto;
  z-index: 4;
  cursor: pointer;
`;

const OrderedText = styled.div`
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.primary.white};
  z-index: 3;
`;

const BlurredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(16, 153, 253, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  height: 25px;
  padding: 33px 20px 25px 20px;
  margin: -20px;
  z-index: 4;
`;
