import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useBoundStore } from '@stores/BoundStore';

import Toast from '@elements/Toast/Toast';

interface ResponseData {
  [key: string]: unknown;
}

interface ErrorHandlers {
  [statusCode: number]: (data: ResponseData) => void | undefined;
}

const defaultConfig: ErrorHandlers = {};
const defaultErrors: unknown[] = [];

export const useHTTPErrorHandler = (config: ErrorHandlers = defaultConfig) => {
  const queryClient = useQueryClient();
  const { t: translation } = useTranslation();
  const t = useRef(translation);

  const setIsLoggedIn = useBoundStore((state) => state.setIsLoggedIn);

  // Predefined handlers
  const defaultHandlers: ErrorHandlers = useMemo(
    () => ({
      401: () => {
        queryClient
          .cancelQueries()
          .then(() => {
            setIsLoggedIn(null);
            window.location.href = '/login';
          })
          .catch((err) => {
            console.error('Failed to cancel queries:', err);
          });
      },
      400: () => {
        toast.error(
          <Toast
            title="Bad Request Error"
            message={t.current('checkAndTryAgain')}
          />
        );
      },
      404: () => {
        toast.error(
          <Toast
            title="Not found"
            message={t.current('403')}
          />
        );
      },
      423: () => {
        toast.error(
          <Toast
            title="Account Locked"
            message={t.current('423')}
          />
        );
      },
      429: () => {
        toast.error(
          <Toast
            title="Too many requests"
            message={t.current('429-503')}
          />
        );
      },
      500: () => {
        toast.error(
          <Toast
            title="Internal server error"
            message={t.current('429-503')}
          />
        );
      },
      503: () => {
        toast.error(
          <Toast
            title="Service unavailable"
            message={t.current('429-503')}
          />
        );
      },
    }),
    [queryClient, setIsLoggedIn]
  );

  const handleHTTPErrors = useCallback(
    (errors: unknown[] = defaultErrors) => {
      errors.forEach((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;
            const handler = config[status] || defaultHandlers[status];
            if (handler) {
              handler(data);
            } else {
              console.error(`An unexpected HTTP error occurred: ${error.message}`);
            }
          } else {
            console.error('Network error. Please check your internet connection.');
          }
        } else if (error instanceof Error) {
          console.error('Error: ', error.message);
        } else {
          console.error('An unknown error occurred', error);
        }
      });
    },
    [config, defaultHandlers]
  );

  return { handleHTTPErrors };
};
