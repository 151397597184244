import { format } from 'date-fns';
import { isArray } from 'lodash';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useGetContactTransactions } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { TransactionCommons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { SortAscending, SortDefault, SortDescending } from '@constants/images';

import { ExportButtonContactTransaction } from '@components/ExportButton/ExportButtonContactTransaction';
import TransactionDetailsPendingModal from '@components/PendingDepositModal/TransactionDetailsPendingModal';
import TransactionDetails from '@components/transaction/TransactionDetails';

interface Props {
  className?: string;
  contactId: string;
}

const ContactTransactionHistory = ({ className, contactId }: Props) => {
  const [sort, setSort] = useState<{ amount: number; dateAndTime: number }>({
    amount: 0,
    dateAndTime: 0,
  });

  const openModal = useBoundStore((state) => state.openModal);
  const setSelectedTransaction = useBoundStore((state) => state.setSelectedTransaction);
  const setRepeat = useBoundStore((state) => state.setRepeat);

  const repeatTransaction = (transaction: DbTransaction) => {
    setRepeat(true);

    setSelectedTransaction({
      transactions: {
        id: transaction.id,
        name: transaction.name,
        amount: transaction.amount,
        currency: transaction.currency,
        tag: transaction.tag,
        dateAndTime: transaction.dateAndTime,
        scheduledAt: transaction.scheduledAt,
        status: transaction.status,
        userId: transaction.userId,
        externalId: transaction.externalId,
        recipientAddress: transaction.recipientAddress,
        accountNumber: transaction.accountNumber,
        iban: transaction.iban,
        bicSwift: transaction.bicSwift,
        routingNumber: transaction.routingNumber,
        bankName: transaction.bankName,
        bankAddress: transaction.bankAddress,
        bankCountry: transaction.bankCountry,
        vendor: transaction.vendor,
        reason: transaction.reason,
        fee: transaction.fee,
        reference: transaction.reference,
        additionalBeneficiaryDetails: transaction.additionalBeneficiaryDetails,
        type: transaction.type,
      },
      contacts: null,
    });
  };

  const { data: results } = useGetContactTransactions(contactId);

  const SortIcon = {
    0: <SortDefault />,
    1: <SortAscending />,
    2: <SortDescending />,
  };

  return (
    <Container className={className}>
      <Header>
        <Heading>Transaction history</Heading>
        <ExportButtonContactTransaction />
      </Header>
      <Content>
        <Table>
          <TableHeadingGroup>
            <AmountHeading
              onClick={() =>
                setSort((prevState) => ({
                  ...prevState,
                  amount: prevState.amount === 2 ? 0 : prevState.amount + 1,
                }))
              }
            >
              <span>Amount</span>
              <span>{SortIcon[sort.amount as keyof typeof SortIcon]}</span>
            </AmountHeading>
            <DateTimeHeading
              onClick={() =>
                setSort((prevState) => ({
                  ...prevState,
                  dateAndTime: prevState.dateAndTime === 2 ? 0 : prevState.dateAndTime + 1,
                }))
              }
            >
              <span>Date & Time</span>
              <span>{SortIcon[sort.dateAndTime as keyof typeof SortIcon]}</span>
            </DateTimeHeading>
            {/*<TableHeading>#Tag</TableHeading>*/}
            <TableHeading>Status</TableHeading>
          </TableHeadingGroup>
          {results && isArray(results) && (
            <TableBody>
              {results.map((transaction: DbTransaction, index: number) => (
                <TableRow key={index}>
                  <AmountCell $failedTransaction={transaction.status === '4'}>
                    {transaction.amount}
                  </AmountCell>
                  <DateTimeCell>
                    {transaction.scheduledAt
                      ? format(new Date(transaction.scheduledAt), 'd.MM.yyyy h:mm a')
                      : format(new Date(transaction.dateAndTime), 'd.MM.yyyy h:mm a')}
                  </DateTimeCell>
                  {/*<TagCell>{transaction.tag}</TagCell>*/}
                  <StatusCell $status={Number(transaction.status)}>
                    <span>{transaction.status}</span>
                    <StatusContainer>
                      <DetailsButton
                        $repeat={transaction.direction === 'in'}
                        onClick={() => {
                          const IS_STATUS_PENDING = transaction.status.toUpperCase() === 'PENDING';
                          openModal(
                            IS_STATUS_PENDING ? (
                              <TransactionDetailsPendingModal transaction={transaction} />
                            ) : (
                              <TransactionDetails
                                transaction={transaction}
                                icon={TransactionCommons.getTransactionIcon(transaction)}
                              />
                            )
                          );
                        }}
                      >
                        Details
                      </DetailsButton>
                      {transaction.direction === 'out' && (
                        <RepeatButton onClick={() => repeatTransaction(transaction)}>
                          Repeat
                        </RepeatButton>
                      )}
                    </StatusContainer>
                  </StatusCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </Content>
    </Container>
  );
};

export default ContactTransactionHistory;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.primary.white};
  border-radius: 20px;
  box-sizing: border-box;
  height: 402px;
  transition: ease-in-out 300ms;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 35px 26px 35px;
`;

const Heading = styled.h4`
  ${FontStyles.h4};
  align-self: start;
`;

const Content = styled.div`
  overflow-x: hidden;
`;

const StatusContainer = styled.div`
  //needed for slide in
  //display: flex;
  display: none;
  justify-content: center;
  gap: 5px;
  position: absolute;
  right: -100%;
  transition: 300ms ease-in-out;
  background-color: ${({ theme }) => theme.palette.greys.lightLightGrey};
  width: 200px;
`;

const Table = styled.div`
  width: 100%;
`;

const TableHeading = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
`;

const AmountHeading = styled(TableHeading)`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  display: flex;
  gap: 9px;
`;

const DateTimeHeading = styled(TableHeading)`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  display: flex;
  gap: 9px;
  justify-content: center;
  min-width: 280px;
`;

const TableHeadingGroup = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  height: 21px;
  display: flex;
  padding: 0 35px;

  ${TableHeading}:nth-child(4) {
    justify-content: end;
    min-width: 220px;
  }
`;

const TableBody = styled.div`
  padding: 15px 0;
`;

const TableRow = styled.div`
  cursor: pointer;
  display: flex;
  //transition: 300ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.greys.lightLightGrey};
  }
`;

const TableCell = styled.div<{ status?: number }>`
  ${FontStyles.bodyMiddleGilroy};
  height: 50px;
  display: flex;
  align-items: center;
  flex: 1;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  //transition: color 300ms ease-in-out;
`;

const AmountCell = styled(TableCell)<{ $failedTransaction: boolean }>`
  padding-left: 35px;
  color: ${({ theme, $failedTransaction }) =>
    $failedTransaction ? theme.palette.greys.darkestGrey : theme.palette.primary.black};

  ${TableRow}:hover & {
    color: ${({ theme }) => theme.palette.primary.blue};
  }
`;

const DateTimeCell = styled(TableCell)`
  justify-content: center;
  min-width: 280px;

  ${TableRow}:hover & {
    background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const TagCell = styled(TableCell)`
  ${TableRow}:hover & {
    background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const StatusCell = styled(TableCell)<{ $status: number }>`
  padding-right: 35px;
  min-width: 220px;
  justify-content: end;
  position: relative;

  ${TableRow}:hover & {
    ${StatusContainer} {
      margin-left: auto;
      right: 0;
      display: flex;
    }
  }

  & span {
    ${({ $status }) =>
      ($status === 1 || $status === 2) &&
      css`
        color: ${({ theme }) => theme.palette.primary.blue};
      `}

    ${({ $status }) =>
      ($status === 3 || $status === 4 || $status === 5 || $status === 7) &&
      css`
        color: ${({ theme }) => theme.palette.negative.contrastRed};
      `}

    ${({ status }) =>
      status === 0 &&
      css`
        background: ${({ theme }) => theme.palette.gradients.goldishGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      `}
  }
`;

const Button = styled.button`
  ${FontStyles.bodySmallGilroy};
  padding: 8px 20px;
  border-radius: 30px;
  box-sizing: border-box;
  transition: 300ms ease-in-out;
`;

const DetailsButton = styled(Button)<{ $repeat: boolean }>`
  color: ${({ theme }) => theme.palette.primary.black};
  border: 1px solid ${({ theme }) => theme.palette.primary.black};
  background: linear-gradient(145deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #232222, #34354a);
  background-size: 500% auto;
  margin-left: ${(props) => (props.$repeat ? '50px' : 'initial')};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.white};
    border: 1px solid transparent;
    background-position: 90% 120%;
  }
`;

const RepeatButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.white};
  background: linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a);
  background-size: 500% auto;

  &:hover {
    background-position: 90% 120%;
  }
`;
