import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconDeleteLarge } from '@constants/icons';
import { PaymentDeleteDesktop, PaymentDeleteMobile } from '@constants/images';

import Modal from '@components/Modal/Modal';

import LargeButton from '@elements/LargeButton/LargeButton';

export const CustomModal = styled(Modal)`
  padding: 0 20px 0 20px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: image-set(url(${PaymentDeleteMobile[0]}) 1x, url(${PaymentDeleteMobile[1]}) 2x)
    no-repeat center center / 100% auto;
  background-size: cover;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 40px 0 40px;
    min-height: unset;
    height: 660px;
    background: image-set(url(${PaymentDeleteDesktop[0]}) 1x, url(${PaymentDeleteDesktop[1]}) 2x)
      no-repeat center center / 100% auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column-reverse;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const Icon = styled.div`
  width: 76px;
  height: 76px;
  margin-bottom: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.white};
  box-shadow:
    8px 8px 20px 0 rgba(174, 174, 192, 0.2),
    6px 6px 15px 0 rgba(123, 123, 138, 0.08),
    -8px -8px 20px 0 #fff,
    -6px -6px 15px 0 #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 120px;
    height: 120px;
  }
`;

export const StyledIconDelete = styled(IconDeleteLarge)`
  width: 38px;
  height: 38px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 64px;
    height: 64px;
  }
`;

export const HeadText = styled.div`
  ${FontStyles.h3};
  margin-bottom: 10px;
  background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.h2};
  }
`;

export const SubText = styled.div`
  ${FontStyles.h6};
  margin-bottom: 35px;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
    color: ${({ theme }) => theme.palette.primary.black};
  }
`;

export const CustomLargeButton = styled(LargeButton)`
  width: 230px;
  height: 50px;
`;
