import { MouseEvent } from 'react';
import styled from 'styled-components';

import { Checked, UnChacked } from '@constants/icons';

interface Props {
  checked: boolean;
  onChange: () => void;
}

const CheckBox = ({ checked = false, onChange }: Props) => {
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onChange();
  };

  return <Container onClick={handleClick}>{checked ? <Checked /> : <UnChacked />}</Container>;
  // <input
  //   onMouseLeave={onMouseLeave}
  //   onMouseEnter={onMouseEnter}
  //   type="checkbox"
  //   checked={checked}
  //   onChange={onChange}
  // />
};

export default CheckBox;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.palette.primary.middleBlue};
  }
`;
