const ALLOWED_COUNTRIES = [
  'Austria',
  'Belgium',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Turkey',
];

const ALLOWED_LANGUAGES_ISO: LanguageMap = {
  Austria: 'de', // German
  Belgium: 'nl', // Dutch (predominant language, also French and German spoken)
  Croatia: 'hr', // Croatian
  Cyprus: 'el', // Greek (also Turkish)
  'Czech Republic': 'cs', // Czech
  Denmark: 'da', // Danish
  Estonia: 'et', // Estonian
  Finland: 'fi', // Finnish (also Swedish)
  France: 'fr', // French
  Georgia: 'ka', // Georgian
  Germany: 'de', // German
  Greece: 'el', // Greek
  Hungary: 'hu', // Hungarian
  Iceland: 'is', // Icelandic
  Ireland: 'en', // Irish (English is more commonly spoken, but Irish is the first official language)
  Italy: 'it', // Italian
  Latvia: 'lv', // Latvian
  Liechtenstein: 'de', // German
  Lithuania: 'lt', // Lithuanian
  Luxembourg: 'lb', // Luxembourgish (also French and German)
  Malta: 'mt', // Maltese (also English)
  Netherlands: 'nl', // Dutch
  Norway: 'no', // Norwegian
  Poland: 'pl', // Polish
  Portugal: 'pt', // Portuguese
  Romania: 'ro', // Romanian
  Slovakia: 'sk', // Slovak
  Slovenia: 'sl', // Slovene
  Spain: 'es', // Spanish
  Sweden: 'sv', // Swedish
  Turkey: 'tr', // Turkish
  'United Kingdom': 'en', // English
};

const ALLOWED_COUNTRIES_ISO_TO_FULL = {
  AT: 'Austria',
  BE: 'Belgium',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  GE: 'Georgia',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IS: 'Iceland',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  TR: 'Turkey',
  GB: 'United Kingdom',
};

export { ALLOWED_COUNTRIES, ALLOWED_LANGUAGES_ISO, ALLOWED_COUNTRIES_ISO_TO_FULL };
