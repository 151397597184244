import { CanceledError } from 'axios';

import { axiosClient } from '@clients/axios-client';

const sendEmailVerificationCode = async (email: string) => {
  try {
    const axiosResponse = await axiosClient.post('/corporate/send-email-code', { email });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

const verifyEmail = async ({
  email,
  verificationCode,
  signal,
}: WeavrEmailVerification & { signal: AbortSignal }) => {
  try {
    const result = await axiosClient.post(
      '/corporate/verify-email',
      {
        email,
        verificationCode,
      },
      {
        signal,
      }
    );
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

export const OnboardingService = {
  sendEmailVerificationCode,
  verifyEmail,
};
