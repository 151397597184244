import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import CardOverview from '@components/Cards/tabs/overview/CardOveriew';

import { TabItem } from '@elements/Tab/Tab';
import Tab from '@elements/Tab/Tab';

import CartPage from '../../components/Cards/CartPage';

const tabs: TabItem[] = [
  { name: 'Card Overview', component: <CardOverview /> },
  { name: 'Manage Card', component: <div>Content for Tab 2</div> },
  { name: 'Manage Limits', component: <div>Content for Tab 3</div> },
  { name: 'Add to Wallets', component: <div>Content for Tab 4</div> },
];
const CardPage = () => {
  return (
    <Container>
      <Tab tabs={tabs} />
    </Container>
  );
};

export default CardPage;

export const Container = styled.div`
  background: ${(props) => props.theme.palette.primary.white};
`;
