import { MouseEvent, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconSave } from '@constants/icons';

interface Props {
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isSavable: boolean;
}

const SavePopOver = ({ className, onClick, isSavable }: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [opened, setOpened] = useState<boolean>(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'top',
  });

  const handleMouseEnter = () => {
    setOpened(true);
  };

  const handleMouseLeave = () => {
    setOpened(false);
  };

  return (
    <>
      <Button
        $isSavable={isSavable}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        type="button"
        ref={setReferenceElement}
        className={className}
      >
        <IconSave />
      </Button>
      {opened && (
        <Content
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {isSavable ? 'Click to save note' : 'Type note to save it'}
          <Arrow
            ref={setArrowElement}
            style={styles.arrow}
          />
        </Content>
      )}
    </>
  );
};
export default SavePopOver;

const Button = styled.button<{ $isSavable: boolean }>`
  display: flex;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) =>
    props.$isSavable ? props.theme.palette.primary.black : props.theme.palette.greys.middleGrey};
  transition: 300ms ease-in-out;

  &:hover {
    color: ${(props) =>
      props.$isSavable ? props.theme.palette.primary.blue : props.theme.palette.greys.asphaltTint};
  }
`;

const Content = styled.div`
  display: flex;
  padding: 10px 15px;
  background-color: white;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.greys.asphaltTint};
  height: 40px;
  box-sizing: border-box;
`;

const Arrow = styled.div`
  display: flex;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin-top: 45px;
`;
