import { useMutation, useQuery } from '@tanstack/react-query';

import { AdditionalFactorsService } from '@services/additional-factors-service';

const useAdditionalFactors = (token: string) => {
  return useQuery({
    queryKey: ['listAdditionalAuthenticationFactors'],
    queryFn: async () => {
      return await AdditionalFactorsService.list(token);
    },
  });
};

const useEnrollDevice = () => {
  return useMutation({
    mutationFn: async () => {
      return await AdditionalFactorsService.enrollDevice();
    },
  });
};

const useVerifyDevice = () => {
  return useMutation({
    mutationFn: async (verificationCode: string) => {
      return await AdditionalFactorsService.verifyDevice(verificationCode);
    },
  });
};

export { useAdditionalFactors, useEnrollDevice, useVerifyDevice };
