import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import Avatar from '@components/Avatar/Avatar';

interface Props {
  name?: string;
  role?: string;
  image?: string;
  verified: boolean;
  className: string;
}
const SettingsUserPanel = ({ name, role, verified, image, className }: Props) => {
  return (
    <Container
      $verified={verified}
      className={className}
    >
      {verified ? (
        <>
          <UserAvatar
            text={name}
            src={image}
          />
          <InnerWrapper>
            <TextBox>
              <NameText $verified={verified}>{name}</NameText>
              {role}
            </TextBox>
          </InnerWrapper>
        </>
      ) : (
        <>
          <InnerWrapper>
            <NameText $verified={verified}>{name}</NameText>
          </InnerWrapper>
          <InnerWrapper>
            <TextBox>Status:</TextBox>
            <StatusText>not verified yet</StatusText>
          </InnerWrapper>
        </>
      )}
    </Container>
  );
};

export default SettingsUserPanel;

const Container = styled.div<{ $verified: boolean }>`
  display: flex;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  max-height: 80px;
  padding: 15px;
  align-items: center;
  gap: 15px;
  flex-direction: ${(props) => (props.$verified ? 'row' : 'column')};
  flex: 1 0 auto;
  width: 100%;
  user-select: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 25px;
    max-height: 120px;
    width: 411px;
  }
`;

const UserAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
  ${FontStyles.h4};
  line-height: 100%;
  transition: initial;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 70px;
    width: 70px;
    ${FontStyles.h3};
  }
`;

const TextBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  white-space: nowrap;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

const NameText = styled.p<{ $verified: boolean }>`
  ${FontStyles.h5};
  color: ${(props) =>
    props.$verified ? props.theme.palette.primary.black : props.theme.palette.primary.blue};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.h4};
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
`;

const StatusText = styled.div`
  color: ${(props) => props.theme.palette.negative.lessContrastRed};
  ${FontStyles.bodySmallGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;
