import styled from 'styled-components';

import { IconProgressBarEnd } from '@constants/icons';

interface Props {
  progress: number;
}

const ProgressBar = ({ progress }: Props) => {
  return (
    <Container>
      <Line>
        <Progress $progress={progress}>
          <End />
        </Progress>
      </Line>
    </Container>
  );
};

export default ProgressBar;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 16px;
  justify-content: center;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 137px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    width: 160px;
  }
`;

const Line = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 25px;
  height: 4px;
  align-items: center;
`;

const Progress = styled.div<{ $progress: number }>`
  display: flex;
  background: ${(props) => props.theme.palette.gradients.primaryBlueGradient};
  border-radius: 25px;
  height: 4px;
  width: ${(props) => props.$progress}%;
  align-items: center;
  justify-content: flex-end;
  transition: 1000ms ease-in-out;
  flex-shrink: 0;
`;

const End = styled(IconProgressBarEnd)`
  margin-right: -7px;
  flex-shrink: 0;
  display: flex;
`;
