import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import PopOver from '@elements/PopOver/PopOver';

interface Props {
  value: string;
  label: string;
  className?: string;
  copyBottom?: boolean;
  oneLine?: boolean;
}
const CopyField = ({ value, label, className, copyBottom = true, oneLine = false }: Props) => {
  const { t } = useTranslation();

  return value ? (
    <Container
      $oneLine={oneLine}
      value={value}
      className={className}
    >
      {oneLine ? (
        <>
          <Wrapper>
            <Label>
              <Trans t={t}>{label}</Trans>
            </Label>
            <CustomCopyPopoverTablet copyValue={value} />
          </Wrapper>
          <Wrapper
            value={value}
            $oneLine={oneLine}
            className={value.length > 32 ? 'start' : ''}
            id={oneLine ? 'oneLine' : ''}
          >
            <Field
              $oneLine={oneLine}
              className={oneLine ? 'oneLine' : ''}
            >
              {value}
            </Field>
            <CustomCopyPopoverMobile copyValue={value} />
          </Wrapper>
        </>
      ) : (
        <>
          <Wrapper>
            <Label>{label}</Label>
            {!copyBottom && <PopOver copyValue={value} />}
          </Wrapper>
          <Wrapper>
            <Field>{value}</Field>
            {copyBottom && <PopOver copyValue={value} />}
          </Wrapper>
        </>
      )}
    </Container>
  ) : null;
};

const Container = styled.div<{ value: string; $oneLine: boolean }>`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  border-bottom: 1px solid #c7aee7;
  min-width: ${(props) => (props.value.length > 20 ? '100%' : '215px')};
  flex-wrap: wrap;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: ${(props) => (props.$oneLine ? 'row' : 'column')};
    justify-content: ${(props) => (props.$oneLine ? 'start' : 'start')};
  }
`;

const Wrapper = styled.div<{ value?: string; $oneLine?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    &#oneLine {
      & > :first-child {
        justify-content: end;
      }
    }
    &#oneLine.start {
      min-width: 100%;
      & > :first-child {
        justify-content: start;
      }
    }
  }
`;

const Label = styled.label`
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  display: flex;
  padding-right: 13px;
`;

const Field = styled.div<{ $oneLine?: boolean }>`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.primary.black};
  padding: ${(props) => (props.$oneLine ? '12px 0 15px' : '10px 10px 10px 0')};
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }
`;

const CustomCopyPopoverTablet = styled(PopOver)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: inline-block;
  }
`;

const CustomCopyPopoverMobile = styled(PopOver)`
  display: inline-block;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export default CopyField;
