import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdatePassword, useValidatePassword } from '@api/Password/passwordApi';

import { useBoundStore } from '@stores/BoundStore';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import {
  BottomContent,
  Container,
  Content,
  CustomModal,
  CustomPasswordInfoBlock,
  FieldContainer,
  HeadLine,
  SubHeadLine,
  TopContent,
  WeavrPassword,
} from '@components/ChangePasswordModal/ChangePasswordModal.styles';

import LargeButton from '@elements/LargeButton/LargeButton';
import Toast from '@elements/Toast/Toast';

const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const closeModal = useBoundStore((state) => state.closeModal);

  const formRef = useRef<HTMLFormElement>(null); // Ref to our form component
  const passwordSecureFormRef = useRef<Form | null>(null); // Ref to window.OpcUxSecureClient.form()
  const newPasswordSecureFormRef = useRef<Form | null>(null); // Ref to window.OpcUxSecureClient.form()

  const isWeavrUiComponentLibraryLoaded = useBoundStore(
    (state) => state.isWeavrUiComponentLibraryLoaded
  );

  const [submitting, setSubmitting] = useState<boolean>(false);

  const errorHandling = useMemo(
    () => ({
      409: (error: unknown) => {
        const { errorCode } = error as { errorCode: string };
        if (errorCode === 'PASSWORD_ALREADY_USED') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordKeyAlreadyInUse')}
            />
          );
        } else if (
          errorCode === 'PASSWORD_TOO_SHORT' ||
          errorCode === 'PASSWORD_TOO_LONG' ||
          errorCode === 'PASSWORD_TOO_SIMPLE'
        ) {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordTooSimple')}
            />
          );
        } else if (errorCode === 'PASSWORD_INCORRECT') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordNotMatch')}
            />
          );
        }
      },
    }),
    [t]
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errorHandling);

  const { mutate: validatePasswordMutation } = useValidatePassword();
  const { mutate: updatePasswordMutation } = useUpdatePassword();

  useEffect(() => {
    if (isWeavrUiComponentLibraryLoaded && window.OpcUxSecureClient) {
      passwordSecureFormRef.current = window.OpcUxSecureClient.form();
      newPasswordSecureFormRef.current = window.OpcUxSecureClient.form();

      const oldPasswordInput = passwordSecureFormRef.current.input('oldPassword', 'password', {
        placeholder: 'Old password',
        maxlength: 30,
        style: {
          base: {
            fontSize: '16px',
            fontWeight: '500',
          },
        },
      });

      const newPasswordInput = newPasswordSecureFormRef.current.input('newPassword', 'password', {
        placeholder: 'New password',
        maxlength: 30,
        style: {
          base: {
            fontSize: '16px',
            fontWeight: '500',
          },
        },
      });

      const confirmNewPasswordInput = newPasswordSecureFormRef.current.input(
        'confirmNewPassword',
        'confirmPassword',
        {
          placeholder: 'Confirm new password',
          maxlength: 30,
          style: {
            base: {
              fontSize: '16px',
              fontWeight: '500',
            },
          },
        }
      );

      oldPasswordInput.mount('#oldPassword');
      newPasswordInput.mount('#newPassword');
      confirmNewPasswordInput.mount('#confirmNewPassword');
      confirmNewPasswordInput.on('submit', () => {
        if (formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      });
    }
  }, [isWeavrUiComponentLibraryLoaded]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (submitting) return;

    setSubmitting(true);

    if (!newPasswordSecureFormRef.current) {
      setSubmitting(false);
      return;
    }

    newPasswordSecureFormRef.current.tokenize(
      (response: TokenizedResponse) => {
        if (!response || !response.newPassword) {
          toast.error(
            <Toast
              title="Password Error"
              message="Please enter your new password!"
            />
          );
          setSubmitting(false);
          return;
        }

        const { newPassword } = response;

        validatePasswordMutation(newPassword, {
          onSuccess: () => {
            if (!passwordSecureFormRef.current) {
              setSubmitting(false);
              return;
            }

            passwordSecureFormRef.current.tokenize(
              (response: TokenizedResponse) => {
                if (!response || !response.oldPassword) {
                  toast.error(
                    <Toast
                      title="Password Error"
                      message="Please enter your current password!"
                    />
                  );
                  setSubmitting(false);
                  return;
                }

                const { oldPassword } = response;
                updatePasswordMutation(
                  { oldPassword, newPassword },
                  {
                    onSuccess: () => {
                      toast.success(
                        <Toast
                          title="User Details updated"
                          message="Your data has been successfully updated."
                        />
                      );
                      setSubmitting(false);
                      closeModal();
                    },
                    onError: (error) => {
                      handleHTTPErrors([error]);
                      setSubmitting(false);
                    },
                  }
                );
              },
              () => {
                toast.error(
                  <Toast
                    title="Password Error"
                    message="There has been a problem with your old password."
                  />
                );
                setSubmitting(false);
              }
            );
          },
          onError: (error) => {
            handleHTTPErrors([error]);
            setSubmitting(false);
          },
        });
      },
      () => {
        toast.error(
          <Toast
            title="Password Error"
            message={t('passwordNotMatch')}
          />
        );
        setSubmitting(false);
      }
    );
  };

  return (
    <CustomModal>
      <Container>
        <Content
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <TopContent>
            <HeadLine>Password change</HeadLine>
            <SubHeadLine>Setup new Password here</SubHeadLine>
          </TopContent>
          <FieldContainer>
            Old Password
            <WeavrPassword id="oldPassword" />
          </FieldContainer>
          <FieldContainer>
            New Password
            <WeavrPassword id="newPassword" />
          </FieldContainer>
          <FieldContainer>
            Repeat Password
            <WeavrPassword id="confirmNewPassword" />
          </FieldContainer>
          <CustomPasswordInfoBlock />
          <BottomContent>
            <LargeButton
              disabled={submitting}
              type="submit"
              text="Finish Setup"
              variant="darkWhite"
              className="button"
            />
          </BottomContent>
        </Content>
      </Container>
    </CustomModal>
  );
};

export default ChangePasswordModal;
