import { StateCreator } from 'zustand';

export interface NotificationSlice {
  notifications: boolean;
  setNotifications: (value: boolean) => void;
  notificationsOpen: boolean;
  setNotificationsOpen: (value: boolean) => void;
  notify: boolean;
  setNotify: (value: boolean) => void;
  countdown: number;
  setCountdown: (value: number | ((value: number) => number)) => void;
  message?: string;
  setMessage: (value: string | undefined) => void;
  popUpType: PopUpType;
  setPopUpType: (value: PopUpType) => void;
}

export const createNotificationSlice: StateCreator<NotificationSlice> = (set) => ({
  notifications: false,
  setNotifications: (value) => set({ notifications: value }),
  notificationsOpen: false,
  setNotificationsOpen: (value) => set({ notificationsOpen: value }),
  notify: false,
  setNotify: (value) => set({ notify: value }),
  countdown: 5,
  setCountdown: (value) =>
    set((state) => ({ countdown: typeof value === 'function' ? value(state.countdown) : value })),
  message: '',
  setMessage: (value) => set({ message: value }),
  popUpType: 'open',
  setPopUpType: (value) => set({ popUpType: value }),
});
