import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  RefObject,
  SetStateAction,
} from 'react';
import styled, { CSSProperties } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconEyeDashed, IconEyeVisible, IconLocked, IconRotatedPlus } from '@constants/icons';

import { TextInputContainer } from '@elements/input/TextInput/TextInput';

interface Props {
  placeholder: string;
  icon?: ReactNode;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  type?: string;
  maxLength?: number;
  value?: string;
  valid?: boolean;
  flipIcon?: boolean;
  locked?: boolean;
  className?: string;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  visible?: boolean;
  setVisible?: Dispatch<SetStateAction<boolean>>;
  reference?: RefObject<HTMLInputElement>;
  onLockedIconClick?: () => void;
  autoComplete?: string;
}

const Input = ({
  placeholder,
  icon,
  onChange,
  onClick,
  style,
  type,
  maxLength,
  value = '',
  valid = true,
  flipIcon,
  locked = false,
  className,
  onBlur,
  onKeyDown,
  autoFocus,
  visible,
  setVisible,
  reference,
  onLockedIconClick,
  autoComplete,
}: Props) => {
  const handleIconClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (reference && reference.current) {
      if (document.activeElement === reference.current) {
        reference.current.blur();
      } else {
        reference.current.focus();
      }
    }
  };

  return (
    <TextInputContainer
      $value={value}
      style={style}
      $valid={valid}
      $flip={flipIcon}
      $icon={icon}
      $locked={locked}
      className={className}
    >
      <Field
        autoComplete={autoComplete}
        ref={reference}
        autoFocus={autoFocus}
        onChange={(event) => onChange(event)}
        onBlur={onBlur}
        onClick={onClick}
        onKeyDown={(event) => {
          if (onKeyDown) {
            onKeyDown(event);
          }
        }}
        value={value}
        placeholder={placeholder}
        type={type}
        maxLength={maxLength}
        $valid={valid}
        className={locked ? 'locked' : className}
        disabled={locked}
      />
      {locked ? (
        onLockedIconClick ? (
          <StyledIconRotatedPlus onClick={onLockedIconClick} />
        ) : (
          <IconLocked />
        )
      ) : setVisible ? (
        <Toggle
          onClick={() => {
            setVisible((prevState) => !prevState);
          }}
        >
          {visible ? <IconEyeVisible /> : <IconEyeDashed />}
        </Toggle>
      ) : (
        icon && (
          <div
            onMouseDown={(event) => {
              handleIconClick(event);
            }}
          >
            {icon}
          </div>
        )
      )}
    </TextInputContainer>
  );
};

const StyledIconRotatedPlus = styled(IconRotatedPlus)`
  &:hover {
    cursor: pointer;
  }
`;

const Field = styled.input<{ $valid: boolean }>`
  ${FontStyles.bodyMiddleGilroy};
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.primary.black};
  border: none;
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  transition: color ease-in-out 300ms;
  box-sizing: border-box;

  &:focus {
    color: ${(props) => props.theme.palette.primary.blue};
    outline: none;
  }

  &:disabled {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    opacity: 1;
  }
`;

const Toggle = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export default Input;
