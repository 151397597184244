import React from 'react';

import CheckBox from '@elements/CheckBox/CheckBox';

import {
  CheckboxContainer,
  CheckboxGroup,
  Container,
  FilterHeader,
  Label,
  Reset,
  Title,
} from './CheckboxFilter.styles';

interface CheckboxFilter {
  onChange: (e: string | undefined) => void;
  filters: SelectOption[];
  title: string;
  selected?: string;
}
const CheckboxFilter = ({ onChange, title, filters, selected }: CheckboxFilter) => {
  const handleReset = () => {
    onChange(undefined);
  };
  const handleChange = (e: SelectOption) => {
    onChange(e.value);
  };

  return (
    <Container>
      <FilterHeader>
        <Title>{title}</Title>
        <Reset onClick={handleReset}>Reset</Reset>
      </FilterHeader>

      <CheckboxGroup>
        {filters.map((filter) => (
          <CheckboxContainer key={filter.value}>
            <CheckBox
              checked={filter.value === selected}
              onChange={() => handleChange(filter)}
            />
            <Label>{filter.text}</Label>
          </CheckboxContainer>
        ))}
      </CheckboxGroup>
    </Container>
  );
};

export default CheckboxFilter;
