import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconLine } from '@constants/icons';

import Avatar from '@components/Avatar/Avatar';

import IconButton from '@elements/IconButton/IconButton';

export const Container = styled.nav<{ $isScrolled: boolean }>`
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-bottom: 1px solid
    ${(props) => (props.$isScrolled ? props.theme.palette.greys.middleGrey : 'transparent')};
  transition: 300ms ease-in-out;
  padding: 10px 20px;
  box-sizing: border-box;
  max-height: 100px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 30px 40px;
  }
`;

export const NavIconBackButton = styled(IconButton)<{ $visibleOnMobile: boolean }>`
  display: ${({ $visibleOnMobile }) => ($visibleOnMobile ? 'flex' : 'none')};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  background-color: unset;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

export const NavIconButton = styled(IconButton)`
  display: none;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  background-color: ${(props) => props.theme.palette.greys.middleGrey};
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.black};
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;

export const Line = styled(IconLine)`
  padding: 0 35px 0 30px;
`;

export const NavGroup = styled.div`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    align-items: center;
    flex-grow: 1;
    display: flex;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-grow: 1;
  padding: unset;
  gap: unset;
  order: -1;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 10px;
    flex-grow: unset;
    padding-right: 30px;
    align-items: center;
    order: unset;
  }
`;

export const MenuGroup = styled.div`
  display: flex;
  order: 1;
  gap: unset;

  > :not(:last-child) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    order: unset;
    align-items: center;
    gap: 5px;

    > *:not(:last-child) {
      display: flex;
    }
  }
`;

const NavButtonCss = css`
  ${FontStyles.h4};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  background: linear-gradient(193.34deg, #c7aee7 9.58%, #8c78a7 79.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  transition: 300ms ease-in-out;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    background: initial;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background-clip: initial;
    text-fill-color: initial;
    padding-right: 30px;

    ${FontStyles.subtitleGilroy};
    color: ${(props) => props.theme.palette.greys.darkGrey};

    &:hover {
      color: ${(props) => props.theme.palette.primary.black};
    }

    &:active,
    &.active {
      color: ${(props) => props.theme.palette.primary.blue};
    }
  }
`;

export const NavigationLink = styled(NavLink)`
  ${NavButtonCss};
`;

export const NavigationButton = styled.button`
  ${NavButtonCss};
`;

export const AvatarHover = styled(Avatar)`
  border: 1px solid transparent;
  cursor: pointer;
  line-height: unset;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.black};
  }
`;

export const MobileCurrentPathText = styled.div`
  ${FontStyles.h4};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: linear-gradient(193.34deg, #c7aee7 9.58%, #8c78a7 79.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const MobileCurrentPathTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;
