import { Avatar, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import styled from 'styled-components';

import { useGetCorporate } from '@api/Corporate/corporateApi';

import { convertCountryFromISO3166ToFullName } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

const SettingsInfo = () => {
  const theme: Theme = useTheme();
  const { handleHTTPErrors } = useHTTPErrorHandler();
  const { data: corporate, isError, error } = useGetCorporate();

  useEffect(() => {
    if (isError && error) {
      handleHTTPErrors([error]);
    }
  }, [error, handleHTTPErrors, isError]);

  const companyName = corporate?.company?.name ?? '-';
  const country = convertCountryFromISO3166ToFullName(corporate?.company?.businessAddress?.country);

  return (
    <Container>
      <Content>
        <AvatarAndCompanyName>
          <Avatar sx={{ backgroundColor: theme.legacy.palette.greys.asphaltTint }}>
            {companyName.charAt(0)}
          </Avatar>
          <CompanyHeading>{companyName}</CompanyHeading>
        </AvatarAndCompanyName>
        <CompanyText>{country}</CompanyText>
      </Content>
    </Container>
  );
};
export default SettingsInfo;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 20px 20px 0;
  flex-direction: column;
  gap: 10px;
  color: ${({ theme }) => theme.palette.primary.white};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: unset;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 36px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};
  overflow: hidden;
`;

const AvatarAndCompanyName = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const CompanyHeading = styled.p`
  max-width: calc(100vw - 172px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${FontStyles.h5};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    max-width: 372px;
  }
`;

const CompanyText = styled.p`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.primary.white};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;
