import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconSettingsLock, IconVerify } from '@constants/icons';
import {
  SettingsKYCBackgroundMobileSet,
  SettingsKYCBackgroundSet,
  SettingsKYCBackgroundTabletSet,
} from '@constants/images';

import LargeButton from '@elements/LargeButton/LargeButton';

export const StatusBar = styled.div`
  display: flex;
  gap: 7px;
  margin-bottom: 20px;
`;

export const CustomIconVerify = styled(IconVerify)`
  margin-left: -10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: -7px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-top: unset;
  }
`;

export const CustomIconSettingsLock = styled(IconSettingsLock)`
  margin-left: -10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: -7px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-top: unset;
  }
`;

export const Container = styled.div`
  padding: 0 20px 0;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 35px;
  }
`;

export const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  background: no-repeat;
  background-size: cover;
  background-position-y: 100%;
  background-image: url(${SettingsKYCBackgroundMobileSet[0]});
  background-image: -webkit-image-set(
    url(${SettingsKYCBackgroundMobileSet[0]}) 1x,
    url(${SettingsKYCBackgroundMobileSet[1]}) 2x
  );
  padding: 20px;
  border-radius: 20px;
  flex-direction: column;

  & > a {
    max-width: 150px;
    padding-left: 39px;
    padding-right: 39px;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    background-position-y: unset;
    background-image: url(${SettingsKYCBackgroundTabletSet[0]});
    background-image: -webkit-image-set(
      url(${SettingsKYCBackgroundTabletSet[0]}) 1x,
      url(${SettingsKYCBackgroundTabletSet[1]}) 2x
    );
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 30px;
    background-image: url(${SettingsKYCBackgroundSet[0]});
    background-image: -webkit-image-set(
      url(${SettingsKYCBackgroundSet[0]}) 1x,
      url(${SettingsKYCBackgroundSet[1]}) 2x
    );
  }
`;

export const CustomLargeButtonLinkTablet = styled(LargeButton)`
  display: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

export const CustomLargeButtonLink = styled(LargeButton)`
  display: flex;
  max-width: 190px;
  white-space: nowrap;
  margin-top: auto;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
    height: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    display: flex;
    height: 50px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

export const ProgressContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 7px;
`;

export const TextContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 20px;
  ${FontStyles.bodySmallGilroy};
  color: #fff;
  flex-direction: column;
  margin-bottom: 15px;

  & > :first-child {
    ${FontStyles.h5};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 10px;
    margin-bottom: unset;
    ${FontStyles.bodyMiddleGilroy};

    & > :first-child {
      ${FontStyles.h4};
    }
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodyLargeGilroy};
    margin-bottom: 30px;
    width: 371px;

    & > :first-child {
      ${FontStyles.h4};
    }
  }
`;
