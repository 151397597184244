import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { Triangle } from '@constants/images';

export const Container = styled.div`
  position: relative;
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;
export const ContainerSmall = styled(Container)<{ $selected?: boolean }>`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: center;
  position: relative;
  background: ${(props) =>
    props.$selected ? props.theme.palette.primary.selected : props.theme.palette.primary.white};
  border-radius: 10px;
  padding: 11px;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;
  width: calc(100% - 7.5px);

  @media (${(props) => props.theme.breakpoints.tablet}) {
    border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
    width: calc(100% - 15px);
  }

  & > :first-child {
    height: 32px;
    width: 32px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    &::after {
      transition: opacity 300ms ease-in-out;
      opacity: ${(props) => (props.$selected ? 1 : 0)};
      content: '';
      position: absolute;
      right: -11px;
      top: 50%;
      width: 22px;
      height: 22px;
      background: url(${Triangle});
      transform: translateY(-50%);
    }

    border: ${(props) =>
      props.$selected
        ? '1px solid transparent'
        : `1px solid ${props.theme.palette.greys.lightLightGrey}`};

    padding-right: 25px;
    cursor: pointer;
    height: 64px;
    max-height: 64px;

    &:hover {
      background-color: ${(props) =>
        props.$selected ? props.theme.palette.primary.selected : props.theme.palette.primary.hover};
    }

    & > :first-child {
      height: 40px;
      width: 40px;
    }
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    width: 442px;
  }
`;

export const TriangleWrap = styled.span`
  opacity: 0;
  position: absolute;
  right: -1.5px;
  top: 34%;
  width: 11px;
  height: 22px;
  transition: opacity 300ms ease-in-out;
`;
export const List = styled.div`
  height: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  column-span: 4;
`;

export const Since = styled.p`
  ${FontStyles.bodySmallGilroy};
  transition: color 300ms ease-in-out;
  margin-top: 7px;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const Name = styled.p`
  ${FontStyles.h4};
  line-height: 100%;
  transition: color 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.black};
`;
export const NameSmall = styled(Name)`
  ${FontStyles.h5};
`;
