import { useMutation, useQuery } from '@tanstack/react-query';

import { ContactService } from '@services/contact-service';
import { TransactionsService } from '@services/transactions-service';

export const useNewTransfer = () => {
  return useMutation({
    mutationFn: (transaction: WeavrTransaction) => {
      return TransactionsService.newTransfer(transaction);
    },
  });
};

export const useCancelTransfer = () => {
  return useMutation({
    mutationFn: (transaction: CancelTransfer) => {
      return TransactionsService.cancelTransfer(transaction);
    },
  });
};

export const useGetContactTransactions = (contactId: string) => {
  const placeholder: DbTransaction[] = [
    {
      id: 'tx123456',
      name: 'John Doe',
      amount: 100.0,
      currency: 'EUR',
      dateAndTime: '2024-04-15T12:00:00Z',
      tag: 'Monthly Subscription',
      status: 'Pending',
      externalId: 'EXT123456',
      scheduledAt: new Date('2024-05-15T12:00:00Z'),
      userId: 'user123',
      accountNumber: '123456789',
      iban: 'GB82 WEST 1234 5698 7654 32',
      bicSwift: 'ABCDDEFFXXX',
      routingNumber: '011000138',
      bankName: 'Bank of Example',
      bankAddress: '123 Example St, City, Country',
      vendor: 'Vendor Name',
      fee: 0.5,
      direction: 'in',
      reason: 'Payment for services rendered',
      reference: 'INV123456',
      additionalBeneficiaryDetails: 'Additional details here',
      type: 'One-time',
    },
  ];
  return useQuery<DbTransaction[]>({
    queryKey: ['contactTransactions', contactId],
    placeholderData: placeholder,
    queryFn: async () => {
      return await TransactionsService.getContactTransactions(contactId);
    },
  });
};

export const useGetContacts = (managedAccountId: string, searchQuery?: string) => {
  const defaultPlaceholder: ContactResponse = {
    contacts: [],
  };
  return useQuery<ContactResponse>({
    queryKey: ['contactData', managedAccountId, searchQuery],
    placeholderData: defaultPlaceholder,
    queryFn: async () => {
      return await ContactService.getContacts(managedAccountId, searchQuery);
    },
    enabled: !!managedAccountId,
  });
};

export const useGetContactsByTransactionId = (managedAccountId: string, transactionId: string) => {
  const defaultPlaceholder: ContactResponse = {
    contacts: [],
  };
  return useQuery<ContactResponse>({
    queryKey: ['contactDataByTransactionId', managedAccountId, transactionId],
    placeholderData: defaultPlaceholder,
    queryFn: async () => {
      return await ContactService.getContactsByTransactionId(managedAccountId, transactionId);
    },
    enabled: !!managedAccountId && !!transactionId,
  });
};

export const useDeleteContact = () => {
  return useMutation({
    mutationFn: (contactId: string) => {
      return ContactService.deleteContact(contactId);
    },
  });
};

export const useDeleteIban = () => {
  return useMutation({
    mutationFn: (iban: string) => {
      return ContactService.deleteIban(iban);
    },
  });
};
export const useMakeMainIban = () => {
  return useMutation({
    mutationFn: (iban: string) => {
      return ContactService.makeMain(iban);
    },
  });
};

export const useAddBankDetails = () => {
  return useMutation({
    mutationKey: ['contactData'],
    mutationFn: ({ contactId, iban, bic, main }: AddBankDetailsInput) => {
      return ContactService.addBankDetails(contactId, iban, bic, main);
    },
  });
};

export const useGetScheduledTransactions = (managedAccountId: string, searchQuery: string) => {
  return useQuery<TransactionWithContact[]>({
    queryKey: ['scheduledData', searchQuery],
    queryFn: async ({ signal }) => {
      return await TransactionsService.getScheduledTransactions(
        signal,
        managedAccountId,
        searchQuery
      );
    },
    enabled: !!managedAccountId,
  });
};

export const useUpdateTransactionComment = () => {
  return useMutation({
    mutationFn: ({ id, comment }: TransactionCommentUpdateRequest) => {
      return TransactionsService.updateTransactionComment(id, comment);
    },
  });
};
