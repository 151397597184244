import { SelectChangeEvent } from '@mui/material';
import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';

import Dropdown from '@elements/Dropdown';

import COMPANY_LEGAL_FORMS from './CompanyLegalForms.json';
import { Container, CustomField } from './CompanyTypeinput.styles';

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  className?: string;
}

const CompanyTypeInput = ({ className, value, setValue }: Props) => {
  const [inputMode, setInputMode] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleBusinessTypeClick = () => {
    setTimeout(() => {
      (inputRef.current as HTMLInputElement).focus();
    }, 20);
  };

  const companyOptions = useMemo(() => {
    const options = COMPANY_LEGAL_FORMS.map((company) => {
      return {
        title: company,
        value: company,
      };
    });
    if (value && !COMPANY_LEGAL_FORMS.includes(value)) {
      options.push({ title: value, value });
    }
    return options;
  }, [value]);

  const handelOnChange = (event: SelectChangeEvent) => {
    const type = event.target.value;
    if (type === 'Other') {
      setInputMode(true);
      handleBusinessTypeClick();
      setValue('');
    } else {
      setValue(type);
    }
  };

  return (
    <Container className={className}>
      {inputMode ? (
        <CustomField
          autoComplete="do-not-autofill"
          ref={inputRef}
          $valid={true}
          placeholder="Type of Business"
          onChange={(event) => setValue(event.target.value)}
          onBlur={() => {
            setInputMode(false);
          }}
        />
      ) : (
        <Dropdown
          label=""
          options={companyOptions}
          onChange={handelOnChange}
          selected={value}
          optionsHeader="Choose type of business"
          placeholder="Choose type of business"
        ></Dropdown>
      )}
    </Container>
  );
};

export default CompanyTypeInput;
