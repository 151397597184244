import styled from 'styled-components';

import AddIbanForm from './AddIbanForm';
import IbanList from './IbanList';

const AddIbanSection = () => {
  return (
    <Container>
      <AddIbanForm />
      <IbanList />
    </Container>
  );
};
export default AddIbanSection;

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;
