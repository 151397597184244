import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconNoMatchesFound } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import { DUMMY_ANALYTICS } from '@mocks/Analytics';

interface Props {
  data: AnalyticsChartData[];
}

const AnalyticsChart = ({ data = DUMMY_ANALYTICS }: Props) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const [maxValue, setMaxValue] = useState<number>(Number(data[0].in));

  const hasEntries = data.some((entry) => Number(entry.in) > 0 || Number(entry.out) > 0);

  useEffect(() => {
    const getRange = (n: AnalyticsChartData[]) => {
      if (n) {
        let maxIn = Number(n[0].in);
        let maxOut = Number(n[0].out);
        n.forEach((month) => {
          if (Number(month.in) > maxIn) maxIn = Number(month.in);
          if (Number(month.out) > maxOut) maxOut = Number(month.out);
        });
        maxIn > maxOut ? roundRange(maxIn) : roundRange(maxOut);
      } else {
        roundRange(20000);
      }
    };

    const getThreshold = (n: number) => {
      return n && Math.pow(10, Math.floor(Math.log10(Math.abs(n))));
    };

    const roundRange = (max: number) => {
      max = Math.ceil(max);
      const threshold = Math.max(getThreshold(max));
      max = max && Math.round(Math.ceil(max / threshold) * threshold);
      setMaxValue(max);
    };

    getRange(data);
  }, [data]);

  const calcHeight = (input: number) => {
    const height = isTablet ? 126 : 156;
    let scale = 0;

    if (maxValue !== 0) {
      scale = height / maxValue;
    }

    return Math.floor(scale * input);
  };

  const month: Record<string, string> = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };

  return (
    <Container>
      <Interval>
        <Text>{maxValue / 1000}k</Text>
        <Text>{(maxValue * 0.75) / 1000}k</Text>
        <Text>{(maxValue * 0.5) / 1000}k</Text>
        <Text>{(maxValue * 0.25) / 1000}k</Text>
        <Text>0k</Text>
      </Interval>
      <GraphsContainer>
        {data.map((dataSet, index) => {
          return (
            <GraphWrapper key={index}>
              <Graph>
                <Bar height={verifiedKYC ? calcHeight(Number(dataSet.in)) : 0} />
                <Bar
                  className="negative"
                  height={verifiedKYC ? calcHeight(Number(dataSet.out)) : 0}
                />
              </Graph>
              <Text>{month[dataSet.month]}</Text>
            </GraphWrapper>
          );
        })}
      </GraphsContainer>
      {!hasEntries && (
        <NoMatchesContainer>
          <IconNoMatchesFound />
          No incoming / outgoing <br />
          transactions
        </NoMatchesContainer>
      )}
    </Container>
  );
};

export default AnalyticsChart;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 13px;
  height: 181px;
  justify-content: start;
  max-width: 815px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 151px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 151px;
    padding-bottom: initial;
    max-width: 810px;
  }
`;

const Interval = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
  flex: 1 0;
  height: 132px;
`;

const Text = styled.p`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkGrey};
`;

const GraphsContainer = styled.div`
  display: flex;
  gap: 27px;
  box-sizing: border-box;
  height: 156px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 126px;
  }
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 156px;
  width: 40px;
  box-sizing: border-box;
  gap: 5px;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 126px;
  }
`;

const Graph = styled.div`
  display: flex;
  gap: 10px;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  box-sizing: border-box;
  align-items: end;
  min-height: 156px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-height: 126px;
  }
`;

const Bar = styled.div<{ height: number }>`
  display: flex;
  width: 15px;
  background-color: ${(props) => props.theme.palette.positive.lessContrastGreen};
  box-sizing: border-box;
  height: ${(props) => props.height}px;

  &.negative {
    background-color: ${(props) => props.theme.palette.negative.lessContrastRed};
  }
`;

const NoMatchesContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  position: absolute;
  left: calc(50% - 100px);
  top: 450px;
  z-index: 2;
  ${FontStyles.h7};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  align-items: center;
  justify-content: center;
  gap: 15px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    position: relative;
    left: initial;
    top: initial;
    right: 100%;
    min-width: 100%;
    margin-top: -20px;
  }
`;
