import { Theme, createTheme } from '@mui/material';

let theme: Theme = createTheme({
  typography: {
    fontFamily: '"Gilroy Medium", sans-serif', // Set Gilroy as the primary font
    button: {
      fontFamily: '"Gilroy Medium", sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px', // 171.429%
      letterSpacing: '0.4px',
      textTransform: 'none', // Prevents all caps on buttons
    },
    h6Main: {
      fontFamily: '"Neue Plak Extended Semi", sans-serif',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '130%',
    },
    body1: {
      fontFamily: '"Gilroy", sans-serif',
      fontSize: '16px',
    },
    // Add more typography styles if needed
  },
  palette: {
    primary: {
      main: '#1099FD',
    },
    secondary: {
      main: '#151515',
    },
  },
  legacy: {
    palette: {
      primary: {
        black: '#151515',
        white: '#FFFFFF',
        blue: '#1099FD',
      },
      greys: {
        lightLightGrey: '#F3F3F9',
        middleGrey: '#D6DDEA',
        darkGrey: '#ACB3C0',
        darkestGrey: '#7B7B8A',
        asphaltTint: '#9B9FB6',
      },
      negative: {
        contrastRed: '#FD4734',
        lessContrastRed: '#FF7878',
        lessContrastRedLight: 'rgba(255, 120, 120, 0.5)',
        lessContrastRedLighter: 'rgba(255, 120, 120, 0.2)',
      },
      info: {
        dark: '#01579B',
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
});

theme = createTheme(theme, {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          backgroundColor: theme.legacy.palette.greys.lightLightGrey,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.legacy.palette.negative.contrastRed,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.legacy.palette.primary.blue,
          },
        },
        input: {
          fontFamily: '"Gilroy Medium", sans-serif',
        },
        notchedOutline: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'transparent',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Gilroy Medium", sans-serif',
          '&.Mui-error': {
            color: theme.legacy.palette.negative.contrastRed,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          alignSelf: 'flex-end',
          '&.Mui-error': {
            fontFamily: '"Gilroy Medium", sans-serif',
            color: theme.legacy.palette.negative.contrastRed,
          },
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          src: local('Gilroy Regular'), local('Gilroy-Regular'),
          url('./assets/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
          url('./assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
          font-display: swap;
        }
      `,
      },
    },
  },
});

export { theme };
