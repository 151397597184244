import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { LargeButtonsCommon } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  onClick?: () => void;
  text: string;
  variant: LargeButtonVariantTypes;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  tabIndex?: number;
  icon?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  id?: string;
  onBlur?: () => void;
}

const LargeButton = ({
  onClick,
  tabIndex,
  text,
  variant,
  className,
  style,
  disabled,
  icon,
  type,
  id,
  onBlur,
}: Props) => {
  return (
    <Button
      tabIndex={tabIndex}
      onClick={onClick}
      $variant={variant}
      className={className}
      style={style}
      disabled={disabled}
      onBlur={onBlur}
      type={type}
      id={id}
    >
      {text}
      {icon && icon}
    </Button>
  );
};

const Button = styled.button<{ $variant: LargeButtonVariantTypes }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 40px;
  box-sizing: border-box;
  border-radius: 75px;
  gap: 10px;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => LargeButtonsCommon.getColor(props.$variant, props)};
  background: ${(props) => LargeButtonsCommon.getBackgroundColor(props.$variant, props)};
  background-size: ${(props) => LargeButtonsCommon.getBackgroundSize(props.$variant)};
  border: ${(props) => LargeButtonsCommon.getBorder(props.$variant, props)};
  transition: ${(props) => LargeButtonsCommon.getTransition(props.$variant)};
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${(props) => LargeButtonsCommon.getHoverBackgroundColor(props.$variant, props)};
    color: ${(props) => LargeButtonsCommon.getHoverColor(props.$variant, props)};
    border: ${(props) => LargeButtonsCommon.getHoverBorder(props.$variant, props)};
    background-position: ${(props) => LargeButtonsCommon.getBackgroundPosition(props.$variant)};
  }

  &:disabled {
    background: ${(props) => LargeButtonsCommon.getDisabledBackgroundColor(props.$variant, props)};
    border: ${(props) => LargeButtonsCommon.getBorder(props.$variant, props)};
    color: ${(props) => LargeButtonsCommon.getColor(props.$variant, props)};
    cursor: unset;
  }
`;

export default LargeButton;
