import React from 'react';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconBlueCheck } from '@constants/icons';
import { SuccessBGs, SuccessBGsMobile } from '@constants/images';

import Modal from '@components/Modal/Modal';

import LargeButton from '@elements/LargeButton/LargeButton';
import RoleInfoBox from '@elements/RoleInfoBox/RoleInfoBox';

interface Props {
  role: string;
}

const WelcomeModal = ({ role }: Props) => {
  const closeModal = useBoundStore((state) => state.closeModal);

  const infoText = (role: string) => {
    const str = 'After Verification, you can';
    switch (role) {
      case 'Auditor':
        return `${str} view the account's transactions, statements, and reports for compliance and audit purposes.`;
      case 'Transaction Manager':
        return `${str} view and facilitate SEPA or SWIFT transactions. Card payment facilitation is exclusive to Full Access Users.`;
      default:
        return '';
    }
  };

  return (
    <CustomModal>
      <Container>
        <Content>
          <TopContent>
            <Icon>
              <IconBlueCheck />
            </Icon>
            <HeadLine>Welcome to the Peanuds </HeadLine>
            <SubHeadLine>
              Your role is defined as: <p className="role">{role}</p>
            </SubHeadLine>
          </TopContent>
          <RoleInfoBox text={infoText(role)} />
          <BottomContent>
            <LargeButton
              onClick={() => closeModal()}
              text="Verify later"
              variant="transparentBlack"
              className="button"
            />
            <LargeButton
              onClick={() => undefined}
              text="Verify now"
              variant="darkWhite"
              className="button"
            />
          </BottomContent>
        </Content>
      </Container>
    </CustomModal>
  );
};

export default WelcomeModal;

const CustomModal = styled(Modal)`
  padding: 0 20px 0 20px;
  min-height: 100%;
  background-image: url(${SuccessBGsMobile[0]});
  background-image: -webkit-image-set(
    url(${SuccessBGsMobile[0]}) 1x,
    url(${SuccessBGsMobile[1]}) 2x
  );
  background-image: image-set(url(${SuccessBGsMobile[0]}) 1x, url(${SuccessBGsMobile[1]}) 2x);
  background-size: cover;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 40px 0 40px;
    min-height: unset;
    height: 660px;
    background-image: url(${SuccessBGs[0]});
    background-image: -webkit-image-set(url(${SuccessBGs[0]}) 1x, url(${SuccessBGs[1]}) 2x);
    background-image: image-set(url(${SuccessBGs[0]}) 1x, url(${SuccessBGs[1]}) 2x);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden auto;

  ${HideScrollbar};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    overflow: unset;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  gap: 20px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    gap: 40px;
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    gap: 10px;
    width: 480px;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
  width: 100%;

  & > .button {
    width: 100%;
    flex-shrink: 0;
    max-width: 240px;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: initial;
    padding-top: 50px;
  }
`;

const HeadLine = styled.h3`
  text-align: center;
  ${FontStyles.h3};
  width: 292px;
  color: ${({ theme }) => theme.palette.primary.black};
  margin-top: 20px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: initial;
    margin-top: initial;
    ${FontStyles.h2};
  }
`;

const SubHeadLine = styled.h6`
  ${FontStyles.h6};
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  margin-top: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  & > .role {
    color: ${({ theme }) => theme.palette.primary.blue};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    color: ${({ theme }) => theme.palette.primary.black};
    ${FontStyles.bodyMiddleGilroy};
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  width: 76px;
  background-color: ${({ theme }) => theme.palette.primary.white};
  box-shadow:
    -6px -6px 15px #ffffff,
    -8px -8px 20px #ffffff,
    6px 6px 15px rgba(123, 123, 138, 0.08),
    8px 8px 20px rgba(174, 174, 192, 0.2);
  border-radius: 85px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }

  svg {
    width: 33px;
    height: 25px;
  }
`;
