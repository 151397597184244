import styled from 'styled-components';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';

import Modal from '@components/Modal/Modal';

import Input from '@elements/input/Input/Input';

export const NoteInput = styled(Input)`
  width: 100%;
`;

export const TransactionDetailModal = styled(Modal)`
  padding: 0 25px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 45px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const IconWithBackground = styled.div<{ kind: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  background-color: ${(props) =>
    props.kind === 'in'
      ? props.theme.palette.positive.contrastGreen
      : props.theme.palette.negative.contrastRed};
  color: ${(props) => props.theme.palette.primary.white};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const HeadText = styled.div`
  ${FontStyles.h3};
  margin-bottom: 10px;
  background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 60px 0 10px 0;
    ${FontStyles.h2};
  }
`;

export const SubText = styled.div`
  ${FontStyles.h6};
  margin-bottom: 35px;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
    color: ${({ theme }) => theme.palette.primary.black};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: 248px;
`;

export const ContentScroll = styled.div`
  ${Scrollbar};
  overflow: hidden auto;
  padding-right: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding-right: 13px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 20px 10px 20px 20px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 25px 12px 25px 25px;
  }
`;

export const Footer = styled.div`
  padding-top: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
`;

export const FooterLower = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  width: 100%;
  flex-direction: column;

  & > :first-child {
    align-self: start;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    & > :first-child {
      align-self: unset;
    }

    flex-direction: row;
  }
`;

export const FooterLowerButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    column-gap: 15px;
    margin-left: auto;
    flex-direction: row;
  }
`;

export const Label = styled.p`
  ${FontStyles.h5};
  margin-bottom: 10px;
`;
export const EditWrap = styled.span`
  cursor: pointer;
  svg {
    stroke: ${(props) => props.theme.palette.primary.black};
  }
  &:hover svg {
    stroke: ${(props) => props.theme.palette.primary.blue};
  }
`;

export const CloseButtonSmall = styled.button`
  width: 100%;
  color: ${(props) => props.theme.palette.primary.black};
  margin-bottom: 33px;
  margin-top: 18px;

  &:hover {
    cursor: pointer;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;
