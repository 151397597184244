import { Box, Modal } from '@mui/material';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const FilterWrap = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: ${(props) => props.theme.palette.greys.darkestGrey};
  }
  &:hover {
    svg {
      fill: ${(props) => props.theme.palette.primary.blue};
    }
  }
`;

export const Container = styled(Box)`
  position: relative;
  right: 0;
  box-sizing: border-box;

  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  height: 100vh;
  width: 300px;
  & .MuiBackdrop-root {
    background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  }
  & .closeIcon {
    width: 30px;
    height: 30px;
  }
  @media (${(props) => props.theme.breakpoints.tablet}) {
    background-color: ${(props) => props.theme.palette.primary.white};
    //width: 50%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: -7px 0 44px rgba(123, 123, 138, 0.1);
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 313px;
  }
`;
export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 115px);
  padding: 27px 40px 0 40px;
  overflow: auto;
  gap: 30px;
`;

export const ModalContainer = styled(Modal)`
  z-index: 10000000000;
  left: unset !important;
  & .MuiBackdrop-root {
    background: linear-gradient(
      249.15deg,
      rgba(191, 200, 219, 0.26) 2.79%,
      rgba(122, 133, 153, 0.26) 97.66%
    );
  }
`;

export const ShowMore = styled.div``;
export const TitleBox = styled.div`
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 300px;
  height: 80px;
  border-end-start-radius: 21px;
  bottom: 0;
  right: 0;
  box-shadow: 0px -1px 18.5px 0px #0000001a;
  background-color: ${(props) => props.theme.palette.primary.white};
  & button {
    width: 236px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 313px;
  }
`;
export const Title = styled.h5`
  ${FontStyles.h5};
`;
