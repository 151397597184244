import React from 'react';
import styled from 'styled-components';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { RocketBlasting } from '@constants/images';

interface TotalCardSpendingProps {
  balance?: number;
}

const TotalCardSpending: React.FC<TotalCardSpendingProps> = ({ balance = 0 }) => {
  const { symbol, amountWithoutSymbol } = Commons.currencyFormatterWithSymbolAtStart(balance);

  return (
    <Card>
      <CardInfo>
        <TotalCardspendings>Total Card spendings</TotalCardspendings>
        <CardBalanceText>
          <CardSymbol>{symbol}</CardSymbol>
          {amountWithoutSymbol}
        </CardBalanceText>
      </CardInfo>
      <CardImage
        src={RocketBlasting}
        alt="RocketBlasting"
      />
    </Card>
  );
};

export default TotalCardSpending;

const CardBalanceText = styled.div`
  ${FontStyles.buttonsNeuePlakType1};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  padding-left: 4px;
  color: #fff;
`;

const CardSymbol = styled.span`
  padding-right: 5px;
`;

const TotalCardspendings = styled.span`
  ${FontStyles.bodyMiddleGilroy};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: 0.15px;
  padding-bottom: 9px;
  color: #fff;
  white-space: nowrap;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 16px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  width: 310px;
  height: 136px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 20px;
  background: var(
    --Gradients-Stone-Grey-Blue-Mix,
    linear-gradient(23deg, #3f3e62 6.8%, #7d7fad 80%)
  );
`;

const CardImage = styled.img`
  position: relative;
  right: 35px;
`;
