import { css } from 'styled-components';

const gradientTransitionWorkaround = css`
  background: linear-gradient(145deg, #232222, #34354a, #74c4ff, #1099fd);
  background-size: 500% auto;
  transition: 0.8s;
  &:hover {
    background-position: 90% 120%;
    cursor: pointer;
  }
`;

export { gradientTransitionWorkaround };
