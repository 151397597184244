import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconInfo } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

interface Props {
  text: string;
  tabIndex?: number;
}

let timeout: NodeJS.Timeout;

const AccountInfoBubble = ({ text, tabIndex }: Props) => {
  const theme = useTheme();
  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);

  const [visible, setVisible] = useState<boolean>(false);

  const handleClick = () => {
    if (!isLaptop) {
      setVisible((prevState) => !prevState);
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      timeout = setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  return (
    <Container
      $visible={!isLaptop ? visible : undefined}
      tabIndex={tabIndex}
      onClick={!isLaptop ? handleClick : undefined}
    >
      <IconInfo />
      <Bubble
        id="bubble"
        $visible={!isLaptop ? visible : undefined}
      >
        {text}
      </Bubble>
    </Container>
  );
};

export default AccountInfoBubble;

const Container = styled.div<{ $visible: boolean | undefined }>`
  display: flex;
  position: relative;
  transition: 300ms ease-in-out;
  align-items: flex-end;
  color: ${(props) =>
    props.$visible ? props.theme.palette.primary.black : props.theme.palette.greys.darkGrey};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};
    cursor: help;
    outline: none;

    & #bubble {
      @media (${(props) => props.theme.breakpoints.laptop}) {
        visibility: visible;
        opacity: 1;
        color: ${(props) => props.theme.palette.primary.black};
      }
    }
  }
`;

const Bubble = styled.div<{ $visible: boolean | undefined }>`
  display: flex;
  position: absolute;
  background: ${(props) => props.theme.palette.primary.white};
  border-radius: 20px;
  justify-content: center;
  align-items: end;
  z-index: 1;
  padding: 10px 15px;
  ${FontStyles.bodySmallGilroy};
  filter: drop-shadow(0px 4px 11px rgba(16, 153, 253, 0.07));
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: ease-in-out 300ms;
  width: 250px;
  left: -250px;
  top: 50%;
  transform: translateX(100%) translateY(-30px);

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 300px;
    left: -300px;
    visibility: hidden;
    opacity: 0;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 25px;
    left: 0;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }
`;
