import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  background: ${({ theme }) => theme.palette.gradients.blueOrangeTint};
  overflow: auto;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 25px 35px;
  }
`;

export const HeadText = styled.div`
  ${FontStyles.h3};
  color: ${({ theme }) => theme.palette.primary.black};
  margin-bottom: 10px;
`;

export const SubText = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  color: ${({ theme }) => theme.palette.primary.black};
  margin-bottom: 30px;
`;

export const Table = styled.table`
  width: 100%;
  border-radius: 20px;
  border-collapse: collapse;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 700px;
  }
`;

export const TH = styled.th`
  ${FontStyles.subtitleGilroy};
  background: ${({ theme }) => theme.palette.primary.white};
  padding: 20px 15px;
  text-align: start;
  box-sizing: border-box;

  :nth-child(1) {
    width: 54%;
    border-radius: 20px 0 0 0;
    text-align: start;
  }

  :nth-child(2) {
    width: 46%;
    border-radius: 0 20px 0 0;
    text-align: center;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 20px 30px;
  }
`;

export const TDHead = styled.td`
  ${FontStyles.h5};
  color: ${({ theme }) => theme.palette.primary.black};
  background: ${({ theme }) => theme.palette.greys.middleGrey};
  padding: 15px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 15px 30px;
  }
`;

export const TD = styled.td`
  ${FontStyles.bodyMiddleGilroy};
  color: ${({ theme }) => theme.palette.primary.black};
  background: ${({ theme }) => theme.palette.primary.white};
  padding: 10px 15px;
  border-top: 1px solid ${({ theme }) => theme.palette.greys.middleGrey};
  border-bottom: 1px solid ${({ theme }) => theme.palette.greys.middleGrey};

  :nth-child(1) {
    text-align: start;
    border-right: 1px solid ${({ theme }) => theme.palette.greys.middleGrey};
  }

  :nth-child(2) {
    text-align: center;
    border-left: 1px solid ${({ theme }) => theme.palette.greys.middleGrey};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 10px 30px;
  }
`;

export const LastRow = styled.td`
  height: 35px;
  background: ${({ theme }) => theme.palette.primary.white};
  border-radius: 0 0 20px 20px;
`;
