import React from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconInfo } from '@constants/icons';

interface Props {
  text: string;
  className?: string;
}

const RoleInfoBox = ({ text, className }: Props) => {
  return (
    <Container className={className}>
      <IconInfo className="icon" />
      <div className="text">{text}</div>
    </Container>
  );
};

export default RoleInfoBox;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  border-radius: 20px;
  padding: 15px;
  gap: 10px;
  width: calc(100vw - 70px);
  justify-content: space-between;
  max-width: 505px;

  & > .icon {
    flex-shrink: 0;
  }

  & > .bold {
    ${FontStyles.buttonGilroyType3};
  }

  & > .text {
    ${FontStyles.bodyMiddleGilroy};
  }
`;
