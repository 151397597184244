import { useQueryClient } from '@tanstack/react-query';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useIssueChallenge } from '@api/Challenges/challengesApi';
import { useGetCorporate } from '@api/Corporate/corporateApi';
import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { useNewTransfer } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { Commons, convertDate, getFees } from '@shared/functions';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import ModalHeadline from '@components/Modal/ModalHeadline';
import {
  Button,
  Container,
  Content,
  CustomModal,
  Footer,
  OneLiner,
  SectionHeading,
  Tag,
  TransactionWrapper,
  UnderlineField,
} from '@components/transaction/TransactionConfirmation/TransactionConfirmation.styles';
import TransactionOtpModal from '@components/transaction/TransactionModals/TransactionOtpModal';
import { convertStringToNumber } from '@components/transaction/halper';

import BlueButton from '@elements/BlueButton/BlueButton';
import BorderButtonBlue from '@elements/BorderButtonBlue/BorderButton';
import Toast from '@elements/Toast/Toast';

interface Props {
  setProceeded: Dispatch<SetStateAction<boolean>>;
}

const calcAmountIncludingFees = (amount: string) => {
  return convertStringToNumber(amount) + getFees('SEPA');
};

const cleanIBAN = (iban: string) => {
  return iban.replace(/ /g, '');
};

const TransactionConfirmation = ({ setProceeded }: Props) => {
  const openModal = useBoundStore((state) => state.openModal);
  const closeModal = useBoundStore((state) => state.closeModal);
  const transactionRecipient = useBoundStore((state) => state.transactionRecipient);
  const tag = useBoundStore((state) => state.tag);
  const amount = useBoundStore((state) => state.amount);
  const description = useBoundStore((state) => state.description);
  const iban = useBoundStore((state) => state.iban);
  const bic = useBoundStore((state) => state.bic);
  const accountNumber = useBoundStore((state) => state.accountNumber);
  const routingNumber = useBoundStore((state) => state.routingNumber);
  const dateOfTransactionExecution = useBoundStore((state) => state.dateOfTransactionExecution);
  const isTransactionToday = useBoundStore((state) => state.isTransactionToday);
  const setIsTransactionToday = useBoundStore((state) => state.setIsTransactionToday);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const [submitting, setSubmitting] = useState(false);

  const { data: corporateData } = useGetCorporate();
  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const { handleHTTPErrors } = useHTTPErrorHandler();
  const { mutate: newTransfer } = useNewTransfer();

  const { mutate: issueChallengeMutation } = useIssueChallenge();

  const controller = new AbortController();

  useEffect(() => {
    if (dateOfTransactionExecution) {
      const parts = dateOfTransactionExecution.split('.');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      const inputDate = new Date(year, month, day);
      const today = new Date();

      // Reset hours for correct comparison
      today.setHours(0, 0, 0, 0);
      inputDate.setHours(0, 0, 0, 0);

      const isToday = inputDate.getTime() === today.getTime();

      setIsTransactionToday(isToday);
    }
  }, [dateOfTransactionExecution, setIsTransactionToday]);

  const queryClient = useQueryClient();

  const transfer: WeavrTransaction = {
    name: transactionRecipient.name,
    amount: convertStringToNumber(amount),
    currency: 'EUR',
    dateAndTime: new Date(),
    tag: tag,
    status: 'status',
    externalId: 'something',
    nextPayment: isTransactionToday
      ? undefined
      : convertDate(dateOfTransactionExecution)?.toString(),
    managedAccountId: managedAccountData?.accounts[0]?.id ?? '',
    reference: description,
    recipientAddress:
      transactionRecipient.address +
      ' ' +
      transactionRecipient.postcode +
      ' ' +
      transactionRecipient.city +
      ' ' +
      transactionRecipient.country,
    address: transactionRecipient.address ?? '',
    postcode: transactionRecipient.postcode ?? '',
    city: transactionRecipient.city ?? '',
    country: transactionRecipient.country ?? '',
    accountNumber: accountNumber,
    iban: cleanIBAN(iban),
    bicSwift: bic,
    routingNumber: routingNumber,
    bankName: '',
    bankAddress: '',
    type: iban && !accountNumber ? 'SEPA' : 'SWIFT',
    contactId: transactionRecipient.id ?? '',
    corporateId: corporateData && corporateData.id.id ? corporateData.id.id : '',
  };

  const handleClick = () => {
    // setProceeded(false);
    closeModal();
  };

  const handleSubmit = async () => {
    if (submitting) return;

    setSubmitting(true);

    if (transfer.amount <= 0) {
      toast.error(
        <Toast
          title="Error"
          message="Please enter an amount."
        />
      );
      setSubmitting(false);
      return;
    }

    newTransfer(transfer, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['contactData'],
          refetchType: 'active',
        });

        if (data.state === 'PENDING_CHALLENGE') {
          const issueChallengeRequestData: ChallengeRequest = {
            idempotency: crypto.randomUUID(),
            resourceType: 'outgoing_wire_transfers',
            resourceIds: [data.id],
          };

          issueChallengeMutation(
            { challenge: issueChallengeRequestData, signal: controller.signal },
            {
              onSuccess: (data) => {
                openModal(<TransactionOtpModal challenge={data.scaChallengeId} />, true);
              },
              onError: (error) => {
                handleHTTPErrors([error]);
                setSubmitting(false);
              },
            }
          );
        } else {
          setSubmitting(false);
        }
      },
      onError: (error) => {
        handleHTTPErrors([error]);
        setSubmitting(false);
      },
    });
  };

  // const getWeekday = (dateString: string): string => {
  //   const [day, month, year] = dateString.split('.').map((num) => parseInt(num));
  //   const date = new Date(year, month - 1, day); // Note: Months are 0-indexed in JS Date
  //
  //   return date.toLocaleDateString('en-US', { weekday: 'long' });
  // };

  // const dateToText = (date: string, frequency: string, amount: string) => {
  //   const [day] = date.split('.');
  //   const dayWithSuffix = ordinalSuffix(Number(day));
  //   const weekday = getWeekday(date);
  //
  //   const paymentSchedules = {
  //     Never: () => t('payment_scheduled_on') + date,
  //     Weekly: () =>
  //       t('every_week_on') +
  //       weekday +
  //       t('you_will_be_charged') +
  //       amount +
  //       '€. ' +
  //       t('first_payment_scheduled') +
  //       date,
  //     Biweekly: () =>
  //       t('every_two_weeks_on') +
  //       weekday +
  //       t('you_will_be_charged') +
  //       amount +
  //       '€. ' +
  //       t('first_payment_scheduled') +
  //       date,
  //     Monthly: () =>
  //       t('each_month') +
  //       dayWithSuffix +
  //       t('you_will_be_charged') +
  //       amount +
  //       '€. ' +
  //       t('first_payment_scheduled') +
  //       date,
  //     Quarterly: () =>
  //       t('every_three_months_uppercase') +
  //       dayWithSuffix +
  //       t('you_will_be_charged') +
  //       amount +
  //       '€. ' +
  //       t('first_payment_scheduled') +
  //       date,
  //   };
  //
  //   const schedule = paymentSchedules[frequency as keyof typeof paymentSchedules];
  //   return schedule ? schedule() : t('first_payment_scheduled') + date;
  // };

  const getAccountBalance = () => {
    if (managedAccountData && managedAccountData.accounts) {
      const balance = managedAccountData.accounts[0].balances.availableBalance ?? 0;
      const currency = managedAccountData.accounts[0].currency ?? 'EUR';

      return Commons.currencyFormatter(
        balance > 0 ? Commons.formatWeavrAmountToPeanuds(balance) : 0,
        currency
      );
    } else {
      return Commons.currencyFormatter(0, 'EUR');
    }
  };

  const getAmountWithFees = () => {
    if (managedAccountData && managedAccountData.accounts) {
      const currency = managedAccountData.accounts[0].currency ?? 'EUR';
      const amountWithFees = calcAmountIncludingFees(amount);

      return Commons.currencyFormatter(amountWithFees, currency);
    } else {
      return Commons.currencyFormatter(0, 'EUR');
    }
  };

  const getTransactionFees = () => {
    if (managedAccountData && managedAccountData.accounts) {
      const currency = managedAccountData.accounts[0].currency ?? 'EUR';
      const fees = getFees('SEPA');
      return Commons.currencyFormatter(fees, currency);
    } else {
      return Commons.currencyFormatter(0, 'EUR');
    }
  };

  const getAmountWithoutFees = () => {
    if (managedAccountData && managedAccountData.accounts) {
      const currency = managedAccountData.accounts[0].currency ?? 'EUR';

      const amountWithoutFees = convertStringToNumber(amount);
      return Commons.currencyFormatter(amountWithoutFees, currency);
    } else {
      return Commons.currencyFormatter(0, 'EUR');
    }
  };

  const getBalanceAfterTransaction = () => {
    if (managedAccountData && managedAccountData.accounts) {
      const currency = managedAccountData.accounts[0].currency ?? 'EUR';
      const balance = managedAccountData.accounts[0].balances.availableBalance ?? 0;
      return Commons.currencyFormatter(
        Commons.formatWeavrAmountToPeanuds(balance) - calcAmountIncludingFees(amount),
        currency
      );
    } else {
      return Commons.currencyFormatter(0, 'EUR');
    }
  };

  return (
    <CustomModal showCloseIcon={false}>
      <ModalHeadline>Last Confirmation</ModalHeadline>

      <Container>
        <Content>
          {tag && <Tag>#{tag}</Tag>}
          {/*<ScheduleRow>*/}
          {/*  <H6>Schedule Payment</H6>*/}
          {/*  <ScheduleCalendar>*/}
          {/*    <DatepickerTrigger onClick={() => openModal(<ScheduleDatepickerModal />)}>*/}
          {/*      <IconCalendarNormal />*/}
          {/*    </DatepickerTrigger>*/}
          {/*    <div>{dateOfTransactionExecution}</div>*/}
          {/*  </ScheduleCalendar>*/}
          {/*</ScheduleRow>*/}
          {/*<ScheduleTextbox>*/}
          {/*  {dateToText(dateOfTransactionExecution, transactionFrequency.type, amount)}*/}
          {/*</ScheduleTextbox>*/}
          <SectionHeading $bg="top">
            Transaction Details
            <Button
              type="button"
              onClick={handleClick}
            >
              Edit
            </Button>
          </SectionHeading>
          <TransactionWrapper>
            <OneLiner>
              <Tag>Account Balance</Tag>
              {getAccountBalance()}
            </OneLiner>
            <OneLiner>
              <Tag>You are about to send</Tag>
              {getAmountWithoutFees()}
            </OneLiner>
            <OneLiner>
              <Tag>Transaction Fees</Tag>
              {getTransactionFees()}
            </OneLiner>
            <OneLiner>
              <Tag>Total Transfer Amount</Tag>
              {getAmountWithFees()}
            </OneLiner>
            <OneLiner>
              <Tag>Balance after transaction</Tag>
              {getBalanceAfterTransaction()}
            </OneLiner>
          </TransactionWrapper>
          <SectionHeading $bg="middle">
            Recipient Details
            <Button
              type="button"
              onClick={handleClick}
            >
              Edit
            </Button>
          </SectionHeading>
          <UnderlineField>
            <Tag>Recipient</Tag>
            {transactionRecipient.name}
          </UnderlineField>
          <UnderlineField>
            <Tag>{iban ? 'IBAN' : 'Account Number'}</Tag>
            {iban ? iban : accountNumber}
          </UnderlineField>
          {(bic || routingNumber) && (
            <UnderlineField>
              <Tag>{bic ? 'BIC' : 'Routing Number (optional)'}</Tag>
              {bic ? bic : routingNumber}
            </UnderlineField>
          )}
          <SectionHeading $bg="bottom">
            Transaction Reference
            <Button
              type="button"
              onClick={handleClick}
            >
              Edit
            </Button>
          </SectionHeading>
          <UnderlineField>
            <Tag>Reference</Tag>
            {description}
          </UnderlineField>
        </Content>
      </Container>
      <Footer>
        <BorderButtonBlue
          onClick={() => handleClick()}
          size="medium"
        >
          Back to Editing
        </BorderButtonBlue>
        <BlueButton
          disabled={submitting}
          onClick={async () => await handleSubmit()}
          size="medium"
        >
          Proceed
        </BlueButton>
      </Footer>
    </CustomModal>
  );
};

export default TransactionConfirmation;
