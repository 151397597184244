import React, { Dispatch, MouseEvent, SetStateAction, useEffect, useRef, useState } from 'react';

import { useLogout } from '@api/Access/accessApi';
import { useGetCorporate } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { parseUsernameWithEmail } from '@shared/functions';

import {
  IconFacebook,
  IconHamburgerClose,
  IconInstagram,
  IconLinkedIn,
  IconTwitter,
} from '@constants/icons';

import { useClickedOutside } from '@hooks/useClickedOutside';
import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';
import { useLockBodyScroll } from '@hooks/useLockBodyScroll';

import Avatar from '@components/Avatar/Avatar';
import CardSetup from '@components/CardSetup/CardSetup';
import {
  BottomGroup,
  CloseIconButton,
  Container,
  Headline,
  NavGroup,
  NavigationButton,
  NavigationLink,
  NotificationGroup,
  SectionDivider,
  SocialGroup,
  SocialMediaIconButton,
  TopGroup,
  UserContentContainer,
  UserEmail,
  UserInfo,
  UserName,
} from '@components/MobileNavigation/MobileNavigation.styles';

interface Props {
  mobileNavOpen: boolean;
  setMobileNavOpen: Dispatch<SetStateAction<boolean>>;
  avatarSrc: string;
  navLinks: NavLinkStructure[];
}

const MobileNavigation = ({ mobileNavOpen, setMobileNavOpen, navLinks }: Props) => {
  const mobileNavRef = useRef<HTMLDivElement>(null);

  const setNotificationsOpen = useBoundStore((state) => state.setNotificationsOpen);
  const setCardSetupVisible = useBoundStore((state) => state.setCardSetupVisible);
  const openModal = useBoundStore((state) => state.openModal);
  const setIsLoggedIn = useBoundStore((state) => state.setIsLoggedIn);
  const setSupportVisible = useBoundStore((state) => state.setSupportVisible);

  const [submitting, setSubmitting] = useState<boolean>(false);

  useLockBodyScroll(mobileNavOpen);
  const { mutate: logout } = useLogout();
  useClickedOutside(mobileNavRef, setMobileNavOpen);

  const { data: corporate, isError, error } = useGetCorporate();
  const { handleHTTPErrors } = useHTTPErrorHandler();

  const name = corporate?.rootUser?.name;
  const surname = corporate?.rootUser?.surname;
  const email = corporate?.rootUser?.email;

  const [userName, userEmail] = parseUsernameWithEmail(name, surname, email);

  // TODO: We can most likely do this in the store directly
  useEffect(() => {
    if (mobileNavOpen) setNotificationsOpen(false);
  }, [mobileNavOpen, setNotificationsOpen]);

  useEffect(() => {
    if (isError && error) {
      handleHTTPErrors([error]);
    }
  }, [error, handleHTTPErrors, isError]);

  const handleLogout = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (submitting) return;

    setSubmitting(true);

    logout(undefined, {
      onSuccess: () => {
        setIsLoggedIn(null);
        window.location.href = '/login';
      },
      onError: (error) => {
        handleHTTPErrors([error]);
        setSubmitting(false);
      },
    });
  };

  return (
    <Container
      $mobileNavOpen={mobileNavOpen}
      ref={mobileNavRef}
    >
      <TopGroup>
        <Headline>Navigation Menu</Headline>
        <CloseIconButton
          onClick={() => setMobileNavOpen(false)}
          icon={<IconHamburgerClose />}
        />
      </TopGroup>
      <UserInfo>
        <Avatar text={userName} />
        <UserContentContainer>
          <UserName>{userName}</UserName>
          <UserEmail>{userEmail}</UserEmail>
        </UserContentContainer>
      </UserInfo>
      <NavGroup>
        {navLinks.map(({ title, url }, index) =>
          index === 2 ? (
            <NavigationButton
              key={index}
              onClick={() => {
                setMobileNavOpen(false);
                setCardSetupVisible(true);
                openModal(<CardSetup />);
              }}
            >
              {title}
            </NavigationButton>
          ) : (
            <NavigationLink
              key={index}
              to={url}
              onClick={() => setMobileNavOpen(false)}
            >
              {title}
            </NavigationLink>
          )
        )}
      </NavGroup>
      <NotificationGroup>
        <SectionDivider />
        <NavigationButton
          onClick={() => {
            setNotificationsOpen(true);
            setMobileNavOpen(false);
          }}
        >
          Notification Center
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setSupportVisible(true);
            window.HubSpotConversations.widget.open();
          }}
        >
          Support
        </NavigationButton>
        <NavigationLink
          to="/settings"
          onClick={() => setMobileNavOpen(false)}
        >
          Settings
        </NavigationLink>
      </NotificationGroup>
      <BottomGroup>
        <SocialGroup>
          <SocialMediaIconButton
            onClick={() => window.open('https://www.facebook.com/peanuds', '_blank')}
            icon={<IconFacebook />}
          />
          <SocialMediaIconButton
            onClick={() => window.open('https://www.instagram.com/getpeanuds', '_blank')}
            icon={<IconInstagram />}
          />
          <SocialMediaIconButton
            onClick={() => window.open('https://twitter.com/getpeanuds', '_blank')}
            icon={<IconTwitter />}
          />
          <SocialMediaIconButton
            onClick={() => window.open('https://www.linkedin.com/company/peanuds/', '_blank')}
            icon={<IconLinkedIn />}
          />
        </SocialGroup>
        <NavigationButton
          disabled={submitting}
          onClick={handleLogout}
        >
          Log Out
        </NavigationButton>
      </BottomGroup>
    </Container>
  );
};

export default MobileNavigation;
