import styled from 'styled-components';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';

import ModalNew from '@components/Modal/ModalNew';

export const CustomModal = styled(ModalNew)`
  max-width: 444px !important;
`;

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.primary.deepPurple};
  border-radius: 15px;
  margin: 16px 24px;
  padding: 25px 9px 25px 25px;
  min-width: 320px;
  height: 424px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-width: 396px;
    height: 400px;
  }
`;

export const Content = styled.div`
  ${Scrollbar};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 20px;
  overflow: hidden auto;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding-right: 13px;
  }
`;

export const TransactionWrapper = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;

  & > :last-child {
    padding-bottom: 5px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  padding: 16px;
  align-items: center;
  justify-content: right;
  border-top: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  & button {
    width: initial;
  }
`;

export const Tag = styled.p`
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const ScheduleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ScheduleCalendar = styled.div`
  display: flex;
  align-items: center;
  ${FontStyles.bodyMiddleGilroy};
  gap: 10px;
`;

export const H6 = styled.div`
  ${FontStyles.h6};
`;

export const DatepickerTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
`;

export const ScheduleTextbox = styled.div`
  background: white;
  border: 1px dashed ${(props) => props.theme.palette.greys.middleGrey};
  width: 100%;
  min-height: 66px;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  ${FontStyles.bodySmallGilroy};
`;

export const SectionHeading = styled.div<{ $bg: string }>`
  display: flex;
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.greys.middleGrey};
  padding: 11px 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  box-sizing: border-box;
  width: 100%;
  background: ${(props) =>
    (props.$bg === 'top' && 'linear-gradient(23.48deg, #3F3E62 6.8%, #7D7FAD 80%)') ||
    (props.$bg === 'middle' && 'linear-gradient(16.05deg, #477A8F 22.34%, #96C2DB 90.77%)') ||
    'linear-gradient(193.34deg, #C7AEE7 9.58%, #8C78A7 79.6%)'};
`;

export const Button = styled.button`
  display: flex;
  box-sizing: border-box;
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.primary.white};
  &:hover {
    cursor: pointer;
  }
`;

export const UnderlineField = styled.div`
  display: flex;
  border-bottom: 1px solid #c7aee7;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  ${FontStyles.bodyMiddleGilroy};
  box-sizing: border-box;
`;

export const OneLiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${FontStyles.bodyMiddleGilroy};
`;

export const CommonButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 75px;
  max-height: 50px;
  ${FontStyles.buttonGilroyType3};

  &:disabled {
    background: none;
    border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
    color: ${(props) => props.theme.palette.greys.middleGrey};
    cursor: unset;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: 300px;
  }
`;

export const LargeButtonTransparent = styled(CommonButton)`
  color: ${(props) => props.theme.palette.primary.black};
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  transition: 300ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
    cursor: pointer;
  }
`;

export const LargeButtonBlue = styled(CommonButton)`
  color: ${(props) => props.theme.palette.primary.white};
  background: linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a);
  background-size: 500% auto;
  transition: 300ms ease-in-out;

  &:hover {
    background-position: 90% 120%;
    cursor: pointer;
  }
`;
