import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconCalendarNormal } from '@constants/icons';
import { AnalyticsCenterSmall, AnalyticsCenters, AnalyticsCentersMin } from '@constants/images';

import DatePickerModal from '@components/DatePickerModal/DatePickerModal';
import TimeframeSelector from '@components/TimeframeSelector/TimeframeSelector';

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}. ${month}. ${year}`;
};

const AnalyticsCenter = () => {
  const openModal = useBoundStore((state) => state.openModal);
  const timespan = useBoundStore((state) => state.timespan);
  return (
    <Container>
      <Group>
        <Headline>Your Analytic Center</Headline>
        <Subline>Choose timeframes for comfortable search and export of transactions.</Subline>
      </Group>
      <Group className="bottom">
        <TimeframeSelector />
        <DatePickerButton
          className="large"
          onClick={() => openModal(<DatePickerModal />)}
        >
          <Inner className="large">
            <IconCalendarNormal />
          </Inner>
          {`${formatDate(timespan.start)} - ${formatDate(timespan.end)}`}
        </DatePickerButton>
      </Group>
    </Container>
  );
};

export default AnalyticsCenter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  min-width: 335px;
  height: 248px;
  border-radius: 20px;
  background-image: url(${AnalyticsCenterSmall});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 18px 20px 17px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    background: ${(props) => props.theme.palette.gradients.blueOrangeTint};
    height: 144px;
    flex-direction: row;
    gap: 85px;
    text-align: unset;
    padding: 20px 25px 20px 20px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 100px;
    padding: 19px 25px 19px 30px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    width: 38%;
    height: 280px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 15px;
    padding: 35px;
    background:
      image-set(url(${AnalyticsCentersMin[0]}) 1x, url(${AnalyticsCentersMin[1]}) 2x) no-repeat
        center center / 100% auto,
      white;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    background:
      image-set(url(${AnalyticsCenters[0]}) 1x, url(${AnalyticsCenters[1]}) 2x) no-repeat center
        center / 100% auto,
      white;
  }
`;

const Headline = styled.div`
  ${FontStyles.h4}
  color: ${(props) => props.theme.palette.primary.black};
`;

const Subline = styled.div`
  ${FontStyles.bodyMiddleGilroy}
  color: ${(props) => props.theme.palette.primary.black};
  max-width: 300px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: unset;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  &.bottom {
    align-items: flex-start;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-items: flex-start;
    gap: 10px;

    &.bottom {
      gap: 14px;
    }
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    &.bottom {
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }

    @media (${(props) => props.theme.breakpoints.smallDesktop}) {
      &.bottom {
        flex-direction: column;
        align-items: start;
        gap: 17px;
      }
    }
  }
`;

const DatePickerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 40px;
  cursor: pointer;
  padding: 0 5px;

  &.large {
    width: initial;
    color: ${(props) => props.theme.palette.primary.blue};
    ${FontStyles.bodyMiddleGilroy};
    gap: 10px;
    padding: 0 15px 0 5px;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background: white;
  border-radius: 40px;
  cursor: pointer;

  &.large {
    background: ${(props) => props.theme.palette.greys.middleGrey};
  }
`;
