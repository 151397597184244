import React, { Dispatch } from 'react';

const RegEx = {
  NAME: /^([a-zA-ZäöüÄÖÜß-]{2,}\s*)+$/,
  EMAIL: /^(|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{6,})?))$/,
  ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
  COMPANY_NAME: /^[a-zA-Z0-9\s&',.()-]+$/,
  PASSWORD_STRENGTH:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=+_{}[\]|;:'",.<>/?`~])[a-zA-Z\d!@#$%^&*()\-=+_{}[\]|;:'",.<>/?`~]{8,30}$/,
  UPPERCASE: /.*[A-Z].*/,
  LOWERCASE: /.*[a-z].*/,
  SPECIAL_CHAR: /.*[^A-Za-z0-9].*/,
  DIGIT: /.*[0-9].*/,
  PHONE_NUMBER: /^(\+?\d[\d\s()-]{3,14})?$/,
};

const getAge = (dateString: string) => {
  const currentDate = new Date();
  const birthDate = new Date(dateString);
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const month = currentDate.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const validateField = (
  value: string,
  regex: RegExp,
  setState: Dispatch<React.SetStateAction<boolean>>
) => {
  setState(regex.test(value) || value === '');
};

export const FormValidation = {
  RegEx,
  validateField,
  getAge,
};
