import { CanceledError } from 'axios';

import { axiosClient, weavrAuthorizedAxiosClient } from '@clients/axios-client';

const createPassword = async ({ userId, password }: WeavrPassword) => {
  try {
    const axiosResponse = await axiosClient.post('/password/create', { userId, password });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
      throw error;
    }
  }
};

const validatePassword = async (password: string) => {
  try {
    const axiosResponse = await axiosClient.post('/password/validate', { password });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
      throw error;
    }
  }
};

const updatePassword = async (credentials: WeavrUpdatePassword) => {
  try {
    const { oldPassword, newPassword } = credentials;
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/password/update', {
      oldPassword,
      newPassword,
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
      throw error;
    }
  }
};

const initiateLostPassword = async (
  email: string,
  { captchaToken, idempotencyRef }: Omit<CloudflareCaptcha, 'isValid'>
) => {
  try {
    const axiosResponse = await axiosClient.post(
      '/password/lost',
      { email },
      {
        headers: {
          ...(captchaToken && { 'cf-captcha-token': captchaToken }),
          ...(idempotencyRef && { 'idempotency-ref': idempotencyRef }),
        },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const resumeLostPassword = async ({ nonce, email, value }: LostPasswordData) => {
  try {
    const axiosResponse = await axiosClient.post('/password/lost/resume', {
      nonce,
      email,
      value,
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

export const PasswordService = {
  createPassword,
  validatePassword,
  updatePassword,
  initiateLostPassword,
  resumeLostPassword,
};
