import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FilterIcon, IconHamburgerClose } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import { allTypes, statuses } from '@components/Filters/Statuses';

import BlueButton from '@elements/BlueButton/BlueButton';

import CheckboxFilter from './CheckboxFilter';
import {
  Container,
  Content,
  FilterWrap,
  ModalContainer,
  ModalFooter,
  Title,
  TitleBox,
} from './Filters.styles';
import RadioFilter from './RadioFilter';

const statusOpts = statuses.map((status) => ({ ...status, label: status.text, name: 'status' }));

const FilterModal: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const setFilterStatus = useBoundStore((state) => state.setFilterStatus);
  const setFilterType = useBoundStore((state) => state.setFilterType);
  const filterType = useBoundStore((state) => state.filterType);
  const filterStatus = useBoundStore((state) => state.filterStatus);
  const selected = useBoundStore((state) => state.selected);
  const setSelected = useBoundStore((state) => state.setSelected);
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <FilterWrap onClick={handleOpen}>
        <FilterIcon />
      </FilterWrap>
      <ModalContainer
        open={open}
        onClose={handleClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Container>
          <Content>
            <TitleBox>
              <Title id="filter-modal-title">Apply Filters</Title>
              <IconButton
                onClick={handleClose}
                className="closeIcon"
              >
                <IconHamburgerClose />
              </IconButton>
            </TitleBox>
            {!isLaptop && (
              <CheckboxFilter
                onChange={setFilterType}
                title="Categories"
                filters={allTypes}
                selected={filterType}
              />
            )}
            <RadioFilter
              title="Transaction Statuses"
              onChange={setFilterStatus}
              filters={statusOpts}
              selectedFilter={filterStatus}
              selectedTxn={selected}
              onSelectedTxn={setSelected}
            />
            {/*<ShowMore>*/}
            {/*  <Link*/}
            {/*    href="#"*/}
            {/*    variant="body2"*/}
            {/*  >*/}
            {/*    Show more*/}
            {/*  </Link>*/}
            {/*</ShowMore>*/}
          </Content>
          <ModalFooter>
            <BlueButton onClick={handleClose}>Apply Filter</BlueButton>
          </ModalFooter>
        </Container>
      </ModalContainer>
    </div>
  );
};

export default FilterModal;
