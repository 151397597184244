import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { IconDelete } from '@constants/icons';

import DeleteContactConfirmation from '@components/DeleteContactConfirmation/DeleteContactConfirmation';

import Contact from './Contact';

interface Props {
  contact: ContactsWithBankDetails;
  className?: string;
}

const ContactHeader = ({ contact, className }: Props) => {
  const openModal = useBoundStore((state) => state.openModal);

  const selectedContact = useBoundStore((state) => state.selectedContact);

  return (
    <Container className={className}>
      <Contact contact={contact} />
      <IconDelete
        onClick={() => {
          if (selectedContact) {
            openModal(<DeleteContactConfirmation selectedContact={selectedContact} />);
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export default ContactHeader;
