import styled from 'styled-components';

import { HideScrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;

  //&.noMatches {
  //  flex-direction: column;
  //  overflow: hidden;
  //}
`;

export const Fixed = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 85px;
  align-items: start;
  justify-content: start;
  min-height: 550px;
  margin-bottom: 13px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-width: 250px;
  }
`;

export const Scrollable = styled.div<{ $scrolled: boolean; $inView: boolean }>`
  position: relative;
  display: flex;
  ${HideScrollbar};
  flex-direction: column;
  overflow: auto hidden;
  min-height: 300px;
  flex: 1 0;
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  background: ${(props) => props.theme.palette.primary.white};
  box-sizing: border-box;
  border-left: 1px solid
    ${(props) => (props.$scrolled ? props.theme.palette.greys.darkGrey : 'none')};
`;

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 0;
  min-height: 36px;
  max-height: 36px;
  box-sizing: border-box;
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.primary.black};
  min-width: 100%;
  width: fit-content;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};

  &.fixed {
    justify-content: initial;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0;
  min-height: 32px;
  max-height: 32px;
  box-sizing: border-box;
  min-width: 100%;
  cursor: pointer;

  &.selected {
    background-color: ${(props) => props.theme.palette.primary.lightBlue};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.greys.lightGrey};
    color: ${(props) => props.theme.palette.primary.black};
    .amount {
      color: ${(props) => props.theme.palette.primary.blue};
    }
  }

  &.fixed {
    justify-content: initial;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const CheckBoxField = styled.div`
  display: flex;
  min-width: 50px;
  justify-content: start;
  align-items: center;
  padding-left: 0;
  position: relative;
  padding-bottom: 8px;

  svg {
    width: 20px;
    height: 20px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    //height: 50px;
    padding-left: 8px;
    align-items: center;
    padding-bottom: 0;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const AmountHeading = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
  display: flex;
  min-width: 150px;
  justify-content: start;
  align-items: center;
  gap: 9px;
`;

export const AmountHeadingTablet = styled(AmountHeading)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const AmountHeadingMobile = styled(AmountHeading)`
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const AmountFieldMobile = styled(AmountHeading)<{ $failed: boolean }>`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) =>
    props.$failed ? props.theme.palette.greys.darkestGrey : props.theme.palette.primary.black};
  justify-content: end;
  min-width: 150px;
  .amount {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

export const AmountFieldTablet = styled(AmountFieldMobile)`
  justify-content: left;
`;

export const DateHeading = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  display: flex;
  min-width: 200px;
  justify-content: start;
  align-items: center;
  gap: 9px;
  //height: 50px;
  cursor: pointer;
`;

export const DateField = styled.div`
  display: flex;
  min-width: 200px;
  justify-content: start;
  align-items: center;
  gap: 9px;
  //height: 50px;
`;

export const TagField = styled.div`
  display: flex;
  min-width: 250px;
  justify-content: start;
  align-items: center;
  //height: 50px;
`;

export const IconField = styled.div`
  display: flex;
  min-width: 100px;
  justify-content: left;
  align-items: center;
  //height: 50px;
`;

export const FromToField = styled.div`
  min-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 758px);
  margin-right: auto;
`;

export const IconWithBackground = styled.div<{ $direction: TransactionDirection; $status: string }>`
  display: flex;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.black};
  margin-right: 6px;
  background-color: ${({ $status, $direction, theme }) => {
    if ($status === 'RETURNED') {
      return theme.palette.positive.lessContrastGreenLighter;
    }
    if ($status === 'PENDING' || $status === 'PENDING_CHALLENGE') {
      return theme.palette.status.pendingFinishStatusIcon;
    }
    return $direction === 'in'
      ? theme.palette.positive.lessContrastGreenLighter
      : theme.palette.negative.lessContrastRedLighter;
  }};

  svg {
    width: 15px;
    pointer-events: none;
  }

  @media (${({ theme }) => theme.breakpoints.mobile}) {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
`;

export const Slider = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 240px;
  padding-right: 15px;

  // &.hovered {
  //   background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  //   display: flex;
  //   right: 0;
  // }
`;

export const Button = styled.button`
  ${FontStyles.bodySmallGilroy};
  padding: 8px 20px;
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 300ms ease-in-out;
`;

export const DetailsButton = styled(Button)<{ $repeat: boolean }>`
  color: ${({ theme }) => theme.palette.primary.black};
  border: 1px solid ${({ theme }) => theme.palette.primary.black};
  background: linear-gradient(145deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #232222, #34354a);
  background-size: 500% auto;
  margin-left: ${(props) => (props.$repeat ? '95px' : 'initial')};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.white};
    border: 1px solid transparent;
    background-position: 90% 120%;
  }
`;

export const RepeatButton = styled(Button)<{ kind: string }>`
  display: ${(props) => (props.kind === 'in' ? 'none' : 'initial')};
  color: ${({ theme }) => theme.palette.primary.white};
  background: linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a);
  background-size: 500% auto;

  &:hover {
    background-position: 90% 120%;
  }
`;

export const NoMatchesFoundRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 500px;
`;

export const NoKYCWraper = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  //& svg {
  //  margin-top: -100px;
  //}
`;
export const TransactionType = styled.div`
  display: flex;
  justify-content: center;

  color: ${(props) => props.theme.palette.primary.black};
  span {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    ${FontStyles.bodySmallGilroy};
  }
`;
export const RowWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 64px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
`;
export const StatusWrap = styled.div`
  color: ${(props) => props.theme.palette.primary.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (${(props) => props.theme.breakpoints.mobile}) {
    align-items: center;
  }
`;
export const RowMobile = styled.div`
  padding: 8px 8px 10px;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  margin: 12px 16px 0 16px;
  &.selected {
    background-color: ${(props) => props.theme.palette.primary.lightBlue};
  }
  &.fixed {
    justify-content: initial;
  }
`;

export const DataSort = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  text-align: center;
`;
