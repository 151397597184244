import { format } from 'date-fns';

export const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export const formatDate = (dateString: string) =>
  format(new Date(dateString), 'dd.MM.yyyy kk:mm a');
