import styled from 'styled-components';

interface Props {
  onClick: () => void;
}

const Hamburger = ({ onClick }: Props) => {
  return (
    <HamburgerWrapper onClick={onClick}>
      <Burger />
      <Burger />
      <Burger />
    </HamburgerWrapper>
  );
};

const HamburgerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  height: 24px;

  & > :nth-child(2) {
    margin-left: 9px;
    width: 15px;
  }

  &:hover {
    cursor: pointer;

    & > * {
      background-color: #34354a;
    }

    & > :nth-child(2) {
      margin-left: 0;
    }
  }
`;

const Burger = styled.div`
  background-color: ${(props) => props.theme.palette.greys.darkestGrey};
  transition: 300ms ease-in-out;
  width: 24px;
  border-radius: 20px;
  height: 2px;
`;
export default Hamburger;
