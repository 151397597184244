import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { IconScrollUp } from '@constants/icons';

const SendMoneyButton = () => {
  return (
    <Button to="/send-money">
      <IconScrollUp />
    </Button>
  );
};

const Button = styled(NavLink)`
  position: fixed;
  z-index: 20;
  right: 25px;
  bottom: 25px;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  display: flex;
  background: ${(props) => props.theme.palette.gradients.primaryBlueGradient};
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;
export default SendMoneyButton;
