import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const TransactionFormContentContainer = styled.div`
  ${FontStyles.h5};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const TransactionFormContentWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  gap: 10px;
`;

export const DescriptionError = styled.div<{ $show: boolean }>`
  display: flex;
  align-self: end;
  ${FontStyles.bodySmallGilroy};
  opacity: ${(props) => (props.$show ? 1 : 0)};
  color: ${(props) => props.theme.palette.negative.lessContrastRed};
`;

export const TransactionFormTop = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  align-items: start;
  justify-content: space-between;
  gap: 13px;
  flex: 1;
  box-sizing: border-box;
  padding: 24px;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 24px;
  }
`;

export const TransactionFormMiddle = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 15px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 24px;
  }
`;

export const TransactionFormMiddleSection = styled.div`
  display: flex;
  flex: 1;
  gap: 25px;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const TransactionFormFieldWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  transition: ease-in-out 300ms;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-width: 315px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-width: 346px;

    &.description {
      max-width: unset;
    }

    &.right {
      max-width: 299px;
    }
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    max-width: 404px;

    &.right {
      max-width: 381px;
    }
  }
`;

export const TransactionFormBottom = styled.div`
  padding-top: 20px;
  display: flex;
  flex: 1;
  box-sizing: border-box;
  gap: 25px;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
    padding-top: 19px;
  }
`;

export const NoPaddingTransactionFormBottom = styled(TransactionFormBottom)`
  margin: 0 24px 24px;
  padding-top: 24px;
  display: flex;
  justify-content: end;
  border-top: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
`;

export const TransactionFormHeadLine = styled.p`
  display: flex;
  box-sizing: border-box;

  & > .blue {
    color: ${(props) => props.theme.palette.primary.blue};
    padding: 0 3px;
  }
`;

export const TransactionFormLine = styled.hr`
  border: 0.5px dashed ${(props) => props.theme.palette.greys.middleGrey};
  display: flex;
  flex: 1;
`;

export const TransactionFormDashedBox = styled.div`
  display: flex;
  border: 1px dashed ${(props) => props.theme.palette.greys.middleGrey};
  border-radius: 20px;
  box-sizing: border-box;
  padding: 15px;
  ${FontStyles.bodySmallGilroy};
  flex-direction: column;
  gap: 10px;
  flex: 1;
  width: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-width: 315px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-width: 381px;
  }
`;

export const TransactionFormGradientText = styled.p`
  ${FontStyles.h6};
  background: linear-gradient(23.48deg, #3f3e62 6.8%, #7d7fad 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const TransactionFormFooter = styled.div<{ scheduledFormMode?: boolean }>`
  display: flex;
  flex: 1;
  gap: 15px;
  padding: 10px 0 13px 0;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ scheduledFormMode }) => (scheduledFormMode ? 'space-between' : 'center')};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
    padding: 20px 0 0 0;
  }
`;

export const TransactionFormDivider = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  margin-bottom: -15px;
  background-color: ${(props) => props.theme.palette.greys.darkGrey};
`;

export const TransactionFormCommonButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 75px;
  max-height: 50px;
  width: 100%;
  ${FontStyles.buttonGilroyType3};
  cursor: pointer;

  &:disabled {
    background: ${(props) => props.theme.palette.greys.darkGrey};
    color: #fff;
    cursor: unset;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: 300px;
  }
`;

export const TransactionFormLargeButtonTransparent = styled(TransactionFormCommonButton)`
  color: ${(props) => props.theme.palette.primary.black};
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  transition: 300ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
  }
`;

export const TransactionFormLargeButtonBlue = styled(TransactionFormCommonButton)`
  color: ${(props) => props.theme.palette.primary.white};
  background: linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a);
  background-size: 500% auto;
  transition: 300ms background-position ease-in-out;

  &.scheduled {
    max-width: 190px;
  }

  &:hover {
    background-position: 90% 120%;
  }
`;

export const TransactionFormLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EndAdornment = styled.span`
  svg {
    width: 23px;
  }
`;
export const TransactionFormSubLine = styled.div`
  display: flex;
  align-items: center;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const TransactionFormDocumentText = styled.div`
  display: flex;
  align-items: center;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkGrey};
  gap: 10px;
`;

export const TransactionFormRemoveContact = styled.button`
  display: flex;
  align-items: center;
  height: 20px;
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  gap: 7.5px;
  transition: color 300ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.negative.lessContrastRed};
  }
`;

export const TransactionFormDocumentButton = styled.button<{ $documentSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme, $documentSelected }) =>
    $documentSelected ? theme.palette.primary.black : theme.palette.greys.darkestGrey};
  transition: color 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.black};
  }
`;

export const TransactionFormRoleContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.primary.black};
  gap: 5px;

  & > .role {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

export const TransactionFormRoleMessage = styled.div`
  display: flex;
  box-sizing: border-box;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.negative.contrastRed};
`;

export const TransactionFormScheduledPaymentsText = styled.div`
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.primary.black};
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
