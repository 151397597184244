import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px 15px;

  .__opc-uxsec-prompt {
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: unset;
    .__opc-uxsec-prompt {
      max-width: 60%;
    }
  }
`;
