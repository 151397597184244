import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconInfo, IconWarning } from '@constants/icons';

import Modal from '@components/Modal/Modal';

import LargeButton from '@elements/LargeButton/LargeButton';

const ChangeDetailsModal = () => {
  const setSupportVisible = useBoundStore((state) => state.setSupportVisible);

  const closeModal = useBoundStore((state) => state.closeModal);

  return (
    <Container>
      <IconContainer>
        <IconWarning />
      </IconContainer>
      <Title>Change of account details</Title>
      <InfoBox>
        <CustomIconInfo />
        Changing your account details, such as Name, Surname, Email, Mobile Number, Business Address
        or Date of birth, will require to refresh you KYC or KYB verification. Please contact our
        support team to proceed with changing your account details.
      </InfoBox>
      <ButtonWrapper>
        <LargeButton
          text="Cancel"
          variant="transparentBlack"
          onClick={() => closeModal()}
          style={{ height: '40px' }}
        />
        <LargeButton
          text="Contact Support"
          variant="blueWhite"
          onClick={() => {
            setSupportVisible(true);
            closeModal();
          }}
          style={{ height: '40px' }}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default ChangeDetailsModal;

const Container = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #fff
    linear-gradient(
      174deg,
      #f3f3f9 31.37%,
      #fff 80.73%,
      rgba(255, 255, 255, 0) 92.5%,
      rgba(255, 255, 255, 0) 92.51%
    );

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 55px 19px 0 19px;
  }
`;

const IconContainer = styled.div`
  margin-top: 15px;
  width: 76px;
  height: 76px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 35px;
  background: ${({ theme }) => theme.palette.primary.white};
  box-shadow:
    -3.8px -3.8px 9.5px 0 #fff,
    -5.067px -5.067px 12.667px 0 #fff,
    3.8px 3.8px 9.5px 0 rgba(123, 123, 138, 0.08),
    5.067px 5.067px 12.667px 0 rgba(174, 174, 192, 0.2);

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: initial;
  }
`;

const Title = styled.div`
  ${FontStyles.h4};
  color: ${({ theme }) => theme.palette.primary.black};
  margin-bottom: 20px;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 419px;
  }
`;

const InfoBox = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  max-width: 370px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.palette.primary.black};
  border-radius: 15px;
  margin-bottom: 40px;
`;

const CustomIconInfo = styled(IconInfo)`
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  color: ${({ theme }) => theme.palette.primary.black};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
