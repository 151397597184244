import { StateCreator } from 'zustand';

export interface CorporateRegistrationDataSlice {
  corporateData: CorporateRegistrationData;
  setCorporateData: (
    value:
      | CorporateRegistrationData
      | ((value: CorporateRegistrationData) => CorporateRegistrationData)
  ) => void;
  resetCorporateData: () => void;
}

const defaultCorporateRegistrationData: CorporateRegistrationData = {
  termsAccepted: false,
  firstName: '',
  lastName: '',
  email: '',
  phonePrefix: '',
  phone: '',
  role: 'DIRECTOR',
  companyAddressCountry: '',
  companyAddressZipCode: '',
  companyAddressCity: '',
  companyName: '',
  companyType: '',
  companyRegistrationNumber: '',
  companyRegistrationCountry: '',
  companyAddressLine1: '',
};

export const createCorporateRegistrationDataSlice: StateCreator<CorporateRegistrationDataSlice> = (
  set
) => ({
  corporateData: defaultCorporateRegistrationData,
  setCorporateData: (value) =>
    set((state) => ({
      corporateData: typeof value === 'function' ? value(state.corporateData) : value,
    })),
  resetCorporateData: () => set(() => ({ corporateData: defaultCorporateRegistrationData })),
});
