import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconLocked } from '@constants/icons';
import { countryPhonePrefixes } from '@constants/phonePrefixes';

import useDebounce from '@hooks/useDebounce';

import { TextInputContainer } from '@elements/input/TextInput/TextInput';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  setPrefixValidated: Dispatch<SetStateAction<boolean>>;
  innerRef?: Ref<HTMLInputElement>;
  onClick?: (event: MouseEvent<HTMLInputElement | HTMLDivElement>) => void;
  locked?: boolean;
  className?: string;
  value?: string;
}

const InputPhonePrefix = ({
  onChange,
  autoFocus,
  setPrefixValidated,
  innerRef,
  onClick,
  locked = false,
  className,
  value,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>(value ?? '');
  const debouncedInputValue = useDebounce(inputValue, 300);
  const [selectedFlag, setSelectedFlag] = useState<string>('');
  const [validPrefix, setValidPrefix] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    const matchedCountry = countryPhonePrefixes.find(
      (country) => country.mobileCode === inputValue || country.mobileCode === value
    );

    if (matchedCountry) {
      setSelectedFlag(matchedCountry.code.toLowerCase());
      setValidPrefix(true);
      setPrefixValidated(true);
    } else {
      setSelectedFlag('');
      if (inputValue === '') {
        setValidPrefix(false);
        setPrefixValidated(true);
      } else {
        setValidPrefix(false);
        setPrefixValidated(false);
      }
    }
  }, [inputValue, setPrefixValidated, value]);

  const filteredCountries = useMemo(
    () =>
      countryPhonePrefixes
        .filter((country) => country.mobileCode.includes(debouncedInputValue))
        .sort((a, b) => {
          const codeA = parseInt(a.mobileCode.replace(/\D/g, ''), 10);
          const codeB = parseInt(b.mobileCode.replace(/\D/g, ''), 10);

          return codeA - codeB;
        }),
    [debouncedInputValue]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!value) {
        setShowDropdown(true);
        const { value } = e.target;
        if (/^[+]?[0-9]*$/.test(value)) {
          const newValue = value === '' ? '' : value.startsWith('+') ? value : `+${value}`;
          setInputValue(newValue);
          onChange(e);
        }
      } else {
        setShowDropdown(true);
        if (/^[+]?[0-9]*$/.test(value)) {
          const newValue = value === '' ? '' : value.startsWith('+') ? value : `+${value}`;
          setInputValue(newValue);
          onChange(e);
        }
      }
    },
    [onChange, value]
  );

  const handleSelect = (mobileCode: string) => {
    const event = { target: { value: mobileCode } } as ChangeEvent<HTMLInputElement>;
    setInputValue(mobileCode);
    setValidPrefix(true);
    setPrefixValidated(true);
    setShowDropdown(false);
    onChange(event);
  };

  return (
    <Container>
      <CustomTextInputContainer
        $locked={locked}
        className={className}
        $valid={validPrefix || inputValue === ''}
        $value={inputValue}
      >
        {selectedFlag === 'us' ? (
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/2/26/Flags_of_Canada_and_the_United_States.svg"
            width="20"
            alt="flag"
          />
        ) : selectedFlag ? (
          <img
            loading="lazy"
            src={`https://flagcdn.com/${selectedFlag}.svg`}
            width="20"
            alt="flag"
          />
        ) : (
          <FlagPlaceholder />
        )}
        <CustomInput
          $locked={locked}
          maxLength={4}
          ref={innerRef}
          autoFocus={autoFocus}
          type="text"
          $valid={validPrefix}
          disabled={locked}
          value={value ? value : inputValue}
          onChange={handleChange}
          placeholder="+1"
          onBlur={() => {
            setTimeout(() => setShowDropdown(false), 200);
          }}
        />
        {locked && <IconLocked />}
      </CustomTextInputContainer>
      <SuggestionBox
        $show={showDropdown && inputValue !== '' && filteredCountries.length > 0}
        $count={filteredCountries.length === 1}
      >
        <ScrollableContent
          $show={showDropdown && inputValue !== '' && filteredCountries.length > 0}
        >
          {filteredCountries.map((country, index) => {
            const matchIndex = country.mobileCode.indexOf(inputValue);
            const matchText = country.mobileCode.substring(
              matchIndex,
              matchIndex + inputValue.length
            );
            const afterMatch = country.mobileCode.substring(matchIndex + inputValue.length);

            return (
              <CountryItem
                key={index}
                onClick={(event) => {
                  handleSelect(country.mobileCode);
                  if (onClick) {
                    onClick(event as MouseEvent<HTMLInputElement>);
                  }
                }}
              >
                {country.code === 'US' ? (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/2/26/Flags_of_Canada_and_the_United_States.svg"
                    width="20"
                    alt="flag"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                    width="20"
                    height="12"
                    alt="flag"
                  />
                )}
                <span>
                  <MatchSpan>{matchText}</MatchSpan>
                  {afterMatch}&nbsp;
                </span>
                <span>({country.countryName})</span>
              </CountryItem>
            );
          })}
        </ScrollableContent>
      </SuggestionBox>
    </Container>
  );
};

export default InputPhonePrefix;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;

const SuggestionBox = styled.div<{ $show: boolean; $count: boolean }>`
  width: 190px;
  max-height: 120px;
  background-color: white;
  padding: 15px 15px ${(props) => (props.$count ? '15px' : '0')};
  box-sizing: border-box;
  box-shadow: 2px 7px 77px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: absolute;
  top: 52px;
  z-index: 1;
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 5px;

  ${Scrollbar};
  ${FontStyles.bodySmallGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    top: 42px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    top: 52px;
  }
`;

const ScrollableContent = styled.div<{ $show: boolean }>`
  max-height: 100px;
  overflow-y: auto;
  ${Scrollbar};
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 5px;

  padding-right: 15px;
  box-sizing: border-box;
`;

const CountryItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 300ms ease-in-out;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

const CustomTextInputContainer = styled(TextInputContainer)<{ $locked: boolean }>`
  box-sizing: border-box;
  height: 50px;
  padding: 14px 15px;
  justify-content: start;
  gap: 7px;

  ${(props) =>
    props.$locked &&
    css`
      color: ${(props) => props.theme.palette.greys.darkestGrey};
    `}

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 50px;
  }
`;

const MatchSpan = styled.span`
  color: ${(props) => props.theme.palette.primary.blue};
  margin-left: 10px;
`;

const CustomInput = styled.input<{ $valid: boolean; $locked: boolean }>`
  all: unset;
  ${FontStyles.bodyMiddleGilroy};
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) =>
    props.$valid
      ? props.theme.palette.primary.black
      : props.theme.palette.negative.lessContrastRed};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color ease-in-out 300ms;
  box-sizing: border-box;
  max-width: 35px;

  ${(props) =>
    props.$locked &&
    css`
      color: ${(props) => props.theme.palette.greys.darkestGrey};
    `}

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

const FlagPlaceholder = styled.div`
  width: 20px;
  height: 15px;
`;
