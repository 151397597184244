import React from 'react';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconBlueCheck } from '@constants/icons';
import { DocumentDeleteSuccessLargeSet, InviteSuccessBGsMobile } from '@constants/images';

import Modal from '@components/Modal/Modal';
import PeanudsLogo from '@components/PeanudsLogo/PeanudsLogo';

import LargeButton from '@elements/LargeButton/LargeButton';

interface Props {
  email: string;
}
const InviteUserSuccess = ({ email }: Props) => {
  const closeModal = useBoundStore((state) => state.closeModal);

  return (
    <MainWrapper>
      <Container>
        <Content>
          <TopContent>
            <Icon>
              <IconBlueCheck />
            </Icon>
            <PeanudsLogo
              gradientId="logo"
              className="peanudsLogo"
            />
            <HeadLine>Success</HeadLine>
            <SubHeadLine>
              Instructions sent to:
              <br /> {email}
            </SubHeadLine>
          </TopContent>
          <BottomContent>
            <CheckApplicationButton
              onClick={() => closeModal()}
              text="Check application"
              variant="blueWhite"
            />
          </BottomContent>
        </Content>
      </Container>
    </MainWrapper>
  );
};

export default InviteUserSuccess;

const MainWrapper = styled(Modal)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  box-sizing: border-box;

  background-image: url(${InviteSuccessBGsMobile[0]});
  background-image: -webkit-image-set(url(${InviteSuccessBGsMobile[0]}) 1x, url(${
    InviteSuccessBGsMobile[1]
  }) 2x,);
  background-image: image-set(url(${InviteSuccessBGsMobile[0]}) 1x, url(${
    InviteSuccessBGsMobile[1]
  }) 2x);
  
  @media (${(props) => props.theme.breakpoints.tablet}) {
    border-radius: 20px;
    width: 650px;
    height: 760px;
    background-image: url(${DocumentDeleteSuccessLargeSet[0]});  
    background-image: -webkit-image-set(url(${DocumentDeleteSuccessLargeSet[0]}) 1x, url(${
      DocumentDeleteSuccessLargeSet[1]
    }) 2x,);

  background-image: image-set(url(${DocumentDeleteSuccessLargeSet[0]}) 1x, url(${
    DocumentDeleteSuccessLargeSet[1]
  }) 2x);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow: hidden auto;
  box-sizing: border-box;

  ${HideScrollbar};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    overflow: unset;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 292px;

  & > .peanudsLogo {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 376px;

    & > .peanudsLogo {
      display: flex;
    }
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  gap: 10px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  width: 76px;
  background-color: ${(props) => props.theme.palette.primary.white};
  box-shadow:
    8px 8px 20px 0 rgba(174, 174, 192, 0.2),
    6px 6px 15px 0 rgba(123, 123, 138, 0.08),
    -8px -8px 20px 0 #fff,
    -6px -6px 15px 0 #fff;
  border-radius: 85px;

  svg {
    width: 38px;
    height: 38px;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 120px;
    width: 120px;

    svg {
      width: initial;
      height: initial;
    }
  }
`;

const HeadLine = styled.h3`
  ${FontStyles.h3};
  color: ${(props) => props.theme.palette.primary.black};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    color: ${(props) => props.theme.palette.positive.contrastGreen};
    ${FontStyles.h2};
  }
`;

const SubHeadLine = styled.h6`
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin-top: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

const CheckApplicationButton = styled(LargeButton)`
  width: 230px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 40px;
  }
`;
