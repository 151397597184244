import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';

import SettingsFullAccessUserPanel from '@components/Settings/SettingsFullAccessUserPanel/SettingsFullAccessUserPanel';
import SettingsInviteUserButton from '@components/Settings/SettingsInviteUserButton/SettingsInviteUserButton';
import SettingsUserPanel from '@components/Settings/SettingsUserPanel/SettingsUserPanel';

const SettingsAccessControl = () => {
  const navigate = useNavigate();

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const fullAccessUser = useBoundStore((state) => state.fullAccessUser);

  /*
   TODO Rewrite so user does not see anything even if they enter the page => perhaps use return null if !fullAccessUser
   */
  useEffect(() => {
    if (!fullAccessUser && window.location.pathname === '/settings/access') navigate('/settings');
  }, [fullAccessUser, navigate]);

  return (
    <Container>
      <TitleBar>
        Access Control Panel
        <RequestButton onClick={() => undefined}>Contact Support</RequestButton>
      </TitleBar>
      <SettingsFullAccessUserPanel
        name={verifiedKYC ? 'Luciano Nonnis' : 'useremail@mail.com'}
        verified={verifiedKYC}
      />
      <Content>
        <ContentHeading>Shared access users</ContentHeading>
        <SettingsInviteUserButtonLaptop />
        <SettingsUserPanel
          verified={true}
          name="Jonathan Green"
          role="Transaction Manager"
        />
        <SettingsUserPanel
          verified={false}
          name="example@email.com"
        />
        <SettingsUserPanel
          verified={false}
          name="example@email.com"
        />
        <SettingsInviteUserButtonMobile />
      </Content>
    </Container>
  );
};

export default SettingsAccessControl;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: calc(100vw - 40px);
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: calc(100vw - 48px);
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 30px;
    padding: 30px 35px;
    width: 100%;
    min-height: 882px;
  }
`;

const SettingsInviteUserButtonLaptop = styled(SettingsInviteUserButton)`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
  }
`;

const SettingsInviteUserButtonMobile = styled(SettingsInviteUserButton)`
  display: flex;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

const TitleBar = styled.div`
  display: flex;
  box-sizing: border-box;
  ${FontStyles.h5};
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.asphaltTint};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: initial;
    gap: initial;
    border: none;
    background: linear-gradient(262deg, #e8e2db 8.5%, #d6dbf3 101.62%);
    color: ${(props) => props.theme.palette.primary.black};
    ${FontStyles.h4};
    padding: 33px 35px 33px 25px;
    border-radius: 20px;
    max-height: 100px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
`;

const RequestButton = styled.button`
  display: flex;
  box-sizing: border-box;
  transition: 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.black};
  padding: 8px 20px;
  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  ${FontStyles.bodySmallGilroy};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  max-width: 148px;
  white-space: nowrap;

  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.palette.primary.black};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: initial;
  }
`;

const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;

  & > :nth-last-child(2) {
    margin-bottom: 10px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    border-top: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
    flex-direction: row;
    gap: 20px;
    padding-top: 25px;

    & > :nth-last-child(2) {
      margin-bottom: initial;
    }
  }
`;

const ContentHeading = styled.p`
  display: flex;
  box-sizing: border-box;
  ${FontStyles.buttonGilroyType3};
  padding-bottom: 5px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;
