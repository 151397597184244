import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconOnboardingCheck } from '@constants/icons';

import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

import LargeButton from '@elements/LargeButton/LargeButton';
import TextInput, { Field } from '@elements/input/TextInput/TextInput';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const CustomIconOnboardingCheck = styled(IconOnboardingCheck)`
  width: 17px;
  height: 15px;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  gap: 30px;
  box-sizing: border-box;
  padding: 50px 20px;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
    width: 100%;
    padding: 0 90px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 25px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 20px 50px 0 95px;
  }
`;

export const Heading = styled.div`
  ${FontStyles.h3};
  text-align: center;
  display: flex;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: center;
    padding-top: 140px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: unset;
    ${FontStyles.h4};
    padding-top: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h3};
    padding-top: 125px;
  }
`;

export const SubHeading = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  text-align: center;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  width: 300px;
  margin-bottom: 10px;
  max-height: 41px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 45px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-bottom: 20px;
  }
`;

export const Mail = styled.div`
  color: ${(props) => props.theme.palette.primary.black};
  ${FontStyles.buttonGilroyType3};
`;

export const ErrorContainer = styled.div<{ $visible: boolean }>`
  ${FontStyles.bodySmallGilroy};
  height: 25px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  opacity: ${(props) => (props.$visible ? '1' : '0')};
  justify-content: end;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 30px;
    margin: 0 0 5px 0;
  }
`;

export const Label = styled.div`
  ${FontStyles.h5};
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h6};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h5};
  }
`;

export const CustomTextInput = styled(TextInput)`
  width: 100%;
  ${FontStyles.bodyMiddleGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    ${FontStyles.body2};
    ${Field} {
      ${FontStyles.body2};
    }
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    ${Field} {
      ${FontStyles.bodyMiddleGilroy};
    }
    height: 50px;
  }
`;

export const BlueButton = styled(LargeButton)`
  max-height: 50px;
  transition: background 300ms ease-in-out;
  min-width: 205px;
  width: 205px;
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 180px;
    margin-top: 20px;
    width: 168px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-height: 40px;
    order: unset;
    width: 182px;
    min-width: 165px;
    margin-top: 50px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-top: initial;
    max-height: 50px;
  }
`;

export const CustomOnboardingStepNavigation = styled(OnboardingStepNavigation)`
  display: none;
  margin-top: 65px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    align-self: start;
    margin-top: unset;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-left: 60px;
    margin-top: 15px;
  }
  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-left: 88px;
    margin-top: 20px;
  }
`;
