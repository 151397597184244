import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { Triangle } from '@constants/images';

interface Props {
  icon: ReactNode;
  text: string;
  to: string;
  onClick?: () => void;
  className: string;
}
const SettingsOption = ({ icon, text, to, onClick, className }: Props) => {
  return (
    <Container
      onClick={onClick}
      className={className}
      to={to}
      end
    >
      {icon}
      {text}
    </Container>
  );
};
export default SettingsOption;

const Container = styled(NavLink)`
  ${FontStyles.buttonGilroyType2};
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  white-space: nowrap;
  text-decoration: none;

  & > :first-child {
    display: none;
  }
  
  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }

  &.selected {
   color: ${(props) => props.theme.palette.primary.blue}
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 12px;
    justify-content: start;
    border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
    border-radius: 10px;
    color: ${(props) => props.theme.palette.primary.black};
    height: 64px;

    & > :first-child {
      display: initial;
    }

    &:hover {
      background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
    }

    &.selected {
      background: linear-gradient(249deg, #bfc8db 2.79%, #7a8599 97.66%);
      color: #fff;
      border: unset;
      position: relative;
      padding: 13px;
      
      &::after {
        content: '';
        position: absolute;
        right: -10px;
        top: 50%;
        width: 11px;
        height: 22px;
        background: url(${Triangle});
        transform: translateY(-50%);
      }
    }
    }
  }
`;
