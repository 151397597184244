import styled, { css } from 'styled-components';

import {
  OnboardingImageLoginDesktop,
  OnboardingImageLoginLaptop,
  OnboardingImageRegisterDesktop,
  OnboardingImageRegisterLaptop,
  OnboardingImageRestoreDesktop1,
  OnboardingImageRestoreDesktop2,
  OnboardingImageRestoreDesktop3,
  OnboardingImageRestoreLaptopMail,
  OnboardingImageRestoreLaptopOtp,
  OnboardingImageRestoreLaptopPw,
  OnboardingImageSMSDesktop,
  OnboardingImageSMSLaptop,
} from '@constants/images';

interface Props {
  type: 'register' | 'login' | 'restore' | 'sms' | 'email' | 'change';
  version?: string;
}

const OnboardingPictureArea = ({ type, version }: Props) => {
  return (
    <Image
      $image={type}
      version={version}
    />
  );
};

export default OnboardingPictureArea;

const Image = styled.div<{ $image: string; version?: string }>`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: initial;
    z-index: 0;
    position: relative;
    min-width: 599px;
    height: 644px;

    ${(props) =>
      (props.$image === 'register' || props.$image === 'change' || props.$image === 'email') &&
      css`
        background-image: url(${OnboardingImageRegisterLaptop[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRegisterLaptop[0]}) 1x,
          url(${OnboardingImageRegisterLaptop[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'login' &&
      css`
        background-image: url(${OnboardingImageLoginLaptop[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageLoginLaptop[0]}) 1x,
          url(${OnboardingImageLoginLaptop[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'restore' &&
      css`
        background-image: url(${OnboardingImageRestoreLaptopMail[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRestoreLaptopMail[0]}) 1x,
          url(${OnboardingImageRestoreLaptopMail[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'restore' &&
      props.version === '2' &&
      css`
        background-image: url(${OnboardingImageRestoreLaptopOtp[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRestoreLaptopOtp[0]}) 1x,
          url(${OnboardingImageRestoreLaptopOtp[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'restore' &&
      props.version === '3' &&
      css`
        background-image: url(${OnboardingImageRestoreLaptopPw[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRestoreLaptopPw[0]}) 1x,
          url(${OnboardingImageRestoreLaptopPw[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'sms' &&
      css`
        background-image: url(${OnboardingImageSMSLaptop[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageSMSLaptop[0]}) 1x,
          url(${OnboardingImageSMSLaptop[1]}) 2x
        );
      `}
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    min-width: 702px;
    height: 755px;

    ${(props) =>
      (props.$image === 'register' || props.$image === 'change' || props.$image === 'email') &&
      css`
        background-image: url(${OnboardingImageRegisterDesktop[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRegisterDesktop[0]}) 1x,
          url(${OnboardingImageRegisterDesktop[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'login' &&
      css`
        background-image: url(${OnboardingImageLoginDesktop[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageLoginDesktop[0]}) 1x,
          url(${OnboardingImageLoginDesktop[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'restore' &&
      css`
        background-image: url(${OnboardingImageRestoreDesktop1[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRestoreDesktop1[0]}) 1x,
          url(${OnboardingImageRestoreDesktop1[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'restore' &&
      props.version === '2' &&
      css`
        background-image: url(${OnboardingImageRestoreDesktop2[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRestoreDesktop2[0]}) 1x,
          url(${OnboardingImageRestoreDesktop2[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'restore' &&
      props.version === '3' &&
      css`
        background-image: url(${OnboardingImageRestoreDesktop3[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageRestoreDesktop3[0]}) 1x,
          url(${OnboardingImageRestoreDesktop3[1]}) 2x
        );
      `}

    ${(props) =>
      props.$image === 'sms' &&
      css`
        background-image: url(${OnboardingImageSMSDesktop[0]});
        background-image: -webkit-image-set(
          url(${OnboardingImageSMSDesktop[0]}) 1x,
          url(${OnboardingImageSMSDesktop[1]}) 2x
        );
      `}
  }
`;
