import { useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CorporateService } from '@services/corporate-service';

import { useLogin } from '@api/Access/accessApi';

import { useBoundStore } from '@stores/BoundStore';

import { FormValidation } from '@shared/formValidation';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import Toast from '@elements/Toast/Toast';

import {
  CustomButton,
  CustomCaptcha,
  CustomIconOnboardingCheck,
  CustomInput,
  EmailErrorMessage,
  FooterRow,
  ForgotPW,
  HeadingRow,
  InputRow,
  Label,
  LoginForm,
  NoAccountText,
  NoAccountTextContainer,
  OnboardingLogo,
  PasswordRow,
  RegisterLink,
  SubTitle,
  WeavrPassword,
} from './OnboardingLogin.styles';

const OnboardingLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isWeavrUiComponentLibraryLoaded = useBoundStore(
    (state) => state.isWeavrUiComponentLibraryLoaded
  );
  const isLoggedIn = useBoundStore((state) => state.isLoggedIn);

  const setIsLoggedIn = useBoundStore((state) => state.setIsLoggedIn);
  const setExternalId = useBoundStore((state) => state.setExternalId);

  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [loginFailed] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [passwordFieldFocused, setPasswordFieldFocused] = useState<boolean>(false);
  const [captchaValidationResult, setCaptchaValidationResult] = useState<CloudflareCaptcha>({
    captchaToken: null,
    idempotencyRef: null,
    isValid: false,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null); // Ref to our form component
  const secureFormRef = useRef<Form | null>(null); // Ref to window.OpcUxSecureClient.form()

  const { mutate: loginMutation } = useLogin(captchaValidationResult);

  const queryClient = useQueryClient();

  const errorHandling = useMemo(
    () => ({
      403: () => {
        toast.error(
          <Toast
            title="Authentication Failed"
            message={t('403')}
          />
        );
      },
      409: () => {
        toast.error(
          <Toast
            title="Password Error"
            message={t('passwordExpired')}
            link={`${process.env.REACT_APP_BASE_URL}/restore`}
          />
        );
      },
      422: (error: unknown) => {
        const { error: message } = error as { error: string };
        toast.error(
          <Toast
            title="Authentication Failed"
            message={message}
          />
        );
      },
    }),
    [t]
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errorHandling);

  useEffect(() => {
    if (isWeavrUiComponentLibraryLoaded && window.OpcUxSecureClient) {
      secureFormRef.current = window.OpcUxSecureClient.form();

      const passwordInput = secureFormRef.current.input('password', 'password', {
        placeholder: 'Password',
        maxlength: 30,
        style: {
          base: {
            fontSize: '16px',
            fontWeight: '500',
          },
        },
      });

      passwordInput.mount('#password');
      passwordInput.on('submit', () => {
        if (formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      });
      passwordInput.on('focus', () => {
        setPasswordFieldFocused(true);
      });
      passwordInput.on('blur', () => {
        setPasswordFieldFocused(false);
      });
    }
  }, [isWeavrUiComponentLibraryLoaded]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (submitting) return;
    if (!secureFormRef.current) return;

    setSubmitting(true);

    if (!captchaValidationResult.isValid) {
      setSubmitting(false);
      return;
    }

    if (!email) {
      setEmailValid(false);
      setSubmitting(false);
      return;
    }

    if (!emailValid) {
      setSubmitting(false);
      return;
    }

    secureFormRef.current.tokenize(
      (response: TokenizedResponse) => {
        if (!response || !response.password) {
          setSubmitting(false);
          return;
        }

        const { password } = response;

        loginMutation(
          { email, password },
          {
            onSuccess: async (loginData) => {
              if (loginData.token) {
                const { token } = loginData;
                try {
                  const data = await queryClient.fetchQuery({
                    queryKey: ['getCorporate'],
                    queryFn: async ({ signal }) => {
                      return await CorporateService.getCorporate(signal, token);
                    },
                  });

                  const { rootUser } = data;
                  const { mobileNumberVerified } = rootUser;

                  setExternalId(rootUser.id.id);

                  if (mobileNumberVerified) {
                    navigate('/otp/login', {
                      state: { ...(token ? { token } : {}) },
                    });
                  } else {
                    setIsLoggedIn(token);
                    navigate('/');
                  }
                } catch (error) {
                  handleHTTPErrors([error]);
                  setSubmitting(false);
                }
              }
            },
            onError: (error) => {
              handleHTTPErrors([error]);
              setSubmitting(false);
            },
          }
        );
      },
      (error) => {
        console.error(error);
        setSubmitting(false);
      }
    );
  };

  const handleRedirect = (destination: string, token?: string) => {
    navigate(destination, {
      state: { from: location.pathname, ...(token ? { token } : {}) },
    });
  };

  // If already logged in and user navigates to login redirect to dashboard
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  return (
    <LoginForm
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <OnboardingLogo gradientId="login" />
      <HeadingRow>
        Log in
        <SubTitle>Your trusted specialist in financial payments</SubTitle>
      </HeadingRow>
      <InputRow>
        <Label>
          Email
          <CustomInput
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            icon={FormValidation.RegEx.EMAIL.test(email) && email && <CustomIconOnboardingCheck />}
            valid={emailValid && !loginFailed}
            onBlur={() =>
              FormValidation.validateField(email, FormValidation.RegEx.EMAIL, setEmailValid)
            }
          />
          <EmailErrorMessage $visible={!emailValid}>
            Please enter a valid email address.
          </EmailErrorMessage>
        </Label>
        <Label>
          <PasswordRow>Password</PasswordRow>
          <WeavrPassword
            id="password"
            $focused={passwordFieldFocused}
          />
          <EmailErrorMessage $visible={loginFailed}>
            Invalid email or password. Please check your credentials and try again.
          </EmailErrorMessage>
        </Label>
        <ForgotPW
          type="button"
          onClick={() => handleRedirect('/restore')}
        >
          Forgot password?
        </ForgotPW>
        <CustomCaptcha onValidationSuccess={setCaptchaValidationResult} />
      </InputRow>
      <FooterRow>
        <NoAccountTextContainer>
          <NoAccountText>Don&apos;t have an account?</NoAccountText>
          <RegisterLink to="/register">Register</RegisterLink>
        </NoAccountTextContainer>
        <CustomButton
          disabled={!captchaValidationResult.isValid || loginFailed || submitting}
          type="submit"
          text="Log in"
          variant="blueWhite"
        />
      </FooterRow>
    </LoginForm>
  );
};
export default OnboardingLogin;
