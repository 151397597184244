import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Dropdown from '@elements/Dropdown';

import { ALLOWED_COUNTRIES } from '@mocks/Countries';

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  className?: string;
  soleTrader?: boolean;
  locked?: boolean;
}

const OnboardingCountryInput = ({ className, value, setValue, soleTrader }: Props) => {
  const countries = useMemo(
    () => (soleTrader ? ['Germany', 'Italy'] : ALLOWED_COUNTRIES),
    [soleTrader]
  );

  useEffect(() => {
    if (soleTrader) setValue('Germany');
  }, [setValue, soleTrader]);

  const options = useMemo(
    () =>
      countries.map((result) => ({
        title: result,
        value: result,
      })),
    [countries]
  );

  return (
    <Container className={className}>
      <Dropdown
        label=""
        options={options}
        onChange={(event) => {
          event.preventDefault();
          setValue(event.target.value as string);
        }}
        selected={value}
        placeholder="Choose Country"
        optionsHeader="Choose Country"
      />
    </Container>
  );
};

export default OnboardingCountryInput;

const Container = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;

  &:focus-within {
    & > :last-child {
      visibility: visible;
    }
  }
`;
