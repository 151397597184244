import styled from 'styled-components';

import QuickTransfer from '@components/QuickTransfer/QuickTransfer';

export const ContentContainer = styled.main`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: initial;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: unset;
    height: calc(100vh - 100px);
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    gap: 30px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    gap: 40px;
    width: 100%;
    max-width: 1840px;
    align-self: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 25px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 20px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    min-height: calc(100vh - 140px);
    margin-top: 5px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    max-width: calc(100% - 390px);
    gap: 30px;
    min-height: initial;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-width: calc(100% - 500px);
    gap: 40px;
  }
`;

export const Inner = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 20px;
  flex: 1;
  min-width: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 24px 24px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 40px 40px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    padding: unset;
  }
`;

export const QuickTransferTabletAndLaptop = styled(QuickTransfer)`
  display: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const QuickTransferMobileAndSmallDesktop = styled(QuickTransfer)`
  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    display: flex;
  }
`;
