import { useQuery } from '@tanstack/react-query';

import { TransactionsService } from '@services/transactions-service';

export const useOverviewData = (
  start: Date,
  filterType: OverviewFilterType,
  limit: number,
  managedAccountId: string
) => {
  return useQuery({
    queryKey: ['overviewData', start, filterType, limit, managedAccountId],
    queryFn: async () => {
      return await TransactionsService.getOverviewData(start, filterType, limit, managedAccountId);
    },
    enabled: !!managedAccountId,
  });
};
