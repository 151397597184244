import { friendlyFormatIBAN } from 'ibantools';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useDeleteIban, useMakeMainIban } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconDelete, Star, StarBlue } from '@constants/icons';

import Toast from '@elements/Toast/Toast';

const IbanList = () => {
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const { mutate: deleteIban, data } = useDeleteIban();
  const { mutate: makeMain, data: updatedData } = useMakeMainIban();

  const handleDelete = (iban: string) => {
    deleteIban(iban);
  };
  const starIban = (iban: string) => {
    makeMain(iban);
  };

  useEffect(() => {
    if (selectedContact && data?.data) {
      const newContact = { ...selectedContact };
      newContact.bankDetails = data.data;

      setSelectedContact(newContact);

      toast.success(
        <Toast
          title="Iban removed"
          message="Iban removed successfully."
        />
      );
    }
  }, [data, selectedContact, setSelectedContact]);

  useEffect(() => {
    if (selectedContact && updatedData?.data) {
      const newContact = { ...selectedContact };
      newContact.bankDetails = updatedData.data;

      setSelectedContact(newContact);
    }
  }, [selectedContact, setSelectedContact, updatedData]);

  return (
    <Container>
      <Label>Saved IBAN’s</Label>
      <List>
        {selectedContact?.bankDetails?.map((bank, i) => {
          return (
            bank.iban && (
              <ListItem key={i}>
                <span>{friendlyFormatIBAN(bank.iban)}</span>
                <Actions>
                  {bank.main ? <StarBlue /> : <Star onClick={() => starIban(bank.iban)} />}

                  <IconDelete onClick={() => handleDelete(bank.iban)} />
                </Actions>
              </ListItem>
            )
          );
        })}
      </List>
    </Container>
  );
};
export default IbanList;

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;
const List = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;
const Label = styled.div`
  ${FontStyles.bodyMiddleGilroy};
`;
const Actions = styled.div`
  display: flex;
  gap: 19px;
`;
const ListItem = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: ${(props) => props.theme.typography.primary.fontFamily};
`;
