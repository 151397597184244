import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-items: center;
  height: 47px;
  flex: 1;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;

  & > :first-child {
    &:hover {
      color: ${(props) => props.theme.palette.primary.black};
      cursor: pointer;
    }
    &:focus {
      color: ${(props) => props.theme.palette.primary.blue};
    }
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;
export const Bottom = styled.div`
  display: flex;
  gap: 5px;
`;

export const Text = styled.p`
  ${FontStyles.bodyMiddleGilroy};
`;

export const TextVariation = styled.p`
  ${FontStyles.buttonGilroyType3};
  background: linear-gradient(23.48deg, #3f3e62 6.8%, #7d7fad 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const Button = styled.button`
  color: ${(props) => props.theme.palette.negative.lessContrastRedLight};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.negative.contrastRed};
  }
`;
