import React from 'react';
import { useTheme } from 'styled-components';

import { NotFound } from '@constants/images';

import { useLockBodyScroll } from '@hooks/useLockBodyScroll';
import useMediaQuery from '@hooks/useMediaQuery';

import { Bottom, Button, Container, InnerRing, OuterRing, Top } from './ErrorPage.styles';

const ErrorPage = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);

  useLockBodyScroll(isTablet);

  return (
    <Container>
      <Top>
        <NotFound />
        <p className="large">Oops! Page not found.</p>
        You must&apos;ve picked the wrong door, this page doesn&apos;t exist
      </Top>
      <Bottom>
        <OuterRing>
          <InnerRing>
            <Button
              to="/"
              replace
            >
              Go to Overview
            </Button>
          </InnerRing>
        </OuterRing>
      </Bottom>
    </Container>
  );
};

export default ErrorPage;
