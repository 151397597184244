import { useMutation } from '@tanstack/react-query';

import { PasswordService } from '@services/password-service';

const useCreatePassword = () => {
  return useMutation({
    mutationFn: ({ userId, password }: WeavrPassword) => {
      return PasswordService.createPassword({ userId, password });
    },
  });
};

const useValidatePassword = () => {
  return useMutation({
    mutationFn: (password: string) => {
      return PasswordService.validatePassword(password);
    },
  });
};

const useUpdatePassword = () => {
  return useMutation({
    mutationFn: (credentials: WeavrUpdatePassword) => {
      return PasswordService.updatePassword(credentials);
    },
  });
};

const useInitiateLostPassword = ({
  captchaToken,
  idempotencyRef,
}: Omit<CloudflareCaptcha, 'isValid'>) => {
  return useMutation({
    mutationFn: (email: string) => {
      return PasswordService.initiateLostPassword(email, { captchaToken, idempotencyRef });
    },
  });
};

const useResumeLostPassword = () => {
  return useMutation({
    mutationFn: ({ nonce, email, value }: LostPasswordData) => {
      return PasswordService.resumeLostPassword({ nonce, email, value });
    },
  });
};

export {
  useCreatePassword,
  useValidatePassword,
  useUpdatePassword,
  useInitiateLostPassword,
  useResumeLostPassword,
};
