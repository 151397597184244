import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const Container = styled.div``;
export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Title = styled.div`
  ${FontStyles.bodySmallGilroy};
`;
export const CheckboxGroup = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  & svg {
    width: 18px;
    height: 18px;
  }
`;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const Label = styled.span`
  margin-left: 3px;
  cursor: pointer;
  ${FontStyles.primary};
`;
export const Reset = styled.span`
  cursor: pointer;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.primary.blue};
`;
