import React, { UIEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import useMediaQuery from '@hooks/useMediaQuery';

import ContactTransactionHistory from '@components/ContactTransactionHistory/ContactTransactionHistory';
import AddIbanSection from '@components/transaction/ContactInformation/AddIbanSection/AddIbanSection';

import BlueButton from '@elements/BlueButton/BlueButton';

import ContactHeader from './ContactHeader';

interface Props {
  className?: string;
}

const TransactionFormWrapper = ({ className }: Props) => {
  const { breakpoints } = useTheme();

  const navigate = useNavigate();

  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setScrolled = useBoundStore((state) => state.setScrolled);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const [currentIndex] = useState<number>(0);

  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);
  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    setScrolled(event.currentTarget.scrollTop > 1);
  };

  const handleConfirm = () => {
    // setSelectedContact(contact);
    if (!isLaptop) {
      navigate('/send-money/transaction-form');
    } else {
      navigate('/send-money');
    }
  };

  return (
    <Container
      onScroll={(event) => handleScroll(event)}
      className={className}
    >
      <TransactionFormContainer>
        <Wrapper>
          {selectedContact && <ContactHeader contact={selectedContact} />}
          <Navigation></Navigation>
        </Wrapper>
        <ContentContainer $currentIndex={currentIndex}>
          <AddIbanSection />
        </ContentContainer>

        <Footer>
          <BlueButton
            onClick={handleConfirm}
            size="large"
          >
            Initiate Payment
          </BlueButton>
        </Footer>
      </TransactionFormContainer>

      {selectedContact?.id && verifiedKYC && (
        <CustomContactTransactionHistory contactId={selectedContact.id} />
      )}
    </Container>
  );
};
export default TransactionFormWrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  box-sizing: border-box;
  flex: 1 0;
  margin-left: 20px;
  margin-right: 20px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    align-self: flex-start;
    max-width: 740px;
    max-height: calc(100vh - 140px);
    ${HideScrollbar};
    margin-left: unset;
    margin-right: unset;
    min-height: 664px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    max-width: 880px;
  }
`;

const TransactionFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: ${(props) => props.theme.palette.primary.white};
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 24px 20px 24px;
  padding-top: 24px;
  border-top: 1px solid ${(props) => props.theme.palette.greys.lightGrey};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.gradients.blueOrangeTint};
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
  padding: 20px 20px 9px 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 24px;
    height: 100px;
  }
`;

const ContentContainer = styled.div<{ $currentIndex: number }>`
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    position: relative;
  }
`;

const CustomContactTransactionHistory = styled(ContactTransactionHistory)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;
