import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useBoundStore } from '@stores/BoundStore';

import { getToken } from '@shared/functions';

import { Container } from './KYB.styles';

const KYB = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isWeavrUiComponentLibraryLoaded = useBoundStore(
    (state) => state.isWeavrUiComponentLibraryLoaded
  );

  useEffect(() => {
    const initKYB = (reference: string) => {
      if (isWeavrUiComponentLibraryLoaded && window.OpcUxSecureClient) {
        window.OpcUxSecureClient.associate(`Bearer ${getToken()}`, () => {
          window.OpcUxSecureClient.kyb().init('#kyb-container', { reference }, () => undefined, {
            lang: 'en',
            customCss: '',
          });
        });
      }
    };

    if (!location.state) {
      navigate('/');
    } else if (!location.state.kybRef) {
      navigate('/');
    } else {
      initKYB(location.state.kybRef);
    }
  }, [isWeavrUiComponentLibraryLoaded, location.state, navigate]);

  return <Container id="kyb-container" />;
};

export default KYB;
