import React from 'react';
import styled from 'styled-components';

import { useGetNotifications } from '@api/Notifications/notificationsApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconNoNotification, IconNotification } from '@constants/icons';

import IconButton from '@elements/IconButton/IconButton';

import {
  NotificationCount,
  NotificationIcon,
  NotificationIconWrapper,
} from './NotificationButton.styles';

const NotificationButton = () => {
  const setNotificationsOpen = useBoundStore((state) => state.setNotificationsOpen);
  const notificationsOpen = useBoundStore((state) => state.notificationsOpen);

  const { data: notificationData } = useGetNotifications();
  const notificationsCount = notificationData?.unreadCount;

  return (
    <NotificationIconWrapper>
      <NotificationIcon
        id="notificationCenterButton"
        onClick={() => setNotificationsOpen(!notificationsOpen)}
        icon={<IconNoNotification />}
        $visibleOnMobile={location.pathname === '/'}
        $newNotification={notificationsCount}
        className={notificationsOpen ? 'open' : ''}
      />
      {notificationsCount ? (
        <NotificationCount $visibleOnMobile={location.pathname === '/'}>
          {notificationsCount}
        </NotificationCount>
      ) : null}
    </NotificationIconWrapper>
  );
};

export default NotificationButton;
