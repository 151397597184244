import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { gradientTransitionWorkaround } from '@constants/styles';

import LargeButton from '@elements/LargeButton/LargeButton';

export const Container = styled.div<{
  $verified: VerifiedKYCType;
  $collapsed: boolean;
}>`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.white};
  flex-direction: column;
  border-radius: 10px;
  min-height: ${({ $verified }) => ($verified ? 339 : 373)}px;
  max-height: ${({ $verified }) => ($verified ? 339 : 373)}px;
  box-sizing: border-box;
  flex: 1 0;
  margin: 0 20px 20px;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: unset;
    min-height: ${({ $verified }) => ($verified ? '400px' : '600px')};
    height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 399px)'
        : !$verified
          ? 'calc(100vh - 447px)'
          : 'calc(100vh - 399px)'};
    max-height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 399px)'
        : !$verified
          ? 'calc(100vh - 447px)'
          : 'calc(100vh - 399px)'};
    flex: 1 0 calc(50% - 10px);
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    flex: 1 0 initial;
    border-radius: 20px;
    height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 435px)'
        : !$verified
          ? 'calc(100vh - 478px)'
          : 'calc(100vh - 435px)'};
    max-height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 435px)'
        : !$verified
          ? 'calc(100vh - 478px)'
          : 'calc(100vh - 435px)'};
    max-width: 360px;

    ${({ $collapsed, $verified }) =>
      $collapsed &&
      css`
        height: ${$verified
          ? 'calc(100vh - 245px)'
          : !$verified
            ? 'calc(100vh - 288px)'
            : 'calc(100vh - 245px)'};
        max-height: ${$verified
          ? 'calc(100vh - 245px)'
          : !$verified
            ? 'calc(100vh - 288px)'
            : 'calc(100vh - 245px)'};
      `}
  }

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    min-height: ${({ $verified }) => ($verified ? '700px' : '743px')};
    height: calc(100vh - 145px);
    max-height: calc(100vh - 45px);
    max-width: 320px;
    margin: 5px 40px 40px 0;

    ${({ $collapsed, $verified }) =>
      $collapsed &&
      css`
        min-height: ${$verified ? '700px' : '743px'};
        height: calc(100vh - 145px);
        max-height: calc(100vh - 45px);
      `}
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    min-height: ${({ $verified }) => ($verified ? '710px' : '753px')};
    max-width: 420px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    padding: 30px 35px 0;
    gap: 16px;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  & > :nth-child(2) {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    & > :nth-child(2) {
      display: flex;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 167px;
  flex-direction: column;
  flex: 1 0;
  padding: 15px 10px 0 20px;
  box-sizing: border-box;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    padding: 15px 15px 0 35px;
  }
`;

export const Content = styled.div<{ $verified: boolean | null }>`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  flex: 1 0;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  width: 100%;
  ${Scrollbar};

  justify-content: center;
  align-items: center;
  padding-left: unset;
  padding-right: unset;
  margin-right: unset;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    min-height: ${({ $verified }) => ($verified ? 'initial' : '380px')};
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0 20px 0 0;
  }
`;

export const Footer = styled.div<{ $verified: boolean | null; $contacts: number }>`
  display: none;
  justify-content: center;
  padding: 0 20px;
  margin-top: auto;
  box-sizing: border-box;

  ${({ $verified, $contacts }) =>
    $verified &&
    $contacts > 0 &&
    css`
      display: flex;
    `};
`;

export const LargeMobileButton = styled(NavLink)`
  ${FontStyles.buttonGilroyType3};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  border: 1px solid ${({ theme }) => theme.palette.greys.middleGrey};
  padding: 15px 39px;
  flex: 1 0;
  border-radius: 75px;
  max-height: 40px;
  margin: 15px 20px 20px;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

export const TopHeading = styled.h1`
  ${FontStyles.h5};

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    ${FontStyles.h4};
  }
`;

export const Fade = styled.div`
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 79.8%, #ffffff 100%);
  height: 40px;
  display: flex;
  width: 100%;
  z-index: 1;
  bottom: 100%;
`;

export const FooterWrapper = styled.div`
  position: relative;
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: block;
  }
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 50px;
  gap: ${({ theme }) => theme.spacing.s};
  color: ${({ theme }) => theme.palette.primary.white};
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  ${FontStyles.h5};
  ${gradientTransitionWorkaround};
`;

export const AddIconButton = styled(NavLink)`
  color: ${({ theme }) => theme.palette.primary.white};
  background: ${({ theme }) => theme.palette.greys.darkGrey};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.black};
  }
`;

export const VerifyButton = styled(LargeButton)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;
