import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import { useBoundStore } from '@stores/BoundStore';

import {
  ErrorContainer,
  Label,
  SubLabel,
} from '@views/Onboarding/OnboardingCompanyDetailsForm/OnboardingCompanyDetailsForm.styles';

import { InputRow, StyledTextField } from './TextFieldStyles';

interface TextFieldComponentProps {
  label?: string;
  labelTop?: ReactNode;
  subLabel?: ReactNode;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  placeholder?: string;
  errorText?: string;
  icon?: ReactNode;
  validate?: (value: string) => string;
  type?: 'outlined' | 'solid';
  error?: boolean | string;
}

const TextFieldComponent = ({
  label,
  labelTop,
  subLabel,
  value,
  onChange,
  placeholder,
  icon,
  validate,
  type = 'solid',
  error,
}: TextFieldComponentProps) => {
  const [touched, setTouched] = useState<boolean>(false);

  const resetForm = useBoundStore((store) => store.resetForm);

  const handleBlur = () => {
    setTouched(true);
  };

  useEffect(() => {
    validate?.(value);
  }, [validate, value]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event);
  };

  useEffect(() => {
    if (resetForm) {
      setTouched(false);
      return;
    }
    if (touched && value === '') {
      const timer = setTimeout(() => {
        setTouched(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [value, touched, resetForm]);

  return (
    <InputRow>
      <Label>
        {labelTop} {subLabel && <SubLabel>{subLabel}</SubLabel>}
      </Label>
      <StyledTextField
        label={label}
        error={touched && Boolean(error)}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleOnChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        autoComplete="off"
        InputProps={{
          endAdornment: icon,
        }}
        type={type}
      />
      <ErrorContainer $visible={touched && Boolean(error)}>{error}</ErrorContainer>
    </InputRow>
  );
};

export default TextFieldComponent;
