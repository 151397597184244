import { useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';

import { useLogout } from '@api/Access/accessApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconInfo } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import InactivityModal from '@components/InactivityModal/InactivityModal';

import LargeButton from '@elements/LargeButton/LargeButton';

const InactivityTimer = () => {
  const theme = useTheme();

  const openModal = useBoundStore((state) => state.openModal);
  const inactivityTime = useBoundStore((state) => state.inactivityTime);
  const setInactivityTime = useBoundStore((state) => state.setInactivityTime);
  const loggedIn = useBoundStore((state) => state.isLoggedIn);
  const setIsLoggedIn = useBoundStore((state) => state.setIsLoggedIn);
  const isTablet = useMediaQuery(`(${theme.breakpoints.tablet})`);
  const { mutate: logout } = useLogout();
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (!intervalRef.current && loggedIn) {
      intervalRef.current = window.setInterval(() => {
        const lastActiveTimestamp = localStorage.getItem('lastActiveTime');
        const currentTime = Date.now();
        const timeElapsed = lastActiveTimestamp
          ? (currentTime - parseInt(lastActiveTimestamp)) / 1000
          : 0;
        const remainingTime = Math.max(300 - timeElapsed, 0);
        setInactivityTime(() => {
          if (inactivityTime <= 0) {
            if (intervalRef.current) window.clearInterval(intervalRef.current);
            intervalRef.current = null;
            logout();
            setIsLoggedIn(null);
            window.location.href = '/login';
            return 0;
          }
          if (inactivityTime === 60 && isTablet) {
            openModal(<InactivityModal />, true);
          }
          return Math.floor(remainingTime);
        });
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [loggedIn, isTablet, openModal, setInactivityTime, setIsLoggedIn, logout, inactivityTime]);

  function debounce(func: () => void) {
    let timeout = true;

    return () => {
      if (timeout) {
        func();
        timeout = false;
        setTimeout(() => (timeout = true), 500);
      }
    };
  }

  useEffect(() => {
    const resetTimer = () => {
      const now = Date.now();
      localStorage.setItem('lastActiveTime', now.toString());
    };

    const debouncedResetTimer = debounce(resetTimer);

    window.addEventListener('mousemove', debouncedResetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('touchstart', resetTimer);

    return () => {
      window.removeEventListener('mousemove', debouncedResetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
    };
  }, []);

  return (
    <>
      {loggedIn && (
        <Container $warn={inactivityTime <= 60}>
          <Top>
            <CustomInfoIcon />
            <H5 $warn={inactivityTime <= 60}>Inactivity Timer</H5>
            <TimerBox $warn={inactivityTime <= 60}>
              {`${Math.floor(inactivityTime / 60)
                .toString()
                .padStart(2, '0')}:${(inactivityTime % 60).toString().padStart(2, '0')}`}
            </TimerBox>
          </Top>
          <Bottom>
            <Divider />
            <InfoText>
              This timer helps ensure your security by checking for activity. If you&apos;re
              inactive for 5 minutes, we&apos;ll ask for a quick re-authentication to keep your
              account safe.
            </InfoText>
            <CustomButton
              onClick={() => undefined}
              variant="darkWhite"
              text="I am here!"
            />
          </Bottom>
        </Container>
      )}
    </>
  );
};

export default InactivityTimer;

const CustomInfoIcon = styled(IconInfo)`
  cursor: pointer;
  transition: 300ms ease-in-out;
  align-self: center;
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.palette.negative.lessContrastRed};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: initial;
    color: ${(props) => props.theme.palette.greys.middleGrey};
  }
`;

const H5 = styled.div<{ $warn: boolean }>`
  ${FontStyles.h5};
  width: 123px;
  align-self: center;
  color: ${(props) => props.theme.palette.negative.lessContrastRed};
  transition: 300ms ease-in-out;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: initial;
    width: 75px;
    color: ${(props) =>
      props.$warn ? props.theme.palette.primary.black : props.theme.palette.greys.asphaltTint};
  }
`;

const TimerBox = styled.div<{ $warn: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 40px;
  border-radius: 20px;
  color: white;
  margin-left: auto;
  background: ${(props) =>
    props.$warn
      ? props.theme.palette.negative.lessContrastRed
      : props.theme.palette.greys.darkGrey};
  ${FontStyles.buttonGilroyType2};
  transition: 300ms ease-in-out;
`;

const Top = styled.div`
  display: flex;
  gap: 5px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.palette.greys.middleGrey};
  margin: 10px 0 5px 0;
`;

const InfoText = styled.div`
  ${FontStyles.body2};
  color: ${(props) => props.theme.palette.primary.black};
`;

const CustomButton = styled(LargeButton)`
  height: 40px;
  width: 167px;
  margin-top: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const Container = styled.div<{ $warn: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: ${(props) => (props.$warn ? 'flex' : 'none')};
  padding: 20px;
  width: 100%;
  z-index: 30;
  border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 0 20px 0 0;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 244px;
    left: 0;
    display: flex;
    padding: 15px;
  }

  &:hover {
    ${Bottom} {
      display: flex;
    }
    ${CustomInfoIcon} {
      color: ${(props) => props.theme.palette.greys.darkGrey};
    }

    ${H5} {
      color: ${(props) => props.theme.palette.greys.darkestGrey};
    }

    ${TimerBox} {
      background: ${(props) =>
        props.$warn
          ? props.theme.palette.negative.lessContrastRed
          : props.theme.palette.greys.darkestGrey};
    }
  }
`;
