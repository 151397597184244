import React from 'react';

import { Container, FilterHeader, Reset } from '@components/Filters/CheckboxFilter.styles';
import { RadioGroup, Title } from '@components/Filters/RadioFilter.styles';
import RadioButtonGroup from '@components/RadioButtonGroup/RadioButtonGroup';

interface IRadioFilterProps {
  title: string;
  selectedFilter?: string;
  filters: RadioOptions[];
  selectedTxn: ExportTransaction[];
  onChange: (e: string | undefined) => void;
  onSelectedTxn: (value: ExportTransaction[]) => void;
}

const RadioFilter: React.FC<IRadioFilterProps> = ({
  onChange,
  title,
  filters,
  selectedFilter,
  selectedTxn,
  onSelectedTxn,
}) => {
  const handleReset = () => {
    onChange(undefined);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedTxn?.length) {
      onSelectedTxn([]);
    }
    onChange(e.target.value);
  };

  return (
    <Container>
      <FilterHeader>
        <Title>{title}</Title>
        <Reset onClick={handleReset}>Reset</Reset>
      </FilterHeader>
      <RadioGroup>
        <RadioButtonGroup
          column={true}
          options={filters}
          selected={selectedFilter}
          onChange={handleChange}
        />
      </RadioGroup>
    </Container>
  );
};

export default RadioFilter;
