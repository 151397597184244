import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconInfo } from '@constants/icons';

interface Props {
  text: string;
  position?: 'left' | 'center' | 'right';
  tabIndex?: number;
  label?: string;
}
const InfoBubble = ({ text, position, tabIndex, label }: Props) => {
  return (
    <Container tabIndex={tabIndex}>
      <IconInfo />
      <Span>{label}</Span>
      <Bubble
        id="bubble"
        position={position}
      >
        {text}
      </Bubble>
    </Container>
  );
};

export default InfoBubble;

const Container = styled.div`
  display: flex;
  position: relative;
  transition: 300ms ease-in-out;
  align-items: flex-end;
  color: ${(props) => props.theme.palette.greys.darkGrey};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};
    cursor: help;
    outline: none;

    & #bubble {
      visibility: visible;
      opacity: 1;
      color: ${(props) => props.theme.palette.primary.black};
    }
  }
`;

const Span = styled.span`
  ${FontStyles.h6};
  padding-left: 8px;
`;

const Bubble = styled.div<{ position?: 'left' | 'center' | 'right' }>`
  display: flex;
  position: absolute;
  background: ${(props) => props.theme.palette.primary.white};
  border-radius: 20px;
  justify-content: center;
  align-items: end;
  white-space: nowrap;
  z-index: 1;
  padding: 10px 15px;
  ${FontStyles.h6};
  filter: drop-shadow(0px 4px 11px rgba(16, 153, 253, 0.07));
  min-width: 131px;
  visibility: hidden;
  opacity: 0;
  transition: ease-in-out 300ms;
  left: 50%;
  transform: translateX(-50%) translateY(-30px);

  ${(props) =>
    props.position === 'right' &&
    css`
      transform: translateX(-85%) translateY(-30px);
    `}

  ${(props) =>
    props.position === 'left' &&
    css`
      transform: translateX(35%) translateY(-30px);
    `}

  &:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;

    ${(props) =>
      props.position === 'right' &&
      css`
        left: 85%;
      `}

    ${(props) =>
      props.position === 'left' &&
      css`
        left: 15%;
      `}
  }
`;
