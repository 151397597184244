import React, { ChangeEvent, FocusEvent, useCallback } from 'react';
import styled from 'styled-components';

import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { useGetContacts } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconRemoveContact } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';
import { recipientValidate } from '@hooks/useValidation';

import Avatar from '@components/Avatar/Avatar';
import DeleteContactConfirmation from '@components/DeleteContactConfirmation';
import { TransactionFormRemoveContact } from '@components/transaction/TransactionForm/TransactionFormStyles';

import TextFieldComponent from '@elements/input/TextField/TextFieldComponent';

interface Props {
  recipient: ContactsWithBankDetails;
  setRecipient: (contact: ContactsWithBankDetails) => void;
  className?: string;
}

const RecipientInput = ({ setRecipient, className, recipient }: Props) => {
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);
  const openModal = useBoundStore((state) => state.openModal);
  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';
  const isTablet = useMediaQuery('(min-width:768px)');
  const { data: contactData } = useGetContacts(managedAccountId);
  const validation = useBoundStore((state) => state.validation);
  const setValidation = useBoundStore((state) => state.setValidation);
  const error = validation.name;
  const { contacts } = contactData ?? { contacts: [], recentContacts: [] };

  let results: ContactsWithBankDetails[] = [];
  if (contacts) {
    results = contacts.filter((contact: ContactsWithBankDetails) =>
      contact.name.toLowerCase().startsWith(recipient.name.toLowerCase())
    );
  }

  const capitalizeWords = (value: string) => {
    return value
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (results.length > 0) document.getElementById('dropdown')?.classList.remove('hide'); //TODO replace with styled component prop
    setRecipient({
      ...recipient,
      name: capitalizeWords(event.target.value),
    });
  };

  const handleValidate = useCallback(
    (value: string) => {
      const validtext = recipientValidate(value);
      setValidation((prevState) => ({ ...prevState, name: validtext }));
      return validtext;
    },
    [setValidation]
  );

  return (
    <Container
      className={recipient.name ? 'show' : className}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          document.getElementById('dropdown')?.classList.add('hide'); //TODO replace with styled component prop
        }
      }}
    >
      <TextFieldComponent
        labelTop="Recipient"
        subLabel={
          selectedContact && (
            <TransactionFormRemoveContact
              onClick={() => {
                if (selectedContact) {
                  openModal(<DeleteContactConfirmation selectedContact={selectedContact} />);
                }
              }}
            >
              <IconRemoveContact />
              {isTablet ? <u>Remove contact from list</u> : 'Remove contact'}
            </TransactionFormRemoveContact>
          )
        }
        placeholder="Type Recipient name here"
        value={capitalizeWords(recipient.name)}
        validate={handleValidate}
        onChange={handleChange}
        error={error}
      />
      {error && (
        <TagAutocomplete
          id="dropdown"
          className={results.length < 1 || selectedContact?.id !== '' ? 'hide' : ''}
        >
          <ContentWrapper>
            <Heading>Proposed recipients</Heading>
            <Content>
              {results.map((result: ContactsWithBankDetails, index: number) => {
                return (
                  <RecipientWrapper
                    key={index}
                    onClick={() => {
                      setRecipient(result);
                      setSelectedContact(result);
                      document.getElementById('dropdown')?.classList.add('hide'); //TODO replace with styled component prop
                    }}
                  >
                    <Avatar src="" />
                    <RecipientInner>
                      <Name>{result.name}</Name>
                      <Tag>{result.tag}</Tag>
                    </RecipientInner>
                  </RecipientWrapper>
                );
              })}
            </Content>
          </ContentWrapper>
        </TagAutocomplete>
      )}
    </Container>
  );
};

export default RecipientInput;

const Container = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;

  &.show {
    & > :last-child {
      display: flex;
    }
  }
  // hide list when no match found
  & > :last-child.hide {
    display: none;
  }
`;

const TagAutocomplete = styled.div`
  display: block;
  position: absolute;
  z-index: 1;
  background-color: ${(props) => props.theme.palette.primary.white};
  flex-direction: column;
  padding: 15px 13px 15px 15px;
  gap: 10px;
  box-shadow: 2px 7px 77px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-sizing: border-box;
  top: 82px;
  flex: 1;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  max-height: 265px;
  box-sizing: border-box;
  flex-direction: column;
`;

const Content = styled.div`
  ${Scrollbar};
  flex: 1;
  display: flex;
  box-sizing: border-box;
  overflow: hidden auto;
  flex-direction: column;
`;

const Heading = styled.p`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin-bottom: 7px;
`;

const RecipientWrapper = styled.button`
  ${FontStyles.buttonGilroyType3};
  display: flex;
  margin-bottom: 10px;
  gap: 12px;

  &:hover {
    cursor: pointer;
    & > :last-child {
      & > :first-child {
        color: ${(props) => props.theme.palette.primary.blue};
      }
      & > :last-child {
        color: ${(props) => props.theme.palette.primary.black};
      }
    }
  }

  &:last-child {
    margin-bottom: initial;
  }
`;

const RecipientInner = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const Name = styled.p`
  transition: 300ms ease-in-out;
`;

const Tag = styled.p`
  transition: 300ms ease-in-out;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;
