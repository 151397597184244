import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export type TabItem = {
  name: string;
  component: ReactNode;
};

interface TabPanelProps {
  children: ReactNode;
  index: string;
  value: string;
}

interface TabProps {
  tabs: TabItem[];
  activeTab?: number;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  orientation?: 'horizontal' | 'vertical';
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
}

export default function TabComponent({
  tabs,
  variant = 'standard',
  orientation = 'horizontal',
  activeTab = 0,
}: TabProps) {
  const [value, setValue] = React.useState(activeTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabsStyled
        orientation={orientation}
        variant={variant}
        value={value}
        onChange={handleChange}
        aria-label="tabs example"
        sx={{
          borderRight: orientation === 'vertical' ? 1 : 0,
          borderBottom: orientation === 'horizontal' ? 1 : 0,
          borderColor: 'divider',
        }}
      >
        {tabs.map((tab: TabItem, index) => (
          <TabItem
            key={tab.name}
            label={tab.name}
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
          />
        ))}
      </TabsStyled>
      {tabs.map((tab: TabItem, index) => (
        <TabPanel
          key={tab.name}
          value={value.toString()}
          index={index.toString()}
        >
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
}

export const TabItem = styled(Tab)`
  padding: 9px 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  min-height: 42px !important;
`;
export const TabsStyled = styled(Tabs)`
  min-height: 42px !important;
  border-bottom: none !important;
  border-right: none !important;
`;
