import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { IconArrowReceive, IconArrowSend } from '@constants/icons';

import AddMoney from '@components/AddMoney/AddMoney';
import AccountInfoBubble from '@components/account/AccountInfoBubble/AccountInfoBubble';

import IconButton from '@elements/IconButton/IconButton';

interface Props {
  collapsed: boolean;
  totalBalance: number;
}

const INFO_TEXT =
  'Your available balance reflects the total amount after deducting fees for each transaction. To view the specific fees paid, please refer to the transaction details in your transaction list.';

const OverviewBox = ({ collapsed, totalBalance }: Props) => {
  const { t } = useTranslation();

  const openModal = useBoundStore((state) => state.openModal);

  return (
    <Container $collapsed={collapsed}>
      <TitleRow $collapsed={collapsed}>
        <AccountInfoBubble text={INFO_TEXT} />
        <div>Available Balance</div>
        {/* !collapsed && <AccountDetailsMenu /> */}
      </TitleRow>
      <BalanceRow $collapsed={collapsed}>
        <BalanceText $collapsed={collapsed}>{Commons.currencyFormatter(totalBalance)}</BalanceText>
      </BalanceRow>
      {/* collapsed && <AccountDetailsMenu /> */}
      <ButtonRow>
        {collapsed ? (
          <>
            <AddMoneyIconButton
              onClick={() => {
                openModal(<AddMoney />);
              }}
              icon={<IconArrowReceive />}
            />
            <SendMoneyIconButton to="/send-money">
              <IconArrowSend />
            </SendMoneyIconButton>
          </>
        ) : (
          <>
            <AddMoneyBorderButton
              onClick={() => {
                openModal(<AddMoney />);
              }}
            >
              {t('add_money')}
            </AddMoneyBorderButton>
            <ButtonSendMoney to="/send-money">{t('send_money')}</ButtonSendMoney>
          </>
        )}
      </ButtonRow>
    </Container>
  );
};

export default OverviewBox;

const Container = styled.div<{ $collapsed: boolean }>`
  display: flex;
  justify-content: space-between;
  min-width: 265px;

  ${(props) =>
    props.$collapsed
      ? css`
          align-items: center;
          max-width: 424px;
          flex: 1;
        `
      : css`
          flex-direction: column;
          padding: 23.5px 0;
          box-sizing: border-box;
        `}
`;

const TitleRow = styled.div<{ $collapsed: boolean }>`
  ${(props) =>
    props.$collapsed
      ? css`
          display: flex;
          flex-direction: column;
          gap: 5px;
          ${FontStyles.buttonGilroyType3};
        `
      : css`
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          ${FontStyles.bodyLargeGilroy};
        `}
`;

const BalanceRow = styled.div<{ $collapsed: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.$collapsed
      ? css`
          gap: 5px;
          margin-left: 13px;
        `
      : css`
          justify-content: end;
          box-sizing: border-box;
        `}
`;

const BalanceText = styled.div<{ $collapsed: boolean }>`
  ${(props) => (props.$collapsed ? FontStyles.buttonsNeuePlakType1 : FontStyles.h3)}
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
`;

const AddMoneyIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.palette.primary.black};

  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
  }
`;

const AddMoneyBorderButton = styled.button`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  flex: 1 0;
  padding: 10px 20px;
  border-radius: 75px;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.black};
  background: transparent;
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  transition: 300ms ease-in-out;
  white-space: nowrap;
  height: 40px;

  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
    cursor: pointer;
  }
`;

const ButtonSendMoney = styled(NavLink)`
  height: 40px;
  color: ${(props) => props.theme.palette.primary.white};
  white-space: nowrap;
  box-sizing: border-box;
  flex: 1 0;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #232222, #34354a, #74c4ff, #1099fd);
  background-size: 500% auto;
  transition: ease-in-out 300ms;
  display: flex;
  text-decoration: none;
  border-radius: 75px;
  ${FontStyles.buttonGilroyType3};
  padding: 11px 21px;

  &:hover {
    background-position: 90% 120%;
  }

  // @media (${(props) => props.theme.breakpoints.laptop}) {
  //   display: flex;
  // }
`;

const SendMoneyIconButton = styled(NavLink)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: linear-gradient(270deg, #232222 0%, #34354a 98.33%);
  color: ${(props) => props.theme.palette.primary.white};

  &:hover {
    background: ${(props) => props.theme.palette.gradients.primaryBlueGradient};
  }
`;
