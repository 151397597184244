import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconRectangleBlue } from '@constants/images';

interface Props {
  text: string;
  active: boolean;
  onClick: () => void;
}
const PointyButton = ({ text, active, onClick }: Props) => {
  return (
    <Container
      className={active ? 'active' : ''}
      onClick={onClick}
    >
      {text}
    </Container>
  );
};

export default PointyButton;

const Container = styled.button`
  display: flex;
  border-radius: 10px;
  box-sizing: border-box;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.greys.asphaltTint};
  ${FontStyles.buttonGilroyType3};
  width: 100%;
  padding: 15px 30px;
  text-align: center;

  &.active {
    background-color: ${(props) => props.theme.palette.primary.blue};
    color: ${(props) => props.theme.palette.primary.white};

    &:after {
      content: '';
      position: absolute;
      top: 49px;
      width: 22px;
      height: 11px;
      background: url(${IconRectangleBlue}) no-repeat;
      transform: rotateX(180deg);
    }
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.buttonGilroyType2};
    width: 240px;
  }
`;
