import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';

import useMediaQuery from '@hooks/useMediaQuery';

import Avatar from '@components/Avatar/Avatar';

interface Props {
  contact: ContactsWithBankDetails;
  className?: string;
}

const Contact = ({ contact, className }: Props) => {
  const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const {
    name,
    // tag
  } = contact;

  const handleClick = () => {
    setSelectedContact(contact);
    if (!isLaptop) {
      navigate('/send-money/transaction-form');
    } else {
      navigate('/send-money');
    }
  };

  return (
    <Container
      className={className}
      onClick={handleClick}
    >
      <Avatar
        src=""
        alt={`Avatar ${name}`}
        text={name ?? 'A'}
      />
      <List>
        <Name>{name}</Name>
        {/*<Tag>{!tag || tag === '' ? 'No hashtag assigned' : tag}</Tag>*/}
      </List>
    </Container>
  );
};

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;

  &.dontCollapse {
    justify-content: start;
    background: ${(props) => props.theme.palette.greys.lightLightGrey};
    border: 1px solid ${(props) => props.theme.palette.negative.lessContrastRed};
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
      & p {
        color: ${(props) => props.theme.palette.primary.black};

        &:nth-child(2) {
          color: ${(props) => props.theme.palette.greys.darkestGrey};
        }
      }
    }
    & > :first-child {
      height: 40px;
      width: 40px;
    }
  }
  cursor: pointer;

  & > :first-child {
    height: 32px;
    width: 32px;
  }

  &:hover p {
    color: ${(props) => props.theme.palette.primary.blue};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    justify-content: start;
    background-color: ${(props) => props.theme.palette.primary.white};
    border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
      & p {
        color: ${(props) => props.theme.palette.primary.black};

        &:nth-child(2) {
          color: ${(props) => props.theme.palette.greys.darkestGrey};
        }
      }
    }

    & > :first-child {
      height: 40px;
      width: 40px;
    }
  }
`;

const Name = styled.p`
  ${FontStyles.buttonGilroyType3};
  transition: color 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.black};
`;

// const Tag = styled.p`
//   ${FontStyles.bodySmallGilroy};
//   transition: color 300ms ease-in-out;
//   color: ${(props) => props.theme.palette.greys.darkestGrey};
// `;

const List = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;

  &.dontCollapse {
    //align-items: start;
    justify-content: start;
    //flex-direction: column;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    //align-items: start;
    justify-content: start;
    //flex-direction: column;
  }
`;

export default Contact;
