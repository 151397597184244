import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useBoundStore } from '@stores/BoundStore';

import { Container } from './KYC.styles';

const KYC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isWeavrUiComponentLibraryLoaded = useBoundStore(
    (state) => state.isWeavrUiComponentLibraryLoaded
  );

  useEffect(() => {
    const initKYC = (reference: string) => {
      if (isWeavrUiComponentLibraryLoaded && window.OpcUxSecureClient) {
        window.OpcUxSecureClient.kyc().init(
          '#director-kyc-container',
          { reference },
          () => undefined,
          {
            lang: 'en',
            customCss: '',
          }
        );
      }
    };

    const reference = searchParams.get('reference');

    if (!reference) {
      navigate('/');
    } else {
      initKYC(reference);
    }
  }, [isWeavrUiComponentLibraryLoaded, navigate, searchParams]);

  return <Container id="director-kyc-container" />;
};

export default KYC;
