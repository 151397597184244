import { useMutation, useQuery } from '@tanstack/react-query';

import { AccessService } from '@services/access-service';

const useLogin = (captchaData: Omit<CloudflareCaptcha, 'isValid'>) => {
  return useMutation({
    mutationFn: (credentials: LoginCredentials) => {
      return AccessService.login(credentials, captchaData);
    },
  });
};

const useLogout = () => {
  return useMutation({
    mutationFn: () => {
      return AccessService.logout();
    },
  });
};

const useAcquireNewAccessToken = (identity: string) => {
  return useMutation({
    mutationFn: () => {
      return AccessService.acquireNewAccessToken(identity);
    },
  });
};

const useGetUserSessionLogs = (userId: string) => {
  return useQuery({
    queryKey: ['userSessionLogs'],
    queryFn: async () => {
      return await AccessService.getUserSessionLogs(userId);
    },
    enabled: !!userId,
  });
};

export { useLogin, useLogout, useAcquireNewAccessToken, useGetUserSessionLogs };
