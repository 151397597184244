import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { NoScheduledPaymentsMobileSet, NoScheduledPaymentsTabletSet } from '@constants/images';

export const Container = styled.div<{
  $verify: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  gap: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  background-image: url(${NoScheduledPaymentsMobileSet[0]});
  background-image: -webkit-image-set(
    url(${NoScheduledPaymentsMobileSet[0]}) 1x,
    url(${NoScheduledPaymentsMobileSet[1]}) 2x
  );
  height: 100%;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    background-image: url(${NoScheduledPaymentsTabletSet[0]});
    background-image: -webkit-image-set(
      url(${NoScheduledPaymentsTabletSet[0]}) 1x,
      url(${NoScheduledPaymentsTabletSet[1]}) 2x
    );
    height: 100%;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    background-image: url(${NoScheduledPaymentsMobileSet[0]});
    background-image: -webkit-image-set(
      url(${NoScheduledPaymentsMobileSet[0]}) 1x,
      url(${NoScheduledPaymentsMobileSet[1]}) 2x
    );
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 10px;
    & > :not(:nth-child(-n + 4)) {
      display: flex;
    }
  }
`;

export const Text = styled.h5<{
  size: 'mobile' | 'tablet';
  $verify?: boolean;
}>`
  text-align: center;
  ${FontStyles.h5};
  color: ${(props) => props.theme.palette.primary.black};
  width: 230px;
  margin-top: 25px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: -110px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-top: 35px;
  }
`;

export const NoTransactionsText = styled.div`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.black};
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

export const TransactionContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 7.5px);
  min-height: 70px;
  max-height: 70px;
  padding: 15px;
  border-radius: 10px;
  background: ${(props) => props.theme.palette.greys.lightLightGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: calc(100% - 15px);
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    width: 442px;
  }
`;

export const Avatar = styled.div`
  background: ${(props) => props.theme.palette.primary.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 13px;
`;

export const TransactionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  min-height: 40px;
  max-height: 40px;
`;

export const TextBlock = styled.div<{ $width: number; $height: number }>`
  background: ${(props) => props.theme.palette.primary.white};
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
`;
