import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { IconArrowLeft, IconRotatedPlus } from '@constants/icons';

import { useLockBodyScroll } from '@hooks/useLockBodyScroll';

interface Props {
  children: ReactNode;
  backdropBackground?: ModalBackdropBackgroundType;
  onClose?: () => void;
  className?: string;
}

const Modal = ({ children, backdropBackground = 'dark', onClose, className }: Props) => {
  const closeModal = useBoundStore((state) => state.closeModal);

  useLockBodyScroll(true);

  const onContentClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleClick = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  };

  return (
    <Overlay
      $backdropBackground={backdropBackground}
      onClick={closeModal}
    >
      <OverlayClickWrapper onClick={(e) => onContentClick(e)}>
        <ModalContainer className={className}>
          <CloseButtonLarge onClick={handleClick}>
            <IconRotatedPlus />
          </CloseButtonLarge>
          <CloseButtonSmall onClick={handleClick}>
            <IconArrowLeft />
          </CloseButtonSmall>
          <ModalContentContainer>{children}</ModalContentContainer>
        </ModalContainer>
      </OverlayClickWrapper>
    </Overlay>
  );
};

const Overlay = styled.div<{
  $backdropBackground: ModalBackdropBackgroundType;
}>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: ${(props) =>
    props.$backdropBackground === 'light' ? css`blur(10.5px)` : css`blur(9px)`};
  -webkit-backdrop-filter: ${(props) =>
    props.$backdropBackground === 'light' ? css`blur(10.5px)` : css`blur(9px)`};
  background: ${(props) =>
    props.$backdropBackground === 'light'
      ? css`linear-gradient(249.15deg, rgba(255, 255, 255, 0.92) 2.79%, rgba(255, 255, 255, 0.92) 97.66%)`
      : props.theme.palette.gradients.greySilverGradient.alpha};
  z-index: 1000;
  justify-content: center;
  animation: ${(props) => props.theme.animations.fadeIn} 0.8s;
`;

const OverlayClickWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: initial;
    height: initial;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: ${(props) => props.theme.palette.primary.white};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
    border-radius: 20px;
    padding: 0 60px;
    width: 530px;
  }
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden auto;
  ${HideScrollbar};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    overflow: unset;
    padding-bottom: 50px;
  }
`;

const CloseButtonSmall = styled.button`
  display: flex;
  min-height: 50px;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const CloseButtonLarge = styled.button`
  display: none;
  position: absolute;
  top: -35px;
  left: calc(50% - 35px);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  color: ${({ theme }) => theme.palette.primary.white};
  background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};

  &:hover {
    cursor: pointer;
  }
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export default Modal;
