import { format } from 'date-fns';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { DefaultTheme, css, useTheme } from 'styled-components';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

import useMediaQuery from '@hooks/useMediaQuery';

interface Props {
  name: string;
  type: string;
  direction: string;
  icon: ReactElement;
  amount: number;
  timestamp: string;
  currency: Currency;
  onClick: () => void;
  status: string;
}

const getBackgroundColor = (direction: TransactionDirection, theme: DefaultTheme) => {
  return {
    in: theme.palette.positive.lessContrastGreenLighter,
    out: theme.palette.negative.lessContrastRedLighter,
  }[direction];
};

const getHoverBackgroundColor = (direction: TransactionDirection, theme: DefaultTheme) => {
  return {
    in: theme.palette.positive.lessContrastGreenLight,
    out: theme.palette.negative.lessContrastRedLight,
  }[direction];
};

const TransactionEntry = ({
  name,
  type,
  direction,
  icon,
  amount,
  timestamp,
  currency = 'EUR',
  onClick,
  status,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);
  const isDesktop = useMediaQuery(`(${theme.breakpoints.desktop})`);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy h:mm a');
  };

  return (
    <Wrapper
      $status={status.toUpperCase()}
      $direction={direction as TransactionDirection}
      onClick={onClick}
    >
      <Row>
        <IconWrapper>
          <IconWithBackground className="transaction-entry-icon">{icon}</IconWithBackground>
        </IconWrapper>
        <LeftCol>
          <Name>{name}</Name>
          <TransferKind>
            <Trans t={t}>{isLaptop ? `${type}` : 'date_and_time'}</Trans>
          </TransferKind>
        </LeftCol>
      </Row>
      <RightCol>
        <Balance className="balance">
          {Commons.currencyFormatter(direction === 'in' ? amount : -amount, currency)}
        </Balance>
        <Timestamp>
          {isDesktop ? `${t('date_and_time')} ${formatDate(timestamp)}` : formatDate(timestamp)}
        </Timestamp>
      </RightCol>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $direction: TransactionDirection; $status: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 15px;
  transition: background-color ease-in-out 300ms;
  border-top: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  min-height: 40px;
  box-sizing: border-box;
  flex-shrink: 0;
  cursor: pointer;

  & .transaction-entry-icon {
    background: ${({ $direction, theme }) => getBackgroundColor($direction, theme)};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.greys.lightLightGrey};

    & .transaction-entry-icon {
      background: ${({ $direction, theme }) => getHoverBackgroundColor($direction, theme)};
    }

    & .balance {
      color: ${(props) => props.theme.palette.primary.blue};
    }
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
    border-radius: 10px;
    padding: 12px 15px;
    box-sizing: border-box;
    height: 64px;
  }

  ${({ $status, theme }) =>
    $status === 'PENDING' &&
    css`
      background-color: ${theme.palette.status.pendingFinishStatusBackground};

      & .transaction-entry-icon {
        background: ${theme.palette.status.pendingFinishStatusIcon};
      }

      &:hover {
        background: ${theme.palette.status.pendingFinishStatusBackgroundHover};

        & .transaction-entry-icon {
          background: ${theme.palette.status.pendingFinishStatusIconHover};
        }
      }

      @media (${theme.breakpoints.laptop}) {
        border: 1px solid ${theme.palette.status.pendingFinishStatus};
        border-radius: 10px;
        padding: 12px 15px;
        box-sizing: border-box;
        height: 64px;
      }
    `}

  ${({ $status, theme }) =>
    $status === 'PENDING_CHALLENGE' &&
    css`
      & .transaction-entry-icon {
        background: ${theme.palette.status.pendingFinishStatusIcon};
      }

      &:hover {
        & .transaction-entry-icon {
          background: ${theme.palette.status.pendingFinishStatusIconHover};
        }
      }
    `}
  
  ${({ $status, theme }) =>
    $status === 'RETURNED' &&
    css`
      & .transaction-entry-icon {
        background-color: ${theme.palette.positive.lessContrastGreenLighter};
      }

      &:hover {
        & .transaction-entry-icon {
          background-color: ${theme.palette.positive.lessContrastGreenLight};
        }
      }
    `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: calc(100% - 140px);
  min-width: 0;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100% - 137px);
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    width: calc(100% - 216px);
  }
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.spacing.s};
  gap: 2px;
  box-sizing: border-box;
  min-width: 0;
  width: 100%;
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  box-sizing: border-box;
`;

const Name = styled.div`
  ${FontStyles.buttonGilroyType3}
  color: ${(props) => props.theme.palette.primary.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 16px);
`;

const TransferKind = styled.div`
  ${FontStyles.bodySmallGilroy}
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const Balance = styled.div`
  ${FontStyles.buttonGilroyType3}
  color: ${(props) => props.theme.palette.primary.black};
  transition: ease-in-out 300ms;
  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

const Timestamp = styled.div`
  ${FontStyles.bodySmallGilroy}
  color: ${(props) => props.theme.palette.greys.asphaltTint};
`;

const IconWithBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: background-color 300ms ease-in-out;

  & svg {
    height: 15px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 40px;
    height: 40px;

    & svg {
      height: 24px;
    }
  }
`;

const IconWrapper = styled.div``;

export default TransactionEntry;
