import { useMutation, useQuery } from '@tanstack/react-query';

import { NotificationsService } from '@services/notifications-service';

export const useGetNotifications = () => {
  return useQuery({
    queryKey: ['getNotifications'],
    queryFn: async ({ signal }) => {
      return await NotificationsService.getNotifications(signal);
    },
  });
};

export const useUpdateNotificationReadStatus = () => {
  return useMutation({
    mutationFn: async () => {
      return await NotificationsService.updateNotificationReadStatus();
    },
  });
};
