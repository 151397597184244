import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Analytics from '@views/Analytics';
import Cards from '@views/Cards';
import ErrorPage from '@views/ErrorPage';
import KYB from '@views/KYB';
import KYC from '@views/KYC';
import { ChallengeOTPForm, LoginOTPForm } from '@views/OTPForm';
import Onboarding from '@views/Onboarding';
import OnboardingAccountDetails from '@views/Onboarding/OnboardingAccountDetails';
import OnboardingAddressForm from '@views/Onboarding/OnboardingAddressForm';
import OnboardingChangeMail from '@views/Onboarding/OnboardingChangeMail';
import OnboardingCheckMail from '@views/Onboarding/OnboardingCheckMail';
import OnboardingCompanyDetailsForm from '@views/Onboarding/OnboardingCompanyDetailsForm';
import OnboardingCreatePassword from '@views/Onboarding/OnboardingCreatePassword';
import OnboardingLogin from '@views/Onboarding/OnboardingLogin';
import OnboardingPersonalInformationForm from '@views/Onboarding/OnboardingPersonalInformationForm';
import OnboardingRedirect from '@views/Onboarding/OnboardingRedirect';
import OnboardingRestorePasswordEnterMail from '@views/Onboarding/OnboardingRestorePasswordEnterMail';
import OnboardingTerms from '@views/Onboarding/OnboardingTerms';
import Overview from '@views/Overview';
import Pricing from '@views/Pricing';
import SendMoney, { TransactionFormRedirect } from '@views/SendMoney';
import SetPassword from '@views/SetPassword';
import Settings from '@views/Settings';

import ProtectedRoute from './ProtectedRoute';

const RoutesComponent = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute component={Overview} />}
      />
      <Route
        path="/send-money"
        element={<ProtectedRoute component={SendMoney} />}
      />
      <Route
        path="/send-money/transaction-form"
        element={<ProtectedRoute component={TransactionFormRedirect} />}
      />
      <Route
        path="/cards"
        element={<ProtectedRoute component={Cards} />}
      />
      <Route
        path="/analytics"
        element={<ProtectedRoute component={Analytics} />}
      />
      <Route
        path="/pricing"
        element={<ProtectedRoute component={Pricing} />}
      />
      <Route
        path="/settings/*"
        element={<ProtectedRoute component={Settings} />}
      />
      <Route
        path="/change"
        element={<Onboarding type="change" />}
      >
        <Route
          index
          element={<OnboardingChangeMail />}
        />
        <Route
          path="*"
          element={<OnboardingRedirect />}
        />
      </Route>
      <Route
        path="/otp/login"
        element={<Onboarding type="sms" />}
      >
        <Route
          index
          element={<LoginOTPForm />}
        />
        <Route
          path="*"
          element={<OnboardingRedirect />}
        />
      </Route>
      <Route
        path="/otp/challenge"
        element={<Onboarding type="sms" />}
      >
        <Route
          index
          element={<ChallengeOTPForm />}
        />
        <Route
          path="*"
          element={<OnboardingRedirect />}
        />
      </Route>
      <Route
        path="/login"
        element={<Onboarding type="login" />}
      >
        <Route
          index
          element={<OnboardingLogin />}
        />
        <Route
          path="*"
          element={<OnboardingRedirect />}
        />
      </Route>
      <Route
        path="/restore"
        element={<Onboarding type="restore" />}
      >
        <Route
          index
          element={<OnboardingRestorePasswordEnterMail />}
        />
        <Route
          path="2"
          element={<OnboardingCheckMail />}
        />
        <Route
          path="*"
          element={<OnboardingRedirect />}
        />
      </Route>
      <Route
        path="/set-password"
        element={<Onboarding type="restore" />}
      >
        <Route
          path="*"
          element={<SetPassword />}
        />
      </Route>
      <Route
        path="/register"
        element={<Onboarding type="register" />}
      >
        <Route
          index
          element={<OnboardingAccountDetails />}
        />
        <Route
          path="2"
          element={<OnboardingPersonalInformationForm />}
        />
        <Route
          path="3"
          element={<OnboardingCompanyDetailsForm />}
        />
        <Route
          path="4"
          element={<OnboardingAddressForm />}
        />
        <Route
          path="5"
          element={<OnboardingTerms />}
        />
        <Route
          path="6/:user"
          element={<OnboardingCreatePassword />}
        />
        <Route
          path="*"
          element={<OnboardingRedirect />}
        />
      </Route>
      <Route
        path="/verify"
        element={<OnboardingRedirect verify={true} />}
      />
      <Route
        path="/kyb"
        element={<ProtectedRoute component={KYB} />}
      />
      <Route
        path="/corporates/kyc"
        element={<KYC />}
      />
      <Route
        path="*"
        element={<ErrorPage />}
      />
    </Routes>
  );
};

export default RoutesComponent;
