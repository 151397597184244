import { ReactElement } from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  onClick: () => void;
  text?: string;
  icon?: ReactElement;
  className?: string;
  id?: string;
  disabled?: boolean;
}

const IconButton = ({ text, icon, onClick, className, id, disabled }: Props) => {
  return text ? (
    <Button
      id={id}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {text}
    </Button>
  ) : (
    <Button
      id={id}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {icon}
    </Button>
  );
};

export default IconButton;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.primary.black};
  transition: ease-in-out 300ms;
  ${FontStyles.bodySmallGilroy};
  line-height: unset;
  cursor: pointer;
`;
