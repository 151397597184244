import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

// TODO (Andriy): If we are ever going to need these metrics, set them up properly and implement reporting.
const reportWebVitals = () => {
  onCLS(() => undefined, { reportAllChanges: true });
  onINP(() => undefined, { reportAllChanges: true });
  onFCP(() => undefined, { reportAllChanges: true });
  onLCP(() => undefined, { reportAllChanges: true });
  onTTFB(() => undefined, { reportAllChanges: true });
};

export default reportWebVitals;
