import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import useMediaQuery from '@hooks/useMediaQuery';

const SupportChat = () => {
  const theme = useTheme();
  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);

  const supportVisible = useBoundStore((state) => state.supportVisible);
  const setSupportVisible = useBoundStore((state) => state.setSupportVisible);

  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    const updateSupportVisibility = () => {
      const scriptId = 'hs-script-loader';
      const existingScript = document.getElementById(scriptId);

      if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        if (supportVisible) {
          window.HubSpotConversations.widget.load();
        } else {
          window.HubSpotConversations.widget.remove();
        }
      }
    };
    updateSupportVisibility();
  }, [supportVisible, setSupportVisible]);

  return null;
};

export default SupportChat;
