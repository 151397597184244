import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconNotification } from '@constants/icons';

import SmallButton from '@elements/SmallButton/SmallButton';

const NotificationPopup = () => {
  const { t } = useTranslation();

  const setNotificationsOpen = useBoundStore((state) => state.setNotificationsOpen);
  const setNotify = useBoundStore((state) => state.setNotify);
  const countdown = useBoundStore((state) => state.countdown);
  const setCountdown = useBoundStore((state) => state.setCountdown);
  const notify = useBoundStore((state) => state.notify);
  const message = useBoundStore((state) => state.message);
  const popUpType = useBoundStore((state) => state.popUpType);

  const handleClick = () => {
    setNotify(false);
    setCountdown(5);
    setNotificationsOpen(popUpType === 'open');
  };

  return notify ? (
    <NotificationWrapper>
      <Container>
        <IconContainer>
          <IconNotification />
        </IconContainer>
        <Content>
          <Title>
            {message ? message : t('incoming_notification')}
            <BlueButtonTablet
              text={t(popUpType)}
              onClick={() => handleClick()}
            />
          </Title>
          <AutoClose>
            {t('auto_close')}
            <Timer>&nbsp;{countdown}</Timer>
          </AutoClose>
          <BlueButtonMobile
            text={t(popUpType)}
            onClick={() => handleClick()}
          />
        </Content>
      </Container>
    </NotificationWrapper>
  ) : null;
};
export default NotificationPopup;

const NotificationWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 90px;
  z-index: 3;
  box-sizing: border-box;
  display: flex;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    top: 80px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  background: rgba(214, 221, 234, 0.88);
  backdrop-filter: blur(2px);
  border-radius: 0 20px 20px 0;
  padding: 8px 16px 8px 15px;
  box-sizing: border-box;
  width: 184px;
  max-height: 116px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 13px;
    background: ${(props) => props.theme.palette.gradients.primaryBlackGradient};
    backdrop-filter: none;
    padding: 20px 25px 20px 20px;
    width: 456px;
    max-height: 125px;
    border-radius: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const IconContainer = styled.div`
  display: none;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 40px;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 69px;
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

const Title = styled.div`
  display: flex;
  gap: 20px;
  ${FontStyles.h6};
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.primary.black};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.h4};
    color: ${(props) => props.theme.palette.primary.white};
  }
`;

const AutoClose = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.primary.black};
  margin-top: 4.5px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.buttonGilroyType3};
    color: ${(props) => props.theme.palette.primary.white};
    margin-top: 0;
  }
`;

const Timer = styled.div`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.primary.blue};
`;

const BlueButtonTablet = styled(SmallButton)`
  color: ${(props) => props.theme.palette.primary.white};
  background: ${(props) => props.theme.palette.gradients.primaryBlueGradient};
  border: none;
  max-width: 77px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 5px;
  cursor: pointer;
  height: 34px;
  display: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 0;
    display: inline-block;
  }
`;

const BlueButtonMobile = styled(BlueButtonTablet)`
  display: inline-block;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;
