import { CanceledError } from 'axios';

import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const issueOtp = async (data?: { token?: string; signal?: AbortSignal }) => {
  try {
    const headers = {
      Authorization: `Bearer ${data?.token}`,
    };

    const axiosResponse = await weavrAuthorizedAxiosClient.post('/stepup/issue', undefined, {
      ...(data?.token ? { headers } : {}),
      ...(data?.signal ? { signal: data?.signal } : {}),
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const verifyOtp = async (data: { verificationCode: string; token: string }) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/stepup/verify',
      {
        verificationCode: data.verificationCode,
      },
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const StepUpService = {
  issueOtp,
  verifyOtp,
};

export default StepUpService;
