import { useTranslation } from 'react-i18next';

import {
  IconStatusBar,
  IconStatusBarBlue,
  IconStatusBarStar,
  IconStatusBarStarBlue,
  IconStatusBarStarCompleted,
} from '@constants/icons';
import { StatusBarProgressCompleted } from '@constants/images';

import useOnboardingSteps from '@hooks/useOnboardingSteps';

import {
  Container,
  Content,
  CustomIconSettingsLock,
  CustomIconVerify,
  CustomLargeButtonLink,
  CustomLargeButtonLinkTablet,
  ProgressContainer,
  StatusBar,
  TextContainer,
  Top,
} from '@components/Settings/SettingsKYC/SettingsKYC.styles';

const SettingsKYC = () => {
  const { t } = useTranslation();
  const {
    submitting,
    emailVerified,
    mobileNumberVerified,
    isKYBApprovedStatus,
    isKYBInitiatedStatus,
    isKYBNotStartedStatus,
    userHasManagedAccounts,
    handleButtonClick,
  } = useOnboardingSteps();

  const getProgressContainerContent = () => {
    if (!emailVerified) {
      return <CustomIconVerify />;
    }

    if (!mobileNumberVerified) {
      return <CustomIconSettingsLock />;
    }

    if (isKYBInitiatedStatus) {
      return (
        <StatusBar>
          <IconStatusBarStar />
          <IconStatusBar />
        </StatusBar>
      );
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return (
        <StatusBar>
          <IconStatusBarStarCompleted />
          <StatusBarProgressCompleted />
        </StatusBar>
      );
    }

    return (
      <StatusBar>
        <IconStatusBarStarBlue />
        <IconStatusBarBlue />
      </StatusBar>
    );
  };

  const getButtonText = () => {
    if (!emailVerified) {
      return 'Verify Email';
    }

    if (!mobileNumberVerified) {
      return 'Get Started';
    }

    if (isKYBInitiatedStatus) {
      return 'View Status';
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return 'Get IBAN';
    }

    if (isKYBNotStartedStatus) {
      return t('verify_account');
    }

    return 'Verify';
  };

  const getHeading = () => {
    if (!emailVerified) {
      return 'Verify your email address';
    }

    if (!mobileNumberVerified) {
      return 'Enable 2-factor authentication';
    }

    if (isKYBInitiatedStatus) {
      return 'Verification process initiated';
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return 'Your account is now verified.';
    }

    if (isKYBNotStartedStatus) {
      return 'Start verification process';
    }

    return 'Verify yourself to access all features';
  };

  const getSubheading = () => {
    if (!emailVerified) {
      return 'Complete your account setup by verifying your email address.';
    }

    if (!mobileNumberVerified) {
      return 'Please confirm your phone number to finalize your registration.';
    }

    if (isKYBInitiatedStatus) {
      return 'We are currently processing the documents you provided.';
    }

    if (isKYBApprovedStatus && !userHasManagedAccounts) {
      return 'Click here to get your IBAN';
    }

    if (isKYBNotStartedStatus) {
      return 'To get started, you need to verify your organization.';
    }

    return 'To get started, you need to verify yourself.';
  };

  return (
    <Container>
      <Content>
        <Top>
          <ProgressContainer>{getProgressContainerContent()}</ProgressContainer>
          <CustomLargeButtonLinkTablet
            disabled={submitting}
            onClick={handleButtonClick}
            text={getButtonText()}
            variant="blueBlack"
          />
        </Top>
        <TextContainer>
          <div>{getHeading()}</div>
          {getSubheading()}
        </TextContainer>
        <CustomLargeButtonLink
          disabled={submitting}
          onClick={handleButtonClick}
          text={getButtonText()}
          variant="blueBlack"
        />
      </Content>
    </Container>
  );
};
export default SettingsKYC;
