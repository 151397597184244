import React from 'react';

import { useBoundStore } from '@stores/BoundStore';

import { getFilterName } from '@shared/functions';

import { allTypes, statuses } from '@components/Filters/Statuses';

import Chip from '@elements/Chip/Chip';

import { ScrollFilters } from './AnalyticsTransactionsWrapper.styles';

const SelectedFilterList = () => {
  const setFilterType = useBoundStore((state) => state.setFilterType);
  const filterType = useBoundStore((state) => state.filterType);
  const filterStatus = useBoundStore((state) => state.filterStatus);
  const setFilterStatus = useBoundStore((state) => state.setFilterStatus);

  const handleResetType = () => {
    setFilterType('');
  };
  const handleResetStatus = () => {
    setFilterStatus(undefined);
  };

  return (
    <ScrollFilters>
      {filterType && (
        <Chip
          name={getFilterName(filterType, allTypes)}
          handleResetType={handleResetType}
        />
        // <Chip>
        //   {' '}
        //   {getFilterName(filterType, allTypes)}{' '}
        //   <IconWrap
        //     onClick={handleResetType}
        //     className="closeIcon"
        //   >
        //     <DeleteItem />
        //   </IconWrap>{' '}
        // </Chip>
      )}
      {filterStatus && (
        <Chip
          name={getFilterName(filterStatus, statuses)}
          handleResetType={handleResetStatus}
        />
      )}
    </ScrollFilters>
  );
};

export default SelectedFilterList;
