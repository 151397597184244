import { useQueryClient } from '@tanstack/react-query';
import React, { ComponentType, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useBoundStore } from '@stores/BoundStore';

import { getToken } from '@shared/functions';

import useOnboardingSteps from '@hooks/useOnboardingSteps';

import InactivityTimer from '@components/InactivityTimer/InactivityTimer';
import Navigation from '@components/Navigation/Navigation';
import NotificationCenter from '@components/NotificationCenter/NotificationCenter';
import OTPModal from '@components/OTPModal';

import Toast from '@elements/Toast/Toast';

import getSocket from '../websocket';

interface Props {
  component: ComponentType;
}

const ProtectedRoute = ({ component: Component }: Props) => {
  const isLoggedIn = useBoundStore((state) => state.isLoggedIn);
  const location = useLocation();
  const queryClient = useQueryClient();

  const setVerifiedKYC = useBoundStore((state) => state.setVerifiedKYC);
  const openModal = useBoundStore((state) => state.openModal);

  const { emailVerified, mobileNumberVerified, isKYBApprovedStatus } = useOnboardingSteps();

  useEffect(() => {
    if (isLoggedIn) {
      setVerifiedKYC(isKYBApprovedStatus);
    }
  }, [isKYBApprovedStatus, isLoggedIn, setVerifiedKYC]);

  useEffect(() => {
    if (emailVerified && !mobileNumberVerified && location.pathname) {
      openModal(<OTPModal />);
    }
  }, [emailVerified, location.pathname, mobileNumberVerified, openModal]);

  useEffect(() => {
    const token = getToken();
    if (!token) return;

    const socket = getSocket(token);

    socket.connect();

    return () => {
      if (socket && socket.connected) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const token = getToken();
    if (!token) return;

    const socket = getSocket(token);

    const handleMessage = async ({
      title,
      message,
      link,
      frontendRefetchKeys,
    }: {
      title: string;
      message: string;
      link?: string;
      frontendRefetchKeys?: string[];
    }) => {
      toast.info(
        <Toast
          title={title}
          message={message}
          link={link}
        />
      );
      if (frontendRefetchKeys && frontendRefetchKeys.length > 0) {
        await queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey.some((key) => frontendRefetchKeys.includes(key as string)),
          refetchType: 'active',
        });
      }
    };

    const handleDisconnect = () => {
      // NOOP
    };

    socket.on('notification', handleMessage);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('notification', handleMessage);
      socket.off('disconnect', handleDisconnect);
    };
  }, [queryClient]);

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/login"
        replace
        state={{ wantedPath: location.pathname }}
      />
    );
  } else {
    return (
      <>
        <InactivityTimer />
        <Navigation />
        <NotificationCenter />
        <Component />
      </>
    );
  }
};

export default ProtectedRoute;
