import { Turnstile } from '@marsidev/react-turnstile';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { toast } from 'react-toastify';

import { useValidateCaptcha } from '@api/Challenges/challengesApi';

import Toast from '@elements/Toast/Toast';

interface Props {
  className?: string;
  onValidationSuccess: Dispatch<SetStateAction<CloudflareCaptcha>>;
}

const Captcha = ({ className, onValidationSuccess }: Props) => {
  const { mutate: verifyCaptcha } = useValidateCaptcha();

  const idempotencyRef = useRef<string | null>(null);

  const turnstileSiteKey = process.env.REACT_APP_TURNSTILE_SITE_KEY || '';

  return (
    <Turnstile
      options={{
        theme: 'light',
        size: 'flexible',
      }}
      className={className}
      siteKey={turnstileSiteKey}
      onWidgetLoad={(widgetId: string) => {
        idempotencyRef.current = widgetId;
      }}
      onSuccess={(captchaToken: string) => {
        verifyCaptcha(
          { captchaToken, idempotencyRef: idempotencyRef.current },
          {
            onSuccess: async ({ success }) => {
              onValidationSuccess({
                captchaToken,
                idempotencyRef: idempotencyRef.current,
                isValid: success,
              });
            },
            onError: () => {
              toast.error(
                <Toast
                  title="Anti-Bot verification failed."
                  message="Try reloading the page or if the issue persists, please contact the support team at support@peanuds.com with the following request Id:"
                />
              );
              onValidationSuccess((prevState) => ({ ...prevState, isValid: false }));
            },
          }
        );
      }}
      onError={(error: string) => {
        console.error(error);
        toast.error(
          <Toast
            title="Captcha Error"
            message="There was an error when trying to solve the Captcha."
          />
        );
        onValidationSuccess((prevState) => ({ ...prevState, isValid: false }));
      }}
    />
  );
};

export default Captcha;
