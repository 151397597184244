import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useIssueChallenge, useVerifyChallenge } from '@api/Challenges/challengesApi';

import OnboardingSMSAuthMobile2x from '@assets/images/ImageOnboardingSMSAuthMobile2x.png';
import OnboardingSMSAuthMobile from '@assets/images/ImageOnboardingSMSAuthMobile.png';
import OnboardingSMSAuthTablet from '@assets/images/ImageOnboardingSMSAuthTablet.png';

import { useBoundStore } from '@stores/BoundStore';

import { IconFieldCheckBox } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import Toast from '@elements/Toast/Toast';

import {
  Container,
  CustomOnboardingStepNavigation,
  Heading,
  Label,
  OtpInputContainer,
  OtpInputField,
  ProblemLink,
  ProblemRow,
  SmsAuthImage,
  SubHeading,
  SupportFeaturesContainer,
  Wrapper,
} from './ChallengeOTPForm.styles';

const ChallengeOTPForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const otpInputRef = useRef<HTMLInputElement | null>(null);

  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const [isOtpInputEnabled, setIsOtpInputEnabled] = useState(false);
  const scaChallengeId = useRef<string | null>(null);

  const { mutate: issueChallengeMutation } = useIssueChallenge();
  const { mutate: verifyChallengeMutation } = useVerifyChallenge();

  const errors = useMemo(
    () => ({
      409: () => {
        toast.error(
          <Toast
            title="Error"
            message="You have to complete the current challenge otherwise the transaction will not be executed."
          />
        );
      },
    }),
    []
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errors);

  useEffect(() => {
    const controller = new AbortController();

    const resetState = () => {
      navigate('/', { replace: true, state: {} });
    };

    if (!location.state) resetState();

    const { challenge } = location.state;

    if (!challenge) resetState();

    const issueChallengeRequestData: ChallengeRequest = {
      idempotency: crypto.randomUUID(),
      resourceType: 'outgoing_wire_transfers',
      resourceIds: [challenge.id],
    };

    issueChallengeMutation(
      { challenge: issueChallengeRequestData, signal: controller.signal },
      {
        onSuccess: (data) => {
          setIsOtpInputEnabled(true);
          scaChallengeId.current = data.scaChallengeId;
        },
        onError: (error) => {
          setIsOtpInputEnabled(false);
          handleHTTPErrors([error]);
        },
      }
    );

    return () => {
      controller.abort();
    };
  }, [handleHTTPErrors, issueChallengeMutation, location.state, navigate]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (otpInputRef.current) {
      otpInputRef.current.value = event.target.value.slice(0, 6);

      if (otpInputRef.current.value.length === 6) {
        submitHandler();
      }
    }
  };

  const submitHandler = () => {
    if (otpInputRef.current && scaChallengeId.current) {
      const verificationCode = otpInputRef.current.value;
      const verifyChallengeData: ChallengeVerificationRequest = {
        idempotency: crypto.randomUUID(),
        resourceType: 'outgoing_wire_transfers',
        scaChallengeId: scaChallengeId.current,
        verificationCode,
      };

      verifyChallengeMutation(verifyChallengeData, {
        onSuccess: () => {
          setSelectedContact(null);
          navigate('/send-money', { state: { challengeSuccessful: true } });
        },
        onError: (error) => {
          handleHTTPErrors([error]);
          setSelectedContact(null);
          navigate('/send-money', { state: { error } });
        },
      });
    }
  };

  return (
    <Wrapper>
      <SmsAuthImage
        srcSet={`${OnboardingSMSAuthMobile2x} 320w, ${OnboardingSMSAuthTablet} 680w`}
        src={OnboardingSMSAuthMobile}
      />
      <CustomOnboardingStepNavigation
        text="Cancel and go back"
        to="/send-money"
      />
      <Container>
        <Heading>SMS Authentication</Heading>
        <SubHeading>
          We sent a verification code to your phone number, please enter it below to verify that it
          is you.
        </SubHeading>
        <Label>
          Enter code below
          <OtpInputContainer>
            <OtpInputField
              ref={otpInputRef}
              maxLength={6}
              onChange={(event) => changeHandler(event)}
              placeholder="Enter code"
              disabled={!isOtpInputEnabled}
            />
            {otpInputRef.current?.value.length === 6 && <IconFieldCheckBox />}
          </OtpInputContainer>
        </Label>
        <SupportFeaturesContainer>
          <p>Didn&apos;t get one?</p>
          <ProblemRow>
            <ProblemLink
              onClick={() => {
                window.HubSpotConversations.widget.load();
                window.HubSpotConversations.widget.open();
              }}
            >
              Contact our support team
            </ProblemLink>
          </ProblemRow>
        </SupportFeaturesContainer>
      </Container>
    </Wrapper>
  );
};

export default ChallengeOTPForm;
