import { friendlyFormatIBAN } from 'ibantools';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  useGetAllManagedAccounts,
  useGetManagedAccountIban,
} from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { LogoSEPA, LogoSwift } from '@constants/logos';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import CopyField from '@components/CopyField/CopyField';

const BankTransfer = () => {
  //const { breakpoints } = useTheme();

  const [choice] = useState<string | number>('sepa');
  // const [detailsOpen, setDetailsOpen] = useState(false);

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const optionsBig = [
    { name: 'addMoney', label: 'Local/SEPA', value: 'sepa' },
    { name: 'addMoney', label: 'International/Swift', value: 'swift' },
  ];

  const optionsSmall = [
    { name: 'addMoney', label: 'Local', value: 'sepa' },
    { name: 'addMoney', label: 'International', value: 'swift' },
  ];

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const {
    data: managedAccountData,
    isError: isGetAllManagedAccountsError,
    error: getAllManagedAccountsError,
  } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const {
    data: mainManagedAccountData,
    isError: isGetManagedAccountIbanError,
    error: getManagedAccountIbanError,
  } = useGetManagedAccountIban(managedAccountId);

  useEffect(() => {
    const errorsToHandle = [];
    if (isGetAllManagedAccountsError && getAllManagedAccountsError) {
      errorsToHandle.push(getAllManagedAccountsError);
    }
    if (isGetManagedAccountIbanError && getManagedAccountIbanError) {
      errorsToHandle.push(getManagedAccountIbanError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [
    getAllManagedAccountsError,
    getManagedAccountIbanError,
    handleHTTPErrors,
    isGetAllManagedAccountsError,
    isGetManagedAccountIbanError,
  ]);

  const getIBAN = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      const IBAN = mainManagedAccountData.bankAccountDetails[1].details.iban;
      return IBAN ? (friendlyFormatIBAN(IBAN) as string) : '--';
    } else {
      return '--';
    }
  };

  const getBIC = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      const BIC = mainManagedAccountData.bankAccountDetails[1].details.bankIdentifierCode;
      return BIC as string;
    } else {
      return '--';
    }
  };

  const getBeneficiary = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      return mainManagedAccountData.bankAccountDetails[1].beneficiaryNameAndSurname ?? '--';
    } else {
      return '--';
    }
  };

  const getBankName = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      return mainManagedAccountData.bankAccountDetails[1].beneficiaryBank ?? '--';
    } else {
      return '--';
    }
  };

  const getBankAddress = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      return mainManagedAccountData.bankAccountDetails[1].beneficiaryBankAddress ?? '--';
    } else {
      return '--';
    }
  };

  return (
    <Container>
      <Top>
        {/* <RadioButtonGroup
          options={isTablet ? optionsBig : optionsSmall}
          column={false}
          onChange={(event) => setChoice(event.target.value)}
        />*/}
        {choice === 'sepa' ? <LogoSEPA /> : <LogoSwift />}
      </Top>
      {choice === 'sepa' ? (
        <Content>
          <CopyField
            value={getBeneficiary()}
            label="Beneficiary"
          />
          <CopyField
            value={getIBAN()}
            label="IBAN"
          />
          <CopyField
            value={getBIC()}
            label="BIC"
          />
          <CopyField
            value={getBankName()}
            label="Bank Name"
          />
          <CopyField
            value={getBankAddress()}
            label="Bank Address"
          />
        </Content>
      ) : (
        <Content>
          <CopyField
            value="John Nettles"
            label="Beneficiary"
          />
          <CopyField
            value="DE91389838983829293099"
            label="Account Number"
          />
          <CopyField
            value="DRESDEFF670"
            label="Swift"
          />
          <CopyField
            value="Royal Bank"
            label="Routing Number"
          />
          <SwiftWrapper>
            <CopyField
              value="Commerzbank"
              label="Bank Name"
            />
            <CopyField
              value="2nd street London Great Britain"
              label="Bank Address"
            />
          </SwiftWrapper>
        </Content>
      )}
      {/*Hidden by request of Peanuds (Marina Alasaniia) Apr.01.24*/}
      {/*<DetailsPopUp*/}
      {/*  open={detailsOpen}*/}
      {/*  setOpen={setDetailsOpen}*/}
      {/*/>*/}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 15px;
  padding: 15px 25px 25px 25px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  ${HideScrollbar};
  overflow: hidden auto;
  max-height: 400px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: unset;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 45px;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  & > :last-child {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    & > :last-child {
      display: flex;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const SwiftWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 20px;
`;

export default BankTransfer;
