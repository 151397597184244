import React, { useState } from 'react';

import { useExportCsv } from '@api/Analytics/analyticsApi';
import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { createFileName, downloadCSV } from '@shared/functions';

import { IconDownload } from '@constants/icons';

import { ButtonContainer } from '@components/ExportButton/ExportButton.styles';

import BlueButton from '@elements/BlueButton/BlueButton';

export const ExportButtonContactTransaction = () => {
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const selectedContact = useBoundStore((state) => state.selectedContact);

  const [timespan] = useState<Timespan>({
    start: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    end: new Date(),
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const { mutate: exportCsvMutation } = useExportCsv();

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const exportClick = () => {
    if (submitting) return;

    setSubmitting(true);

    exportCsvMutation(
      {
        transactionRequest: {
          start: timespan.start,
          end: timespan.end,
          searchQuery: '',
          orderBy: 'date_and_time',
          sortingDirection: 'desc',
          offset: '0',
          limit: '50',
          filterType: undefined,
          filterStatus: undefined,
          managedAccountId,
          contactId: selectedContact?.id ?? '',
        },
        ids: undefined,
      },
      {
        onSuccess: (data) => {
          downloadCSV(data, createFileName());
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <ButtonContainer>
      <BlueButton
        disabled={!verifiedKYC || submitting}
        onClick={exportClick}
      >
        Export
        <IconDownload />
      </BlueButton>
    </ButtonContainer>
  );
};
