import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 20px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding-bottom: 40px;
    margin: 0 40px 40px 40px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-width: 1432px;
    flex-direction: initial;
    align-items: initial;
  }
`;
