import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInitiateLostPassword } from '@api/Password/passwordApi';

import { useBoundStore } from '@stores/BoundStore';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import Captcha from '@components/Captcha/Captcha';

import {
  Container,
  CustomStepNavigation,
  Disclaimer,
  FormWrapper,
  IssueWrapper,
  LinkButton,
  ProblemRow,
  Subtitle,
  SubtitleMail,
  Title,
  TitleWrapper,
} from './OnboardingCheckMail.styles';

const OnboardingCheckMail = () => {
  const navigate = useNavigate();

  const lostPasswordMail = useBoundStore((state) => state.lostPasswordMail);

  const [captchaValidationResult, setCaptchaValidationResult] = useState<CloudflareCaptcha>({
    captchaToken: null,
    idempotencyRef: null,
    isValid: false,
  });

  const { mutate: initiateLostPassword } = useInitiateLostPassword(captchaValidationResult);
  const { handleHTTPErrors } = useHTTPErrorHandler();
  const [submitting, setSubmitting] = useState(false);
  const [cooldown, setCooldown] = useState<number>(0);

  const startCooldown = () => {
    const now = Date.now();
    localStorage.setItem('lastResendVerificationTimestamp', now.toString());
    setCooldown(60);
  };

  const handleResendClick = () => {
    if (submitting) return;

    setSubmitting(true);

    if (cooldown === 0) {
      startCooldown();
      initiateLostPassword(lostPasswordMail, {
        onSuccess: () => {
          setSubmitting(false);
        },
        onError: (error) => {
          handleHTTPErrors([error]);
          setSubmitting(false);
        },
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const lastResendTimestamp = localStorage.getItem('lastResendVerificationTimestamp');
      if (lastResendTimestamp !== null) {
        const currentTime = Date.now();
        const timeElapsed = (currentTime - parseInt(lastResendTimestamp)) / 1000;
        const remainingTime = Math.max(60 - timeElapsed, 0);
        setCooldown(remainingTime);
      } else {
        setCooldown(0);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!lostPasswordMail) {
      navigate('/restore');
    }
  }, [lostPasswordMail, navigate]);

  return (
    <FormWrapper>
      <CustomStepNavigation text="Return to Previous Page" />
      <Container>
        <TitleWrapper>
          <Title>
            Password Reset
            <br /> Email Sent
          </Title>
          <Subtitle>
            We&apos;ve sent instructions to reset your password to the email address:
            <SubtitleMail>{lostPasswordMail}</SubtitleMail>
          </Subtitle>
          <Disclaimer>This email may take a few minutes to arrive.</Disclaimer>
        </TitleWrapper>
        <Captcha onValidationSuccess={setCaptchaValidationResult} />
        <IssueWrapper>
          <p>Didn&apos;t get one?</p>
          <ProblemRow>
            <LinkButton
              disabled={cooldown > 0 || submitting || !captchaValidationResult.isValid}
              onClick={handleResendClick}
            >
              Resend&nbsp;{cooldown > 0 && `(${Math.floor(cooldown)})`}
              {cooldown > 0 && '\u00A0'}
            </LinkButton>
            or
            <LinkButton>&nbsp;Contact our support team</LinkButton>.
          </ProblemRow>
        </IssueWrapper>
      </Container>
    </FormWrapper>
  );
};

export default OnboardingCheckMail;
