import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import WebFont from 'webfontloader';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';

import useMediaQuery from '@hooks/useMediaQuery';

interface Props {
  currentTabIndex: number;
  setCurrentTabIndex: Dispatch<SetStateAction<number>>;
  tabs: { label: string }[];
  uniqueName: string;
  topOffset?: number;
}

const TabNavigation = ({
  currentTabIndex,
  setCurrentTabIndex,
  tabs,
  uniqueName,
  topOffset,
}: Props) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);

  const setScheduledFormMode = useBoundStore((state) => state.setScheduledFormMode);

  const prevIndexRef = useRef(0);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Neue Plak Extended Semi'],
      },
      active() {
        const currentTab = document.getElementById(
          `${uniqueName}-${tabs[currentTabIndex].label}-id`
        );
        const underline = document.getElementById(`${uniqueName}-underline-id`);
        if (currentTab) {
          const currentTabLeft = currentTab.offsetLeft;
          const currentTabWidth = currentTab.offsetWidth;
          if (underline) {
            underline.style.left = `${currentTabLeft}px`;
            underline.style.width = `${currentTabWidth}px`;
            if (currentTabIndex !== prevIndexRef.current) {
              underline.style.transition = 'left 300ms ease-in-out, width 300ms linear';
            }
          }
        }
      },
    });
  }, [tabs, uniqueName, currentTabIndex, isTablet]);

  const handleButtonClick = (index: number) => {
    setScheduledFormMode(false);
    setCurrentTabIndex((prevState) => {
      if (prevState === index) {
        prevIndexRef.current = 0;
        return 0;
      }
      prevIndexRef.current = prevState;
      return index;
    });
  };

  return (
    <NavContainer>
      <ButtonGroup>
        {tabs.map((tab, index) => (
          <Button
            key={index}
            id={`${uniqueName}-${tab.label}-id`}
            $selected={index === currentTabIndex}
            onClick={() => handleButtonClick(index)}
          >
            {tab.label}
          </Button>
        ))}
      </ButtonGroup>
      <Underline
        $topOffset={topOffset}
        id={`${uniqueName}-underline-id`}
      />
    </NavContainer>
  );
};

export default TabNavigation;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
`;

const Button = styled.button<{ $selected: boolean }>`
  ${FontStyles.buttonsNeuePlakType1};
  cursor: pointer;
  transition: color 300ms ease-in-out;
  color: ${({ $selected, theme }) =>
    $selected ? theme.palette.primary.black : theme.palette.greys.darkGrey};

  &:hover {
    color: ${({ $selected, theme }) => ($selected ? '' : theme.palette.greys.darkestGrey)};
  }

  &:active {
    color: ${({ theme }) => theme.palette.primary.black};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.h4};
  }
`;

const Underline = styled.div<{ $topOffset?: number }>`
  height: 3px;
  left: 0;
  width: 0;
  top: ${({ $topOffset }) => ($topOffset ? $topOffset : 20)}px;
  background-color: ${({ theme }) => theme.palette.primary.black};
  display: block;
  position: relative;
`;
