import { useBoundStore } from '@stores/BoundStore';

const ModalOutlet = () => {
  const modals = useBoundStore((state) => state.modals);

  if (modals.length > 0) {
    return modals[modals.length - 1].modal;
  } else {
    return null;
  }
};

export default ModalOutlet;
