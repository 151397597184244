import { RefObject, useEffect } from 'react';

export const useClickedOutside = (
  ref: RefObject<HTMLDivElement>,
  setState: (value: boolean) => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // NotificationCenter button fix
        if (ref.current.id === 'notificationCenter') {
          const target = (event.target as HTMLElement).id;
          const targetInner = (event.target as HTMLElement).closest('#notificationCenterButton');
          if (target === 'notificationCenterButton' || targetInner) {
            return;
          }
        }

        setState(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setState, ref]);
};
