import React from 'react';

import { useBoundStore } from '@stores/BoundStore';

import SettingsAccessControl from '@components/Settings/SettingsAccessControl/SettingsAccessControl';
import SettingsCompanyDetails from '@components/Settings/SettingsCompanyDetails';
import SettingsLogSession from '@components/Settings/SettingsLogSession/SettingsLogSession';
import SettingsNavigation from '@components/Settings/SettingsNavigation/SettingsNavigation';
import SettingsUserDetails from '@components/Settings/SettingsUserDetails';

import { Container, Wrapper } from './Settings.styles';

const Settings = () => {
  const fullAccessUser = useBoundStore((state) => state.fullAccessUser);
  const settingsIndex = useBoundStore((state) => state.settingsIndex);

  return (
    <Container>
      <Wrapper>
        <SettingsNavigation />
        {settingsIndex === 0 ? (
          <SettingsUserDetails />
        ) : settingsIndex === 1 ? (
          <SettingsCompanyDetails />
        ) : settingsIndex === 2 ? (
          <SettingsLogSession />
        ) : (
          settingsIndex === 3 && fullAccessUser && <SettingsAccessControl />
        )}
      </Wrapper>
    </Container>
  );
};

export default Settings;
