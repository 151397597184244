import styled, { CSSProperties } from 'styled-components';

interface Props {
  onClick: () => void;
  text: string;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

const BorderButton = ({ onClick, text, className, disabled, style }: Props) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={style}
    >
      {text}
    </Button>
  );
};

const Button = styled.button`
  padding: 10px 19px;
  border: 1px solid ${(props) => props.theme.palette.primary.white};
  border-radius: 75px;
  font-family: ${(props) => props.theme.typography.buttonGilroyType3.fontFamily};
  font-weight: ${(props) => props.theme.typography.buttonGilroyType3.fontWeight};
  font-size: ${(props) => props.theme.typography.buttonGilroyType3.fontSize};
  line-height: ${(props) => props.theme.typography.buttonGilroyType3.lineHeight};
  color: ${(props) => props.theme.palette.primary.white};
  background: transparent;

  &:hover {
    background: ${(props) => props.theme.palette.primary.white};
    color: ${(props) => props.theme.palette.primary.blue};
    cursor: pointer;
  }

  &:disabled {
    background: ${({ theme }) => theme.palette.greys.middleGrey};
    border: none;
    color: ${({ theme }) => theme.palette.greys.darkestGrey};
    cursor: unset;
  }
`;

export default BorderButton;
