import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useSummary } from '@api/Analytics/analyticsApi';
import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { Scrollbar } from '@shared/css';

import useMediaQuery from '@hooks/useMediaQuery';

import Statistics from '@components/Statistics/Statistics';
import TabNavigation from '@components/TabNavigation/TabNavigation';
import AnalyticsBalance from '@components/analytics/AnalyticsBalance/AnalyticsBalance';
import AnalyticsChart from '@components/analytics/AnalyticsChart/AnalyticsChart';

const tabs = [{ label: 'Statistics' }, { label: 'Incoming / Outgoing' }];

interface Props {
  hashtagStats: { tag: string; sum: number };
  analyticsChartData: AnalyticsChartData[];
}

const defaultSummaryData = {
  positiveSum: 0,
  negativeSum: 0,
};

const StatisticsWrapper = ({ hashtagStats, analyticsChartData }: Props) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);
  const timespan = useBoundStore((state) => state.timespan);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const { data: summaryData } = useSummary(timespan.start, timespan.end, managedAccountId);

  const { positiveSum, negativeSum } = summaryData ?? defaultSummaryData;

  const toggleBalanceVisibility = useCallback(
    () => setIsBalanceVisible((prevState) => !prevState),
    []
  );

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    if (!isTablet) {
      timerId = setTimeout(toggleBalanceVisibility, 30000);
    }

    return () => clearTimeout(timerId);
  }, [isTablet, toggleBalanceVisibility]);

  return (
    <Container $tabIndex={currentIndex}>
      <TabNavigation
        currentTabIndex={currentIndex}
        setCurrentTabIndex={setCurrentIndex}
        tabs={tabs}
        uniqueName="transaction-overview"
        topOffset={10}
      />
      <Divider />
      <Wrapper
        $isSwitched={isBalanceVisible}
        $tabIndex={currentIndex}
        onClick={toggleBalanceVisibility}
      >
        {currentIndex === 0 ? (
          <>
            {/*<CustomStatistics*/}
            {/*  balance={verifiedKYC ? hashtagStats.sum : 0}*/}
            {/*  hashtag={verifiedKYC ? `#${hashtagStats.tag}` : ''}*/}
            {/*/>*/}
            <CustomAnalyticsBalance
              outgoing={verifiedKYC ? negativeSum : 0}
              incoming={verifiedKYC ? positiveSum : 0}
            />
            {/*<DotSwitch>*/}
            {/*  <Dot $isSwitched={isBalanceVisible} />*/}
            {/*  <Dot $isSwitched={!isBalanceVisible} />*/}
            {/*</DotSwitch>*/}
          </>
        ) : (
          <ScrollWrapper>
            <AnalyticsChart data={analyticsChartData} />
          </ScrollWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default StatisticsWrapper;

const CustomStatistics = styled(Statistics)`
  cursor: pointer;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    cursor: unset;
  }
`;

const CustomAnalyticsBalance = styled(AnalyticsBalance)`
  cursor: pointer;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    cursor: unset;
  }
`;

const Container = styled.div<{ $tabIndex: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 277px;
  border-radius: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: ${({ $tabIndex }) => ($tabIndex === 1 ? '20px 20px 11px 20px' : '20px')};
  background: white;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    height: unset;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    height: 280px;
    padding: 35px;
  }

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    width: calc(62% - 30px);
  }
`;

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greys.lightLightGrey};
  margin-top: 8px;
`;

const Wrapper = styled.div<{ $isSwitched: boolean; $tabIndex: number }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $tabIndex }) => ($tabIndex === 1 ? 'initial' : 'center')};
  justify-content: center;
  box-sizing: border-box;
  padding-top: 20px;
  gap: ${({ $isSwitched }) => ($isSwitched ? '10px' : '15px')};
  width: 100%;
  height: 100%;

  // ${CustomStatistics} {
  //   display: ${({ $isSwitched }) => ($isSwitched ? 'flex' : 'none')};
  // }
  //
  // ${CustomAnalyticsBalance} {
  //   display: ${({ $isSwitched }) => ($isSwitched ? 'none' : 'flex')};
  // }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    gap: 20px;
    justify-content: ${({ $tabIndex }) => ($tabIndex === 1 ? 'center' : 'space-between')};

    ${CustomStatistics} {
      display: flex;
    }

    ${CustomAnalyticsBalance} {
      display: flex;
    }
  }

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    gap: 15px;
  }
`;

const DotSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const Dot = styled.div<{ $isSwitched: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 61px;
  transition: 300ms ease-in-out;
  background: ${({ theme, $isSwitched }) =>
    $isSwitched ? theme.palette.primary.blue : theme.palette.greys.middleGrey};
  cursor: pointer;
`;

const ScrollWrapper = styled.div`
  display: flex;
  overflow: auto hidden;
  ${Scrollbar};
  max-height: 188.5px;
  box-sizing: border-box;
  padding-bottom: 7.5px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    max-height: 159.5px;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    max-height: 151px;
    overflow: initial;
  }
`;
