import { CanceledError } from 'axios';

import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const list = async (token: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/additional-factors/list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const enrollDevice = async () => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/additional-factors/enroll',
      undefined
    );
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const verifyDevice = async (verificationCode: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/additional-factors/verify', {
      verificationCode,
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

export const AdditionalFactorsService = {
  list,
  enrollDevice,
  verifyDevice,
};
