import { KeyboardEvent, useRef } from 'react';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconLocked, IconUnlocked } from '@constants/icons';

const CardPopup = () => {
  const wrongField = useBoundStore((state) => state.wrongField);
  const setWrongField = useBoundStore((state) => state.setWrongField);
  const wrongPin = useBoundStore((state) => state.wrongPin);
  const setWrongPin = useBoundStore((state) => state.setWrongPin);
  const attempts = useBoundStore((state) => state.attempts);
  const setAttempts = useBoundStore((state) => state.setAttempts);
  const timer = useBoundStore((state) => state.timer);
  const setTimer = useBoundStore((state) => state.setTimer);
  const timeLeft = useBoundStore((state) => state.timeLeft);
  const setHidden = useBoundStore((state) => state.setHidden);
  const pin = useRef<HTMLInputElement | null>(null);

  const theme = useTheme();

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleOnChange = () => {
    setWrongField(false);
    if (pin.current) {
      pin.current.value = pin.current.value.slice(0, 4);
    }
  };

  const handleOnKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && pin.current) {
      if (pin.current.value === '0000') {
        setTimer(true);
        setHidden(false);
        setAttempts(3);
        setWrongPin(false);
        setWrongField(false);
      } else {
        setWrongPin(true);
        setWrongField(true);
        setAttempts(attempts > 0 ? attempts - 1 : 0);
      }
    }
  };

  return (
    <Container>
      <Headline>{timer ? 'Timer' : 'Enter Pin Code'}</Headline>
      <InnerContainer timer={timer}>
        {timer ? (
          <Timer>{formatTime(timeLeft)}</Timer>
        ) : (
          <>
            <PinHeadline style={{ visibility: wrongPin ? 'visible' : 'hidden' }}>
              Wrong PIN
            </PinHeadline>
            <Input
              ref={pin}
              type="number"
              placeholder="Enter Code"
              onChange={handleOnChange}
              onKeyDown={handleOnKeyPress}
              isWrongPin={wrongField}
            />
            <Attempts style={{ visibility: wrongPin ? 'visible' : 'hidden' }}>
              {attempts.toString()} attempts left
            </Attempts>
          </>
        )}
      </InnerContainer>
      {timer ? (
        <IconUnlocked />
      ) : (
        <IconLocked
          style={{
            color: wrongField
              ? theme.palette.negative.lessContrastRed
              : theme.palette.greys.darkestGrey,
          }}
        />
      )}
    </Container>
  );
};
export default CardPopup;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 19px 12px 17px 12px;
  width: 158px;
  box-sizing: border-box;
  background: white;
`;

const InnerContainer = styled.div<{ timer: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  background: ${(props) => props.theme.palette.gradients.primaryBlackGradient};
  color: ${(props) => props.theme.palette.greys.darkGrey};
  margin-top: 10px;
  padding: ${(props) => (props.timer ? '34px 44px' : '0 10px')};
  margin-bottom: 23px;
  width: 100%;
  box-sizing: border-box;
`;

const Input = styled.input<{ isWrongPin: boolean }>`
  ${FontStyles.buttonGilroyType2}
  background-color: transparent;
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.isWrongPin ? props.theme.palette.negative.lessContrastRed : '#ccc')};
  outline: none;
  width: 113px;
  color: ${(props) =>
    props.isWrongPin
      ? props.theme.palette.negative.lessContrastRed
      : props.theme.palette.greys.darkGrey};
  transition: border-color 0.3s, color 0.3s;
  text-align: center;
  padding-bottom: 10px;
  margin-top: 7px;

  ::placeholder {
    color: ${(props) => props.theme.palette.greys.darkGrey};
  }

  /* Remove spinner arrows */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    color: ${(props) =>
      props.isWrongPin
        ? props.theme.palette.negative.lessContrastRed
        : props.theme.palette.primary.white};
    border-color: ${(props) =>
      props.isWrongPin
        ? props.theme.palette.negative.lessContrastRed
        : props.theme.palette.primary.white};
  }
  }
`;

const Headline = styled.div`
  ${FontStyles.h5}
`;

const PinHeadline = styled.div`
  margin-top: 6px;
  ${FontStyles.h7};
  color: ${(props) => props.theme.palette.primary.white};
`;

const Attempts = styled.div`
  ${FontStyles.body2};
  color: ${(props) => props.theme.palette.primary.white};
  margin-top: 3px;
`;

const Timer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${FontStyles.buttonGilroyType2};
  color: ${(props) => props.theme.palette.greys.darkGrey};
`;
