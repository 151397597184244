import styled from 'styled-components';

import { HideScrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';

import Modal from '@components/Modal/Modal';
import PasswordInfoBlock from '@components/PasswordInfoBlock/PasswordInfoBlock';

export const CustomModal = styled(Modal)`
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: unset;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden auto;

  ${HideScrollbar};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    overflow: unset;
  }
`;

export const Content = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  width: 100%;

  & > :nth-child(3) {
    margin-top: 25px;
  }

  & > :nth-child(4) {
    margin-top: 10px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    gap: unset;
  }
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
  width: 100%;

  & > .button {
    width: 100%;
    flex-shrink: 0;
    max-width: 240px;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: initial;
  }
`;

export const HeadLine = styled.h3`
  text-align: center;
  ${FontStyles.h4};
  width: 292px;
  color: ${({ theme }) => theme.palette.primary.black};
  margin-top: 20px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: initial;
    margin-top: 60px;
    ${FontStyles.h3};
  }
`;

export const SubHeadLine = styled.h6`
  text-align: center;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  ${FontStyles.bodyMiddleGilroy};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    color: ${({ theme }) => theme.palette.greys.darkestGrey};
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 10px;
  ${FontStyles.h5};
  flex-direction: column;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 381px;
  }
`;

export const WeavrPassword = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  transition: border ease-in-out 300ms;
  height: 50px;
  max-height: 50px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;

  & > * {
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    max-height: 40px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    max-height: 50px;
  }
`;

export const CustomPasswordInfoBlock = styled(PasswordInfoBlock)`
  width: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 381px;
  }
`;
