import { AxiosRequestConfig, CanceledError } from 'axios';

import { axiosClient, weavrAuthorizedAxiosClient } from '@clients/axios-client';

const getCorporate = async (signal: AbortSignal, token?: string) => {
  try {
    const config: AxiosRequestConfig = { signal, headers: {} };

    if (token) {
      if (config.headers) config.headers['authorization'] = `Bearer ${token}`;
    }

    const result = await weavrAuthorizedAxiosClient.get('/corporate/get', config);
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const startKYB = async () => {
  try {
    const result = await weavrAuthorizedAxiosClient.post('/corporate/kyb');
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const getKYB = async (signal: AbortSignal) => {
  try {
    const result = await weavrAuthorizedAxiosClient.get('/corporate/kyb', { signal });
    return result.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const updateCorporate = async (updatedCorporateData: WeavrUpdatedCorporateData) => {
  try {
    return await weavrAuthorizedAxiosClient.patch('/corporate', updatedCorporateData);
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const createCorporate = async (
  corporateData: CorporateRegistrationData,
  { captchaToken, idempotencyRef }: Omit<CloudflareCaptcha, 'isValid'>
) => {
  try {
    const axiosResponse = await axiosClient.post('/corporate/register', corporateData, {
      headers: {
        ...(captchaToken && { 'cf-captcha-token': captchaToken }),
        ...(idempotencyRef && { 'idempotency-ref': idempotencyRef }),
      },
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};
const chargeFee = async (feeBody: WeavrChargeFeeBody) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/corporate/charge', feeBody);

    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

export const CorporateService = {
  getCorporate,
  startKYB,
  updateCorporate,
  createCorporate,
  getKYB,
  chargeFee,
};
