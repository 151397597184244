import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

import LargeButton from '@elements/LargeButton/LargeButton';
import Input from '@elements/input/Input/Input';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  box-sizing: border-box;
  padding: 65px 25px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
    padding: unset;
    margin-bottom: initial;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 15px 25px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 31px 50px 0 95px;
  }
`;

export const Label = styled.div`
  ${FontStyles.h5};
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h6};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h5};
  }
`;

export const HalfRow = styled.div`
  display: flex;
  gap: 22px;
  box-sizing: border-box;
`;

export const CustomInput = styled(Input)`
  max-height: 50px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-height: 40px;
    ${FontStyles.bodySmallGilroy};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-height: 50px;
    ${FontStyles.bodyMiddleGilroy};
  }
`;

export const CustomButton = styled(LargeButton)`
  max-height: 50px;
  transition: background 300ms ease-in-out;
  width: 153px;
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: end;
    margin-top: 95px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-height: 40px;
    order: unset;
    width: 134px;
    margin-top: 75px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-height: 50px;
    width: 153px;
  }
`;

export const CustomOnboardingStepNavigation = styled(OnboardingStepNavigation)`
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: initial;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 30px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-bottom: 20px;
  }
`;

export const Heading = styled.p`
  ${FontStyles.h3};
  text-align: center;
  width: 232px;
  margin-bottom: 5px;
  display: flex;
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const ErrorMessage = styled.div<{ $visible: boolean }>`
  box-sizing: border-box;
  ${FontStyles.bodySmallGilroy};
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  justify-content: end;
  height: 30px;
  align-items: center;
`;
