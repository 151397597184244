import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import Avatar from '@components/Avatar/Avatar';

interface Props {
  name: string;
  image?: string;
  verified: VerifiedKYCType;
}
const SettingsFullAccessUserPanel = ({ name, image, verified }: Props) => {
  return (
    <Container>
      <UserAvatar
        $verified={verified}
        text={verified ? name : '?'}
        src={image}
      />
      <TextBox>
        <NameText>{name}</NameText>
        Full Access User
      </TextBox>
    </Container>
  );
};

export default SettingsFullAccessUserPanel;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  height: 80px;
  padding: 15px;
  align-items: center;
  gap: 15px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 25px;
    height: 120px;
  }
`;

const UserAvatar = styled(Avatar)<{ $verified: VerifiedKYCType }>`
  height: 50px;
  width: 50px;
  ${FontStyles.h4};
  line-height: 100%;
  background-color: ${(props) => (props.$verified ? '' : '#fff')};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 70px;
    width: 70px;
    ${FontStyles.h3};
  }
`;

const TextBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

const NameText = styled.p`
  ${FontStyles.h5};
  color: ${(props) => props.theme.palette.primary.black};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
  }
`;
