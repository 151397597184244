import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCorporate } from '@api/Corporate/corporateApi';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import { Container, HeadText, LastRow, SubText, TD, TDHead, TH, Table } from './Pricing.styles';

type PriceDetails = {
  title: string;
  value: string;
};

type PriceData = {
  [key: string]: PriceDetails;
};

enum PriceKey {
  sepaOut = 'Wire Transfer Out (SEPA)',
  sepaIn = 'Wire Transfer In (SEPA)',
}

const Pricing = () => {
  const { t } = useTranslation();

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const { data: corporate, isError, error } = useGetCorporate();

  const feeGroup = corporate?.feeGroup ?? '';

  useEffect(() => {
    if (isError && error) {
      handleHTTPErrors([error]);
    }
  }, [error, handleHTTPErrors, isError]);

  const data: { [key in PriceKey]: PriceData } = {
    [PriceKey.sepaOut]: {
      perTransaction: {
        title: 'Per Transaction',
        value: `${
          feeGroup === 'DEFAULT'
            ? '€2,00'
            : feeGroup === 'LOW'
              ? '€2.00'
              : feeGroup === 'HIGH'
                ? '€50.00'
                : '-'
        }`,
      },
      percent: {
        title: 'Fees %',
        value: `${feeGroup === 'HIGH' ? '0.2%' : '-'}`,
      },
    },
    [PriceKey.sepaIn]: {
      perTransaction: {
        title: 'Per Transaction',
        value: `${
          feeGroup === 'DEFAULT'
            ? '€0.50'
            : feeGroup === 'LOW'
              ? '€0.50'
              : feeGroup === 'HIGH'
                ? '20.00'
                : '-'
        }`,
      },
      percent: {
        title: 'Fees %',
        value: '-',
      },
    },
  };

  const renderData = (priceTypeData: PriceData, priceType: PriceKey) => (
    <>
      <tr>
        <TDHead colSpan={2}>{priceType}</TDHead>
      </tr>
      {Object.values(priceTypeData).map((item, index) => (
        <tr key={index}>
          <TD>{item.title}</TD>
          <TD>{item.value}</TD>
        </tr>
      ))}
    </>
  );

  return (
    <Container>
      <HeadText>{t('pricing')}</HeadText>
      <SubText>{t('for_corporate_clients')}</SubText>
      <Table>
        <thead>
          <tr>
            <TH>General Pricing</TH>
            <TH>Charge in EUR</TH>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([key, value]) => renderData(value, key as PriceKey))}
        </tbody>
        <tr>
          <LastRow colSpan={2} />
        </tr>
      </Table>
    </Container>
  );
};

export default Pricing;
