import { TextField } from '@mui/material';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
      && {
            border-radius: 10px;
            outline: 1px transparent solid;
            background-color: ${(props) =>
              props.type === 'solid' ? props.theme.palette.greys.lightLightGrey : 'transparent'};
            border:${(props) =>
              props.type === 'outlined'
                ? `1px solid ${props.theme.palette.greys.darkGrey}`
                : 'none'};
          
            .MuiInputBase-root {
                  padding-right: 20px;
            }

            .Mui-focused {
                  .MuiOutlinedInput-notchedOutline {
                        border: 1px solid ${(props) => props.theme.palette.primary.blue} !important;
                  }
                  .MuiInputBase-input {
                        color:${(props) => props.theme.palette.primary.blue}
                  }
              
            }
            .Mui-error {
                  .MuiOutlinedInput-notchedOutline {
                        border: 1px solid ${(props) =>
                          props.theme.palette.negative.contrastRed} !important;
                  }
            }
        
            .MuiInputBase-input {
                  height: 20px;
                  padding: 15px 20px;
                  color: ${(props) => props.theme.palette.greys.blackGrey};
                  ${FontStyles.bodyMiddleGilroy};
                  border-radius: 10px !important;
                  outline: 1px transparent solid;

                  &::placeholder {
                    color: ${(props) => props.theme.palette.greys.darkestGrey};
                    opacity: 1;
                  }

              // @media (${(props) => props.theme.breakpoints.laptop}) {
                  //       height: 10px;
                  // }
                  //
                  // @media (${(props) => props.theme.breakpoints.desktop}) {
                  //       height: 50px;
                  // }

              & .MuiInputAdornment-root {
                width: 10px;
              }
            }

            .MuiOutlinedInput-notchedOutline {
                  outline: 1px transparent solid;
                  border: none;
            }

            .MuiOutlinedInput-root {
                  border-radius: 10px !important;
            }
            & .MuiInputAdornment-root {
              width: 10px;
            }
      },
.MuiOutlinedInput-notchedOutline {
      border-color: #ccc;
}

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
                  //border-color: ${(props) => props.theme.palette.primary.blue};
            border: 1px solid ${(props) => props.theme.palette.primary.blue} !important;
      }

      @media (${(props) => props.theme.breakpoints.laptop}) {
            ${FontStyles.bodySmallGilroy};
      }

      @media (${(props) => props.theme.breakpoints.desktop}) {
            ${FontStyles.bodyMiddleGilroy};
      }
}
`;
