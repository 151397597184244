import { ExecutionContext } from 'styled-components';

import {
  IconArrowReceive,
  IconArrowSend,
  IconBankWithCross,
  IconCardPayment,
  IconCardPaymentError,
  IconPendingChallenge,
  IconRecipe,
  IconTimer,
} from '@constants/icons';

import { ALLOWED_COUNTRIES_ISO_TO_FULL } from '@mocks/Countries';

export const Commons = {
  currencyFormatter: (amount: number, currency?: Currency) => {
    const formatter = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: currency || 'EUR',
    });
    return formatter.format(amount);
  },

  formatWeavrAmountToPeanuds: (amount: number) => {
    return amount / 100;
  },

  currencyFormatterWithSymbolAtStart: (amount: number, currency: Currency = 'EUR') => {
    const symbolFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });

    const parts = symbolFormatter.formatToParts(amount);
    const symbol = parts.find((part) => part.type === 'currency')?.value || '';

    const formattedAmount = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);

    const amountWithoutSymbol = formattedAmount.replace(/[^0-9,.]/g, '');

    return { symbol, amountWithoutSymbol };
  },
};

export const IconButtonsCommon = {
  getHoverColor: (variant: VariantType, props: ExecutionContext) => {
    return {
      light: props.theme.palette.greys.darkestGrey,
      dark: props.theme.palette.primary.white,
      darkBlue: props.theme.palette.primary.white,
      transparent: props.theme.palette.primary.white,
      red: props.theme.palette.primary.white,
    }[variant];
  },

  getBackgroundColor: (variant: VariantType, props: ExecutionContext) => {
    return {
      light: props.theme.palette.greys.middleGrey,
      dark: props.theme.palette.greys.darkGrey,
      darkBlue: props.theme.palette.greys.darkGrey,
      transparent: 'transparent',
      red: props.theme.palette.negative.lessContrastRed,
    }[variant];
  },

  getHoverBackgroundColor: (variant: VariantType, props: ExecutionContext) => {
    return {
      light: props.theme.palette.greys.middleGrey,
      dark: props.theme.palette.primary.black,
      darkBlue: props.theme.palette.primary.blue,
      transparent: props.theme.palette.primary.white,
      red: props.theme.palette.negative.lessContrastRedLight,
    }[variant];
  },
};

export const LargeButtonsCommon = {
  getBorder: (variant: LargeButtonVariantTypes, props: ExecutionContext) => {
    return {
      transparentBlack: `1px solid ${props.theme.palette.primary.black}`,
      transparentWhite: `1px solid ${props.theme.palette.greys.darkestGrey}`,
      transparentInactive: `1px solid ${props.theme.palette.greys.darkGrey}`,
      negativeRed: 'none',
      darkWhite: 'none',
      blueWhite: 'none',
      blueBlack: 'none',
      inactive: 'none',
      opaqueWhite: `1px solid ${props.theme.palette.primary.black}`,
    }[variant];
  },

  getHoverBorder: (variant: LargeButtonVariantTypes, props: ExecutionContext) => {
    return {
      transparentBlack: '1px solid transparent',
      transparentWhite: `1px solid ${props.theme.palette.primary.white}`,
      transparentInactive: 'none',
      negativeRed: 'none',
      darkWhite: 'none',
      blueWhite: 'none',
      blueBlack: 'none',
      inactive: 'none',
      opaqueWhite: '1px solid transparent',
    }[variant];
  },

  getColor: (variant: LargeButtonVariantTypes, props: ExecutionContext) => {
    return {
      transparentBlack: props.theme.palette.primary.black,
      transparentWhite: props.theme.palette.primary.white,
      transparentInactive: props.theme.palette.greys.darkGrey,
      negativeRed: props.theme.palette.primary.white,
      darkWhite: props.theme.palette.primary.white,
      blueWhite: props.theme.palette.primary.white,
      blueBlack: props.theme.palette.primary.black,
      inactive: props.theme.palette.primary.white,
      opaqueWhite: props.theme.palette.primary.black,
    }[variant];
  },

  getHoverColor: (variant: LargeButtonVariantTypes, props: ExecutionContext) => {
    return {
      transparentBlack: props.theme.palette.primary.blue,
      transparentWhite: props.theme.palette.primary.black,
      transparentInactive: props.theme.palette.greys.darkGrey,
      negativeRed: props.theme.palette.primary.white,
      darkWhite: props.theme.palette.primary.white,
      blueWhite: props.theme.palette.primary.white,
      blueBlack: props.theme.palette.primary.black,
      inactive: props.theme.palette.primary.white,
      opaqueWhite: props.theme.palette.primary.blue,
    }[variant];
  },

  getBackgroundColor: (variant: LargeButtonVariantTypes, props: ExecutionContext) => {
    return {
      transparentBlack: 'none',
      transparentWhite: 'none',
      transparentInactive: 'none',
      negativeRed: props.theme.palette.negative.lessContrastRed,
      darkWhite: 'linear-gradient(145deg, #232222, #34354a, #74c4ff, #1099fd)',
      blueWhite: 'linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a)',
      blueBlack: 'linear-gradient(145deg, #74c4ff, #1099fd, #ffffff, #ffffff)',
      inactive: props.theme.palette.greys.darkGrey,
      opaqueWhite: props.theme.palette.primary.white,
    }[variant];
  },

  getDisabledBackgroundColor: (variant: LargeButtonVariantTypes, props: ExecutionContext) => {
    return {
      transparentBlack: 'none',
      transparentWhite: 'none',
      transparentInactive: 'none',
      negativeRed: props.theme.palette.greys.darkGrey,
      darkWhite: props.theme.palette.greys.darkGrey,
      blueWhite: props.theme.palette.greys.darkGrey,
      blueBlack: props.theme.palette.greys.darkGrey,
      inactive: props.theme.palette.greys.darkGrey,
      opaqueWhite: 'none',
    }[variant];
  },

  getHoverBackgroundColor: (variant: LargeButtonVariantTypes, props: ExecutionContext) => {
    return {
      transparentBlack: `linear-gradient(white, white) padding-box, ${props.theme.palette.gradients.primaryBlueGradient} border-box`,
      transparentWhite: props.theme.palette.primary.white,
      transparentInactive: 'transparent',
      negativeRed: props.theme.palette.negative.contrastRed,
      darkWhite: '',
      blueWhite: '',
      blueBlack: '',
      inactive: props.theme.palette.greys.darkGrey,
      opaqueWhite: `linear-gradient(white, white) padding-box, ${props.theme.palette.gradients.primaryBlueGradient} border-box`,
    }[variant];
  },

  getTransition: (variant: LargeButtonVariantTypes) => {
    return {
      transparentBlack: '300ms ease-in-out',
      transparentWhite: '300ms ease-in-out',
      transparentInactive: '300ms ease-in-out',
      negativeRed: '300ms ease-in-out',
      darkWhite: '0.8s',
      blueWhite: '300ms ease-in-out',
      blueBlack: '300ms ease-in-out',
      inactive: '300ms ease-in-out',
      opaqueWhite: '300ms ease-in-out',
    }[variant];
  },

  getBackgroundSize: (variant: LargeButtonVariantTypes) => {
    return {
      transparentBlack: 'auto',
      transparentWhite: 'auto',
      transparentInactive: 'auto',
      negativeRed: 'auto',
      darkWhite: '500% auto',
      blueWhite: '500% auto',
      blueBlack: '500% auto',
      inactive: 'auto',
      opaqueWhite: 'auto',
    }[variant];
  },

  getBackgroundPosition: (variant: LargeButtonVariantTypes) => {
    return {
      transparentBlack: '',
      transparentWhite: '',
      transparentInactive: '',
      negativeRed: '',
      darkWhite: '90% 120%',
      blueWhite: '90% 120%',
      blueBlack: '90% 120%',
      inactive: '',
      opaqueWhite: '',
    }[variant];
  },
};

export const TransactionCommons = {
  /* Card and Chargeback are currently NOT Supported and only in place as placeholder for later, the first if will always be true */
  getTransactionIcon: (transaction: Transaction | DbTransaction) => {
    const status = transaction.status.toUpperCase();

    if (status === 'PENDING') {
      return <IconTimer />;
    } else if (status === 'PENDING_CHALLENGE') {
      return <IconPendingChallenge />;
    } else if (status === 'FAILED' || status === 'REJECTED') {
      return <IconBankWithCross />;
    } else if (status === 'RETURNED') {
      return <IconRecipe />;
    } else if (status === 'CANCELLED') {
      if (transaction.type === 'CARD') {
        return <IconCardPaymentError />;
      } else {
        return <IconBankWithCross />;
      }
    } else if ('direction' in transaction && transaction.direction === 'in') {
      return <IconArrowReceive />;
    } else if ('direction' in transaction && transaction.direction === 'out') {
      return <IconArrowSend />;
    } else if (transaction.type === 'CARD') {
      return <IconCardPayment />;
    } else if (status === 'REFUND') {
      return <IconRecipe />;
    } else {
      return <></>;
    }
  },

  getStatus: (value: string) => {
    switch (capitalizeFirstLetter(value)) {
      case 'Pending':
        return 'The transaction has been initiated but has not been completed yet.';
      case 'Authorized':
        return 'The bank has approved the transaction and funds have been set aside.';
      case 'Completed':
        return 'The transaction has been successfully processed and the funds have been transferred.';
      case 'Cancelled':
        return 'The transaction has been cancelled, and the funds will not be transferred.';
      case 'Failed':
        return 'The transaction could not be completed due to an error, and the funds have not been transferred.';
      case 'Reversed':
        return 'A previously completed transaction has been reversed, and the funds have been returned to the original account.';
      case 'Hold':
        return 'The bank has placed a temporary hold on the funds, which may be released at a later time.';
      case 'Disputed':
        return 'The transaction is being disputed by one of the parties involved, and an investigation is ongoing.';
    }
  },
};

function capitalizeFirstLetter(input: string): string {
  if (!input) return input;
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

/*
 * Function: ordinalSuffix
 * Purpose: To append the correct ordinal suffix to a given number (typically a date).
 * input Parameter: date (number) - The number to which the ordinal suffix will be added.
 * Return Value: A string representing the number with its corresponding ordinal suffix.
 *
 * Variables:
 * firstDigit: Represents the last digit of the date number.
 * Its calculated using date % 10, which gives the remainder when date is divided by 10.
 * This is important for determining the correct ordinal suffix for most numbers.
 *
 * lastDigit: Represents the last two digits of the date number.
 * Its obtained using date % 100, which gives the remainder when date is divided by 100.
 * This helps in handling special cases for numbers ending in 11, 12, and 13, which always take the "th" suffix.
 *
 * */
export const ordinalSuffix = (date: number): string => {
  const firstDigit = date % 10,
    lastDigit = date % 100;
  if (firstDigit === 1 && lastDigit !== 11) {
    return date + 'st';
  }
  if (firstDigit === 2 && lastDigit !== 12) {
    return date + 'nd';
  }
  if (firstDigit === 3 && lastDigit !== 13) {
    return date + 'rd';
  }
  return date + 'th';
};

export const convertDate = (date: string) => {
  const parts = date.split('.');
  const year = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[0], 10);
  return new Date(Date.UTC(year, month, day));
};

//Convert from DateObject to dd-mm-YYYY
export const formatDateObject = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

/**
 * Returns decoded token or null.
 * Do not use this to check if user is logged in or not.
 * Use isLoggedIn and setIsLoggedIn from Zustand store instead.
 * Only use this to retreive decoded token.
 */
export const getToken = (): string | null => {
  return localStorage.getItem('@@peanuds_auth@@');
};

export const convertCountryFromISO3166ToFullName = (countryCode: string | undefined): string => {
  if (!countryCode) return '-';

  const country = Object.entries(ALLOWED_COUNTRIES_ISO_TO_FULL).find(
    (entry) => countryCode === entry[0]
  );
  return country ? country[1] : '-';
};

export const parseUserName = (
  firstName: string | undefined,
  lastName: string | undefined
): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (!firstName && lastName) {
    return `${lastName}`;
  } else if (firstName && !lastName) {
    return `${firstName}`;
  } else {
    return '?';
  }
};

export const parseEmail = (email: string | undefined): string => {
  return email ?? '?';
};

export const parseUsernameWithEmail = (
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined
) => {
  return [parseUserName(firstName, lastName), parseEmail(email)];
};

export const getFees = (type: string) => {
  //TODO get fees and update this function
  switch (type) {
    case 'SEPA':
      return Commons.formatWeavrAmountToPeanuds(
        process.env.REACT_APP_WEAVR_DEFAULT_OWT_FEE
          ? parseInt(process.env.REACT_APP_WEAVR_DEFAULT_OWT_FEE)
          : 0
      );
    default:
      return 0.5;
  }
};

export const downloadCSV = (data: string, filename: string) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const createFileName = () => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  return `Peanuds_export_${day}_${month}_${year}_${hour}_${minutes}`;
};

export const getFilterName = (value: string, filters: SelectOption[]) => {
  const filter = filters.find((elem) => elem.value === value);
  return filter?.text;
};
