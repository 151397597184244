import { useMutation, useQuery } from '@tanstack/react-query';

import { TransactionsService } from '@services/transactions-service';

export const useAnalyticsChart = (managedAccountId: string) => {
  return useQuery({
    queryKey: ['analyticChart', managedAccountId],
    queryFn: async () => {
      return await TransactionsService.getAnalyticsChart(managedAccountId);
    },
    enabled: !!managedAccountId,
  });
};

export const useMostCommonHashtag = (start: Date, managedAccountId: string) => {
  return useQuery({
    enabled: !!managedAccountId,
    queryKey: ['mostCommonHashtag', managedAccountId],
    queryFn: async () => {
      return await TransactionsService.getMostCommonHashtag(start, managedAccountId);
    },
  });
};

export const useRecentHashtag = (managedAccountId: string) => {
  return useQuery({
    enabled: !!managedAccountId,
    queryKey: ['recentHashtag', managedAccountId],
    queryFn: async () => {
      return await TransactionsService.getRecentHashtag(managedAccountId);
    },
  });
};

export const useSummary = (start: Date, end: Date, managedAccountId: string) => {
  return useQuery({
    queryKey: ['summary', start, end, managedAccountId],
    enabled: !!managedAccountId,
    queryFn: async () => {
      return await TransactionsService.getSummary(start, end, managedAccountId);
    },
  });
};

export const useTransactions = (transactionRequest: TransactionRequest) => {
  return useQuery({
    queryKey: ['transactionRequest', transactionRequest],
    queryFn: async () => {
      return await TransactionsService.getTransactions(transactionRequest);
    },
    enabled: !!transactionRequest.managedAccountId, // Only run query if managedAccountId is present
  });
};

export const useExportCsv = () => {
  return useMutation({
    mutationFn: async ({
      transactionRequest,
      ids,
    }: {
      transactionRequest: TransactionRequest;
      ids?: ExportTransaction[];
    }) => {
      return await TransactionsService.exportCsv(transactionRequest, ids);
    },
  });
};
