import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const Title = styled.div`
  ${FontStyles.bodySmallGilroy};
  margin-bottom: 16px;
`;

export const RadioGroup = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  & label {
    ${FontStyles.primary};
    color: ${(props) => props.theme.palette.primary.black};
  }
`;
