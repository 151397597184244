import { Trans, useTranslation } from 'react-i18next';
import styled, { ExecutionContext, useTheme } from 'styled-components';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

import useMediaQuery from '@hooks/useMediaQuery';

interface Props {
  kind: 'incoming' | 'outgoing';
  balance: number;
  selected: boolean;
  currency: Currency;
  onClick: () => void;
}

const getFontSize = (input: number, props: ExecutionContext) => {
  if (input > 19) {
    return '16px';
  } else {
    return props.theme.typography.buttonsNeuePlakType1.fontSize;
  }
};

const CashFlowSummary = ({ kind, balance, currency, onClick, selected }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const formatBalance = (input: number) => {
    const str = Commons.currencyFormatter(input, currency).toString();
    return str.startsWith('-') ? str : '+' + str;
  };

  const formattedBalance = formatBalance(balance);
  const formattedBalanceLength = formattedBalance.length;

  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);

  const handleClick = (onClick: () => void) => {
    onClick();
  };

  return (
    <Container
      className={kind}
      onClick={() => handleClick(onClick)}
      $selected={selected}
    >
      <Title $selected={selected}>
        <Trans t={t}>{kind}</Trans>
        {isLaptop ? ':' : '(30d):'}
      </Title>
      <Amount fontSize={formattedBalanceLength}>{formattedBalance}</Amount>
    </Container>
  );
};

const Container = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 15px;
  transition: ease-in-out 300ms;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  flex: 1;

  &.incoming {
    background: ${(props) =>
      props.$selected
        ? props.theme.palette.positive.lessContrastGreen
        : props.theme.palette.positive.lessContrastGreenLighter};
    &:hover {
      background: ${(props) =>
        props.$selected
          ? props.theme.palette.positive.lessContrastGreen
          : props.theme.palette.positive.lessContrastGreenLight};
    }
  }

  &.outgoing {
    background: ${(props) =>
      props.$selected
        ? props.theme.palette.negative.lessContrastRed
        : props.theme.palette.negative.lessContrastRedLighter};
    &:hover {
      background: ${(props) =>
        props.$selected
          ? props.theme.palette.negative.lessContrastRed
          : props.theme.palette.negative.lessContrastRedLight};
    }

    @media (${(props) => props.theme.breakpoints.laptop}) {
      min-width: 232px;
    }
  }
`;

const Title = styled.div<{ $selected: boolean }>`
  ${FontStyles.h7};
  color: ${(props) =>
    props.$selected ? props.theme.palette.primary.white : props.theme.palette.greys.darkestGrey};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h6};
  }
`;

const Amount = styled.div<{ fontSize: number }>`
  ${FontStyles.h7};
  color: ${(props) => props.theme.palette.primary.black};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.buttonsNeuePlakType1};
    font-size: ${(props) => getFontSize(props.fontSize, props)};
  }
`;

export default CashFlowSummary;
