import styled from 'styled-components';

export const ButtonContainer = styled.div`
  cursor: pointer;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    order: 3;
    margin-left: initial;
  }
`;

export const ButtonContainerGrey = styled(ButtonContainer)`
  background-color: ${({ theme }) => theme.palette.greys.borderGrey};
  color: ${({ theme }) => theme.palette.greys.darkestGrey};

  svg {
    fill: ${({ theme }) => theme.palette.greys.darkestGrey};
  }
`;

export const Button = styled.button`
  display: flex;
  height: 40px;
  border-radius: 50%;
  //background-color: ${({ theme }) => theme.palette.greys.lightLightGrey};
  color: ${({ theme }) => theme.palette.primary.black};
  cursor: pointer;
  transition: 300ms ease-in-out;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.blue};
  }
`;
