import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  balance: number;
  hashtag: string;
  className?: string;
}

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});
const formatBalance = (input: number) => {
  const formattedInput = formatter.format(Math.abs(input));
  if (input < 0) {
    return `€ -${formattedInput}`;
  } else {
    return `€ ${formattedInput}`;
  }
};
const Statistics = ({ balance, hashtag, className }: Props) => {
  return (
    <Container className={className}>
      <Headline>Most common hashtag spendings</Headline>
      <Balance>{formatBalance(balance)}</Balance>
      <Bottom>
        <Headline>Most common Hashtag:</Headline>
        <Hashtag>{hashtag}</Hashtag>
      </Bottom>
    </Container>
  );
};

export default Statistics;

const Container = styled.div`
  display: flex;
  min-width: 295px;
  width: 100%;
  height: 163px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
  gap: 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.palette.greys.lightLightGrey};
  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-width: 330px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    min-width: 515px;
    height: 141px;
  }
  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    min-width: initial;
    max-width: 397.5px;
  }
`;

const Headline = styled.div`
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  ${FontStyles.bodyMiddleGilroy};
`;

const Balance = styled.div`
  ${FontStyles.h4};
  color: ${(props) => props.theme.palette.primary.black};
  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 8px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: row;
    gap: 51px;
  }
  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    justify-content: space-between;
    gap: unset;
  }
`;

const Hashtag = styled.div`
  ${FontStyles.h5}
  color: #7D7FAD;
`;
