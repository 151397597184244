import { SvgIcon, SxProps, Theme } from '@mui/material';
import { DateValidationError, DateField as MUIXDateField } from '@mui/x-date-pickers';
import { DateFieldSlotProps } from '@mui/x-date-pickers/DateField/DateField.types';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import React from 'react';

import { IconCalendarNormal } from '@constants/icons';

interface Props {
  value: Date | null | undefined;
  onChange: (newValue: Date | null, error: FieldChangeHandlerContext<DateValidationError>) => void;
  format?: string;
  fullWidth?: boolean;
  slotProps?: DateFieldSlotProps<Date, false> | undefined;
  sx?: SxProps<Theme>;
}

const InputSxProps: SxProps<Theme> = {
  height: { xs: 50, lg: 40 },
  paddingRight: '20px',
  boxSizing: 'border-box',
  '&.Mui-focused': {
    color: (theme) => theme.legacy.palette.primary.blue,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: (theme) => `1px solid ${theme.legacy.palette.primary.blue}`,
  },
  '& .MuiOutlinedInput-input': {
    padding: { xs: '13.5px 0 13px 20px', lg: '8.5px 0 8px 20px' },
  },
};

const defaultSlotProps = {
  textField: {
    InputProps: {
      endAdornment: (
        <SvgIcon
          component={IconCalendarNormal}
          sx={{
            width: 16,
            height: 18,
          }}
          inheritViewBox
        />
      ),
      sx: InputSxProps,
    },
  },
};

const DateField = ({
  value,
  onChange,
  format = 'dd-MM-yyyy',
  fullWidth = true,
  slotProps,
  sx,
}: Props) => {
  return (
    <MUIXDateField
      value={value}
      format={format}
      onChange={onChange}
      fullWidth={fullWidth}
      slotProps={{ ...defaultSlotProps, ...slotProps }}
      sx={sx}
    />
  );
};

export default DateField;
