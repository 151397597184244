import React, { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useVerifyChallenge } from '@api/Challenges/challengesApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconPendingChallenge } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import ModalNew from '@components/Modal/ModalNew';
import ModalSubheadline from '@components/Modal/ModalSubheadline';
import SuccessError from '@components/transaction/TransactionModals/SuccessError';

import Toast from '@elements/Toast/Toast';

interface Props {
  challenge: string;
}

const TransactionOtpModal = ({ challenge }: Props) => {
  const [isOtpInputDisabled, setIsInputDisabled] = useState(false);

  const closeModal = useBoundStore((state) => state.closeModal);
  const openModal = useBoundStore((state) => state.openModal);

  const setResetForm = useBoundStore((state) => state.setResetForm);

  const otpInputRef = useRef<HTMLInputElement | null>(null);

  const { mutate: verifyChallengeMutation } = useVerifyChallenge();

  const errors = useMemo(
    () => ({
      409: () => {
        toast.error(
          <Toast
            title="Error"
            message="You have to complete the current challenge otherwise the transaction will not be executed."
          />
        );
      },
    }),
    []
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errors);

  const handleOTPSubmit = () => {
    if (otpInputRef.current && challenge !== '') {
      const verificationCode = otpInputRef.current.value;
      const verifyChallengeData: ChallengeVerificationRequest = {
        idempotency: crypto.randomUUID(),
        resourceType: 'outgoing_wire_transfers',
        scaChallengeId: challenge,
        verificationCode,
      };

      setIsInputDisabled(true);
      verifyChallengeMutation(verifyChallengeData, {
        onSuccess: () => {
          setResetForm(true);
          openModal(
            <SuccessError
              isError={false}
              message="Your transaction has been submitted and will be sent shortly."
            />,
            true
          );
        },
        onError: (error) => {
          handleHTTPErrors([error]);
          setResetForm(true);
          openModal(
            <SuccessError
              isError={true}
              message={error.message}
            />,
            true
          );
        },
      });
    }
  };

  return (
    <ModalNew>
      {/*<CloseIcon>*/}
      {/*  <IconHamburgerClose*/}
      {/*    onClick={() => {*/}
      {/*      closeModal();*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</CloseIcon>*/}
      <Content>
        <TopIcon>
          <IconPendingChallenge />
        </TopIcon>

        <CustomModalHeadline>SMS Authentication</CustomModalHeadline>
        <CustomModalSubheadline>
          Enter your Code key in the field below to proceed with the transaction
        </CustomModalSubheadline>
        <Label>Enter code below</Label>
        <OtpInputContainer>
          <OtpInputField
            ref={otpInputRef}
            maxLength={6}
            placeholder="Enter code"
            disabled={isOtpInputDisabled}
            onChange={() => {
              if (otpInputRef.current && otpInputRef.current.value.length === 6) {
                handleOTPSubmit();
              }
            }}
          />
        </OtpInputContainer>
        <SupportFeaturesContainer>
          <p>Didn&apos;t get one?</p>
          <ProblemRow>
            <ProblemLink
              onClick={() => {
                window.HubSpotConversations.widget.load();
                window.HubSpotConversations.widget.open();
                closeModal();
              }}
            >
              Contact Support
            </ProblemLink>
          </ProblemRow>
        </SupportFeaturesContainer>
      </Content>
    </ModalNew>
  );
};

export default TransactionOtpModal;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 24px;
`;

const TopIcon = styled.div`
  display: flex;
  height: 76px;
  width: 76px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.primary.blue};

  box-shadow:
    -3.8px -3.8px 9.5px 0 #fff,
    -5.067px -5.067px 12.667px 0 #fff,
    3.8px 3.8px 9.5px 0 rgba(123, 123, 138, 0.08),
    5.067px 5.067px 12.667px 0 rgba(174, 174, 192, 0.2);

  & > svg {
    height: 36px;
    width: 36px;
  }
`;

const CustomModalHeadline = styled.div`
  ${FontStyles.h4}
  text-align: center;
  margin-top: 34px;
`;

const CustomModalSubheadline = styled(ModalSubheadline)`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  max-width: 300px;
  margin-top: 18px;
  padding: 0;
  margin-bottom: 20px;
`;

const Label = styled.div`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin-bottom: 10px;
`;

const ProblemRow = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 5px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const ProblemLink = styled.button`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.blue};
  transition: 300ms ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};
  }

  &:disabled {
    transition: unset;
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    cursor: initial;
    &:hover {
      color: ${(props) => props.theme.palette.greys.darkestGrey};
    }
  }
`;

const OtpInputContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  transition: border ease-in-out 300ms;
  box-sizing: border-box;
  height: 70px;
  max-height: 70px;
  width: 100%;
  margin-bottom: 25px;

  &:focus-within {
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
  }
`;

const OtpInputField = styled.input`
  ${FontStyles.bodyMiddleGilroy};
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.primary.black};
  border: none;
  display: flex;
  align-items: center;
  flex: 1;
  transition: color ease-in-out 300ms;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  &:focus {
    color: ${(props) => props.theme.palette.primary.blue};
    outline: none;
  }

  &:disabled {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

const SupportFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 5px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin: 25px 0 30px;
`;
