import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconCheckPricing } from '@constants/icons';

interface Props {
  className?: string;
}

const CheckPricing = ({ className }: Props) => {
  return (
    <CustomLink
      to="/pricing"
      className={className}
    >
      Check Pricing
      <IconCheckPricing />
    </CustomLink>
  );
};

export default CheckPricing;

const CustomLink = styled(Link)`
  ${FontStyles.buttonGilroyType3};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.black};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
`;
