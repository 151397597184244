import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useCreateCorporate } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import Captcha from '@components/Captcha/Captcha';

import Toast from '@elements/Toast/Toast';
import ToggleSwitch from '@elements/ToggleSwitch/ToggleSwitch';

import {
  Container,
  CustomButton,
  CustomMarkdown,
  CustomOnboardingStepNavigation,
  Headline,
  Link,
  SwitchArea,
  SwitchText,
  TermBox,
} from './OnboardingTerms.styles';

const OnboardingTerms = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [terms, setTerms] = useState('');
  const legalEntity = 'Corporate';

  const [captchaValidationResult, setCaptchaValidationResult] = useState<CloudflareCaptcha>({
    captchaToken: null,
    idempotencyRef: null,
    isValid: false,
  });

  const setCorporateData = useBoundStore((state) => state.setCorporateData);
  const corporateData = useBoundStore((state) => state.corporateData);
  const resetCorporateData = useBoundStore((state) => state.resetCorporateData);
  const TERMS_AND_CONDITIONS_URL =
    process.env.REACT_APP_TERMS_LINK ??
    'http://localhost:8000/legal/corporate/terms-and-conditions/';
  const { mutate: registrationMutation } = useCreateCorporate(captchaValidationResult);

  const errorHandling = useMemo(
    () => ({
      403: () => {
        toast.error(
          <Toast
            title="Authentication Failed"
            message={t('403')}
          />
        );
      },
      409: (error: unknown) => {
        const { errorCode } = error as { errorCode: string };
        if (errorCode === 'ROOT_EMAIL_NOT_UNIQUE') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('rootEmailNotUnique')}
            />
          );
        } else if (errorCode === 'TERMS_NOT_ACCEPTED') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('termsNotAccepted')}
            />
          );
        } else if (errorCode === 'COUNTRY_INVALID' || errorCode === 'COUNTRY_UNSUPPORTED') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('countryInvalidOrUnsupported')}
            />
          );
        } else if (errorCode === 'MOBILE_OR_COUNTRY_CODE_INVALID') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('mobileOrCountryCodeInvalid')}
            />
          );
        } else if (errorCode === 'COMPANY_TYPE_UNSUPPORTED') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('companyTypeUnsupported')}
            />
          );
        } else if (errorCode === 'EMAIL_DOMAIN_NOT_ALLOWED') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('emailDomainNotAllowed')}
            />
          );
        } else if (errorCode === 'PROFILE_INACTIVE') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('profileInactive')}
            />
          );
        } else if (
          errorCode === 'PAYMENT_MODEL_CONSTRAINTS_VIOLATED' ||
          errorCode === 'PROFILE_NOT_FOUND'
        ) {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('paymentModelConstraintsViolatedOrProfileNotFound')}
            />
          );
        } else if (errorCode === 'ROOT_EMAIL_NOT_VERIFIED') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('rootEmailNotVerified')}
            />
          );
        } else if (errorCode === 'ROOT_USER_INACTIVE') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('rootUserInactive')}
            />
          );
        } else if (errorCode === 'KYC_APPROVAL_MISSING') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('kycApprovalMissing')}
            />
          );
        } else if (errorCode === 'PROFILE_MISMATCH') {
          toast.error(
            <Toast
              title="Registration Error"
              message={t('profileMismatch')}
            />
          );
        }
      },
    }),
    [t]
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errorHandling);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTerms = async () => {
      const res = await fetch(`/legal/${legalEntity}.md`, { signal });
      const md = await res.text();
      setTerms(md);
    };

    fetchTerms().catch((error) => {
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error(error);
      }
    });

    return () => {
      controller.abort();
    };
  }, [legalEntity]);

  const isAddressValid = (): boolean => {
    let result =
      corporateData.companyAddressLine1 !== '' &&
      corporateData.companyAddressLine1.length >= 5 &&
      corporateData.companyAddressLine1.length <= 150 &&
      corporateData.companyAddressCity !== '' &&
      corporateData.companyAddressCity.length >= 1 &&
      corporateData.companyAddressCity.length <= 50 &&
      corporateData.companyAddressZipCode !== '' &&
      corporateData.companyAddressZipCode.length >= 4 &&
      corporateData.companyAddressZipCode.length <= 10 &&
      corporateData.companyAddressCountry !== '' &&
      corporateData.companyAddressCountry.length >= 4;

    if (corporateData.companyAddressLine2) {
      result = result && corporateData.companyAddressLine2.length <= 150;
    }
    if (corporateData.companyAddressState) {
      result = result && corporateData.companyAddressState.length <= 50;
    }

    return result;
  };

  const handleSubmit = async () => {
    if (submitting) return;

    setSubmitting(true);

    toast.info(
      <Toast
        title="Please Wait"
        message="We’re validating your data"
      />,
      { autoClose: 2000, pauseOnFocusLoss: false }
    );

    if (isAddressValid()) {
      registrationMutation(corporateData, {
        onSuccess: (data) => {
          const simplifiedUser = {
            id: data.rootUser.id.id,
            email: data.rootUser.email,
          };
          const jsonString = JSON.stringify(simplifiedUser);
          const ENCODED_BASE64 = btoa(jsonString);
          resetCorporateData();
          navigate(`/register/6/${ENCODED_BASE64}`, {
            state: {
              from: location.pathname,
              corporate: data,
            },
          });
        },
        onError: (error) => {
          handleHTTPErrors([error]);
          setSubmitting(false);
        },
      });
    } else {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setCorporateData((prevState) => ({ ...prevState, termsAccepted }));
  }, [setCorporateData, termsAccepted]);

  return (
    <Container>
      <CustomOnboardingStepNavigation text="Previous Step" />
      <Headline>Terms & Conditions</Headline>
      <TermBox>
        <CustomMarkdown>{terms}</CustomMarkdown>
      </TermBox>
      <SwitchArea>
        <ToggleSwitch
          isActive={termsAccepted}
          setIsActive={setTermsAccepted}
        />
        <SwitchText>
          I hereby agree to the
          <Link
            href={TERMS_AND_CONDITIONS_URL}
            target="_blank"
          >
            &nbsp;Terms and Conditions
          </Link>
        </SwitchText>
      </SwitchArea>
      <StyledCaptcha onValidationSuccess={setCaptchaValidationResult} />
      <CustomButton
        disabled={!termsAccepted || submitting || !captchaValidationResult.isValid}
        onClick={async () => await handleSubmit()}
        text="Proceed"
        variant="blueWhite"
      />
    </Container>
  );
};

export default OnboardingTerms;

const StyledCaptcha = styled(Captcha)`
  padding-bottom: 25px;
`;
