import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { IconCompanyDetails, IconLogSession, IconUserDetails } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';
import useOnboardingSteps from '@hooks/useOnboardingSteps';

import SettingsInfo from '@components/Settings/SettingsInfo/SettingsInfo';
import SettingsKYC from '@components/Settings/SettingsKYC';
import SettingsOption from '@components/Settings/SettingsOption/SettingsOption';

const SettingsNavigation = () => {
  const theme = useTheme();
  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);
  const { isKYBApprovedStatus, userHasManagedAccounts } = useOnboardingSteps();

  const setSettingsIndex = useBoundStore((state) => state.setSettingsIndex);
  const settingsIndex = useBoundStore((state) => state.settingsIndex);

  return (
    <Container $verifiedKYC={isKYBApprovedStatus}>
      <SettingsInfo />
      {(!isKYBApprovedStatus || !userHasManagedAccounts) && !isLaptop && <SettingsKYC />}
      <ScrollContainer>
        <OptionList>
          <SettingsOption
            icon={<IconUserDetails />}
            text="User Details"
            to=""
            className={settingsIndex === 0 ? 'selected' : ''}
            onClick={() => setSettingsIndex(0)}
          />
          <SettingsOption
            icon={<IconCompanyDetails />}
            text="Company Details"
            to=""
            className={settingsIndex === 1 ? 'selected' : ''}
            onClick={() => setSettingsIndex(1)}
          />
          <SettingsOption
            icon={<IconLogSession />}
            text="Session Logs"
            to=""
            className={settingsIndex === 2 ? 'selected' : ''}
            onClick={() => setSettingsIndex(2)}
          />
          {/* TODO: uncomment after MVP
          {fullAccessUser && (
            <SettingsOption
              icon={<IconAccountManagement />}
              text="Account Management"
              to="access"
            />
          )}
          */}
        </OptionList>
      </ScrollContainer>
      {(!isKYBApprovedStatus || !userHasManagedAccounts) && isLaptop && <SettingsKYC />}
    </Container>
  );
};

export default SettingsNavigation;

const Container = styled.div<{ $verifiedKYC: VerifiedKYCType }>`
  display: flex;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => (props.$verifiedKYC ? 25 : 20)}px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: initial;
    background-color: ${(props) => props.theme.palette.primary.white};
    max-height: calc(100vh - 140px);
    max-width: 500px;
    min-height: 882px;

    & > :nth-child(2) {
      margin-top: initial;
    }
  }
`;

const ScrollContainer = styled.div`
  overflow: scroll hidden;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  ${HideScrollbar};
  border-top: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    justify-content: center;
    align-items: center;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    border: none;
    align-items: start;
    height: 100%;
  }
`;

const OptionList = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 35px;
  padding: 15px 20px;
  flex-shrink: 0;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    border: none;
    overflow: initial;
    padding: 30px 35px;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
`;
