import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useBoundStore } from '@stores/BoundStore';

import { FormValidation } from '@shared/formValidation';

import InputPhonePrefix from '@elements/input/InputPhonePrefix/InputPhonePrefix';

import {
  ButtonRow,
  Container,
  CustomIconOnboardingCheck,
  CustomLargeButton,
  CustomRadioGroup,
  CustomStepNavigation,
  CustomTextInput,
  ErrorContainer,
  InputRow,
  Label,
  MobilePhoneRow,
  RadioInputRow,
  RadioLabel,
} from './OnboardingPersonalInformationForm.styles';

/*const MIN_USER_AGE = 18;*/

const options = [
  { name: 'position', label: 'Director', value: 'DIRECTOR' },
  { name: 'position', label: 'Authorised Representative', value: 'AUTHORISED_REPRESENTATIVE' },
];

// ToDo: Enable date of birth for consumer. Keep disabled for corporate

const OnboardingPersonalInformationForm = () => {
  /*const openModal = useBoundStore((state) => state.openModal);*/
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) navigate('/register');
    if (!location.state.from || location.state.from !== '/register') navigate('/register');
  }, [location.state, navigate]);

  const setCorporateData = useBoundStore((state) => state.setCorporateData);
  const corporateData = useBoundStore((state) => state.corporateData);

  const [phonePrefixValid, setPhonePrefixValid] = useState<boolean>(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(true);
  /*const [dateOfBirthValid, setDateOfBirthValid] = useState<boolean>(true);*/
  const [phoneNumberTouched, setPhoneNumberTouched] = useState<boolean>(false);
  /*const [opened, setOpened] = useState<boolean>(false);*/

  const prefixInputRef = useRef<HTMLInputElement>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);

  const handleNextField = (currentRef: React.RefObject<HTMLInputElement>) => {
    if (currentRef.current) {
      currentRef.current.focus();
    }
  };

  const handleChange = (name: string, value: string) => {
    setCorporateData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    setPhoneNumberValid(
      FormValidation.RegEx.PHONE_NUMBER.test(corporateData.phone) && corporateData.phone !== ''
    );
    /*setDateOfBirthValid(
      opened && FormValidation.getAge(corporateData.dateOfBirth.toISOString()) >= MIN_USER_AGE
    );*/

    setPhonePrefixValid(
      /^\+[0-9]+$/.test(corporateData.phonePrefix) && corporateData.phonePrefix !== ''
    );

    if (
      /*dateOfBirthValid &&
      opened &&
      corporateData.dateOfBirth &&*/
      phonePrefixValid &&
      corporateData.phonePrefix !== '' &&
      phoneNumberValid &&
      corporateData.phone !== ''
    ) {
      navigate('/register/3', {
        state: {
          from: location.pathname,
        },
      });
    }
  };

  useEffect(() => {
    if (phoneNumberTouched) {
      setPhoneNumberValid(
        FormValidation.RegEx.PHONE_NUMBER.test(corporateData.phone) && corporateData.phone !== ''
      );
    }
    /*if (opened)
      setDateOfBirthValid(
        FormValidation.getAge(corporateData.dateOfBirth.toISOString()) >= MIN_USER_AGE
      );*/
  }, [corporateData.phone, phoneNumberTouched]);

  return (
    <Container>
      <InputRow>
        <CustomStepNavigation text="Previous Step" />
      </InputRow>
      <InputRow>
        <Label>Enter your Mobile number</Label>
        <MobilePhoneRow>
          <InputPhonePrefix
            innerRef={prefixInputRef}
            onClick={() => handleNextField(numberInputRef)}
            onChange={(event) => handleChange('phonePrefix', event.target.value)}
            setPrefixValidated={setPhonePrefixValid}
          />
          <CustomTextInput
            ref={numberInputRef}
            autoComplete="do-not-autofill"
            value={corporateData.phone}
            placeholder="123456789"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPhoneNumberTouched(true);
              handleChange('phone', event.target.value);
            }}
            icon={corporateData.phone && phoneNumberValid && <CustomIconOnboardingCheck />}
            valid={phoneNumberValid || !corporateData.phone}
            blurHandler={() =>
              setPhoneNumberValid(
                FormValidation.RegEx.PHONE_NUMBER.test(corporateData.phone) &&
                  corporateData.phone !== ''
              )
            }
          />
        </MobilePhoneRow>
        <ErrorContainer $visible={!phoneNumberValid}>
          Please enter a valid phone number
        </ErrorContainer>
      </InputRow>
      <RadioInputRow>
        <RadioLabel>Company Position</RadioLabel>
        <CustomRadioGroup
          options={options}
          onChange={(event) => handleChange('role', event.target.value)}
          column={false}
          onboarding
        />
      </RadioInputRow>
      {/*
      <InputRow>
        <DateOfBirthLabel>Date of Birth</DateOfBirthLabel>
        <DatepickerInput
          $opened={opened}
          onClick={() => {
            setOpened(true);
            openModal(<OnboardingDatepickerModal tabIndex={1} />);
          }}
        >
          {opened ? formatDateObject(corporateData.dateOfBirth) : 'dd-mm-yyyy'}
          <IconCalendarNormal />
        </DatepickerInput>
        <EmailErrorMessage visible={!dateOfBirthValid}>
          You have to be 18 or older to use this App.
        </EmailErrorMessage>
      </InputRow>
      */}
      <ButtonRow>
        <CustomLargeButton
          onClick={() => handleSubmit()}
          text="Proceed"
          variant="blueWhite"
        />
      </ButtonRow>
    </Container>
  );
};

export default OnboardingPersonalInformationForm;
