import styled, { css } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { LogoMasterCard } from '@constants/logos';

import CardSetup from '@components/CardSetup/CardSetup';

import PopOver from '@elements/PopOver/PopOver';

interface Props {
  cardNumber: string;
  companyName: string;
  cvv: number;
  validThrough: string;
  status: 'Active' | 'Inactive';
}

const maskCardNumber = (cardNumber: string, hidden: boolean) => {
  if (hidden) {
    return cardNumber.slice(0, -4) + cardNumber.slice(-4).replace(/\d/g, '*');
  }
  return cardNumber;
};
const VirtualCard = ({ cardNumber, cvv, validThrough, status }: Props) => {
  const hidden = useBoundStore((state) => state.hidden);
  const openModal = useBoundStore((state) => state.openModal);

  return (
    <Container $status={status}>
      {status === 'Active' ? (
        <>
          <Headline>Virtual Debit Card</Headline>
          <Group>
            <div style={{ padding: '12px 0 8px 0' }}>{maskCardNumber(cardNumber, hidden)}</div>
            <CustomPopOver copyValue={cardNumber} />
          </Group>
          <Group>
            <div style={{ padding: '12px 0 8px 0' }}>CVV</div>
            <CustomPopOver copyValue={cvv.toString()} />
          </Group>
          <Group>
            <div style={{ padding: '12px 0 8px 0' }}>{validThrough}</div>
            <CustomPopOver copyValue={validThrough} />
          </Group>
        </>
      ) : (
        <button onClick={() => openModal(<CardSetup />)}>Order Virtual Card Now.</button>
      )}
      <LogoMasterCard style={{ height: '25px', width: '40px' }} />
    </Container>
  );
};

export default VirtualCard;

const Container = styled.div<{ $status: 'Active' | 'Inactive' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  box-sizing: border-box;
  flex: 1;
  padding: 0 10px 0 15.87px;
  transition: 300ms ease-in-out;
  background: ${(props) => props.theme.palette.gradients.primaryBlackGradient};
  color: ${(props) => props.theme.palette.primary.white};
  ${FontStyles.h5};

  ${(props) =>
    props.$status === 'Active'
      ? css`
          width: 626px;
        `
      : css`
          max-width: 626px;
        `}

  &:hover {
    background: ${(props) => props.theme.palette.gradients.primaryBlueGradient};
    cursor: pointer;
  }
`;

const Headline = styled.div`
  color: ${(props) => props.theme.palette.greys.darkGrey};
  padding: 12px 0 8px 0;
  ${FontStyles.h7};
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 5px;
  color: ${(props) => props.theme.palette.primary.white};
  ${FontStyles.h5}
`;

const CustomPopOver = styled(PopOver)`
  margin-left: 8px;
  padding: 12px 0 8px 0;
`;
