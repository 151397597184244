import { useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useState } from 'react';

import { useDeleteIban } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { IconDelete } from '@constants/icons';

import {
  Bottom,
  Button,
  Container,
  Text,
  TextVariation,
  Top,
} from '@components/IbanInputAutocompleteItem/IbanInputAutocompleteItem.styles';

interface Props {
  iban: string;
  bankName: string;
  setValue: (value: string) => void;
  setShowDropDown: Dispatch<SetStateAction<boolean>>;
}

const IbanInputAutocompleteItem = ({ iban, bankName, setValue, setShowDropDown }: Props) => {
  const queryClient = useQueryClient();

  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { mutate: deleteIban } = useDeleteIban();

  const handleDeleteIbanClick = () => {
    if (submitting) return;

    setSubmitting(true);

    deleteIban(iban, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['contactData'],
          refetchType: 'active',
        });
        if (selectedContact && selectedContact.bankDetails) {
          const updatedBankDetails = selectedContact.bankDetails.filter(
            (detail) => detail.iban !== iban
          );
          setSelectedContact({
            ...selectedContact,
            bankDetails: updatedBankDetails,
          });
        }
        setSubmitting(false);
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <Container>
      <Top>
        <Button
          type="button"
          onClick={() => {
            setShowDropDown(false);
            setValue(iban);
          }}
        >
          <Text>{iban}</Text>
        </Button>
        <Button
          disabled={submitting}
          type="button"
          onClick={handleDeleteIbanClick}
          style={{ height: '20px' }}
        >
          <IconDelete />
        </Button>
      </Top>
      <Bottom>
        <Text>Bank name:</Text> <TextVariation>{bankName}</TextVariation>
      </Bottom>
    </Container>
  );
};

export default IbanInputAutocompleteItem;
