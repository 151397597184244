import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';

const TimeframeSelector = () => {
  const timeframes: { timeframe: string; date: Date }[] = [
    { timeframe: '1D', date: new Date() },
    { timeframe: '1W', date: new Date(new Date().setDate(new Date().getDate() - 7)) },
    { timeframe: '2W', date: new Date(new Date().setDate(new Date().getDate() - 14)) },
    { timeframe: '1M', date: new Date(new Date().setMonth(new Date().getMonth() - 1)) },
    { timeframe: '3M', date: new Date(new Date().setMonth(new Date().getMonth() - 3)) },
    { timeframe: '6M', date: new Date(new Date().setMonth(new Date().getMonth() - 6)) },
    { timeframe: '1Y', date: new Date(new Date().setMonth(new Date().getMonth() - 12)) },
    { timeframe: 'All', date: new Date(2023, 0, 1) },
  ];

  const setTimespan = useBoundStore((state) => state.setTimespan);
  const timespan = useBoundStore((state) => state.timespan);
  return (
    <Container>
      {Object.values(timeframes).map(({ timeframe, date }) => (
        <Timeframe
          key={timeframe}
          onClick={() => {
            setTimespan({ start: date, end: new Date() });
          }}
          $isSelected={timespan.start.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)}
        >
          {timeframe}
        </Timeframe>
      ))}
    </Container>
  );
};

export default TimeframeSelector;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  box-sizing: border-box;
  background: white;
  border-radius: 25px;
  padding: 0 10px 0 10px;
  @media (${(props) => props.theme.breakpoints.tablet}) {}
  }
`;

const Timeframe = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: 300ms ease-in-out;
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) =>
    props.$isSelected ? props.theme.palette.primary.blue : props.theme.palette.greys.darkestGrey};
  &:hover {
    cursor: pointer;
    color: ${(props) =>
      props.$isSelected ? props.theme.palette.primary.blue : props.theme.palette.primary.black};
  }
`;
