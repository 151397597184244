import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1 0;
  max-width: 1440px;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 30px;
    padding: 0 24px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 30px;
    padding: 0 40px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    min-width: 1432px;
    gap: 40px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  margin-top: 10px;
  min-width: 337px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 15px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-top: 5px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    flex-direction: initial;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    gap: 40px;
  }
`;
