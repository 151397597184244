import styled, { useTheme } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconLogSessionControl, IconLogSessionControlGray } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import SettingsLog from '@components/Settings/SettingsLog/SettingsLog';

const SettingsLogSession = () => {
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  const titleBarText = !isLaptop ? ' Session Logs' : 'Session Logs';

  return (
    <Container>
      <TitleBar>
        <IconLogSessionControlMobile />
        {titleBarText}
      </TitleBar>
      <Content>
        <SupportContainer>
          <SupportOverviewContainer>
            <IconLogSessionControlGray /> Overview
          </SupportOverviewContainer>
          <RequestButton onClick={() => undefined}>Contact Support</RequestButton>
        </SupportContainer>
        <SettingsLog />
      </Content>
    </Container>
  );
};
export default SettingsLogSession;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  max-width: calc(100vw - 40px);
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: calc(100vw - 48px);
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 30px;
    max-height: 882px;
    max-width: calc(100vw - 500px);
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    max-width: calc(100vw - 600px);
  }
`;

export const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 20px;
  flex-direction: column;
  padding: 0 20px;
  overflow: hidden;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 35px 35px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 20px 20px 0;
  ${FontStyles.h5};
  gap: 10px;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    background: linear-gradient(262deg, #e8e2db 8.5%, #d6dbf3 101.62%);
    color: ${(props) => props.theme.palette.primary.black};
    ${FontStyles.h4};
    padding: 30px 35px;
    border-radius: 20px;
    gap: initial;
    justify-content: space-between;
  }
`;

const IconLogSessionControlMobile = styled(IconLogSessionControl)`
  display: initial;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

const SupportContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.asphaltTint};
  padding-bottom: 20px;
`;

const RequestButton = styled.button`
  display: flex;
  box-sizing: border-box;
  transition: 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.black};
  padding: 8px 20px;
  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  ${FontStyles.bodySmallGilroy};
  justify-content: center;
  cursor: pointer;
  align-items: center;
  max-width: 148px;
  white-space: nowrap;

  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.palette.primary.black};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: initial;
  }
`;

const SupportOverviewContainer = styled.div`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
    gap: 10px;
    ${FontStyles.h4};
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    align-items: center;
  }
`;
