import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconAddDoc, IconUploadFile } from '@constants/icons';

import InfoBubble from '@elements/InfoBubble/InfoBubble';

interface Props {
  file?: File;
}

const SupportingDocument = ({ file }: Props) => {
  const [documentAvailable, setDocumentAvailable] = useState(false);
  const [fileName, setFileName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (file) {
      setDocumentAvailable(true);
      setFileName(file.name);
    }
  }, [file]);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFileName(event.target.files[0].name);
      setDocumentAvailable(true);
      file = event.target.files[0];
    }
  };

  return (
    <Container>
      <ContentLeft>
        {t('supporting_document')} <InfoBubble text="Info goes here" />
      </ContentLeft>
      <Input
        htmlFor="file"
        $file={documentAvailable}
      >
        <input
          type="file"
          id="file"
          hidden={true}
          onChange={(event) => handleUpload(event)}
        />
        {documentAvailable
          ? fileName.length > 10
            ? fileName.slice(0, 10) + '...'
            : fileName
          : t('add_document')}
        {documentAvailable ? <IconUploadFile /> : <IconAddDoc />}
      </Input>
    </Container>
  );
};

export default SupportingDocument;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom: 1px solid #c7aee7;
  box-sizing: border-box;
  gap: 10px;
  padding-bottom: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 12px 0 17px 0;
    gap: unset;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ContentLeft = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.greys.darkestGrey};

  & > :last-child {
    color: ${(props) => props.theme.palette.greys.darkGrey};
  }
`;

const Input = styled.label<{ $file: boolean }>`
  justify-content: start;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) =>
    props.$file ? props.theme.palette.primary.black : props.theme.palette.greys.darkGrey};
  align-items: center;
  display: flex;
  gap: 10px;
  transition: 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.black};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    justify-content: start;
  }
`;
