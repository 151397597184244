import { InputLabel, MenuItem, MenuProps as MuiMenuProps, OutlinedInput } from '@mui/material';
import React, { ComponentType } from 'react';

import { IconArrowFilledDown } from '@constants/icons';

import { DropdownProps } from './Dropdown.types';
import { Placeholder, StyledFormControl, StyledMenu, StyledSelect } from './DropdownStyles';

const Dropdown = ({
  label,
  options,
  icon,
  selected,
  onChange,
  optionsHeader,
  placeholder,
}: DropdownProps) => {
  return (
    <StyledFormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        {icon && <span className="left-icon">{icon}</span>}
        {label}
      </InputLabel>
      <StyledSelect
        label={label}
        displayEmpty
        value={selected}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        IconComponent={IconArrowFilledDown}
        renderValue={(selected: any) => {
          if (!selected) {
            return <em>{placeholder}</em>;
          }
          return <span>{selected}</span>;
        }}
        MenuProps={{
          PaperProps: {
            component: StyledMenu as ComponentType<MuiMenuProps>,
          },
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {optionsHeader && (
          <MenuItem
            disabled
            value="header"
          >
            <Placeholder>{optionsHeader}</Placeholder>
          </MenuItem>
        )}
        {options?.map(({ title, value, icon, onClick }) => (
          <MenuItem
            className="dropdown-item"
            onClick={onClick}
            key={value}
            value={value}
          >
            {icon}
            {title}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default Dropdown;
