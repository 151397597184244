import React from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  className?: string;
}

const PasswordInfoBlock = ({ className }: Props) => {
  return (
    <Container className={className}>
      <PasswordInfoBlockHeading>Password must contain:</PasswordInfoBlockHeading>
      <UnorderedList>
        {[
          'Letters (8-30)',
          'Lowercase character',
          'Uppercase character',
          'Digit and a Special character',
        ].map((item, index) => (
          <ListElement key={index}>{item}</ListElement>
        ))}
      </UnorderedList>
    </Container>
  );
};

export default PasswordInfoBlock;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
  padding: 10px;
  box-sizing: border-box;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  height: 135px;
  justify-content: start;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 364px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    width: 381px;
  }
`;

const PasswordInfoBlockHeading = styled.p`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const UnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ListElement = styled.li`
  ${FontStyles.bodySmallGilroy};
  gap: 5px;
  list-style: inside;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  &::marker {
    content: '- ';
  }
`;
