import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useVerifyCorporateEmail } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import Toast from '@elements/Toast/Toast';

interface Props {
  verify?: boolean;
}

const OnboardingRedirect = ({ verify }: Props) => {
  const isLoggedIn = useBoundStore((state) => state.isLoggedIn);

  const navigate = useNavigate();

  const { mutate: verifyEmailMutation } = useVerifyCorporateEmail();

  useEffect(() => {
    const controller = new AbortController();

    if (verify) {
      const queryParams = new URLSearchParams(location.search);
      const email = queryParams.get('email');
      const verificationCode = queryParams.get('nonce');

      if (email && verificationCode) {
        verifyEmailMutation(
          { email, verificationCode, signal: controller.signal },
          {
            onSuccess: () => {
              toast.success(
                <Toast
                  title="Email verified"
                  message="Your Email has been verified. Thank you!"
                />
              );
              if (isLoggedIn) {
                navigate('/', { replace: true });
              } else {
                navigate('/login', { replace: true });
              }
            },
            onError: (error) => {
              if (error instanceof AxiosError && error.response) {
                if (error.response.status === 409) {
                  const { errorCode } = error.response.data;
                  toast.error(
                    <Toast
                      title="Verification Error"
                      message={
                        errorCode === 'EMAIL_NOT_UNIQUE'
                          ? 'You previously used this email. Please use another one.'
                          : errorCode === 'VERIFICATION_CODE_INVALID'
                            ? 'Your verification code is invalid. Please double-check and try again.'
                            : 'Something went wrong please try again.'
                      }
                    />
                  );
                }
                if (isLoggedIn) {
                  navigate('/', { replace: true });
                } else {
                  navigate('/login', { replace: true });
                }
              } else {
                console.error('Error: ', error.message);
              }
            },
          }
        );
      }
    } else {
      navigate('/login');
    }
    return () => {
      controller.abort();
    };
  }, [isLoggedIn, navigate, verify, verifyEmailMutation]);

  return null;
};

export default OnboardingRedirect;
