import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { IconArrowLeft } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import OnboardingPictureArea from '@components/OnboardingPictureArea/OnboardingPictureArea';
import { renderOnboardingStep } from '@components/OnboardingStep/OnboardingStep';
import OnboardingTopBar from '@components/OnboardingTopBar/OnboardingTopBar';

import { Container, ContentContainer, ReturnButton } from './Onboarding.styles';

interface Props {
  type: 'register' | 'login' | 'restore' | 'sms' | 'email' | 'change';
}

const extractStep = (path: string) => {
  return location.pathname === `/${path}` || location.pathname === `/${path}/`
    ? '1'
    : location.pathname.replace(`/${path}/`, '');
};

const Onboarding = ({ type }: Props) => {
  const navigate = useNavigate();
  const registerStep = extractStep('register');
  const restoreStep = extractStep('restore');

  const isLaptop = useMediaQuery('(min-width: 1200px)');

  return (
    <Container $sms={type === 'sms'}>
      <OnboardingTopBar variant={type} />
      <ReturnButton
        $sms={type === 'sms'}
        onClick={() => {
          if (type === 'sms') {
            navigate('/login');
          }
        }}
      >
        <p>{type === 'sms' && !isLaptop && 'Return to login'}</p>
        <div>{type === 'sms' && !isLaptop && <IconArrowLeft />}</div>
      </ReturnButton>
      <ContentContainer>
        <OnboardingPictureArea
          type={type}
          version={restoreStep}
        />
        {renderOnboardingStep(type, registerStep)}
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default Onboarding;
