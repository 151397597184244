import { StateCreator } from 'zustand';

export interface PinTimerSlice {
  wrongField: boolean;
  setWrongField: (value: boolean) => void;
  wrongPin: boolean;
  setWrongPin: (value: boolean) => void;
  attempts: number;
  setAttempts: (value: number) => void;
  timer: boolean;
  setTimer: (value: boolean) => void;
  timeLeft: number;
  setTimeLeft: (value: number) => void;
  hidden: boolean;
  setHidden: (value: boolean) => void;
  enter: boolean;
  setEnter: (value: boolean) => void;
}

export const createPinTimerSlice: StateCreator<PinTimerSlice> = (set) => ({
  wrongField: false,
  setWrongField: (value) => set({ wrongField: value }),
  wrongPin: false,
  setWrongPin: (value) => set({ wrongPin: value }),
  attempts: 3,
  setAttempts: (value) => set({ attempts: value }),
  timer: false,
  setTimer: (value) => set({ timer: value }),
  timeLeft: 300,
  setTimeLeft: (value) => set({ timeLeft: value }),
  hidden: true,
  setHidden: (value) => set({ hidden: value }),
  enter: false,
  setEnter: (value) => set({ enter: value }),
});

/*
  useEffect(() => {
    if (timer && timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (timer && timeLeft <= 0) {
      setTimer(false);
      setAttempts(3);
      setWrongPin(false);
      setWrongField(false);
      setHidden(true);
      setTimeLeft(3);
      setEnter(false);
    }
  }, [timeLeft, timer]);
 */
