import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconOnboardingCheck } from '@constants/icons';

import Captcha from '@components/Captcha/Captcha';
import PeanudsLogo from '@components/PeanudsLogo/PeanudsLogo';

import LargeButton from '@elements/LargeButton/LargeButton';
import Input from '@elements/input/Input/Input';

export const WeavrPassword = styled.div<{ $focused: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  transition: border ease-in-out 300ms;
  height: 50px;
  max-height: 50px;
  box-sizing: border-box;

  ${({ $focused }) =>
    $focused &&
    css`
      border: 1px solid ${(props) => props.theme.palette.primary.blue};
    `}

  & > * {
    width: 100%;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    max-height: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 50px;
    max-height: 50px;
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 22.5px;
  width: 100%;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 79px 79px;
    height: unset;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: unset;
  }
`;

export const CustomIconOnboardingCheck = styled(IconOnboardingCheck)`
  width: 17px;
  height: 15px;
`;

export const HeadingRow = styled.div`
  display: flex;
  ${FontStyles.h3};
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 26px;

  @media (${(props) => props.theme.breakpoints.mobile}) {
    gap: 20px;
    margin-top: 85px;
    margin-bottom: 60px;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 20px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    padding: 0 25px 0 60px;
    margin-bottom: 45px;
    margin-top: unset;
    align-items: start;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h3};
    padding: 0 55px 0 95px;
    margin-bottom: 51px;
  }
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  ${FontStyles.bodyMiddleGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

export const OnboardingLogo = styled(PeanudsLogo)`
  position: relative;
  z-index: 1;
  margin-left: auto;
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 79px;
    display: initial;
    width: 90px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-bottom: 117px;
    width: 110px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 25px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 0 55px 0 95px;
  }
`;

export const Label = styled.div`
  ${FontStyles.h5};
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const FooterRow = styled.div`
  display: flex;
  gap: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 85px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 25px 0 60px;
    flex-direction: row;
    margin-top: 48px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 0 55px 0 95px;
    margin-top: 48px;
  }
`;

export const NoAccountTextContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: column;
    align-items: initial;
  }
`;

export const NoAccountText = styled.p`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const RegisterLink = styled(NavLink)`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.blue};
  text-decoration: none;
  transition: color 300ms ease-in-out;

  &:hover,
  &:focus {
    border: none;
    outline: none;
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export const EmailErrorMessage = styled.div<{ $visible: boolean }>`
  box-sizing: border-box;
  ${FontStyles.bodySmallGilroy};
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  justify-content: end;
  height: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 20px;
  }
`;

export const CustomInput = styled(Input)`
  overflow: hidden;
  height: 50px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 50px;
  }
`;

export const CustomButton = styled(LargeButton)`
  max-height: 50px;
  transition: background 300ms ease-in-out;
  order: -1;
  min-width: 205px;
  width: 205px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-height: 40px;
    order: unset;
    width: 182px;
    min-width: 182px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-height: 50px;
    width: 165px;
    min-width: 165px;
  }
`;

export const ForgotPW = styled.button`
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  text-decoration: underline;
  cursor: pointer;
  align-self: end;
  ${FontStyles.bodyMiddleGilroy};
  transition: color 300ms ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: unset;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

export const PasswordRow = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const CustomCaptcha = styled(Captcha)`
  padding: 30px 0;
`;
