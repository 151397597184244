import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconArrowDown } from '@constants/icons';

interface Props {
  options: SelectOption[];
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

const SelectBox = ({ options, value, setValue }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleClick = (input: string) => {
    setValue(input);
    setIsVisible(false);
  };

  const handleVisibleChange = (value: boolean) => {
    setIsVisible(value);
  };

  return (
    <Container onMouseOut={() => handleVisibleChange(false)}>
      <Selection
        tabIndex={0}
        onFocus={() => handleVisibleChange(true)}
        onMouseOver={() => handleVisibleChange(true)}
      >
        <Trans t={t}>{value}</Trans>
        <Icon $isVisible={isVisible} />
      </Selection>
      <DropDown
        $isVisible={isVisible}
        tabIndex={0}
        onFocus={() => handleVisibleChange(true)}
        onBlur={() => handleVisibleChange(false)}
        onMouseOver={() => handleVisibleChange(true)}
      >
        {options.map((option, index) => {
          return (
            <Option
              tabIndex={0}
              key={index}
              className={value === option.value ? 'active' : ''}
              onClick={() => handleClick(option.value)}
            >
              {option.text}
            </Option>
          );
        })}
      </DropDown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const Selection = styled.div`
  ${FontStyles.bodySmallGilroy};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 20px 11px 15px;
  border-radius: 40px;
  border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  transition: ease-in-out 300ms;
  gap: 10px;

  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

const DropDown = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  top: 42px;
  display: ${(props) => (props.$isVisible ? 'inline-block' : 'none')};
  padding: 20px 18px;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  border-radius: 10px;
  filter: drop-shadow(0 2px 14px rgba(0, 0, 0, 0.07));
  transition: all 300ms;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
`;

const Option = styled.button`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  width: 100%;

  &:not(:first-child) {
    margin-top: 15px;
  }

  &:focus,
  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }

  &.active {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

const Icon = styled(IconArrowDown)<{ $isVisible: boolean }>`
  transition: ease-in-out 300ms;
  transform: ${(props) => props.$isVisible && 'scaleY(-1)'};
`;

export default SelectBox;
