import styled from 'styled-components';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { LogoEurope } from '@constants/logos';

interface Props {
  balance: number;
}

const BalanceBox = ({ balance }: Props) => {
  return (
    <Container>
      <ContentContainer>
        <Title>Your EUR Balance</Title>
        <Balance>{Commons.currencyFormatter(balance)}</Balance>
      </ContentContainer>
      <LogoEurope />
    </Container>
  );
};

export default BalanceBox;

const Container = styled.div`
  display: inline-flex;
  background: ${(props) => props.theme.palette.gradients.stoneGreyBlueMix};
  border-radius: 15px;
  gap: 55px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.primary.white};
  min-width: 275px;
  min-height: 55px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  ${FontStyles.buttonGilroyType3};
`;

const Balance = styled.span`
  ${FontStyles.h4};
  width: auto;
`;
