import { FormControl, Select } from '@mui/material';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const StyledFormControl = styled(FormControl)`
  margin: 1rem;
  min-width: 120px;
`;

export const StyledSelect = styled(Select)`
  border-radius: 10px !important;
  height: 50px;
  align-items: center;
  padding: 15px 10px;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) =>
    props.value ? props.theme.palette.primary.black : props.theme.palette.greys.darkestGrey};
  ${FontStyles.bodyMiddleGilroy};
  cursor: pointer;
  outline: 1px transparent solid;
  box-shadow: none;

  &.Mui-focused {
    color: ${(props) => props.theme.palette.primary.blue};

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${(props) => props.theme.palette.primary.blue} !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    top: -9px;
    border: none !important;
  }

  .MuiSelect-icon {
    right: 19px;
    top: calc(50% - 0.1em);
  }

  &:focus {
    outline: 1px solid ${(props) => props.theme.palette.primary.blue};

    & > :last-child {
      transition: 300ms ease-in-out;
      transform: rotate(-180deg);
      color: ${(props) => props.theme.palette.primary.blue};
    }
  }

  em {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }

  span,
  em {
    width: 100%;
    ${FontStyles.bodyMiddleGilroy};

    @media (${(props) => props.theme.breakpoints.laptop}) {
      height: 40px;
      ${FontStyles.bodySmallGilroy};
    }

    @media (${(props) => props.theme.breakpoints.desktop}) {
      ${FontStyles.bodyMiddleGilroy};

      height: 50px;
    }
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    ${FontStyles.bodySmallGilroy};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 40px;
    ${FontStyles.bodyMiddleGilroy};
  }
`;

export const Placeholder = styled('em')`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const StyledMenu = styled('div')`
  .MuiMenu-paper {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
  }

  &.MuiPaper-root {
    max-height: 295px;
    box-shadow: 2px 7px 77px 0px #0000001a;
    border-radius: 15px !important;
  }

  .MuiMenuItem-root {
    ${FontStyles.bodyMiddleGilroy};

    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.palette.primary.blue};
    }

    &.Mui-selected {
      background-color: rgba(25, 118, 210, 0.08);
    }
  }
`;
