export const countryPhonePrefixes = [
  {
    code: 'AT',
    mobileCode: '+43',
    countryName: 'Austria',
  },
  {
    code: 'BE',
    mobileCode: '+32',
    countryName: 'Belgium',
  },
  {
    code: 'HR',
    mobileCode: '+385',
    countryName: 'Croatia',
  },
  {
    code: 'CY',
    mobileCode: '+357',
    countryName: 'Cyprus',
  },
  {
    code: 'CZ',
    mobileCode: '+420',
    countryName: 'Czech Republic',
  },
  {
    code: 'DK',
    mobileCode: '+45',
    countryName: 'Denmark',
  },
  {
    code: 'EE',
    mobileCode: '+372',
    countryName: 'Estonia',
  },
  {
    code: 'FI',
    mobileCode: '+358',
    countryName: 'Finland',
  },
  {
    code: 'FR',
    mobileCode: '+33',
    countryName: 'France',
  },
  {
    code: 'GE',
    mobileCode: '+995',
    countryName: 'Georgia',
  },
  {
    code: 'DE',
    mobileCode: '+49',
    countryName: 'Germany',
  },
  {
    code: 'GR',
    mobileCode: '+30',
    countryName: 'Greece',
  },
  {
    code: 'HU',
    mobileCode: '+36',
    countryName: 'Hungary',
  },
  {
    code: 'IS',
    mobileCode: '+354',
    countryName: 'Iceland',
  },
  {
    code: 'IE',
    mobileCode: '+353',
    countryName: 'Ireland',
  },
  {
    code: 'IT',
    mobileCode: '+39',
    countryName: 'Italy',
  },
  {
    code: 'LV',
    mobileCode: '+371',
    countryName: 'Latvia',
  },
  {
    code: 'LI',
    mobileCode: '+423',
    countryName: 'Liechtenstein',
  },
  {
    code: 'LT',
    mobileCode: '+370',
    countryName: 'Lithuania',
  },
  {
    code: 'LU',
    mobileCode: '+352',
    countryName: 'Luxembourg',
  },
  {
    code: 'MT',
    mobileCode: '+356',
    countryName: 'Malta',
  },
  {
    code: 'NL',
    mobileCode: '+31',
    countryName: 'Netherlands',
  },
  {
    code: 'NO',
    mobileCode: '+47',
    countryName: 'Norway',
  },
  {
    code: 'PL',
    mobileCode: '+48',
    countryName: 'Poland',
  },
  {
    code: 'PT',
    mobileCode: '+351',
    countryName: 'Portugal',
  },
  {
    code: 'RO',
    mobileCode: '+40',
    countryName: 'Romania',
  },
  {
    code: 'SK',
    mobileCode: '+421',
    countryName: 'Slovakia',
  },
  {
    code: 'SI',
    mobileCode: '+386',
    countryName: 'Slovenia',
  },
  {
    code: 'ES',
    mobileCode: '+34',
    countryName: 'Spain',
  },
  {
    code: 'SE',
    mobileCode: '+46',
    countryName: 'Sweden',
  },
  {
    code: 'TR',
    mobileCode: '+90',
    countryName: 'Turkey',
  },
  {
    code: 'GB',
    mobileCode: '+44',
    countryName: 'United Kingdom',
  },
];
