import Markdown from 'react-markdown';
import styled from 'styled-components';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';

import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

import LargeButton from '@elements/LargeButton/LargeButton';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  box-sizing: border-box;
  padding: 65px 25px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
    padding: unset;
    margin-bottom: initial;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 15px 25px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 31px 50px 0 95px;
  }
`;

export const Headline = styled.div`
  ${FontStyles.h5};
  display: flex;
  justify-self: center;
  align-self: center;
  margin-bottom: 10px;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    align-self: end;
  }
`;

export const CustomOnboardingStepNavigation = styled(OnboardingStepNavigation)`
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: initial;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 20px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-bottom: 20px;
  }
`;

export const TermBox = styled.div`
  ${Scrollbar};
  display: flex;
  overflow-y: scroll;
  overflow-wrap: anywhere;
  box-sizing: border-box;
  padding: 20px 16px 20px 16px;
  width: 100%;
  height: auto;
  max-height: 456px;
  background: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 15px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-height: 480px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-height: 335px;
    max-width: 364px;
  }
  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-height: 412px;
    max-width: 383px;
  }
`;

export const SwitchArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin: 25px 0 25px 0;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
`;

export const SwitchText = styled.div`
  ${FontStyles.bodySmallGilroy};
  display: flex;
  flex-wrap: wrap;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.blue};
  cursor: pointer;
  transition: 300ms ease-in-out;
  display: inline-block;

  &:hover,
  &:focus {
    outline: none;
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export const CustomButton = styled(LargeButton)`
  max-height: 50px;
  transition: background 300ms ease-in-out;
  width: 153px;
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: end;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-height: 40px;
    order: unset;
    width: 134px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    max-height: 50px;
    width: 153px;
  }
`;

export const CustomMarkdown = styled(Markdown)`
  color: ${(props) => props.theme.palette.primary.black};

  & h1 {
    ${FontStyles.h5};
    margin-bottom: 10px;
  }

  & h2 {
    ${FontStyles.h6};
    margin-bottom: 20px;
  }

  & h3 {
    ${FontStyles.h7};
    margin-left: 1vw;
    margin-bottom: 10px;
  }

  & h4,
  & ol,
  & ul,
  & ol p,
  & ul p {
    ${FontStyles.bodySmallGilroy};
    line-height: 130%;
    margin-left: 2vw;
    margin-bottom: 40px;
  }

  & p {
    ${FontStyles.bodySmallGilroy};
    line-height: 150%;
    margin-left: 1vw;
    margin-bottom: 40px;
  }
`;
