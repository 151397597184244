import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  size?: Size;
}

const BorderButton = ({ onClick, className, style, children, size = 'small' }: Props) => {
  return (
    <ButtonWrap
      onClick={onClick}
      className={className}
      style={style}
      size={size}
    >
      {children}
    </ButtonWrap>
  );
};

const ButtonWrap = styled(Button)`
  padding: ${(props) => (props.size === 'small' ? '3px 19px' : '6px 16px')} !important;
  border: 1px solid ${(props) => props.theme.palette.primary.blue}!important;
  font-size: ${(props) =>
    props.size === 'small' ? '12px' : props.size === 'medium' ? '14px' : '15px'}!important;
  font-family: ${(props) => props.theme.typography.buttonGilroyType3.fontFamily}!important;
  font-weight: ${(props) => props.theme.typography.buttonGilroyType3.fontWeight}!important;
  border-radius: 75px !important;
  ${FontStyles.buttonMedium};
  color: ${(props) => props.theme.palette.primary.blue}!important;
  background: transparent !important;
  text-align: center;
  text-transform: initial !important;
  height: ${(props) =>
    props.size === 'small' ? '22px' : props.size === 'medium' ? '40px' : '50px'};
  &:hover {
    background: ${(props) => props.theme.palette.primary.white};
    color: ${(props) => props.theme.palette.primary.blue};
    cursor: pointer;
  }
`;

export default BorderButton;
