import { css } from 'styled-components';

export const Scrollbar = css`
  scrollbar-width: thin;
  scrollbar-color: rgba(204, 179, 236, 0.4) transparent;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: initial;
  }
  -ms-overflow-style: initial;

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(204, 179, 236, 0.4);
  }

  ::-webkit-scrollbar-track {
    background: rgba(204, 179, 236, 0.2);
    border: 2px solid transparent;
    background-clip: padding-box;
  }
`;

export const HideScrollbar = css`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;

  scrollbar-width: none;
  scrollbar-color: transparent transparent;
`;
