import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './translation/en.json';

export const resources = {
  en: {
    translation: en,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    returnEmptyString: false,
    debug: false,
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'localStorage'],

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',

      // cache user language on
      caches: ['localStorage'],
    },
  })
  .catch((error) => console.error(error));

export default i18n;
