import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import useMediaQuery from '@hooks/useMediaQuery';

import OnboardingCountryInput from '@components/OnboardingCountryInput/OnboardingCountryInput';

import {
  Container,
  CustomButton,
  CustomInput,
  CustomOnboardingStepNavigation,
  ErrorMessage,
  HalfRow,
  Heading,
  Label,
} from './OnboardingAddressForm.styles';

const OnboardingAddressForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);

  useEffect(() => {
    if (!location.state) navigate('/register');
    if (!location.state.from) navigate('/register');
    if (location.state.from !== '/register/3') navigate('/register');
  }, [location.state, navigate]);

  const [formValid, setFormValid] = useState<boolean>(true);
  const [country, setCountry] = useState<string>('');

  const setCorporateData = useBoundStore((state) => state.setCorporateData);
  const corporateData = useBoundStore((state) => state.corporateData);

  const handleChange = useCallback(
    (name: string, value: string) => {
      // setFormValid(true);
      setCorporateData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setCorporateData]
  );

  const isAddressValid = useCallback((): boolean => {
    let result =
      corporateData.companyAddressLine1 !== '' &&
      corporateData.companyAddressLine1.length >= 5 &&
      corporateData.companyAddressLine1.length <= 150 &&
      corporateData.companyAddressCity !== '' &&
      corporateData.companyAddressCity.length >= 1 &&
      corporateData.companyAddressCity.length <= 50 &&
      corporateData.companyAddressZipCode !== '' &&
      corporateData.companyAddressZipCode.length >= 4 &&
      corporateData.companyAddressZipCode.length <= 10 &&
      corporateData.companyAddressCountry !== '' &&
      corporateData.companyAddressCountry.length >= 4;

    if (corporateData.companyAddressLine2) {
      result = result && corporateData.companyAddressLine2.length <= 150;
    }
    if (corporateData.companyAddressState) {
      result = result && corporateData.companyAddressState.length <= 50;
    }

    return result;
  }, [
    corporateData.companyAddressCity,
    corporateData.companyAddressCountry,
    corporateData.companyAddressLine1,
    corporateData.companyAddressLine2,
    corporateData.companyAddressState,
    corporateData.companyAddressZipCode,
  ]);

  useEffect(() => {
    if (isAddressValid()) {
      setFormValid(true);
    }
  }, [isAddressValid]);

  useEffect(() => {
    handleChange('companyAddressCountry', country);
  }, [country, handleChange]);

  return (
    <Container>
      <CustomOnboardingStepNavigation text="Previous Step" />
      <Heading>Submit Your Details</Heading>
      {isTablet && (
        <Label>
          Business Address
          <CustomInput
            autoComplete="do-not-autofill"
            placeholder="Address Line 1"
            onChange={(event) => handleChange('companyAddressLine1', event.target.value)}
            value={corporateData.companyAddressLine1}
          />
          <CustomInput
            autoComplete="do-not-autofill"
            placeholder="Address Line 2 (optional)"
            onChange={(event) => handleChange('companyAddressLine2', event.target.value)}
            value={corporateData.companyAddressLine2}
          />
        </Label>
      )}
      <HalfRow>
        <Label>
          City
          <CustomInput
            autoComplete="do-not-autofill"
            placeholder="Name of City"
            onChange={(event) => handleChange('companyAddressCity', event.target.value)}
            value={corporateData.companyAddressCity}
          />
        </Label>
        <Label>
          Post Code
          <CustomInput
            autoComplete="do-not-autofill"
            placeholder="Post Code"
            onChange={(event) => handleChange('companyAddressZipCode', event.target.value)}
            value={corporateData.companyAddressZipCode}
          />
        </Label>
      </HalfRow>
      <HalfRow>
        <Label>
          Country
          <OnboardingCountryInput
            setValue={setCountry}
            value={country}
          />
        </Label>
        <Label>
          State/Region
          <CustomInput
            autoComplete="do-not-autofill"
            placeholder="Enter State"
            onChange={(event) => handleChange('companyAddressState', event.target.value)}
            value={corporateData.companyAddressState}
          />
        </Label>
      </HalfRow>
      {!isTablet && (
        <Label>
          Business Address
          <CustomInput
            autoComplete="do-not-autofill"
            placeholder="Address Line 1"
            onChange={(event) => handleChange('companyAddressLine1', event.target.value)}
            value={corporateData.companyAddressLine1}
          />
          <CustomInput
            autoComplete="do-not-autofill"
            placeholder="Address Line 2 (optional)"
            onChange={(event) => handleChange('companyAddressLine2', event.target.value)}
            value={corporateData.companyAddressLine2}
          />
        </Label>
      )}
      <ErrorMessage $visible={!formValid}>Please double-check your information</ErrorMessage>
      <CustomButton
        disabled={!formValid}
        onClick={() => {
          if (isAddressValid()) {
            navigate('/register/5', {
              state: {
                from: location.pathname,
              },
            });
          } else {
            setFormValid(false);
          }
        }}
        text="Complete"
        variant="blueWhite"
      />
    </Container>
  );
};

export default OnboardingAddressForm;
