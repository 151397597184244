import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  infoList: string[];
}
const InfoBox = ({ infoList }: Props) => {
  return (
    <Container>
      {infoList.map((info, i) => (
        <Info key={i}>{info}</Info>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 15px;
  border-radius: 20px;

  border: 1px dashed var(--action-active, rgba(0, 0, 0, 0.56));
`;
const Info = styled.p`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.textGrey};
  margin-bottom: 26px;
  line-height: 175%;
  &:last-child {
    margin-bottom: 0;
  }
`;
export default InfoBox;
