import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import OnboardingPictureArea from '@components/OnboardingPictureArea/OnboardingPictureArea';
import OnboardingTopBar from '@components/OnboardingTopBar/OnboardingTopBar';

export const Container = styled.div<{ $sms: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: start;
  background-color: white;
  min-height: 100vh;

  ${(props) =>
    props.$sms &&
    css`
      padding-top: 77px;
    `}

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 75px;
    background-color: unset;
    min-height: unset;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 100vh;
    justify-content: center;
    padding: 0 25px 25px 25px;
    background-color: ${(props) => props.theme.palette.greys.middleGrey};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 0 35px 35px 35px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.primary.white};
  box-sizing: border-box;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
    border-radius: 25px;
    width: 658px;
    min-height: 705px;
    padding: 40px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 1119px;
    min-height: 714px;
    padding: 35px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    width: 1320px;
    min-height: 845px;
    padding: 45px;
  }
`;

export const ReturnButton = styled.button<{ $sms: boolean }>`
  display: ${(props) => (props.$sms ? 'flex' : 'none')};
  cursor: pointer;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  transition: color 300ms ease-in-out;
  align-items: center;
  gap: 10px;
  position: absolute;
  ${FontStyles.bodySmallGilroy};
  top: 20px;
  left: 20px;
  width: calc(100vw - 40px);
  justify-content: space-between;

  ${(props) =>
    props.$sms &&
    css`
      ${FontStyles.h5};
    `}

  div {
    order: -1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 35%,
      rgba(35, 34, 34, 1) 58%,
      rgba(52, 53, 74, 1) 100%
    );
    background-size: 500% auto;
    transition:
      background-position,
      color,
      300ms ease-in-out;
    color: ${(props) => props.theme.palette.greys.darkestGrey};

    ${(props) =>
      props.$sms &&
      css`
        border: 1px solid ${props.theme.palette.greys.darkGrey};

        @media (${(props) => props.theme.breakpoints.laptop}) {
          border: none;
        }
      `}
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};

    & > div {
      background-position: 90% 120%;
      color: ${(props) => props.theme.palette.primary.white};
    }
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
    top: 25px;
    width: initial;
    align-self: end;
    justify-content: center;
    left: initial;
    ${FontStyles.bodySmallGilroy};

    div {
      order: initial;
    }

    @media (max-height: 1024px) {
      & > p {
        display: none;
      }
    }
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    top: 35px;
  }
`;
