import { useMutation } from '@tanstack/react-query';

import StepupService from '@services/stepup-service';

const useIssueOtp = () => {
  return useMutation({
    mutationFn: async (data?: { token?: string; signal?: AbortSignal }) => {
      return await StepupService.issueOtp(data);
    },
  });
};

const useVerifyOtp = () => {
  return useMutation({
    mutationFn: async (data: { verificationCode: string; token: string }) => {
      return await StepupService.verifyOtp(data);
    },
  });
};

export { useIssueOtp, useVerifyOtp };
