import React, { useEffect } from 'react';

import { useBoundStore } from '@stores/BoundStore';

import ContactsListSendMoney from '@components/ContactsListSendMoney/ContactsListSendMoney';

import { Container, CustomTransactionFormWrapper } from './SendMoney.styles';

const SendMoney = () => {
  const setScrolled = useBoundStore((state) => state.setScrolled);

  useEffect(() => {
    setScrolled(false);
  }, [setScrolled]);

  return (
    <Container>
      <ContactsListSendMoney />
      <CustomTransactionFormWrapper />
    </Container>
  );
};

export default SendMoney;
