import { StateCreator } from 'zustand';

export interface ContactListSlice {
  selectedContact: ContactsWithBankDetails | null;
  setSelectedContact: (contact: ContactsWithBankDetails | null) => void;
}

export const createContactListSlice: StateCreator<ContactListSlice> = (set) => ({
  selectedContact: null,
  setSelectedContact: (contact) => set({ selectedContact: contact }),
});
