import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

export const OtpInputContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  transition: border ease-in-out 300ms;
  box-sizing: border-box;
  height: 70px;
  width: 250px;

  &:focus-within {
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
  }
`;

export const OtpInputField = styled.input`
  ${FontStyles.bodyMiddleGilroy};
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.primary.black};
  border: none;
  display: flex;
  align-items: center;
  flex: 1;
  transition: color ease-in-out 300ms;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  &:focus {
    color: ${(props) => props.theme.palette.primary.blue};
    outline: none;
  }

  &:disabled {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px 65px 20px;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
    width: 100%;
    padding: 0 90px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 25px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 0 50px 0 95px;
  }
`;

export const Heading = styled.p`
  ${FontStyles.h3};
  text-align: center;
  display: flex;
  padding-top: 24px;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: center;
    padding-top: 60px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    justify-content: center;
    padding-top: 84px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h3};
    padding-top: 114px;
  }
`;

export const SubHeading = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  text-align: center;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin-top: 10px;
  box-sizing: border-box;
  align-self: center;
  max-width: 330px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: 378px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
    max-width: 380px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

export const Label = styled.div`
  ${FontStyles.h5};
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.black};
  margin-top: 30px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h6};
    margin-top: 45px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h5};
    margin-top: 72px;
  }
`;

export const CustomOnboardingStepNavigation = styled(OnboardingStepNavigation)`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
    align-self: start;
    margin-left: 60px;
    margin-top: 15px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-left: 88px;
    margin-top: 20px;
  }
`;

export const SupportFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 5px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin: 25px 0 30px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin: 25px 0 0 0;
  }
`;

export const ProblemRow = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 5px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const ProblemLink = styled.button`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.blue};
  transition: 300ms ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};
  }
  &:disabled {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    cursor: initial;
    &:hover {
      color: ${(props) => props.theme.palette.greys.darkestGrey};
    }
  }
`;

export const SmsAuthImage = styled.img`
  display: flex;
  outline: none;
  border: none;
  box-sizing: border-box;
  width: calc(100% - 40px);
  height: 208px;
  object-fit: contain;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;
