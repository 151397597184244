import { ReactNode } from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  children?: ReactNode;
  className?: string;
}

// TODO: Vielleicht refactoren, sodass überall gleiches Styling ist? Bitte Modals mit Figma vergleichen!
const ModalHeadline = ({ children, className }: Props) => {
  return <HeadLine className={className}>{children}</HeadLine>;
};

const HeadLine = styled.h3`
  ${FontStyles.h6Main};
  color: ${(props) => props.theme.palette.primary.black};
  text-align: left;

  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.h6Main};
  }
`;

export default ModalHeadline;
