import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

interface Props {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
}

const ToggleSwitch = ({ isActive = false, setIsActive }: Props) => {
  return (
    <Container
      $isActive={isActive}
      onClick={() => setIsActive(!isActive)}
    >
      <Circle $isActive={isActive} />
    </Container>
  );
};

export default ToggleSwitch;

const Circle = styled.div<{ $isActive: boolean }>`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.white};
  position: absolute;
  left: 3px;
  transform: ${(props) => (props.$isActive ? 'translateX(21px)' : 'translateX(0)')};
  transition: 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Container = styled.button<{ $isActive: boolean }>`
  width: 46px;
  height: 25px;
  border-radius: 31px;
  box-sizing: border-box;
  padding: 3px;
  transition: ease-in-out 0.3s;
  background-color: ${(props) =>
    props.$isActive ? props.theme.palette.primary.blue : props.theme.palette.greys.darkGrey};
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;
