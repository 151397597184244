interface Props {
  gradientId: string;
  className?: string;
}
const PeanudsLogo = ({ gradientId, className }: Props) => {
  return (
    <svg
      width="119"
      height="40"
      viewBox="0 0 119 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M40.5838 23.4936H31.8234C32.211 25.0022 33.2964 25.7168 35.1182 25.7168C36.2811 25.7168 37.1339 25.3198 37.7153 24.5258L40.1186 25.955C38.9557 27.662 37.2889 28.4957 35.0407 28.4957C33.1026 28.4957 31.5908 27.9002 30.4279 26.7092C29.265 25.5183 28.6836 24.0097 28.6836 22.2233C28.6836 20.4368 29.265 18.968 30.3892 17.7373C31.5133 16.5463 32.9863 15.9111 34.8081 15.9111C36.5137 15.9111 37.9479 16.5066 39.0333 17.7373C40.1574 18.9283 40.7001 20.4368 40.7001 22.2233C40.7001 22.5409 40.6613 22.9775 40.5838 23.4936ZM31.7846 21.1117H37.7153C37.5603 20.3177 37.2114 19.7222 36.6687 19.3252C36.1648 18.9283 35.5446 18.6901 34.8081 18.6901C33.9941 18.6901 33.3351 18.8886 32.7925 19.3252C32.2885 19.7619 31.9397 20.3574 31.7846 21.1117Z"
        fill={`url(#${gradientId}_paint1)`}
      />
      <path
        d="M51.5538 17.6582V16.2688H54.5386V28.1784H51.5538V26.789C50.6623 27.9403 49.3831 28.5357 47.7551 28.5357C46.2046 28.5357 44.8866 27.9403 43.7625 26.7096C42.6771 25.4789 42.0957 24.0101 42.0957 22.2236C42.0957 20.4769 42.6384 19.008 43.7625 17.7773C44.8866 16.5467 46.2046 15.9512 47.7551 15.9512C49.3831 15.9512 50.6623 16.507 51.5538 17.6582ZM46.0107 24.6452C46.631 25.2804 47.3674 25.5583 48.2978 25.5583C49.2281 25.5583 50.0033 25.2407 50.5848 24.6452C51.205 24.0101 51.5151 23.2161 51.5151 22.2236C51.5151 21.2311 51.205 20.4372 50.5848 19.802C49.9646 19.1668 49.2281 18.8492 48.2978 18.8492C47.3674 18.8492 46.5922 19.1668 46.0107 19.802C45.3905 20.4372 45.1192 21.2311 45.1192 22.2236C45.1192 23.2161 45.3905 24.0101 46.0107 24.6452Z"
        fill={`url(#${gradientId}_paint2)`}
      />
      <path
        d="M63.7253 15.9512C65.0045 15.9512 66.0511 16.3879 66.8651 17.2612C67.7179 18.1346 68.1055 19.3653 68.1055 20.8738V28.1784H65.1208V21.2311C65.1208 20.4372 64.927 19.8417 64.5006 19.405C64.0742 18.9683 63.5315 18.7698 62.795 18.7698C62.0198 18.7698 61.3608 19.008 60.9344 19.5241C60.4693 20.0402 60.2367 20.7547 60.2367 21.7075V28.1784H57.252V16.2688H60.2367V17.6185C60.9732 16.507 62.1361 15.9512 63.7253 15.9512Z"
        fill={`url(#${gradientId}_paint3)`}
      />
      <path
        d="M78.3776 22.6998V16.2686H81.3624V28.1782H78.3776V26.8285C77.6799 27.94 76.517 28.4958 74.9278 28.4958C73.6486 28.4958 72.602 28.0591 71.7492 27.1858C70.9352 26.3124 70.5088 25.0817 70.5088 23.5732V16.2686H73.4935V23.2159C73.4935 24.0099 73.6873 24.6053 74.1137 25.042C74.5401 25.4787 75.0828 25.6772 75.8193 25.6772C76.5946 25.6772 77.2148 25.439 77.6799 24.9229C78.1451 24.4068 78.3776 23.6526 78.3776 22.6998Z"
        fill={`url(#${gradientId}_paint4)`}
      />
      <path
        d="M92.9142 17.6582V14.5617C92.9142 12.8546 94.2709 11.5049 95.8989 11.5049V28.1784H92.9142V26.789C92.0226 27.9402 90.7822 28.5357 89.1542 28.5357C87.6037 28.5357 86.247 27.9402 85.1229 26.7096C84.0375 25.4789 83.4561 24.0101 83.4561 22.2236C83.4561 20.4768 83.9987 19.008 85.1229 17.7773C86.247 16.5466 87.5649 15.9512 89.1542 15.9512C90.7822 15.9512 92.0226 16.507 92.9142 17.6582ZM87.3711 24.6452C87.9913 25.2804 88.7666 25.5583 89.6969 25.5583C90.6272 25.5583 91.4024 25.2407 91.9839 24.6452C92.6041 24.0101 92.8754 23.2161 92.8754 22.2236C92.8754 21.2311 92.5653 20.4371 91.9839 19.802C91.3637 19.1668 90.6272 18.8492 89.6969 18.8492C88.7666 18.8492 87.9913 19.1668 87.3711 19.802C86.7509 20.4371 86.4796 21.2311 86.4796 22.2236C86.4796 23.2161 86.7897 24.0101 87.3711 24.6452Z"
        fill={`url(#${gradientId}_paint5)`}
      />
      <path
        d="M101.325 19.6435C101.325 20.1596 102.023 20.5962 103.457 20.9535C103.961 21.0726 104.387 21.1917 104.814 21.3505C105.201 21.5093 105.628 21.7078 106.015 21.9857C106.442 22.2636 106.752 22.6209 106.984 23.0973C107.217 23.5737 107.333 24.0898 107.333 24.6852C107.333 25.9159 106.868 26.8687 105.977 27.5039C105.085 28.139 103.961 28.4963 102.643 28.4963C100.24 28.4963 98.6116 27.5436 97.7588 25.6777L100.356 24.1692C100.705 25.2013 101.48 25.7174 102.643 25.7174C103.728 25.7174 104.232 25.3601 104.232 24.6852C104.232 24.1692 103.534 23.7325 102.1 23.3752C101.558 23.2164 101.131 23.0973 100.744 22.9385C100.356 22.7797 99.9683 22.5812 99.5419 22.3033C99.1155 22.0254 98.7666 21.6681 98.5728 21.2314C98.3402 20.7947 98.2239 20.2787 98.2239 19.6832C98.2239 18.4922 98.6503 17.5791 99.4644 16.9042C100.317 16.2294 101.364 15.8721 102.643 15.8721C103.573 15.8721 104.465 16.0706 105.24 16.5073C106.015 16.9439 106.635 17.5791 107.062 18.3731L104.504 19.8023C104.116 19.0083 103.496 18.5716 102.643 18.5716C102.255 18.5716 101.945 18.651 101.674 18.8495C101.402 19.048 101.325 19.3656 101.325 19.6435Z"
        fill={`url(#${gradientId}_paint6)`}
      />
      <path
        d="M16.9387 22.7004H11.667C11.7833 20.9933 13.1787 19.6436 14.8843 19.6436H16.9775L16.9387 22.7004Z"
        fill={`url(#${gradientId}_paint7)`}
      />
      <path
        d="M16.4736 11.5049H22.5594C24.1099 11.5049 25.4278 12.0607 26.5132 13.1325C27.5986 14.2044 28.1025 15.5542 28.1025 17.1024C28.1025 18.6507 27.5598 20.0005 26.5132 21.0723C25.4278 22.1442 24.1099 22.7 22.5594 22.7H19.6909V24.8834C19.6909 26.7096 18.2567 28.1784 16.4736 28.1784V11.5049ZM19.6909 19.6432H22.5594C23.2571 19.6432 23.7998 19.405 24.265 18.9286C24.7301 18.4522 24.9239 17.8567 24.9239 17.1421C24.9239 16.4276 24.6914 15.7924 24.265 15.316C23.7998 14.8396 23.2571 14.6014 22.5594 14.6014H19.6909V19.6432Z"
        fill={`url(#${gradientId}_paint8)`}
      />
      <path
        d="M21.9387 19.6436H19.6904V22.7004H21.9387V19.6436Z"
        fill={`url(#${gradientId}_paint9)`}
      />
      <defs>
        <linearGradient
          id={`${gradientId}_paint1`}
          x1="39.6586"
          y1="28.2717"
          x2="37.8329"
          y2="15.1533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#34354A"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint2`}
          x1="53.4602"
          y1="28.3117"
          x2="51.6947"
          y2="15.1765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#34354A"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint3`}
          x1="67.1649"
          y1="27.9608"
          x2="65.2623"
          y2="15.2528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#34354A"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint4`}
          x1="80.4217"
          y1="28.2782"
          x2="78.5192"
          y2="15.5702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#34354A"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint5`}
          x1="94.8205"
          y1="28.2325"
          x2="91.6342"
          y2="10.715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#34354A"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint6`}
          x1="106.503"
          y1="28.2716"
          x2="104.223"
          y2="15.2566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#34354A"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint7`}
          x1="17.4106"
          y1="21.5052"
          x2="15.5681"
          y2="18.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop
            offset="1"
            stopColor="#2B2A33"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint8`}
          x1="27.0946"
          y1="27.8816"
          x2="23.8369"
          y2="10.7849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#34354A"
          />
        </linearGradient>
        <linearGradient
          id={`${gradientId}_paint9`}
          x1="19.6537"
          y1="21.2398"
          x2="21.9077"
          y2="21.2151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232222" />
          <stop
            offset="1"
            stopColor="#2B2B34"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PeanudsLogo;
