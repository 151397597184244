import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { IconArrowLeft } from '@constants/icons';

interface Props {
  to?: string;
}
const OnboardingNavigationTopButton = ({ to }: Props) => {
  const navigate = useNavigate();
  const navigateHandler = (to?: string) => {
    if (to) navigate(to);
    else navigate(-1);
  };

  return (
    <Container onClick={() => navigateHandler(to)}>
      <IconArrowLeft />
    </Container>
  );
};

export default OnboardingNavigationTopButton;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.white};
  border-radius: 50%;
  width: 44px;
  height: 44px;
  left: 20px;
  top: 15px;
  cursor: pointer;
  transition: 300ms ease-in-out;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.palette.primary.black};
    background-color: ${({ theme }) => theme.palette.primary.white};
  }
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;
