import { Socket, io } from 'socket.io-client';

const url = process.env.REACT_APP_WEBSOCKET_URL || 'http://localhost:8080'; // Default to local if not specified

let socket: Socket | null = null;
let currentToken = '';

const getSocket = (token: string): Socket => {
  if (socket && currentToken !== token) {
    socket.removeAllListeners();
    socket.disconnect();
    socket = null;
  }

  if (!socket) {
    socket = io(url, {
      auth: { token },
      transports: ['websocket'], // Ensuring to use WebSockets only
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 5000,
      autoConnect: false,
      path: '/api/v1/notifications/ws',
    });
    currentToken = token;
  }

  return socket;
};

export default getSocket;
