import styled from 'styled-components';

import TransactionItem from '@components/Cards/TransactionItem';

import CardHeader from './CardHeader';

const CardOverview = () => {
  return (
    <Container>
      <CardHeader></CardHeader>
      {/*<TransactionItem*/}

      {/*/>*/}
    </Container>
  );
};

export default CardOverview;

export const Container = styled.div``;
