import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

export const FormWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 64px 20px;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    align-items: start;
    padding: 0;
    height: unset;
    width: unset;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    margin: 15px 9px 0 60px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin: 15px 50px 0 95px;
  }
`;

export const CustomStepNavigation = styled(OnboardingStepNavigation)`
  display: none;
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0 39px 0 39px;
    width: calc(100% - 78px);
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    margin: 0;
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 30px 0;
  gap: 25px;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 140px 0 50px 0;
    gap: 20px;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    margin: 140px 0 40px 0;
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin: 190px 0 70px 0;
  }
`;

export const Title = styled.div`
  ${FontStyles.h3};
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    ${FontStyles.h4};
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    ${FontStyles.h3};
  }
`;

export const Subtitle = styled.div`
  ${FontStyles.bodySmallGilroy};
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 380px;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    width: 361px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

export const SubtitleMail = styled.div`
  ${FontStyles.buttonGilroyType3};
  color: ${({ theme }) => theme.palette.primary.black};
  font-size: 16px;
`;

export const Disclaimer = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  color: ${({ theme }) => theme.palette.primary.black};
`;

export const IssueWrapper = styled.div`
  ${FontStyles.bodySmallGilroy};
  color: ${({ theme }) => theme.palette.primary.black};
  text-align: center;
  margin-top: 45px;
`;

export const ProblemRow = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 2px;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const LinkButton = styled.button`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.blue};
  transition: 300ms ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.black};
  }

  &:disabled {
    transition: unset;
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    cursor: initial;
    &:hover {
      color: ${(props) => props.theme.palette.greys.darkestGrey};
    }
  }
`;
