import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useIssueOtp, useVerifyOtp } from '@api/Stepup/stepupApi';

import OnboardingSMSAuthMobile2x from '@assets/images/ImageOnboardingSMSAuthMobile2x.png';
import OnboardingSMSAuthMobile from '@assets/images/ImageOnboardingSMSAuthMobile.png';
import OnboardingSMSAuthTablet from '@assets/images/ImageOnboardingSMSAuthTablet.png';

import { useBoundStore } from '@stores/BoundStore';

import { IconFieldCheckBox } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import Toast from '@elements/Toast/Toast';

import {
  Container,
  CustomOnboardingStepNavigation,
  Heading,
  Label,
  OtpInputContainer,
  OtpInputField,
  ProblemLink,
  ProblemRow,
  SmsAuthImage,
  SubHeading,
  SupportFeaturesContainer,
  Wrapper,
} from './LoginOTPForm.styles';

const LoginOTPForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const otpInputRef = useRef<HTMLInputElement | null>(null);

  const setIsLoggedIn = useBoundStore((state) => state.setIsLoggedIn);

  const [isOtpInputEnabled, setIsOtpInputEnabled] = useState(false);
  const [resetButtonCountdown, setResetButtonCountdown] = useState<number>(15);
  const [resetButtonDisabled, setResetButtonDisabled] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const errors = useMemo(
    () => ({
      400: () => {
        toast.error(
          <Toast
            title="Error"
            message="You have requested too many verification codes. Please return to Log In and try again."
          />
        );
        setResetButtonCountdown(0);
        setIsError(true);
      },
      409: () => {
        toast.error(
          <Toast
            title="Error"
            message="Please wait a couple of seconds before you request a new code."
          />
        );
      },
    }),
    []
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errors);

  const wantedPath = location.state?.wantedPath || '/';

  const { mutate: issueOtpMutation } = useIssueOtp();
  const { mutate: verifyOtpMutation } = useVerifyOtp();

  useEffect(() => {
    const controller = new AbortController();

    const { token } = location.state;
    if (!token) navigate('/login');

    issueOtpMutation(
      { token, signal: controller.signal },
      {
        onSuccess: () => {
          setIsOtpInputEnabled(true);
        },
        onError: (error) => {
          handleHTTPErrors([error]);
        },
      }
    );

    return () => {
      controller.abort();
    };
  }, [handleHTTPErrors, issueOtpMutation, location.state, navigate]);

  useEffect(() => {
    let countdown: ReturnType<typeof setInterval>;
    const handleCountdown = () => {
      setResetButtonCountdown((prevState) => {
        if (prevState === 0) {
          clearInterval(countdown);
          return 0;
        } else {
          return prevState - 1;
        }
      });
    };

    if (!isError) {
      countdown = setInterval(handleCountdown, 1000);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [isError]);

  useEffect(() => {
    if (resetButtonCountdown === 0 && !isError) {
      setResetButtonDisabled(false);
    }
  }, [isError, resetButtonCountdown]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (otpInputRef.current) {
      otpInputRef.current.value = event.target.value.slice(0, 6);

      if (otpInputRef.current.value.length === 6) {
        submitHandler();
      }
    }
  };

  const submitHandler = () => {
    if (otpInputRef.current) {
      const verificationCode = otpInputRef.current.value;
      verifyOtpMutation(
        { verificationCode, token: location.state.token },
        {
          onSuccess: () => {
            setIsLoggedIn(location.state.token);
            const state = {};
            navigate(wantedPath, {
              replace: true,
              state,
            });
          },
          onError: () => {
            const state = {};
            navigate('/login', { state });
          },
        }
      );
    }
  };

  return (
    <Wrapper>
      <SmsAuthImage
        srcSet={`${OnboardingSMSAuthMobile2x} 320w, ${OnboardingSMSAuthTablet} 680w`}
        src={OnboardingSMSAuthMobile}
      />
      <CustomOnboardingStepNavigation
        text="Return to Log In"
        to="/login"
      />
      <Container>
        <Heading>SMS Authentication</Heading>
        <SubHeading>
          We sent a verification code to your phone number, please enter it below to verify that it
          is you.
        </SubHeading>
        <Label>
          Enter code below
          <OtpInputContainer>
            <OtpInputField
              ref={otpInputRef}
              maxLength={6}
              onChange={(event) => changeHandler(event)}
              placeholder="Enter code"
              disabled={!isOtpInputEnabled}
            />
            {otpInputRef.current?.value.length === 6 && <IconFieldCheckBox />}
          </OtpInputContainer>
        </Label>
        <SupportFeaturesContainer>
          <p>Didn&apos;t get one?</p>
          <ProblemRow>
            {/*TODO simplify & dry code*/}
            <ProblemLink
              disabled={resetButtonDisabled}
              onClick={() => {
                setResetButtonDisabled(true);
                const { token } = location.state;
                if (!token) return;
                issueOtpMutation(
                  { token },
                  {
                    onSuccess: () => {
                      setIsOtpInputEnabled(true);
                    },
                    onError: (error) => {
                      handleHTTPErrors([error]);
                    },
                  }
                );
              }}
              tabIndex={3}
            >
              {`Resend${resetButtonCountdown > 0 ? ` (${resetButtonCountdown})` : ''}`}
            </ProblemLink>
            <span>or</span>
            <ProblemLink
              onClick={() => {
                window.HubSpotConversations.widget.load();
                window.HubSpotConversations.widget.open();
              }}
            >
              Contact our support team
            </ProblemLink>
          </ProblemRow>
        </SupportFeaturesContainer>
      </Container>
    </Wrapper>
  );
};

export default LoginOTPForm;
