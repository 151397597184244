import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import CheckPricing from '@components/CheckPricing/CheckPricing';

import LargeButton from '@elements/LargeButton/LargeButton';
import InputPhonePrefix from '@elements/input/InputPhonePrefix/InputPhonePrefix';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: calc(100vw - 40px);
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: calc(100vw - 48px);
    margin-bottom: 60px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 30px;
    width: 100%;
    min-height: 882px;
    margin-bottom: 0;
  }
`;

export const TitleBar = styled.div`
  box-sizing: border-box;
  background: linear-gradient(262deg, #e8e2db 8.5%, #d6dbf3 101.62%);
  color: ${(props) => props.theme.palette.primary.black};
  ${FontStyles.h4};
  padding: 33px 35px 33px 25px;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
    padding: 30px 35px;
  }
`;

export const ChangePasswordButton = styled.button`
  display: flex;
  box-sizing: border-box;
  transition: 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.white};
  padding: 8px 20px;
  border-radius: 30px;
  ${FontStyles.bodySmallGilroy};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background-color: ${(props) => props.theme.palette.primary.black};
  width: fit-content;

  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.palette.primary.blue};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    align-self: start;
  }
`;

export const RequestButton = styled.button`
  display: flex;
  box-sizing: border-box;
  transition: 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.black};
  padding: 8px 20px;
  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  ${FontStyles.bodySmallGilroy};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: fit-content;

  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.palette.primary.black};
  }
`;

export const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 20px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 35px;
  }
`;

export const FieldRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: row;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 10px;
  ${FontStyles.h5};
  flex-direction: column;
  width: 100%;
`;

export const PhoneFieldContainer = styled(FieldContainer)`
  order: 1;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    order: initial;
  }
`;

export const PasswordFieldContainer = styled(FieldContainer)`
  order: 2;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    order: initial;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 20px;
  order: 2;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: initial;
    align-items: start;
    justify-content: space-between;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    order: initial;
    display: none;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.primary.black};
  ${FontStyles.h4};
  gap: 10px;
`;

export const TitleCheckPricing = styled(CheckPricing)`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
  }
`;

export const ChangePasswordFieldButton = styled.button`
  ${FontStyles.bodyMiddleGilroy};
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  border: none;
  display: flex;
  flex: 1;
  width: 100%;
  transition: color ease-in-out 300ms;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px 20px;
  cursor: pointer;
  height: 50px;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    padding: 10px 20px;
  }

  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

export const SaveChangesButton = styled(LargeButton)`
  padding: 8px 20px;
  ${FontStyles.bodySmallGilroy};
`;

export const ChangeWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 10px;
`;

export const PhoneWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 10px;
  width: 100%;

  & > :nth-child(2) {
    width: 100%;
  }
`;

export const CustomPhonePrefixInput = styled(InputPhonePrefix)`
  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
  }
`;
