import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconLightning } from '@constants/icons';

import BankTransfer from '@components/BankTransfer/BankTransfer';
import Modal from '@components/Modal/Modal';
import ModalHeadline from '@components/Modal/ModalHeadline';
import ModalSubHeadline from '@components/Modal/ModalSubheadline';
import TabNavigation from '@components/TabNavigation/TabNavigation';

const AddMoney = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { t } = useTranslation();
  const tabs = [{ label: t('credit_card') }, { label: t('bank_transfer') }];
  tabs.splice(0, 1); //TODO remove when implementing swift
  return (
    <CustomModal>
      <ModalHeadline>{t('add_money')}</ModalHeadline>
      <ModalSubHeadline>
        <>
          <IconLightning />
          {t('add_money_subheading')}
        </>
      </ModalSubHeadline>
      <TabNavContainer>
        <TabNavigation
          currentTabIndex={currentIndex}
          setCurrentTabIndex={setCurrentIndex}
          tabs={tabs}
          uniqueName="add-money"
        />
      </TabNavContainer>
      <Divider />
      {currentIndex === 0 ? /*<CreditCardForm />*/ <BankTransfer /> : <BankTransfer />}
    </CustomModal>
  );
};

const TabNavContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    justify-content: start;
  }
`;

const Divider = styled.div`
  padding-top: 18px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
`;

export default AddMoney;

const CustomModal = styled(Modal)`
  box-sizing: border-box;
  padding-top: 50px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    box-sizing: initial;
  }
`;
