import axios from 'axios';

import { getToken } from '@shared/functions';

const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  baseURL: process.env.REACT_APP_API_URL,
});

const weavrAuthorizedAxiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  baseURL: process.env.REACT_APP_API_URL,
});

weavrAuthorizedAxiosClient.interceptors.request.use((config) => {
  const token = getToken();

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export { axiosClient, weavrAuthorizedAxiosClient };
