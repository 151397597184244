export const DUMMY_ANALYTICS = [
  { month: 0, in: '0', out: '0' },
  { month: 1, in: '0', out: '0' },
  { month: 2, in: '0', out: '0' },
  { month: 3, in: '0', out: '0' },
  { month: 4, in: '0', out: '0' },
  { month: 5, in: '0', out: '0' },
  { month: 6, in: '0', out: '0' },
  { month: 7, in: '0', out: '0' },
  { month: 8, in: '0', out: '0' },
  { month: 9, in: '0', out: '0' },
  { month: 10, in: '0', out: '0' },
  { month: 11, in: '0', out: '0' },
];
