import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  max-height: 100%;
  width: 100vw;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.asphaltTint};
  text-align: center;
  box-sizing: border-box;
  padding: 0 50px;

  & > .large {
    padding-top: 50px;
    padding-bottom: 10px;
    color: ${(props) => props.theme.palette.primary.black};
    ${FontStyles.h4};
  }

  svg {
    width: 100%;
    max-width: 856px;
    height: auto;
    max-height: 354px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    min-height: 50%;
    max-height: 50%;

    & > .large {
      padding-bottom: 40px;
      ${FontStyles.h2};
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 40px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    min-height: 40%;
    max-height: 40%;
  }
`;

export const OuterRing = styled.div`
  display: flex;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 365px;
  width: 365px;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-in-out;
  flex-shrink: 0;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 680px;
    width: 680px;
    max-height: 680px;
    max-width: 680px;
  }
`;

export const InnerRing = styled.div`
  display: flex;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 285px;
  width: 285px;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-in-out;
  flex-shrink: 0;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 530px;
    width: 530px;
  }
`;

export const Button = styled(NavLink)`
  display: flex;
  border-radius: 50%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.black};
  transition: 300ms ease-in-out;
  flex-shrink: 0;
  ${FontStyles.buttonGilroyType3};

  &:hover {
    height: 220px;
    width: 220px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 380px;
    width: 380px;
    padding-top: 115px;
    align-items: flex-start;
    ${FontStyles.h4};

    &:hover {
      height: 400px;
      width: 400px;
    }
  }
`;
