import { StateCreator } from 'zustand';

import { isToday } from '@shared/datehalper';

export interface OrderBy {
  amount: number;
  dateAndTime: number;
}

export interface AnalyticsSlice {
  amount: string;
  timespan: Timespan;
  setTimespan: (value: Timespan) => void;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  orderBy: OrderBy;
  orderByField: OrderByField;
  sortingDirection: SortingDirection;
  setOrderBy: (value: OrderBy) => void;
  offset: number;
  setOffset: (value: number) => void;
  visibleRows: number;
  setVisibleRows: (value: number) => void;
  filterType: string;
  setFilterType: (value: string | undefined) => void;
  filterStatus: string | undefined;
  setFilterStatus: (value: string | undefined) => void;
  selected: ExportTransaction[];
  setSelected: (value: ExportTransaction[]) => void;
}

export const createAnalyticsSlice: StateCreator<AnalyticsSlice> = (set) => ({
  amount: '0.00',
  timespan: {
    start: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    end: new Date(),
  },
  setTimespan: (value) => {
    let endDate = value.end;
    if (isToday(value.end)) {
      const now = new Date();
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    }
    set({ timespan: { start: value.start, end: endDate } });
  },
  searchQuery: '',
  setSearchQuery: (value) => set({ searchQuery: value }),
  orderBy: {
    amount: 0,
    dateAndTime: 0,
  },
  orderByField: 'date_and_time',
  sortingDirection: 'desc',
  setOrderBy: (value) => {
    let orderByField: OrderByField;
    let sortingDirection: SortingDirection;

    if (value.dateAndTime === 0 && value.amount > 0) {
      orderByField = 'amount';
      sortingDirection = value.amount === 1 ? 'asc' : 'desc';
    } else {
      orderByField = 'date_and_time';
      sortingDirection = value.dateAndTime === 1 ? 'asc' : 'desc';
    }
    set({
      orderBy: {
        amount: value.amount,
        dateAndTime: value.dateAndTime,
      },
    });
    set({ orderByField });
    set({ sortingDirection });
  },
  offset: 0,
  setOffset: (value) => set({ offset: value }),
  visibleRows: 10,
  setVisibleRows: (value) => set({ visibleRows: value }),
  filterType: '',
  setFilterType: (value) => set({ filterType: value }),
  filterStatus: '',
  setFilterStatus: (value) => set({ filterStatus: value }),
  selected: [],
  setSelected: (value) => set({ selected: value }),
});
