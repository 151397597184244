import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconInactivityLock, IconInactivityModalBottom, IconInfo } from '@constants/icons';

import ModalNew from '@components/Modal/Modal';

import LargeButton from '@elements/LargeButton/LargeButton';

const InactivityModal = () => {
  const closeModal = useBoundStore((state) => state.closeModal);
  const inactivityTime = useBoundStore((state) => state.inactivityTime);

  return (
    <CustomModal>
      <Container>
        <IconBackground>
          <CustomIconInactivityLock />
        </IconBackground>
        <TextRow>
          <Title>Are you still here?</Title>
          <Subtitle>Confirm your presence</Subtitle>
        </TextRow>
        <TimerContainer>
          <CustomInfoIcon />
          <H5>Inactivity Timer</H5>
          <TimerBox $warn={inactivityTime > 0}>
            {`${Math.floor(inactivityTime / 60)
              .toString()
              .padStart(2, '0')}:${(inactivityTime % 60).toString().padStart(2, '0')}`}
          </TimerBox>
        </TimerContainer>
        <StyledButton
          onClick={closeModal}
          variant="blueWhite"
          text="Yes, I am here"
        />
      </Container>
      <BottomIcon />
    </CustomModal>
  );
};

export default InactivityModal;

const CustomModal = styled(ModalNew)`
  padding: 0 40px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 450px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    padding-bottom: 171px;
  }
`;

const CustomIconInactivityLock = styled(IconInactivityLock)`
  width: 40px;
  height: 40px;
`;

const IconBackground = styled.div`
  width: 76px;
  height: 76px;
  margin-top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.white};
  border-radius: 50%;
  box-shadow:
    8px 8px 20px 0 rgba(174, 174, 192, 0.2),
    6px 6px 15px 0 rgba(123, 123, 138, 0.08),
    -8px -8px 20px 0 #fff,
    -6px -6px 15px 0 #fff;
`;

const Title = styled.div`
  ${FontStyles.h4};

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    ${FontStyles.h3};
  }
`;

const Subtitle = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    ${FontStyles.bodySmallGilroy};
  }
`;

const TextRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
`;

const TimerContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 15px;
  width: 246px;
  height: 70px;
  margin-bottom: 35px;
  border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 20px;
  flex-direction: row;
`;

const CustomInfoIcon = styled(IconInfo)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin-right: 7px;
`;

const H5 = styled.div`
  ${FontStyles.h5};
  width: 123px;
  align-self: center;
  color: ${(props) => props.theme.palette.primary.black};
  transition: 300ms ease-in-out;
`;

const TimerBox = styled.div<{ $warn: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 40px;
  border-radius: 20px;
  color: white;
  margin-left: auto;
  background: ${(props) =>
    props.$warn
      ? props.theme.palette.negative.lessContrastRed
      : props.theme.palette.greys.darkGrey};
  ${FontStyles.buttonGilroyType2};
  transition: 300ms ease-in-out;
`;

const StyledButton = styled(LargeButton)`
  min-width: 215px;
`;

const BottomIcon = styled(IconInactivityModalBottom)`
  position: absolute;
  bottom: 0;
  height: 100px;
`;
