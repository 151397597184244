import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import IconButton from '@elements/IconButton/IconButton';

export const CloseIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  box-sizing: border-box;
`;

export const SocialMediaIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.white};
  background-color: ${(props) => props.theme.palette.greys.darkGrey};
  box-sizing: border-box;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.black};
  }
`;

export const Container = styled.div<{ $mobileNavOpen: boolean }>`
  display: flex;
  flex-direction: column;
  transition: 300ms ease-in-out;
  position: fixed;
  box-sizing: border-box;
  z-index: 100;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  width: 100vw;
  height: 100%;
  opacity: ${(props) => (props.$mobileNavOpen ? 1 : 0)};
  right: ${(props) => (props.$mobileNavOpen ? 0 : '-100%')};
  overflow: auto;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    background-color: ${(props) => props.theme.palette.primary.white};
    width: 50%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: -7px 0 44px rgba(123, 123, 138, 0.1);
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 390px;
  }
`;

export const TopGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 10px 20px 10px 35px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 30px 35px;
    max-height: 100px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => props.theme.palette.gradients.blueOrangeTint};
  border-radius: 20px;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 20px 0 20px 20px;
  margin: 20px 20px 15px 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin: 20px 20px 20px 35px;
  }
`;

export const UserName = styled.h4`
  ${FontStyles.h5};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.h4};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding-top: 5px;
  }
`;
export const UserEmail = styled.h4`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

export const UserContentContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  margin-left: 10px;
  box-sizing: border-box;
`;

export const Headline = styled.h4`
  ${FontStyles.h4};
`;

export const NotificationGroup = styled.div`
  margin-top: 167px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 76px;
  }
`;

export const NavGroup = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const BottomGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  box-sizing: border-box;
  margin-bottom: 15px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: auto;
  }
`;

export const SocialGroup = styled.div`
  display: flex;
  gap: 10px;
  padding: 22px 0 22px 25px;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 17px 0 17px 40px;
  }
`;

export const ButtonCss = css`
  display: flex;
  text-decoration: none;
  align-items: center;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  transition: 300ms ease-in-out;
  padding: 22px 0 22px 25px;
  box-sizing: border-box;
  cursor: pointer;
  height: 50px;
  ${FontStyles.h5};

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
    background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  }

  &:active,
  &.active {
    color: ${(props) => props.theme.palette.primary.blue};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 22px 0 22px 40px;
  }
`;

export const NavigationLink = styled(NavLink)`
  ${ButtonCss};
`;

export const NavigationButton = styled.button`
  ${ButtonCss};
`;

export const SectionDivider = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 2px;
  background: ${(props) => props.theme.palette.gradients.blueOrangeTint};
  align-self: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: calc(100% - 50px);

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 319px;
  }
`;
