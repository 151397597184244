import React, { useState } from 'react';

import { FormValidation } from '@shared/formValidation';

import {
  BlueButton,
  Container,
  CustomIconOnboardingCheck,
  CustomOnboardingStepNavigation,
  CustomTextInput,
  ErrorContainer,
  Heading,
  Label,
  Mail,
  SubHeading,
  Wrapper,
} from './OnboardingChangeMail.styles';

const OnboardingChangeMail = () => {
  const [currentMail, setCurrentMail] = useState<string>('John.doe@example.com');
  const [value, setValue] = useState<string>('');
  return (
    <Wrapper>
      <CustomOnboardingStepNavigation text="Previous Step" />
      <Container>
        <Heading>Change Mail</Heading>
        <SubHeading>
          You&apos;re going to change your email: <Mail>{currentMail}</Mail>
        </SubHeading>
        <Label>
          New Email
          <CustomTextInput
            onChange={(event) => setValue(event.target.value)}
            placeholder="Email"
            icon={FormValidation.RegEx.EMAIL.test(value) && value && <CustomIconOnboardingCheck />}
            value={value}
            valid={FormValidation.RegEx.EMAIL.test(value)}
          />
          <ErrorContainer $visible={!FormValidation.RegEx.EMAIL.test(value) && value !== ''}>
            Not a valid email
          </ErrorContainer>
        </Label>
        <BlueButton
          onClick={() => undefined}
          variant="blueWhite"
          text="Get Started"
          disabled={!FormValidation.RegEx.EMAIL.test(value) || !value}
        />
      </Container>
    </Wrapper>
  );
};

export default OnboardingChangeMail;
