import { CanceledError } from 'axios';

import { CorporateService } from '@services/corporate-service';

import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const getNotifications = async (signal: AbortSignal) => {
  try {
    const corporateResult = await CorporateService.getCorporate(signal);

    if (!corporateResult) return;

    const userId = corporateResult?.rootUser?.id?.id ?? '?';
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/notifications', {
      params: { userId },
    });

    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

const updateNotificationReadStatus = async () => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/notifications/update-read-status'
    );

    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

export const NotificationsService = {
  getNotifications,
  updateNotificationReadStatus,
};
