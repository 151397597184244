import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

const AnalyticsLinkTransactionEntry = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentLeft>
        <Text>The above is a limited overview of your transaction list.</Text>
        <Text>Don’t worry - use your analytics page instead.</Text>
      </ContentLeft>
      <Button to="/analytics">{t('analytics')}</Button>
    </Wrapper>
  );
};

export default AnalyticsLinkTransactionEntry;

const Wrapper = styled.div`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
    background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
    border-radius: 10px;
    gap: 10px;
    padding: 12px 15px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
  }
`;

const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  & > :last-child {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

const Button = styled(NavLink)`
  ${FontStyles.buttonGilroyType3};
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 15px 39px;
  border-radius: 75px;
  background: ${(props) => props.theme.palette.primary.blue};
  color: ${(props) => props.theme.palette.primary.white};
  text-decoration: none;
  height: 40px;
`;

const Text = styled.p`
  ${FontStyles.bodySmallGilroy};
`;
