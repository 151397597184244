import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { IconArrowLeft } from '@constants/icons';

interface Props {
  proceeded: boolean;
  setProceeded: Dispatch<SetStateAction<boolean>>;
}

const FormNavigation = ({ proceeded, setProceeded }: Props) => {
  const goToNextPage = () => {
    setProceeded(true);
  };

  const goToPrevPage = () => {
    setProceeded(false);
  };

  return (
    <Container>
      <NextButton
        $rotate={false}
        $disabled={!proceeded}
        disabled={!proceeded}
        onClick={goToPrevPage}
      >
        <IconArrowLeft />
      </NextButton>
      <NextButton
        $rotate
        $disabled={proceeded}
        disabled={proceeded}
        onClick={goToNextPage}
      >
        <IconArrowLeft />
      </NextButton>
    </Container>
  );
};

export default FormNavigation;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const NextButton = styled.button<{ $rotate: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transform: ${(props) => (props.$rotate ? 'rotate(180deg)' : 'none')};
  border: 1px solid
    ${(props) =>
      props.$disabled ? props.theme.palette.greys.darkestGrey : props.theme.palette.primary.black};
  color: ${(props) =>
    props.$disabled ? props.theme.palette.greys.darkestGrey : props.theme.palette.primary.black};
  &:hover {
    cursor: pointer;
  }
`;
