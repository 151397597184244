import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const Container = styled.div`
  display: flex;
  border-radius: 20px;
  min-width: 335px;
  background-color: ${(props) => props.theme.palette.primary.white};
  flex-direction: column;
  padding-bottom: 35px;
  margin-bottom: 40px;
  box-sizing: border-box;
  & > :last-child {
    margin-top: auto;
  }
`;

export const Title = styled.div`
  padding: 12px 16px 0 16px;
  ${FontStyles.h6Main};
  line-height: 160%;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    padding: 16px 16px 4px;
  }
`;

export const Top = styled.div`
  display: flex;
  padding: 16px 15px 16px;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  ${FontStyles.h5};
  justify-content: space-between;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 15px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    padding: 8px 16px;
    gap: 30px;
  }
`;

export const LeftWrapp = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  & > :nth-child(1) {
    margin-right: 7px;
    min-width: 259px;
  }
`;
export const LeftWrapFilter = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
`;

export const ItemCount = styled.div`
  //styleName: table/header;
  ${FontStyles.bodySmallGilroy};
  margin-right: 8px;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
  }
  & span {
    display: inline-block;
    min-width: 10px;
  }
`;

export const ScrollFilters = styled.div`
  display: flex;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 0;
  }
`;
