import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useCancelTransfer } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import {
  ButtonContainer,
  Container,
  CustomLargeButton,
  CustomModal,
  HeadText,
  Icon,
  StyledIconDelete,
  SubText,
} from '@components/ScheduleDeleteModal/ScheduleDeleteModal.styles';

const ScheduleDeleteModal = () => {
  const selectedTransaction = useBoundStore((state) => state.selectedTransaction);
  const setSelectedTransaction = useBoundStore((state) => state.setSelectedTransaction);
  const closeModal = useBoundStore((state) => state.closeModal);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const transfer = {
    cancellations: [
      { id: selectedTransaction ? selectedTransaction.transactions.externalId : 'cancel' },
    ],
  };

  const { mutate: cancelTransfer } = useCancelTransfer();

  const handleSubmit = () => {
    if (submitting) return;

    setSubmitting(true);

    cancelTransfer(transfer as CancelTransfer, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['scheduledData'],
          exact: true,
          refetchType: 'active',
        });
        setSelectedTransaction(null);
        setSubmitting(false);
        closeModal();
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <CustomModal>
      <Container>
        <Icon>
          <StyledIconDelete />
        </Icon>
        <HeadText>Cancel Payment</HeadText>
        <SubText>Are you sure you want to delete your scheduled payment?</SubText>
        <ButtonContainer>
          <CustomLargeButton
            disabled={submitting}
            onClick={handleSubmit}
            text="Cancel Payment"
            variant="negativeRed"
          />
          <CustomLargeButton
            onClick={closeModal}
            text="Close"
            variant="transparentBlack"
          />
        </ButtonContainer>
      </Container>
    </CustomModal>
  );
};

export default ScheduleDeleteModal;
