import React, { ChangeEvent, KeyboardEvent, MouseEvent, ReactNode, Ref } from 'react';
import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconLocked } from '@constants/icons';

interface Props {
  id?: string;
  placeholder: string;
  icon?: ReactNode;
  valid?: boolean;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  blurHandler?: () => void;
  inputMode?: 'numeric' | 'search' | 'email' | 'text';
  setValue?: (value: string) => void;
  value?: string;
  flipIcon?: boolean;
  locked?: boolean;
  autoFocus?: boolean;
  className?: string;
  editable?: boolean;
  ref?: Ref<HTMLInputElement>;
  autoComplete?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      placeholder,
      icon,
      valid = true,
      maxLength,
      onChange,
      onKeyDown,
      blurHandler,
      onClick,
      inputMode,
      flipIcon,
      locked,
      value = '',
      setValue,
      autoFocus,
      className,
      editable,
      autoComplete,
    },
    ref
  ) => {
    return (
      <TextInputContainer
        $value={value}
        $valid={valid}
        $icon={icon}
        $flip={flipIcon}
        $locked={locked}
        className={className}
        onClick={onClick}
      >
        {/* TODO refactor: separate field from container to enable restyling of both */}
        <Field
          autoComplete={autoComplete}
          ref={ref}
          id={id}
          $valid={valid}
          onKeyDown={(event) => {
            if (onKeyDown) onKeyDown(event);
          }}
          onBlur={() => {
            if (blurHandler) blurHandler();
          }}
          onChange={(event) => {
            if (editable || editable === undefined) {
              if (onChange) {
                onChange(event);
              }
              if (setValue) {
                setValue(event.target.value);
              }
            }
          }}
          placeholder={placeholder}
          inputMode={inputMode}
          maxLength={maxLength}
          className={locked ? 'locked' : ''}
          disabled={locked}
          value={value}
          autoFocus={autoFocus}
        />
        {locked ? <IconLocked /> : icon}
      </TextInputContainer>
    );
  }
);

export const TextInputContainer = styled.div<{
  $value: string;
  $valid: boolean;
  $icon?: ReactNode;
  $flip?: boolean;
  $locked?: boolean;
}>`
  display: flex;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  transition: border ease-in-out 300ms;
  max-height: 50px;
  box-sizing: border-box;
  border: ${(props) =>
    props.$valid
      ? '1px solid transparent'
      : `1px solid ${props.theme.palette.negative.lessContrastRed}`};
  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
  }
  ${(props) =>
    props.$icon &&
    css`
      & > :last-child {
        transition: ease-in-out 300ms;
        color: ${props.$locked
          ? props.theme.palette.greys.darkestGrey
          : props.$value
            ? props.$valid
              ? props.theme.palette.primary.black
              : props.theme.palette.negative.lessContrastRed
            : props.theme.palette.greys.darkGrey};
      }
    `}

  &:focus-within {
    border: 1px solid
      ${(props) => (props.$locked ? 'transparent' : props.theme.palette.primary.blue)};

    & > :last-child {
      transition: ease-in-out 300ms;
      color: ${(props) =>
        props.$locked
          ? props.theme.palette.greys.darkestGrey
          : props.$valid
            ? props.theme.palette.primary.blue
            : props.theme.palette.primary.black};
      ${(props) =>
        props.$flip &&
        css`
          transform: rotate(-180deg);
        `}
    }
  }

  ${(props) =>
    props.$locked &&
    css`
      color: ${props.theme.palette.greys.darkestGrey};
    `}
`;

export const Field = styled.input<{ $valid: boolean }>`
  /* TODO refactor: separate field from container to enable restyling of both */
  ${FontStyles.bodyMiddleGilroy};
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) =>
    props.$valid ? props.theme.palette.primary.black : props.theme.palette.primary.black};
  border: none;
  display: flex;
  align-items: center;
  flex: 1;
  transition: color ease-in-out 300ms;
  box-sizing: border-box;
  width: 100%;

  &:focus {
    color: ${(props) => props.theme.palette.primary.blue};
    outline: none;
  }

  &:disabled {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

TextInput.displayName = 'TextInput';

export default TextInput;
